import { BaseFilter, FacetValue } from './FacetValueBase';

export type InquiryTransitionFacetValuePayload = {
  pagePathCountBeforeSession?: number[];
};
export class InquiryTransitionFacetValue extends FacetValue<InquiryTransitionFacetValuePayload> {
  constructor(payload: InquiryTransitionFacetValuePayload) {
    super('InquiryTransitionFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.pagePathCountBeforeSession) {
      return {
        or: [
          {
            and: [
              {
                member: `ViewSession.pagePathCountBeforeSession`,
                operator: 'gte',
                values: [this.payload.pagePathCountBeforeSession[0].toString()],
              },
              {
                member: `ViewSession.pagePathCountBeforeSession`,
                operator: 'lt',
                values: [this.payload.pagePathCountBeforeSession[1].toString()],
              },
            ],
          },
        ],
      };
    }
  }

  isDefault(): boolean {
    return !this.payload.pagePathCountBeforeSession;
  }
}
