import LinkIcon from '@mui/icons-material/Link';
import {
  Button,
  TextField,
  MenuItem,
  Stack,
  Typography,
  Paper,
  Box,
} from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import { useChannelContext } from 'Components/Hooks/Channel';
import useOperator from 'Components/Hooks/Operator';
import useJobs from 'Components/Hooks/Queries/Jobs';
import useTranslation from 'Components/Hooks/Translate';
import { useWaitIncommingCall } from 'Components/Hooks/WaitIncommingCall';
import { useWaitCalls } from 'Components/Hooks/WatingCalls';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Job } from 'Utils/supabase';

interface BeforeSessionProps {
  onConnect: (job: Job, alias: string) => void;
}

/** セッション開始前の画面コンポーネント */
const BeforeSession = (props: BeforeSessionProps) => {
  const t = useTranslation('Session');
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const waitCalls = useWaitCalls();
  const channels = useChannelContext();
  const { pickUpCall } = useWaitIncommingCall(channels);

  const autoStart = (location.state as any)?.autoStart;
  const paramAlias = searchParams.get('alias');
  const paramJob = searchParams.get('job');

  // URLにjobとショートコードが含まれている場合は初期値とする
  const [alias, setAlias] = useState(paramAlias ?? '');
  const [selectedJob, setSelectedJob] = useState(paramJob ?? '');

  useEffect(() => {
    setAlias(paramAlias ?? '');
    setSelectedJob(paramJob ?? '');
  }, [paramAlias, paramJob]);

  // ユーザーに紐づくjobsを取得
  const operator = useOperator();
  const jobSelect = useJobs(operator?.id || '', {
    refetchOnWindowFocus: false,
  });

  const job = jobSelect.data?.find((x) => x.id === paramJob);
  const onConnect = props.onConnect;
  useEffect(() => {
    if (autoStart && job != null) {
      // 切断時に自動起動しないようにstateをクリア
      navigate(location.pathname, {});
      // 自動接続
      onConnect(job, paramAlias ?? '');
    }
  }, [autoStart, job, location.pathname, navigate, paramAlias, onConnect]);

  return (
    //権限を見る
    //アクションセンター・ポップアップを出すのは、ビデオコールONのユーザのみ。
    <>
      <Can crud="read" componentName="video_call">
        <Box sx={{ position: 'fixed' }}>
          <Paper sx={{ p: 2, m: 1 }} elevation={4}>
            <Stack spacing={2}>
              <Typography align="center">
                {waitCalls.length < 1
                  ? t('call.noQueue')
                  : t('call.incomingCall')}
              </Typography>
              <Button
                color="success"
                sx={{ bgcolor: 'success.light' }}
                disabled={waitCalls.length < 1}
                onClick={pickUpCall}
              >
                {t('call.answer')}
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Can>
      <Can crud="read" componentName="cobrowse">
        <form onSubmit={(event) => event.preventDefault()}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ p: 4, height: '90%' }}
            spacing={4}
          >
            <Typography>{t('controller.startSession')}</Typography>
            <Stack alignItems="stretch" spacing={3}>
              <TextField
                id="job-select"
                select
                label={t('controller.job')}
                value={selectedJob}
                onChange={(ev) => setSelectedJob(ev.target.value)}
                variant="outlined"
                sx={{ minWidth: 250 }}
              >
                {jobSelect.data?.map((job) => (
                  <MenuItem key={job.id} value={job.id}>
                    {job.name}
                  </MenuItem>
                )) ?? []}
              </TextField>
              <TextField
                // sx={{ width: '15ch' }}
                label={t('controller.alias')}
                value={alias}
                variant="outlined"
                onChange={(ev) => setAlias(ev.target.value)}
              />
            </Stack>
            <Button
              size="large"
              type="submit"
              title={t('controller.connect')}
              // disabled={selectedJob === '' || alias === ''}
              sx={{
                borderRadius: 200,
                flexDirection: 'column',
                minHeight: '50px',
                minWidth: '50px',
                aspectRatio: '1',
              }}
              onClick={() =>
                props.onConnect(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  jobSelect.data!.find((x) => x.id === selectedJob)!,
                  alias
                )
              }
            >
              <LinkIcon />
              {t('controller.connect')}
            </Button>
          </Stack>
        </form>
      </Can>
    </>
  );
};

export default BeforeSession;
