import usePageHistories from 'Components/Hooks/Queries/PageHistories';
import UAParser from 'ua-parser-js';

import useCustomer from '../Customer';

const useUserAgent = () => {
  const customerId = useCustomer()?.userId;
  const pageHistories = usePageHistories(customerId ?? '', false, {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
  });

  const data = (() => {
    const agent = pageHistories.data?.find((_x) => true)?.contextUserAgent;
    if (agent == null) return null;

    return new UAParser(agent).getResult();
  })();

  return { ...pageHistories, data };
};
export default useUserAgent;
