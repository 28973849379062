import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs as BreadcrumbMui, Link } from '@mui/material';
import LinkBehavior from 'Components/Atoms/LinkBehavior';

// TODO:依存関係が逆になってしまっている（Components → Providers）
// RouterとBreadcrumbsを共通のインスタンスで処理するため例外的に許容する。
import { useRouteConfig } from 'Providers/RouteProvider';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(useRouteConfig());
  return (
    <BreadcrumbMui
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <Link
          key={index}
          component={LinkBehavior}
          href={match.pathname}
          underline="hover"
          color="inherit"
        >
          {breadcrumb}
        </Link>
      ))}
      {/* <Typography color="text.primary">Dummy</Typography> */}
    </BreadcrumbMui>
  );
};

export default Breadcrumbs;
