/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
} from '@mui/material';
import DialogTitleWithClose from 'Components/Atoms/DialogTitleWithClose';
import {
  GadgetCategory,
  useGetGadgets,
  useGetGadgetType,
} from 'Components/Hooks/AccessGadgetFacetMaster';
import useLoading from 'Components/Hooks/Loading';
import useGadgets, {
  useDeleteGadget,
  useUpsertGadget,
} from 'Components/Hooks/Queries/Gadgets';
import useTranslation from 'Components/Hooks/Translate';
import GadgetList from 'Components/Molecules/Analytics/GadgetList';
import Enumerable from 'linq';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const EditGadget = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dashboardId = useParams<{ dashboardId?: string }>().dashboardId!;

  const isOpen = useLocation().pathname.endsWith('edit-gadgets');
  return (
    <Dialog open={isOpen} maxWidth="lg" onClose={() => navigate(dashboardId)}>
      {/* 開閉によってmount/unmountされるので別コンポーネントで実装 */}
      <EditGadgetModalContents />
    </Dialog>
  );
};
export default EditGadget;

const EditGadgetModalContents = () => {
  const navigate = useNavigate();
  const t = useTranslation('Analytics');

  // URLからパラメータ取得
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dashboardId = useParams<{ dashboardId?: string }>().dashboardId!;

  const gadgets = useGadgets(dashboardId, { refetchOnWindowFocus: false });
  const upsertGadget = useUpsertGadget();
  const deleteGadget = useDeleteGadget();
  useLoading(
    gadgets.isLoading || upsertGadget.isLoading || deleteGadget.isLoading
  );

  const [tabIdx, setTabIdx] = React.useState(0);
  const onChangeTab = (ev: any, newValue: number) => {
    setTabIdx(newValue);
  };

  const categories: GadgetCategory[] = ['chart', 'table', 'kpi', 'pie'];
  const getGadgets = useGetGadgets();
  const getGadgetType = useGetGadgetType();

  const nextY = Enumerable.from(gadgets.data ?? [])
    .select((x) => x.y + x.height)
    .defaultIfEmpty(0)
    .max();

  return (
    <>
      <DialogTitleWithClose onClose={() => navigate(dashboardId)}>
        {t('gadgets.edit.title')}
      </DialogTitleWithClose>
      <DialogContent>
        <Container sx={{ width: 1, height: 'calc(100vh - 300px)' }}>
          <Tabs
            sx={{
              width: '630px',
              m: 'auto',
            }}
            value={tabIdx}
            onChange={onChangeTab}
            centered={true}
          >
            <Tab label={t('gadgets.edit.label.chart')} key={categories[0]} />
            <Tab label={t('gadgets.edit.label.table')} key={categories[1]} />
            <Tab label={t('gadgets.edit.label.kpi')} key={categories[2]} />
            <Tab label={t('gadgets.edit.label.pie')} key={categories[3]} />
          </Tabs>
          <GadgetList
            alreadyExistings={gadgets.data?.map((g) => g.gadget) ?? []}
            gadgets={getGadgets(categories[tabIdx])}
            onClickAdd={(gadget) => {
              const type = getGadgetType(gadget);
              upsertGadget.mutateAsync({
                dashboardId,
                gadget,
                width: type === 'Sankey' ? 12 : 6,
                height: 3,
                x: type === 'Sankey' ? 0 : 0, // FIXME: Sankeyじゃないときは縦2列配置にしないといけない
                y: nextY,
              });
            }}
            onClickRemove={(gadget) =>
              deleteGadget.mutateAsync({ dashboardId, gadget })
            }
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          variant="shadow"
          color="secondary"
          onClick={() => navigate(dashboardId)}
        >
          {t('dashboardedit.close')}
        </Button>
      </DialogActions>
    </>
  );
};
