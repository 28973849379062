import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FacetValue } from 'Utils/FacetValue/FacetValueBase';
import { assertNotNull } from 'Utils/guard';

type FacetValues = Record<string, FacetValue<unknown>>;

// ブラウザローカルで保持するファセットの値をステート管理する。
// react-query（キャッシュ機能のみ）を使用し、変更時の部分更新を可能にしている。

const useLocalFacetValues = () => {
  const queryClient = useQueryClient();
  const keys = useMemo(() => ['Facet Value Context'], []);
  const query = useQuery<FacetValues>(keys, {
    initialData: {},
    enabled: false,
  });

  const update = useCallback(
    (value: FacetValue<unknown>) => {
      const state = queryClient.getQueryData<FacetValues>(keys);
      const newState = Object.assign({}, state);
      //isdefaultをチェックしてdefaultだったら消す
      if (value.isDefault()) {
        delete newState[value.type];
      } else newState[value.type] = value;
      queryClient.setQueryData<FacetValues>(keys, newState);
    },
    [queryClient, keys]
  );

  const updateAll = useCallback(
    (values: FacetValue<unknown>[]) => {
      const newState: Record<string, FacetValue<unknown>> = {};
      values.forEach((v) => (newState[v.type] = v));
      queryClient.setQueryData<FacetValues>(keys, newState);
    },
    [queryClient, keys]
  );

  assertNotNull(query.data); // 初期データとキャッシュだけで動くので必ずデータはある
  return { values: query.data, update, updateAll };
};
export default useLocalFacetValues;
