import useCustomer from '../Customer';
import usePageHistories from '../Queries/PageHistories';

export const useElapsedTime = () => {
  const customerId = useCustomer()?.userId;
  const pageHistories = usePageHistories(customerId ?? '', false, {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  const now = Date.now();
  return {
    ...pageHistories,
    data: (pageHistories.data?.[0]?.originalTimestamp ?? now) - now,
  };
};
