import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { Suspense, useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

import Loading from './Loading';

const GadgetFlame = (props: {
  name?: string;
  title: JSX.Element;
  children: JSX.Element;
}) => {
  const flameRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(flameRef, {});
  const [isVisible, setIsVisible] = React.useState(
    !!entry && !!entry.isIntersecting
  );
  React.useEffect(() => {
    if (isVisible) {
      return;
    } else {
      setIsVisible(!!entry && !!entry.isIntersecting);
    }
  }, [entry, isVisible]);
  const { name, title, children } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        boxShadow: (t) => t.shadows[2],
        backgroundColor: '#fff',
      }}
      ref={flameRef}
    >
      <Tooltip title={name ?? ''} placement="top-start" arrow>
        <Box
          sx={{
            width: '100%',
            height: '35px',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
            p: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {title}
        </Box>
      </Tooltip>
      <Box
        sx={{
          width: (t) => `calc(100% - ${t.spacing(1)})`,
          flex: 1,
          overflowY: 'auto',
          m: 1,
        }}
      >
        {(isVisible ?? true) && (
          <Suspense fallback={<Loading />}>{children}</Suspense>
        )}
      </Box>
    </Box>
  );
};
export default GadgetFlame;
