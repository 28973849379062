// このコードは web-ui、snippet で同じコードを使用しています。

import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import { Box } from '@mui/material';
import OptipassVideoSDK from '@optipass/video-sdk';
import { useEffect, useState } from 'react';

const VideoPresenter = (props: {
  track: MediaStreamTrack;
  mirror?: boolean;
  wide?: boolean;
  isVideoMuted?: boolean;
  isAudioMuted?: boolean;
}) => {
  const [element, setElement] = useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (element != null) {
      OptipassVideoSDK.attachMediaStream(props.track, element);
    }
  }, [element, props.track]);

  return (
    <Box sx={{ background: (t) => t.palette.grey[800] }} width={1} height={1}>
      <Box width={1} height={1} position="relative">
        {/* ビデオ表示。高さを維持するために非表示時はhiddenとする */}
        <Box
          visibility={props.isVideoMuted ? 'hidden' : 'visible'}
          width={1}
          height={1}
        >
          <video
            ref={(el) => {
              if (el == null) return;
              setElement(el);
            }}
            autoPlay
            muted // for iOS Safari
            playsInline // for iOS Safari
            style={{
              gridRow: 1,
              gridColumn: 1,
              width: '100%',
              height: '100%',
              display: 'block',
              // objectFit: 'fill', // videoの比率をいじる際に必要
              transform: `scale(${
                (props.mirror === true ? -1 : 1) *
                (props.wide === true ? 1.3333 : 1)
              },${1})`,
            }}
          />
        </Box>

        {
          // 真ん中にカメラミュートステータス表示
          props.isVideoMuted && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: 1,
                height: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: (t) => t.palette.common.white,
              }}
            >
              <VideocamOffOutlinedIcon sx={{ mx: 'auto' }} fontSize="large" />
            </Box>
          )
        }

        {
          //左下にマイクミュートステータス表示
          props.isAudioMuted != null && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: 1,
                height: 1,
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'end',
              }}
            >
              <Box display="grid">
                <Box
                  sx={{
                    color: (t) => t.palette.common.white,
                    gridRow: 1,
                    gridColumn: 1,
                    zIndex: 1,
                  }}
                >
                  {props.isAudioMuted ? (
                    <MicOffOutlinedIcon sx={{ display: 'block' }} />
                  ) : (
                    <MicOutlinedIcon sx={{ display: 'block' }} />
                  )}
                </Box>
                {props.isVideoMuted === false && (
                  <Box
                    sx={{
                      background: (t) => t.palette.common.black,
                      opacity: 0.5,
                      width: 1,
                      height: 1,
                      gridRow: 1,
                      gridColumn: 1,
                    }}
                  />
                )}
              </Box>
            </Box>
          )
        }
      </Box>
    </Box>
  );
};
export default VideoPresenter;
