// このコードは web-ui、snippet で同じコードを使用しています。

import Cookies from 'js-cookie';
import { useVideo } from 'Providers/VideoProvider';
import { useQuery, useQueryClient } from 'react-query';

//import { assertNotNull } from 'Utils/guard';
function assertNotNull<T>(value: T): asserts value is NonNullable<T> {
  if (value == null) throw new Error('value is null or undefined');
}

// useVideoParams は、VideoProviderの外でも使える。
// useVideoOperations は、VideoProviderの中でのみ使用可能。

// 部屋IDはuseQuery使う
const videoRoomIdKey = 'Video Room Id';

// マイクやカメラをクッキーに保持
// eslint-disable-next-line @typescript-eslint/naming-convention
const COOKIE_CAM = 'op-cam';
// eslint-disable-next-line @typescript-eslint/naming-convention
const COOKIE_MIC = 'op-mic';

// eslint-disable-next-line @typescript-eslint/naming-convention
const COOKIE_CAM_IS_MUTE = 'op-cam-mute';
// eslint-disable-next-line @typescript-eslint/naming-convention
const COOKIE_MIC_IS_MUTE = 'op-mic-mute';

export type VideoRoomParams = {
  roomId?: string;
  audioDevice?: string;
  videoDevice?: string;
  isAudioMute: boolean;
  isVideoMute: boolean;
};

const videoRoomParamsInitial: VideoRoomParams = {
  roomId: undefined,
  isAudioMute: Cookies.get(COOKIE_MIC_IS_MUTE) === 'true',
  isVideoMute: Cookies.get(COOKIE_CAM_IS_MUTE) === 'true',
  audioDevice: Cookies.get(COOKIE_MIC),
  videoDevice: Cookies.get(COOKIE_CAM),
};

const useVideoParams = () => {
  const query = useQuery<VideoRoomParams>([videoRoomIdKey], {
    initialData: videoRoomParamsInitial,
    enabled: false,
  });
  assertNotNull(query.data);
  return query.data;
};
export default useVideoParams;

export const useVideoOperations = () => {
  const queryClient = useQueryClient();
  const video = useVideo();
  const videoRoomParams = useVideoParams();

  const redux = (f: (params: VideoRoomParams) => VideoRoomParams) => {
    const oldValue =
      queryClient.getQueryData<VideoRoomParams>([videoRoomIdKey]) ??
      videoRoomParamsInitial;
    const newValue = f(oldValue);
    return queryClient.setQueryData<VideoRoomParams>(
      [videoRoomIdKey],
      newValue
    );
  };

  return {
    // ミュートは未指定時、前回の設定を保持
    join: (roomId: string, isAudioMute?: boolean, isVideoMute?: boolean) => {
      redux((old) => ({
        ...old,
        roomId,
        ...(isAudioMute == null
          ? {}
          : {
              isAudioMute,
            }),
        ...(isVideoMute == null
          ? {}
          : {
              isVideoMute,
            }),
      }));
      if (isAudioMute != null) {
        Cookies.set(COOKIE_MIC_IS_MUTE, isAudioMute ? 'true' : 'false');
      }
      if (isVideoMute != null) {
        Cookies.set(COOKIE_CAM_IS_MUTE, isVideoMute ? 'true' : 'false');
      }
    },

    exit: () => {
      redux((old) => ({
        ...old,
        roomId: undefined,
      }));
    },

    muteAudio: () => {
      video.sdk?.muteAudio();
      redux((old) => ({ ...old, isAudioMute: true }));
      Cookies.set(COOKIE_MIC_IS_MUTE, 'true');
    },
    unmuteAudio: () => {
      video.sdk?.unmuteAudio();
      redux((old) => ({ ...old, isAudioMute: false }));
      Cookies.set(COOKIE_MIC_IS_MUTE, 'false');
    },

    muteVideo: () => {
      video.sdk?.muteVideo();
      redux((old) => ({ ...old, isVideoMute: true }));
      Cookies.set(COOKIE_CAM_IS_MUTE, 'true');
    },
    unmuteVideo: () => {
      video.sdk?.unmuteVideo();
      redux((old) => ({ ...old, isVideoMute: false }));
      Cookies.set(COOKIE_CAM_IS_MUTE, 'false');
    },

    setAudioDevice: (deviceId: string | undefined) => {
      redux((old) => ({ ...old, audioDevice: deviceId }));
      if (deviceId != null) Cookies.set(COOKIE_MIC, deviceId);
      else Cookies.remove(COOKIE_MIC);

      video.sdk?.changeDevice({ audio: deviceId }).then(() => {
        if (videoRoomParams.isAudioMute) video.sdk?.muteAudio();
        if (videoRoomParams.isVideoMute) video.sdk?.muteVideo();
      });
    },
    setVideoDevice: (deviceId: string | undefined) => {
      redux((old) => ({ ...old, videoDevice: deviceId }));
      if (deviceId != null) Cookies.set(COOKIE_CAM, deviceId);
      else Cookies.remove(COOKIE_CAM);

      video.sdk?.changeDevice({ video: deviceId }).then(() => {
        if (videoRoomParams.isAudioMute) video.sdk?.muteAudio();
        if (videoRoomParams.isVideoMute) video.sdk?.muteVideo();
      });
    },
  };
};
