import { Agent } from '@optipass/sdk/lib/agent/agent';
import Loading from 'Components/Atoms/Loading';
import { AgentContext } from 'Components/Hooks/Agent';
import useOperator from 'Components/Hooks/Operator';
import usePermission from 'Components/Hooks/Permission';
import { env } from 'env';
import { ReactNode, useEffect, useState } from 'react';
import supabase, { Tenant, UserProfile } from 'Utils/supabase';

const agent = new Agent();

const AgentProvider = (props: { children: ReactNode }) => {
  const operatorInfo = useOperator();
  const permission = usePermission();

  const [preparedAgent, setPreparedAgent] = useState<Agent | undefined>();

  // 画面遷移時にエージェントセッションを切断する
  // TODO: 将来的に離脱機能が復活したらこの処理は修正する必要がある
  useEffect(() => {
    const handleBeforeUnload = (_ev: BeforeUnloadEvent) => {
      if (preparedAgent?.isSessionActive()) {
        preparedAgent?.requestEndSession();
        preparedAgent?.resetSdk();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [preparedAgent]);

  useEffect(() => {
    if (!operatorInfo || !permission || permission.isGuest()) return;
    let unmounted = false;
    (async () => {
      // ユーザーからテナントIDを取得
      const userProfileResult = await supabase
        .from<UserProfile>('user_profiles')
        .select('tenantId')
        .eq('id', operatorInfo.id);
      if (userProfileResult.error != null) {
        throw userProfileResult.error;
      }
      const tenantId = userProfileResult.data.find(() => true)?.tenantId;

      // テナントIDからトークンを取得
      let cdpToken = undefined as string | undefined;
      if (tenantId != null) {
        const tenantResult = await supabase
          .from<Tenant>('tenants')
          .select('cdpToken')
          .eq('id', tenantId);
        if (tenantResult.error != null) {
          throw tenantResult.error;
        }
        cdpToken = tenantResult.data.find(() => true)?.cdpToken ?? undefined;
        if (cdpToken === '') {
          cdpToken = undefined; // 空文字だとエラーになるので念のため無効化
        }
      }

      const cdpUrl = env.REACT_APP_CDP_URL;
      if (cdpToken == null) {
        console.info('No CDP token is set.');
      }

      if (cdpUrl != null && cdpToken != null) {
        await agent.startAccessContext(cdpUrl, cdpToken);
      }
      if (!unmounted) {
        // AgentにオペレータIDをセット
        agent.getContextDataAccessor.setUserInfo({
          options: { operator: operatorInfo.id },
        });
        setPreparedAgent(agent);
      }
    })();
    return () => {
      unmounted = true;
    };
  }, [operatorInfo, permission]);

  if (operatorInfo && permission) {
    return (
      <AgentContext.Provider value={preparedAgent}>
        {props.children}
      </AgentContext.Provider>
    );
  } else {
    return <Loading />;
  }
};
export default AgentProvider;
