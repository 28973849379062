import BackdropLoading from 'Components/Molecules/Global/BackdropLoading';
import ConfirmModal from 'Components/Molecules/Global/ModalProvider';
import Notification from 'Components/Molecules/Global/Notification';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OverlayContainerProps {}
const OverlayContainer = (_props: OverlayContainerProps) => {
  return (
    <>
      <ConfirmModal />
      <Notification />
      <BackdropLoading />
    </>
  );
};

export default OverlayContainer;
