import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import useConfirm from './Confirm';
import useTranslation from './Translate';

/** 削除ダイアログ表示を行う */
const useConfirmDelete = () => {
  const confirm = useConfirm();
  const t = useTranslation('Global');

  return async () => {
    return await confirm({
      message: t('confirm.deleteMessage'),
      approveMessage: t('confirm.deleteApprove'),
      approveColor: 'warning',
      approveIcon: <DeleteOutlineOutlinedIcon />,
      canCancel: true,
    });
  };
};

export default useConfirmDelete;
