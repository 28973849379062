import { Avatar, ListItemAvatar, SvgIcon } from '@mui/material';
import { useGetGadgetType } from 'Components/Hooks/AccessGadgetFacetMaster';
import { ReactComponent as Area } from 'Images/GadgetIcons/area.svg';
import { ReactComponent as Bar } from 'Images/GadgetIcons/bar.svg';
import { ReactComponent as Codegraph } from 'Images/GadgetIcons/codegraph.svg';
import { ReactComponent as Heatmap } from 'Images/GadgetIcons/heatmap.svg';
import { ReactComponent as Line } from 'Images/GadgetIcons/line.svg';
import { ReactComponent as Number } from 'Images/GadgetIcons/number.svg';
import { ReactComponent as Other } from 'Images/GadgetIcons/other.svg';
import { ReactComponent as Pie } from 'Images/GadgetIcons/pie.svg';
import { ReactComponent as Sanburst } from 'Images/GadgetIcons/sanburst.svg';
import { ReactComponent as Sankey } from 'Images/GadgetIcons/sankey.svg';
import { ReactComponent as Table } from 'Images/GadgetIcons/table.svg';
import { ReactComponent as WordCrowd } from 'Images/GadgetIcons/wordcrowd.svg';
import { Gadget } from 'Utils/supabase';

interface GadgetImageProps {
  gadget: Gadget;
}

const GadgetImage = (props: GadgetImageProps) => {
  const getGadgetType = useGetGadgetType();

  const selectImage = (g: Gadget) => {
    switch (getGadgetType(g)) {
      case 'TimelineChart':
        return <Line />;
      case 'Area':
        return <Area />;
      case 'BarChart':
        return <Bar />;
      case 'PieChart':
        return <Pie />;
      case 'Table':
        return <Table />;
      case 'Number':
        return <Number />;
      case 'Heatmap':
        return <Heatmap />;
      case 'Sankey':
        return <Sankey />;
      case 'BipartiteGraph':
        return <Other />;
      case 'CodeGraph':
        return <Codegraph />;
      case 'Wordcrowd':
        return <WordCrowd />;
      case 'Sanburst':
        return <Sanburst />;
      default:
        return <></>;
    }
  };
  return (
    <ListItemAvatar>
      <Avatar sx={{ backgroundColor: '#fff' }}>
        <SvgIcon>{selectImage(props.gadget)}</SvgIcon>
      </Avatar>
    </ListItemAvatar>
  );
};

export default GadgetImage;
