import { Query } from '@cubejs-client/core';
import { useQuery, UseQueryOptions } from 'react-query';
import cubejsApi from 'Utils/cubejs';

const key = 'Track Histories';

/** Cubejsから特定ユーザーのトラック情報リストを取得。
 * ソートはタイムスタンプの古い順
 */
const useTrackHistories = (
  customerId: string,
  options?: Omit<UseQueryOptions<TrackHistory[]>, 'queryKey' | 'queryFn'>
) => {
  const keys = [key, customerId];
  return useQuery<TrackHistory[]>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await getTrackHistories(customerId!);
    },
    options
  );
};

export default useTrackHistories;

export type TrackHistory = {
  originalTimestamp: number;
  event: string;
  element: TrackHistoryElement;
};

export type TrackHistoryElement = {
  id: string;
  cta: string;
  tagName: string;
  className: string;
  type: string;
  name: string;
  text: string;
  placeholder: string;
};

export const getTrackHistories = async (customerId: string) => {
  return (
    (await cubejsApi.load(trackHistoriesQueryBuilder(customerId)))
      .rawData()
      // データは扱いやすく加工して返す
      .map<TrackHistory>((x) => ({
        // タイムゾーン情報が外れて(それでいてUTCで)帰ってくるため、Zを付与してパース
        originalTimestamp: Date.parse(x['Tracks.originalTimestamp'] + 'Z'),
        event: x['Tracks.event'],
        // イベント種別ごとに別テーブルに配置されるので、扱いやすくまとめる
        element: {
          id: getFirst(
            x['Click.elementId'],
            x['Input.elementId'],
            x['Search.elementId'],
            x['Videoplay.elementId']
          ),
          cta: getFirst(x['Click.elementCta']),
          tagName: getFirst(
            x['Click.elementTagName'],
            x['Input.elementTagName'],
            x['Search.elementTagName'],
            x['Videoplay.elementTagName']
          ),
          className: getFirst(
            x['Click.elementClassName'],
            x['Input.elementClassName'],
            x['Search.elementClassName'],
            x['Videoplay.elementClassName']
          ),
          type: getFirst(x['Input.type'], x['Search.type']),
          name: getFirst(x['Input.elementName'], x['Search.elementName']),
          text: getFirst(x['Input.text'], x['Search.text']),
          placeholder: getFirst(
            x['Input.elementPlaceholder'],
            x['Search.elementPlaceholder']
          ),
        },
      }))
  );
};

const getFirst = (...x: string[]) => x.find((y) => (y ?? '') !== '') ?? '';

const trackHistoriesQueryBuilder = (customerId: string) =>
  ({
    measures: [],
    timeDimensions: [
      {
        dimension: 'Tracks.originalTimestamp',
      },
    ],
    order: [
      ['Tracks.originalTimestamp', 'asc'],
      ['Tracks.event', 'asc'],
    ],
    dimensions: [
      'Tracks.event',
      'Tracks.originalTimestamp',

      'Click.elementId',
      'Click.elementCta',
      'Click.elementTagName',
      'Click.elementClassName',

      'Input.elementId',
      'Input.elementTagName',
      'Input.elementClassName',
      'Input.elementPlaceholder',
      'Input.elementName',
      'Input.text',
      'Input.type',

      'Videoplay.elementId',
      'Videoplay.elementClassName',
      'Videoplay.elementTagName',

      'Search.elementId',
      'Search.elementClassName',
      'Search.elementTagName',
      'Search.elementName',
      'Search.text',
      'Search.type',
    ],
    limit: 5000,
    filters: [
      {
        member: 'Tracks.userId',
        operator: 'equals',
        values: [customerId],
      },
      {
        member: 'Tracks.event',
        operator: 'equals',
        values: ['click', 'input', 'search', 'play'],
      },
    ],
  } as Query);
