import { Query } from '@cubejs-client/core';
import { useQuery, UseQueryOptions } from 'react-query';
import cubejsApi from 'Utils/cubejs';

import { getTrackHistories, TrackHistory } from './TrackHistories';

const key = 'Page Histories';

/** Cubejsから特定ユーザーのページ情報リストを取得。
 * ソートはタイムスタンプの古い順
 */
const usePageHistories = (
  customerId: string,
  withTracks?: boolean,
  options?: Omit<UseQueryOptions<PageHistory[]>, 'queryKey' | 'queryFn'>
) => {
  const keys = [key, customerId, withTracks ?? false];
  return useQuery<PageHistory[]>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      const pageHistories = (
        await cubejsApi.load(pageHistoriesQueryBuilder(customerId))
      )
        .rawData()
        // データは扱いやすく加工して返す
        .map<PageHistory>((x) => ({
          userId: x['Pages.userId'],
          id: x['Pages.id'],
          title: x['Pages.title'],
          // タイムゾーン情報が外れて(それでいてUTCで)帰ってくるため、Zを付与してパース
          originalTimestamp: Date.parse(x['Pages.originalTimestamp'] + 'Z'),
          path: x['Pages.path'],
          url: x['Pages.url'],
          endTimestamp: 0,
          tracks: [],
          contextUserAgent: x['Pages.contextUserAgent'],
          anonymousId: x['Pages.anonymousId'],
        }));

      const handledPageHistories = handlePageHistories(
        customerId,
        pageHistories,
        withTracks
      );

      return handledPageHistories;
    },
    options
  );
};
export default usePageHistories;

export const usePageHistoriesDistinctUserByAnonymousId = (
  anonymousId: string,
  withTracks?: boolean,
  options?: Omit<UseQueryOptions<number>, 'queryKey' | 'queryFn'>
) => {
  const keys = [key, anonymousId, withTracks ?? false];
  return useQuery<number>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      const pageHistories = await cubejsApi.load(
        pageHistoriesDistinctUserByAnonymousIdQueryBuilder(anonymousId)
      );

      return pageHistories.rawData()[0]['Pages.uniqueUserCount'];
    },
    options
  );
};

export const handlePageHistories = async (
  customerId: string,
  pageHistories: PageHistory[],
  withTracks?: boolean
) => {
  const trackHistories = withTracks ? await getTrackHistories(customerId) : [];

  // 終了時間を代入（次ページの始まりか、最後のイベントか、startと同じ）
  for (const { x, i } of pageHistories.map((x, i) => ({ x, i }))) {
    x.endTimestamp = Math.max(
      x.originalTimestamp,
      pageHistories.length > i + 1
        ? pageHistories[i + 1].originalTimestamp - 1
        : trackHistories.slice(-1)[0]?.originalTimestamp ?? 0
    );
  }

  for (const page of pageHistories) {
    // ページ内のtrackを取得
    page.tracks = trackHistories.filter(
      (x) =>
        x.originalTimestamp >= page.originalTimestamp &&
        x.originalTimestamp <= page.endTimestamp
    );
  }

  return pageHistories;
};

export type PageHistory = {
  id: string;
  title: string;
  originalTimestamp: number;
  path: string;
  url: string;
  endTimestamp: number;
  tracks: TrackHistory[];
  contextUserAgent: string;
  userId: string;
  anonymousId: string;
};

const pageHistoriesQueryBuilder = (customerId: string) =>
  ({
    measures: [],
    timeDimensions: [
      {
        dimension: 'Pages.originalTimestamp',
      },
    ],
    order: [['Pages.originalTimestamp', 'asc']],
    dimensions: [
      'Pages.id',
      'Pages.path',
      'Pages.originalTimestamp',
      'Pages.title',
      'Pages.url',
      'Pages.userId',
      'Pages.contextUserAgent',
      'Pages.anonymousId',
    ],
    filters: [
      {
        member: 'Pages.userId',
        operator: 'equals',
        values: [customerId],
      },
    ],
  } as Query);

const pageHistoriesDistinctUserByAnonymousIdQueryBuilder = (
  anonymousId: string
) =>
  ({
    measures: ['Pages.uniqueUserCount'],
    order: [],
    limit: 5000,
    filters: [
      {
        member: 'Pages.anonymousId',
        operator: 'equals',
        values: [anonymousId],
      },
    ],
  } as Query);
