import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

// eslint-disable-next-line @typescript-eslint/naming-convention
const ITEM_HEIGHT = 48;
// eslint-disable-next-line @typescript-eslint/naming-convention
const ITEM_PADDING_TOP = 8;

interface SelectedGroupsContentItem {
  displayName: string;
  id: string;
}

interface SelectGroupsContentProps {
  title?: string;
  caption: string;
  onChange: (event: { target: { value: any } }) => void;
  items: SelectedGroupsContentItem[];
  selectedItemIds: string[];
  disabled?: boolean;
}

const SelectMultiple = (props: SelectGroupsContentProps) => {
  const { items, selectedItemIds, onChange } = props;

  const renderSelectedItem = (_selectedId: string) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {selectedItemIds.map((sjId) => {
          const item = items.find((j) => sjId === j.id);
          return (
            <Chip key={item?.id} label={item?.displayName} sx={{ m: '1px' }} />
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {props.title && (
        <Typography variant="h3" sx={{ fontSize: '16px' }}>
          {props.title}
        </Typography>
      )}

      <FormControl fullWidth>
        <InputLabel>{props.caption}</InputLabel>
        <Select
          label={props.caption}
          multiple
          value={selectedItemIds}
          disabled={props.disabled}
          onChange={onChange}
          // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selectedId: any) =>
            renderSelectedItem(selectedId as string)
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 'max-content',
                maxWidth: '90%',
                minWidth: '256px',
              },
            },
          }}
        >
          {items.map((j) => (
            <MenuItem key={j.id} value={j.id}>
              <Checkbox
                checked={
                  selectedItemIds.findIndex((itemId) => itemId === j.id) >= 0
                }
              />
              <ListItemText primary={j.displayName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectMultiple;
