import { BaseFilter, FacetValue } from './FacetValueBase';

export type InquiryStayingTimeFacetValuePayload = {
  inquiryTime?: number[];
};
export class InquiryStayingTimeFacetValue extends FacetValue<InquiryStayingTimeFacetValuePayload> {
  constructor(payload: InquiryStayingTimeFacetValuePayload) {
    super('InquiryStayingTimeFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.inquiryTime) {
      return {
        or: [
          {
            and: [
              {
                member: `ViewSession.inquiryTime`,
                operator: 'gte',
                values: [(this.payload.inquiryTime[0] * 60).toString()],
              },
              {
                member: `ViewSession.inquiryTime`,
                operator: 'lt',
                values: [(this.payload.inquiryTime[1] * 60).toString()],
              },
            ],
          },
        ],
      };
    }
  }

  isDefault(): boolean {
    return !this.payload.inquiryTime;
  }
}
