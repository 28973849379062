import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HistoryIcon from '@mui/icons-material/History';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Badge,
  Box,
  IconButton,
  IconButtonProps,
  styled,
  Stack,
  Tooltip,
} from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import LinkBehaviorBlank from 'Components/Atoms/LinkBehaviorBlank';
import { useConfirmDisconnectSession } from 'Components/Hooks/ConfirmDisconnectSession';
import useTranslation from 'Components/Hooks/Translate';
import { useWaitCalls } from 'Components/Hooks/WatingCalls';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavButtonProps extends IconButtonProps {
  href?: string;
  active?: boolean;
}

export const NavIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<NavButtonProps>(({ active, theme }) => {
  const commonStyle = {
    width: '40px',
    height: '40px',
    margin: '8px',
  };

  return active
    ? {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        boxShadow: '0px 2px 5px #3a3a3a',
        ...commonStyle,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
          cursor: 'default',
        },
      }
    : {
        color: theme.palette.common.white,
        ...commonStyle,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          backgroundColor: theme.palette.grey[900],
        },
      };
});

export type NavigationType =
  | 'session'
  | 'analytics'
  | 'settings'
  | 'account'
  | 'session-histories';

export type PathType =
  | 'session'
  | 'analytics'
  | 'settings'
  | 'account'
  | 'session-histories';

const GlobalNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const confirmDisconnectSession = useConfirmDisconnectSession();
  const t = useTranslation('Global');
  const waitCalls = useWaitCalls();

  const checkIsActive = (navType: PathType) =>
    location.pathname.split('/')[1] === navType;

  const handleClickNav = async (navigatedTo: NavigationType) => {
    if (await confirmDisconnectSession()) {
      navigate(navigatedTo);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Can componentName={['cobrowse', 'video_call']} crud="read">
        <Tooltip title={t('menu.session').toString()} placement="right" arrow>
          <NavIconButton
            onClick={() => handleClickNav('session')}
            active={checkIsActive('session')}
          >
            <Badge
              color="error"
              badgeContent={waitCalls.length}
              // eslint-disable-next-line @typescript-eslint/naming-convention
              sx={{ '& span': { right: '-5px' } }}
            >
              <HeadsetMicOutlinedIcon />
            </Badge>
          </NavIconButton>
        </Tooltip>
      </Can>
      <Can componentName={['dashboard', 'view']} crud="read">
        <Tooltip title={t('menu.analytics').toString()} placement="right" arrow>
          <NavIconButton
            onClick={() => handleClickNav('analytics')}
            active={checkIsActive('analytics')}
          >
            <DashboardOutlinedIcon />
          </NavIconButton>
        </Tooltip>
      </Can>
      <Can
        componentName={[
          'user',
          'job',
          'role',
          'contact_reason',
          'contact_reason_category',
        ]}
        crud="read"
      >
        <Tooltip title={t('menu.settings').toString()} placement="right" arrow>
          <NavIconButton
            onClick={() => handleClickNav('settings')}
            active={checkIsActive('settings')}
          >
            <SettingsOutlinedIcon />
          </NavIconButton>
        </Tooltip>
      </Can>
      <Can componentName="session_history" crud="read">
        <Tooltip
          title={t('menu.session-histories').toString()}
          placement="right"
          arrow
        >
          <NavIconButton
            onClick={() => handleClickNav('session-histories')}
            active={checkIsActive('session-histories')}
          >
            <HistoryIcon />
          </NavIconButton>
        </Tooltip>
      </Can>
      <Stack sx={{ mt: 'auto' }}>
        <NavIconButton
          LinkComponent={LinkBehaviorBlank}
          href={'https://doc.optipass.xyz/'}
        >
          <HelpOutlineOutlinedIcon />
        </NavIconButton>
        {/* <NavIconButton
          onClick={() => window.open('https://doc.optipass.xyz/', '_blank')}
        >
          <HelpOutlineOutlinedIcon />
        </NavIconButton> */}
      </Stack>
    </Box>
  );
};

export default GlobalNavigation;
