import { Box } from '@mui/material';
import GlobalNavigation from 'Components/Molecules/Global/GlobalNavigation';
import { ReactNode } from 'react';

const GlobalNavigationLayout = (props: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundColor: (t) => t.palette.primary.main,
          zIndex: 1300,
          p: 0.5,
        }}
      >
        <GlobalNavigation />
      </Box>
      <Box gridColumn={2}>{props.children}</Box>
    </Box>
  );
};
export default GlobalNavigationLayout;
