import { TimeDimensionGranularity } from '@cubejs-client/core';
import { Select, MenuItem } from '@mui/material';
import { useGetGadgetType } from 'Components/Hooks/AccessGadgetFacetMaster';
import { useUpdateGadgetGuranularities } from 'Components/Hooks/GadgetGuranularity';
import useTranslation from 'Components/Hooks/Translate';
import { Gadget } from 'Utils/supabase';
interface GranularitySwitcherProps {
  gadget: Gadget;
  onChange?: (format: string) => void;
}

const GranularitySwitcher = (props: GranularitySwitcherProps) => {
  const update = useUpdateGadgetGuranularities();
  const t = useTranslation('Analytics');
  const { gadget } = props;
  const getGadgetType = useGetGadgetType();
  switch (getGadgetType(gadget)) {
    case 'BarChart':
    case 'TimelineChart':
      return (
        <Select
          onChange={(e) =>
            update(props.gadget, e.target.value as TimeDimensionGranularity)
          }
          defaultValue="day"
          sx={{ fontSize: '0.8em' }}
        >
          <MenuItem value="day">{t('gadgets.granularity.daily')}</MenuItem>
          <MenuItem value="week">{t('gadgets.granularity.weekly')}</MenuItem>
          <MenuItem value="month">{t('gadgets.granularity.monthly')}</MenuItem>
        </Select>
      );
    default:
      return null;
  }
};
export default GranularitySwitcher;
