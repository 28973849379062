import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useViewdPagesNum } from 'Components/Hooks/SummaryQueries/ViewdPagesNum';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const ViewdPageNum = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useViewdPagesNum();

  return (
    <Panel
      icon={<RemoveRedEyeOutlinedIcon />}
      title={t('ViewdPageNum.title')}
      content={data?.toString() ?? ''}
      isLoading={isLoading}
    />
  );
};

export default ViewdPageNum;
