import moment from 'moment';

/** 全角なら最初の二文字、半角なら2語目までの単語の最初の字を表示*/
export const generateInitial = (name: string) => {
  // eslint-disable-next-line no-control-regex
  if (name.charAt(0).match(/^[^\x01-\x7E\xA1-\xDF]+$/)) {
    return name.substring(0, 2);
  } else {
    return name
      .split(' ')
      .map((d) => d.charAt(0))
      .join('')
      .substring(0, 2);
  }
};

export const generateUuid = () => {
  const chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case 'x':
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case 'y':
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join('');
};

// 秒の数値から H:MM:SS に変換する
export const secondsToTimeString = (seconds: number) => {
  const totalSeconds = Math.floor(seconds);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const zeroMinutes = ('0' + minutes).slice(-2);
  const zeroSeconds = ('0' + (totalSeconds % 60)).slice(-2);
  return `${hours}:${zeroMinutes}:${zeroSeconds}`;

  // test code
  //
  // for (const v of [
  //   0,
  //   1,
  //   59.9,
  //   60,
  //   119.9,
  //   120,
  //   3600 - 0.1,
  //   3600,
  //   3600 * 24 - 0.1,
  //   3600 * 24,
  //   3600 * 1000,
  // ]) {
  //   console.debug(v.toString(), secondsToTime(v));
  // }
  //
  // 0        → 0:00:00
  // 1        → 0:00:01
  // 59.9     → 0:00:59
  // 60       → 0:01:00
  // 119.9    → 0:01:59
  // 120      → 0:02:00
  // 3599.9   → 0:59:59
  // 3600     → 1:00:00
  // 86399.9  → 23:59:59
  // 86400    → 24:00:00
  // 3600000  → 1000:00:00
};

// granuralityの単位からフォーマット関数に変換
export const granularityToTickFormatter = (granularity: string) => {
  return granularity === 'month'
    ? (t: number) => moment(t).format('YYYY/MM')
    : undefined;
};

/** 日時を現地のTImezoneに調整したうえで、フォーマット*/
export const timeZoneFormatter = (
  datetime: any,
  format = 'yyyy/MM/DD HH:mm'
) => {
  const newValue = new Date(datetime as Date);
  newValue.setMinutes(
    (datetime as Date).getMinutes() - new Date().getTimezoneOffset()
  );
  return moment(newValue).format(format);
};
