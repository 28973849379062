/* eslint-disable @typescript-eslint/naming-convention */
// To visualize machines use https://xstate.js.org/viz/

export default {
  id: 'agent',
  initial: 'view',
  states: {
    view: {
      on: {
        START_CONTROL: 'waitApproval',
        START_SPOTLIGHT: 'spotlight',
        START_DRAWING: 'drawing',
        TERMINATE: 'terminated',
      },
    },
    waitApproval: {
      entry: 'requestControl',
      on: {
        START_VIEW: 'view',
        START_SPOTLIGHT: 'spotlight',
        START_DRAWING: 'drawing',
        CONTROL_APPROVE: 'control',
        CONTROL_REJECT: {
          target: 'view',
          actions: ['controlReject'],
        },
        TERMINATE: 'terminated',
      },
    },
    control: {
      entry: 'entryControl',
      exit: 'exitControl',
      on: {
        START_VIEW: 'view',
        START_SPOTLIGHT: 'spotlight',
        START_DRAWING: 'drawing',
        TERMINATE: 'terminated',
      },
    },
    spotlight: {
      entry: 'entrySpotlight',
      exit: 'exitSpotlight',
      on: {
        START_VIEW: 'view',
        START_CONTROL: 'waitApproval',
        START_DRAWING: 'drawing',
        TERMINATE: 'terminated',
      },
    },
    drawing: {
      entry: 'entryDrawing',
      exit: 'exitDrawing',
      on: {
        START_VIEW: 'view',
        START_CONTROL: 'waitApproval',
        START_SPOTLIGHT: 'spotlight',
        TERMINATE: 'terminated',
      },
    },
    terminated: {
      entry: 'terminate',
    },
  },
};
