import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import useUserAgent from 'Components/Hooks/SummaryQueries/UserAgent';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const DeviceInfo = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useUserAgent();

  let device = data?.device.model ?? '';
  if (device === '' && data?.os.name === 'Windows') {
    device = 'PC';
  }

  return (
    <Panel
      icon={<DevicesOutlinedIcon />}
      title={t('DeviceInfo.title')}
      content={device}
      isLoading={isLoading}
    />
  );
};
export default DeviceInfo;
