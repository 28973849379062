import { BaseFilter, FacetValue } from './FacetValueBase';

export type JobFacetValuePayload = { checkedJobIds: string[] };
export class JobFacetValue extends FacetValue<JobFacetValuePayload> {
  constructor(payload: JobFacetValuePayload) {
    super('JobFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.checkedJobIds.length === 0) {
      return undefined;
    } else {
      return {
        member: `SupabaseSessionHistories.jobId`,
        operator: 'equals',
        values: this.payload.checkedJobIds,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.checkedJobIds.length === 0;
  }
}
