import { CubeProvider } from '@cubejs-client/react';
import { CssBaseline } from '@mui/material';
import { useErrorLogHandler } from 'Components/Hooks/ErrorLogHandler';
import OverlayContainer from 'Components/Organisms/Global/OverlayContainer';
import AgentProvider from 'Providers/AgentProvider';
import ChannelProvider from 'Providers/ChannelProvider';
import PermissionProvider from 'Providers/PermissionProvider';
import RouteProvider from 'Providers/RouteProvider';
import { StatusProvider } from 'Providers/StatusProvider';
import ThemeProvider from 'Providers/ThemeProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReactSupabaseProvider } from 'react-supabase';
import cubejsApi from 'Utils/cubejs';
import supabase from 'Utils/supabase';

const queryClient = new QueryClient();

const App = () => {
  useErrorLogHandler();
  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <CssBaseline />
      <CubeProvider cubejsApi={cubejsApi}>
        <ReactSupabaseProvider value={supabase}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              {/* メッセージングが間に合わないことがあるため、OverlayContainerはRouterの前に定義する */}
              <OverlayContainer />
              <BrowserRouter>
                <PermissionProvider>
                  <AgentProvider>
                    <ChannelProvider>
                      <StatusProvider>
                        <RouteProvider />
                      </StatusProvider>
                    </ChannelProvider>
                  </AgentProvider>
                </PermissionProvider>
              </BrowserRouter>
            </ThemeProvider>
          </QueryClientProvider>
        </ReactSupabaseProvider>
      </CubeProvider>
    </div>
  );
};
export default App;
