import { Button, Stack, TextField, Typography } from '@mui/material';
import useLoading from 'Components/Hooks/Loading';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';
import { AppError } from 'Utils/error';
import supabase from 'Utils/supabase';
const Forgot = () => {
  const [email, setEmail] = useState('');
  const [isSend, setIsSend] = useState(false);
  const { load, isLoading } = useLoading();
  const t = useTranslation('Auth');

  const resetPassword = async () => {
    const { error } = await load(() =>
      supabase.auth.api.resetPasswordForEmail(email, {
        redirectTo:
          window.location.protocol + '//' + window.location.host + '/register',
      })
    );
    if (error != null)
      throw new AppError(
        error,
        'Sys',
        'SettingsPasswordUpdate',
        'UpdateOne',
        '01'
      );
    setIsSend(true);
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: 'center',
        width: '330px',
      }}
    >
      {!isSend ? (
        <Stack spacing={2}>
          <Typography>{t('forgot.inputemail')}</Typography>
          <TextField
            id="email"
            label={t('forgot.email')}
            value={email}
            variant="standard"
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <Button
            type="button"
            size="large"
            disabled={isLoading || email == null}
            onClick={() => resetPassword()}
          >
            {t('common.send')}
          </Button>
        </Stack>
      ) : (
        <Typography>{t('forgot.checkinbox')}</Typography>
      )}
    </div>
  );
};

export default Forgot;
