import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  TextField,
  Stack,
  Box,
  Tab,
  Tabs,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { usePermissionMasters } from 'Components/Hooks/Queries/PermissionMasters';
import { RoleFormRadioGroup } from 'Components/Molecules/Settings/RoleFormRadioGroup';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { groupBy } from 'Utils/array';
import { PermissionMaster } from 'Utils/supabase';

import useTranslation from '../../Components/Hooks/Translate';

export type RoleSection =
  | 'settings'
  | 'analytics'
  | 'sessions'
  | 'session-histories';

// 編集フォームの実装
const RoleForm = (props: {
  isNew: boolean;
  item: any;
  form: UseFormReturn<any, any>;
}) => {
  const t = useTranslation('Settings');
  const {
    register,
    formState: { errors },
  } = props.form;

  const [rolePermIds, setRolePermIds] = useState<string[]>(
    props.item.rolePermIds
  );
  const [section, setSection] = useState<RoleSection>('settings');

  // 権限マスターデータの取得
  const permMasters = usePermissionMasters();

  const onChangeRoleRadio = (beforeId: string, currentId: string) => {
    // beforeを削除し、currentを追加
    const newIds = rolePermIds
      .filter((id) => id !== beforeId)
      .concat([currentId]);
    setRolePermIds(newIds);
    props.form.setValue('rolePermIds', newIds);
  };

  return (
    <>
      <Stack spacing={3}>
        {props.isNew === false && (
          <TextField
            label={t('roles.field.id')}
            inputProps={{ readOnly: true }}
            {...register('id')}
          />
        )}
        <TextField
          required
          label={t('roles.field.name')}
          error={'name' in errors}
          helperText={errors.name?.message}
          {...register('roleName')}
        />

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={section}
              onChange={(ev, newValue) => setSection(newValue)}
              aria-label="lab API tabs example"
            >
              <Tab label={t('roles.tab.settings')} value="settings" />
              <Tab label={t('roles.tab.analytics')} value="analytics" />
              <Tab label={t('roles.tab.sessions')} value="sessions" />
              <Tab
                label={t('roles.tab.sessionHistories')}
                value="session-histories"
              />
            </Tabs>
          </Box>
          {groupBy(
            permMasters.data?.filter((perm) => {
              // 現在選択中のタブ内で表示させる権限のみフィルタ
              switch (section) {
                case 'settings':
                  return (
                    perm.object === 'user' ||
                    perm.object === 'role' ||
                    perm.object === 'job' ||
                    perm.object === 'contact_reason' ||
                    perm.object === 'contact_reason_category'
                  );
                case 'analytics':
                  return perm.object === 'dashboard' || perm.object === 'view';
                case 'sessions':
                  return (
                    perm.object === 'cobrowse' || perm.object === 'video_call'
                  );
                case 'session-histories':
                  return perm.object === 'session_history';
              }
              return false;
            }) ?? [],
            (x) => x.object
          )
            // オブジェクト毎にマッピングしコンポーネントへ変換
            .map(([object, perms]) => (
              <Accordion key={object}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {(() => {
                      switch (object) {
                        case 'user':
                          return t(`users.name`);
                        case 'role':
                          return t(`roles.name`);
                        case 'job':
                          return t(`jobs.name`);
                        case 'contact_reason':
                          return t(`contact_reasons.name`);
                        case 'contact_reason_category':
                          return t(`contact_reason_categories.name`);
                        case 'dashboard':
                          return t(`roles.settingContents.dashboard`);
                        case 'view':
                          return t(`roles.settingContents.view`);
                        case 'cobrowse':
                          return t(`roles.settingContents.cobrowse`);
                        case 'video_call':
                          return t(`roles.settingContents.videoCall`);
                        case 'session_history':
                          return t(`roles.settingContents.sessionHistory`);
                      }
                    })()}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DataGrid
                    sx={{ height: '250px' }}
                    headerHeight={0}
                    hideFooter
                    columns={[
                      {
                        field: 'action',
                        headerName: 'action',
                        valueGetter: (x) => {
                          switch (x.value) {
                            case 'read':
                              return t('roles.action.read');
                            case 'create':
                              return t('roles.action.create');
                            case 'update':
                              return t('roles.action.update');
                            case 'delete':
                              return t('roles.action.delete');
                            case 'execute':
                              return t('roles.action.execute');
                          }
                        },
                      },
                      {
                        field: 'perms',
                        headerName: '',
                        flex: 1,
                        renderCell: (data) => (
                          <RoleFormRadioGroup
                            values={Object.entries(data.value)}
                            onChange={onChangeRoleRadio}
                          />
                        ),
                      },
                    ]}
                    getRowId={(x) => x.action}
                    rows={groupBy(perms, (x) => x.action).map(
                      // まずはAction毎に分ける
                      ([action, perms]) => ({
                        action,
                        perms: perms.reduce((target, perm) => {
                          // 次にAcope毎に分ける
                          target[perm.scope] = {
                            id: perm.id,
                            checked:
                              rolePermIds.find(
                                (id: string) => id === perm.id
                              ) != null,
                          };
                          return target;
                        }, {} as { [key in PermissionMaster['scope']]: { id: string; checked: boolean } }),
                      })
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
      </Stack>
    </>
  );
};

export default RoleForm;
