import { CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <CircularProgress
      size={24}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      }}
    />
  );
};
export default Loading;
