import { yupResolver } from '@hookform/resolvers/yup';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Container, DialogActions, DialogContent, Button } from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import useTranslation from 'Components/Hooks/Translate';
import { useForm } from 'react-hook-form';

import NoReadPermission from './NoReadPermission';
import { SettingsTableConfig } from './SettingsConfig';

// 設定フォームのフォーム部分の Wrapper コンポーネント

const SettingsItemForm = (props: {
  table: SettingsTableConfig;
  isNew: boolean;
  item: any;
  onSave: (item: any) => void;
  onCancel: () => void;
  onDelete?: (itemId: string) => void;
}) => {
  // このフックスはDialogの内部コンポーネントで作成する必要があるのでここで定義。
  const form = useForm({
    defaultValues: props.item,
    resolver: yupResolver(props.table.validation),
  });
  const t = useTranslation('Settings');

  // const onSubmit: SubmitHandler<any> = (data) => {
  //   // バリデーションチェックOK！なときに行う処理を追加
  //   props.save(data);
  // };

  return (
    <>
      <DialogContent>
        <Can
          crud="read"
          componentName={props.table.resourceName}
          no={<NoReadPermission displayName={props.table.displayName} />}
        >
          <Container sx={{ pt: 4, pb: 3 }}>
            {props.table.renderItem(props.isNew, props.item, form)}
          </Container>
        </Can>
      </DialogContent>
      <DialogActions>
        <Button
          variant="shadow"
          onClick={props.onCancel}
          data-test-id={'cancel_' + props.table.resourceName}
        >
          {t('common.cancel')}
        </Button>
        <Can crud="update" componentName={props.table.resourceName}>
          <Button
            onClick={form.handleSubmit((x) => props.onSave(x))}
            startIcon={<SaveOutlinedIcon />}
            data-test-id={'save_' + props.table.resourceName}
          >
            {t('common.save')}
          </Button>
        </Can>
        {props.onDelete && (
          <Can crud="delete" componentName={props.table.resourceName}>
            <Button
              color="warning"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => props.onDelete!(props.item.id)}
              startIcon={<DeleteOutlineOutlinedIcon />}
              data-test-id={'delete_' + props.table.resourceName}
            >
              {t('common.delete')}
            </Button>
          </Can>
        )}
      </DialogActions>
    </>
  );
};

export default SettingsItemForm;
