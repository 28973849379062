import { BaseFilter, FacetValue } from './FacetValueBase';

export type StayingTimeFacetValuePayload = { timeOnPage?: number[] };
export class StayingTimeFacetValue extends FacetValue<StayingTimeFacetValuePayload> {
  constructor(payload: StayingTimeFacetValuePayload) {
    super('StayingTimeFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.timeOnPage) {
      return {
        or: [
          {
            and: [
              {
                member: `ViewSession.timeOnPage`,
                operator: 'gte',
                values: [(this.payload.timeOnPage[0] * 60).toString()],
              },
              {
                member: `ViewSession.timeOnPage`,
                operator: 'lt',
                values: [(this.payload.timeOnPage[1] * 60).toString()],
              },
            ],
          },
        ],
      };
    }
  }

  isDefault(): boolean {
    return !this.payload.timeOnPage;
  }
}
