import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useCTANum } from 'Components/Hooks/SummaryQueries/CTANum';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const CTANum = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useCTANum();

  return (
    <Panel
      icon={<AccessTimeIcon />}
      title={t('CTA.title')}
      content={`${data?.toString() ?? '0'}${t('CTA.unit')}`}
      isLoading={isLoading}
    />
  );
};

export default CTANum;
