import DropDown from 'Components/Atoms/DropDown';
import { Dashboard } from 'Utils/supabase';

const DashboardSelector = ({
  dashboards,
  currentDashbordId,
  disabled,
  onChangeDashboard,
}: {
  dashboards: Dashboard[];
  currentDashbordId?: string;
  disabled?: boolean;
  onChangeDashboard: (dashboard?: Dashboard) => void;
}) => {
  return (
    <DropDown
      disabled={disabled ?? false}
      id="select-dashboard"
      variant="outlined"
      size="small"
      defaultKey={currentDashbordId}
      data={dashboards.map((db) => {
        return { key: db.id, value: db.name };
      })}
      autoWidth
      onChange={(selected) =>
        onChangeDashboard(dashboards.find((db) => db.id === selected?.key))
      }
    />
  );
};

export default DashboardSelector;
