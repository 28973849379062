import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import DialogTitleWithClose from 'Components/Atoms/DialogTitleWithClose';
import useAgent from 'Components/Hooks/Agent';
import { useHandleError } from 'Components/Hooks/HandleError';
import { useNotify } from 'Components/Hooks/Notify';
import useOperator from 'Components/Hooks/Operator';
import useContactReasons from 'Components/Hooks/Queries/ContactReasons';
import useTranslation from 'Components/Hooks/Translate';
import ContactReasonCategory from 'Components/Molecules/Sessions/ContactReasonCategory';
import { useState } from 'react';

interface ContactReasonPaneProps {
  jobId: string;
  contactReasonModal: boolean;
  setContactReasonModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSentContactReasons: React.Dispatch<React.SetStateAction<boolean>>;
  afterSession?: boolean;
}

const ContactReasonPane = (props: ContactReasonPaneProps) => {
  const handleError = useHandleError();
  const notify = useNotify();
  const t = useTranslation('Session');
  const { data: contactReasons } = useContactReasons(props.jobId);
  const [checkedContactReasonId, setCheckedContactReasonId] = useState<
    string | undefined
  >();
  const [memo, setMemo] = useState<string | undefined>();

  const agent = useAgent();
  const opInfo = useOperator();

  const generateCheckboxes = () => {
    const categoryIds = Array.from(
      new Set(contactReasons?.map((r) => r.contactReasonCategoryId) ?? [])
    );
    return categoryIds.map((id) => {
      const childReasons =
        contactReasons?.filter((r) => r.contactReasonCategoryId === id) ?? [];
      return (
        <ContactReasonCategory
          key={id}
          title={childReasons[0].category.name}
          contactReasons={childReasons}
          checkedId={checkedContactReasonId}
          onCheck={setCheckedContactReasonId}
        />
      );
    });
  };
  //データを送信↓↓
  const sendData = async () => {
    if (!checkedContactReasonId) {
      notify(t('notification.noselectedEntity'), 'warning');
      return;
    }

    if (!agent || !opInfo) {
      //TODO: メッセージを辞書に登録してhandleErrorを呼ぶ
      handleError(
        'Unknown',
        {
          message: 'Agent SDK has not been established.',
        },
        false
      );
      return;
    }
    await agent.getContextDataAccessor.registerContactReason(
      checkedContactReasonId,
      memo
    );

    props.setSentContactReasons(true);
    notify(t('notification.save'), 'success');
  };

  return (
    <Dialog open={props.contactReasonModal}>
      <DialogTitleWithClose onClose={() => props.setContactReasonModal(false)}>
        {t('controller.reason')}
      </DialogTitleWithClose>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Stack>
          <Box sx={{ maxHeight: 'calc(100vh - 400px)', overflow: 'overlay' }}>
            <Box sx={{ p: 1, overflow: 'initial' }}>{generateCheckboxes()}</Box>
          </Box>
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              height: '80px',
            }}
          >
            <TextField
              sx={{
                width: '100%',
                mt: '10px',
              }}
              label={t('registerReason.memo')}
              value={memo}
              onChange={(ev) => setMemo(ev.target.value)}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        {props.afterSession ? (
          <></>
        ) : (
          <Button
            onClick={() => props.setContactReasonModal(false)}
            color="secondary"
          >
            {t('registerReason.back')}
          </Button>
        )}
        <Button sx={{ mx: 1 }} onClick={sendData}>
          {t('registerReason.send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactReasonPane;
