// このコードは web-ui、snippet で同じコードを使用しています。

import OptipassVideoSDK from '@optipass/video-sdk';
import { useEffect, useState } from 'react';

const AudioPresenter = (props: {
  track: MediaStreamTrack;
  deviceId?: string;
}) => {
  const [element, setElement] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (element != null) {
      OptipassVideoSDK.attachMediaStream(props.track, element);
    }
  }, [element, props.track]);

  // スピーカー選択はブラウザが吸収してしまっているのかうまくいかないので保留
  // useEffect(() => {
  //   const setSinkId = (element as any)?.setSinkId;
  //   if (props.deviceId != null && setSinkId != null) {
  //     console.info('setSinkId', setSinkId, props.deviceId);
  //     console.info('sinkId', (element as any).sinkId);

  //     // const setSinkId = (el as any)?.setSinkId as Promise<undefined>;
  //     // await setSinkId('default')

  //     // (async () => {
  //     //   const devices = await navigator.mediaDevices.enumerateDevices();
  //     //   console.info('devices', devices);
  //     // })();

  //     // setSinkId('default')
  //     //   .then(function () {
  //     //     console.info('Audio output device attached:', props.deviceId);
  //     //   })
  //     //   .catch(function (error: any) {
  //     //     console.error(error);
  //     //   });
  //   }
  // }, [element, props.track, props.deviceId]);

  return (
    <audio
      ref={(el) => {
        if (el == null) return;
        setElement(el);
      }}
      autoPlay
      // muted // for iOS Safari
      // playsInline // for iOS Safari
    />
  );
};
export default AudioPresenter;
