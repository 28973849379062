import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';

export const RoleFormRadioGroup = (props: {
  values: [string, { id: string; checked: boolean }][];
  onChange: (beforeId: string, currentId: string) => void;
}) => {
  const t = useTranslation('Settings');
  const [currentId, setCurrentId] = useState<string | null>(
    // チェックされている物があればそれを指定するが、なければ"none"のを選択中とする
    props.values.find((x) => x[1].checked)?.[1].id ??
      props.values.find(([scope]) => scope === 'none')?.[1].id ??
      null
  );

  return (
    <RadioGroup row value={currentId}>
      {props.values.map(([scope, { id }]) => (
        <FormControlLabel
          value={id}
          onChange={(ev, checked) => {
            if (checked) {
              const bef = currentId ?? '';
              const cur = (ev.target as any).value;
              setCurrentId(cur);
              props.onChange(bef, cur ?? '');
            }
          }}
          control={<Radio />}
          label={(() => {
            switch (scope) {
              case 'none':
                return t('roles.scope.none');
              case 'own':
                return t('roles.scope.own');
              case 'job':
                return t('roles.scope.job');
              case 'any':
                return t('roles.scope.any');
            }
          })()}
          // disabled={
          //   permission === 'ownJob' && !props.item.ownJob
          // }
          // sx={
          //   permission !== 'ownJob' || props.item.ownJob
          //     ? {}
          //     : {
          //         '& *, & .MuiFormControlLabel-label.Mui-disabled':
          //           {
          //             color: 'transparent',
          //           },
          //       }
          // }
          key={scope}
        />
      ))}
    </RadioGroup>
  );
};
