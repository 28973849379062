import { BaseFilter, FacetValue } from './FacetValueBase';

export type LocaleFacetValuePayload = { locales: string[] };
export class LocaleFacetValue extends FacetValue<LocaleFacetValuePayload> {
  constructor(payload: LocaleFacetValuePayload) {
    super('LocaleFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.locales.length === 0) {
      return undefined;
    } else {
      return {
        member: `Users.contextLocale`,
        operator: 'equals',
        values: this.payload.locales,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.locales.length === 0;
  }
}
