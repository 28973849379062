import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import { useInputNum } from 'Components/Hooks/SummaryQueries/InputNum';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const InputNum = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useInputNum();

  return (
    <Panel
      icon={<KeyboardAltOutlinedIcon />}
      title={t('InputNum.title')}
      content={(data?.toString() ?? '') + t('InputNum.unit')}
      isLoading={isLoading}
    />
  );
};

export default InputNum;
