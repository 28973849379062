import KeyboardIcon from '@mui/icons-material/Keyboard';
import MouseIcon from '@mui/icons-material/Mouse';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  ListItemButton,
  ListItemText,
  Typography,
  LinearProgress,
  Stack,
  Collapse,
  List,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { PageHistory } from 'Components/Hooks/Queries/PageHistories';
import useTranslation from 'Components/Hooks/Translate';
import React, { memo } from 'react';

import ActionLogTrack, { ActionLogTrackData } from './ActionLogTrack';

interface ActionLogPageProps {
  stayTime: number;
  pageLogData: PageHistory;
  currentStayRatio: number;
  pageStayRatio: number;
  data: ActionLogTrackData[];
  onTrackClick?: (value: ActionLogTrackData) => void;
}

/** アクションログの単一のページ要素を表現する
 * （最適化のためざっくりしたメモ化がされています）
 */
// eslint-disable-next-line react/display-name
const ActionLogPage = memo(
  (props: ActionLogPageProps) => {
    const {
      stayTime: duration,
      pageLogData,
      currentStayRatio,
      pageStayRatio,
    } = props;
    const t = useTranslation('Session');

    const [open, setOpen] = React.useState(false);

    const d = new Date(props.pageLogData.originalTimestamp);
    const date = `${d.getMonth() + 1}/${d.getDate()}`;
    const time = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;

    const getCount = (ev: string) =>
      props.data.filter((e) => e.event === ev).length;
    const clickCount = getCount('click');
    const inputCount = getCount('input');
    const searchCount = getCount('search');
    const playCount = getCount('play');

    const timeToString = (milliseconds: number) => {
      const sec = milliseconds / 1000;
      if (sec < 60) {
        return `${Math.floor(sec)} ${t('actionLog.seconds')}`;
      } else if (sec < 60 * 60) {
        return `${Math.floor(sec / 60)} ${t('actionLog.minutes')}`;
      } else {
        return `${Math.floor(sec / 60 / 60)} ${t('actionLog.hours')}`;
      }
    };

    return (
      <>
        <Tooltip
          title={
            <Stack>
              <Typography align="center" variant="caption">
                {date}
              </Typography>
              <Typography align="center" variant="caption">
                {time}
              </Typography>
            </Stack>
          }
          placement="right"
          arrow
        >
          <ListItemButton
            onClick={() => setOpen(!open)}
            sx={{
              borderTop: '1px solid #bcd5eb',
              backgroundColor: '#f1faff',
            }}
            alignItems="flex-start"
          >
            <Stack direction="column" sx={{ width: '100%' }}>
              <ListItemText primary={pageLogData.title} />
              <Box sx={{ ml: 3, mt: 0.5 }}>
                <Box
                  sx={{
                    display: 'grid',
                    gap: 1,
                    alignItems: 'center',
                    gridTemplateColumns: '1fr auto',
                  }}
                >
                  <LinearProgress
                    color="primary"
                    value={currentStayRatio * 100}
                    valueBuffer={pageStayRatio * 100}
                    variant="buffer"
                    sx={{
                      gridColumn: 1,
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      '& > .MuiLinearProgress-dashed': {
                        animation: 'none',
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ gridColumn: 2, minWidth: 35 }}
                    align="right"
                  >
                    {timeToString(duration)}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  {[
                    { count: clickCount, icon: <MouseIcon /> },
                    { count: inputCount, icon: <KeyboardIcon /> },
                    { count: searchCount, icon: <SearchIcon /> },
                    { count: playCount, icon: <PlayCircleFilledIcon /> },
                  ].map((x, i) => (
                    <Stack
                      direction={'row'}
                      sx={{
                        fontSize: 'small',
                        color: (t) =>
                          x.count > 0 ? t.palette.accent.main : 'text.disabled',
                      }}
                      key={i}
                    >
                      {x.icon}
                      <Typography align="center" sx={{ minWidth: '19px' }}>
                        {x.count}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </ListItemButton>
        </Tooltip>
        <Collapse
          in={open}
          timeout={'auto'}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <List disablePadding>
            {props.data.map((v, i) => (
              <ActionLogTrack
                key={i}
                data={v}
                onClick={() => props.onTrackClick?.(v)}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  },
  (a, b) =>
    a.data.length === b.data.length &&
    a.stayTime === b.stayTime &&
    Math.abs(a.currentStayRatio - b.currentStayRatio) < 1 / 20 && // ratio系は一定量以上変化したら
    Math.abs(a.pageStayRatio - b.pageStayRatio) < 1 / 20
);

export default ActionLogPage;
