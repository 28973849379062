import {
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import { useGetGadgetType } from 'Components/Hooks/AccessGadgetFacetMaster';
import {
  useUpdateSankeyGadgetPercentage,
  useUpdateSankeyManualLimit,
} from 'Components/Hooks/GadgetGuranularity';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';
import { Gadget } from 'Utils/supabase';

const PercentageSwitcher = (props: { gadget: Gadget }) => {
  const updateSankey = useUpdateSankeyGadgetPercentage();
  const updateSankeyManual = useUpdateSankeyManualLimit();
  const t = useTranslation('Analytics');
  const { gadget } = props;
  const getGadgetType = useGetGadgetType();
  const [sankeyManual, setSankeyManual] = useState(false);
  const [manualValue, setManualValue] = useState(1);

  switch (getGadgetType(gadget)) {
    case 'Sankey':
      return (
        <>
          <Select
            onChange={(e) => {
              if (e.target.value === 'manual') {
                setSankeyManual(true);
                updateSankey(gadget, -1);
              } else {
                setSankeyManual(false);
                updateSankey(
                  gadget,
                  typeof e.target.value === 'number'
                    ? e.target.value
                    : Number(e.target.value)
                );
              }
            }}
            defaultValue={100}
            sx={{ fontSize: '0.8em', ml: 'auto' }}
          >
            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse().map((n) => (
              <MenuItem value={n} key={n}>
                {n}%
              </MenuItem>
            ))}
            <MenuItem value={'manual'} key="manual">
              {t('gadgets.sankey.manual')}
            </MenuItem>
          </Select>
          {sankeyManual ? (
            <FormControl
              size="small"
              disabled={!sankeyManual}
              sx={{
                fontSize: '0.8em',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '& .MuiInputBase-root': {
                  width: '10em',
                  ml: 1,
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '& .MuiInputBase-input': {
                  py: 0,
                  px: 1,
                  height: '18px',
                  fontSize: '0.8em',
                },
              }}
            >
              <OutlinedInput
                type="number"
                endAdornment={
                  <InputAdornment
                    position="end"
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    sx={{ '& .MuiTypography-root': { fontSize: '0.7em' } }}
                  >
                    {t('gadgets.sankey.minimumCount')}
                  </InputAdornment>
                }
                onChange={(e) => {
                  updateSankeyManual(gadget, Number(e.target.value));
                  setManualValue(Number(e.target.value));
                }}
                defaultValue={manualValue}
              />
            </FormControl>
          ) : (
            <></>
          )}
        </>
      );
    default:
      return null;
  }
};
export default PercentageSwitcher;
