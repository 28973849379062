import { useCallback, useEffect, useRef } from 'react';

/** 子要素のスケールを自動調整する機能を提供 */
const useAutoScaleChild = (maxScale?: number) => {
  const keepParent = useRef<HTMLElement>();
  const keepChild = useRef<HTMLElement>();
  const keepParentStyles = useRef<any>({});
  const keepChildStyles = useRef<any>({});

  // サイズ変更イベント監視
  const resizeObserver = useRef<ResizeObserver>();

  // アンマウント時はサイズ監視を解放
  useEffect(() => {
    return () => {
      if (resizeObserver.current != null) {
        resizeObserver.current.disconnect();
      }
    };
  }, []);

  const adjustChildTransform = useCallback(() => {
    // 比率調整
    const child = keepChild.current;
    const parent = keepParent.current;
    if (
      child != null &&
      parent != null &&
      parent.clientWidth > 0 &&
      parent.clientHeight > 0 &&
      child.clientWidth > 0 &&
      child.clientHeight > 0
    ) {
      const widthRatio = parent.clientWidth / child.clientWidth;
      const heightRatio = parent.clientHeight / child.clientHeight;
      const minRatio = Math.min(widthRatio, heightRatio, maxScale ?? 1000000);
      const offsetX = (parent.clientWidth - child.clientWidth * minRatio) / 2;
      const offsetY = (parent.clientHeight - child.clientHeight * minRatio) / 2;
      child.style.transform = `translate(${offsetX}px,${offsetY}px) scale(${minRatio})`;
    }
  }, [maxScale]);

  const setAutoScaleChild = useCallback(
    (child: HTMLElement) => {
      const parent = child.parentElement ?? undefined;

      // 以前の parent と child のスタイルを戻す
      if (keepParent.current != null) {
        keepParent.current.style.width = keepParentStyles.current.width;
        keepParent.current.style.height = keepParentStyles.current.height;
        keepParent.current.style.overflow = keepParentStyles.current.overflow;
        keepParent.current.style.position = keepParentStyles.current.position;
      }
      keepParent.current = parent;

      if (keepChild.current != null) {
        keepChild.current.style.position = keepChildStyles.current.position;
        keepChild.current.style.transformOrigin =
          keepChildStyles.current.transformOrigin;
        keepChild.current.style.transform = keepChildStyles.current.transform;
      }
      keepChild.current = child;

      // サイズ監視を一度停止
      if (resizeObserver.current != null) {
        resizeObserver.current.disconnect();
        resizeObserver.current = undefined;
      }

      // parent と childがいれば新たに親子付け
      if (parent != null && child != null) {
        // 指定のdiv内に新たなコンテナdivを作り、そのdivは親の100%サイズで配置
        keepParentStyles.current = {
          width: parent.style.width,
          height: parent.style.height,
          overflow: parent.style.overflow,
          position: parent.style.position,
        };
        parent.style.width = '100%';
        parent.style.height = '100%';
        parent.style.overflow = 'hidden';
        parent.style.position = 'relative';

        // 内部用divを作り、親のサイズに影響せず実際のサイズで展開するよう配置
        keepChildStyles.current = {
          position: child.style.position,
          transformOrigin: child.style.transformOrigin,
          transform: child.style.transform,
        };
        child.style.position = 'absolute';
        child.style.transformOrigin = 'top left';

        resizeObserver.current = new ResizeObserver((_entries) =>
          adjustChildTransform()
        );
        resizeObserver.current.observe(parent);
        resizeObserver.current.observe(child);
      }
    },
    [adjustChildTransform]
  );

  return { setAutoScaleChild };
};

export default useAutoScaleChild;
