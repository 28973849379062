import { AttachFile } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, ListSubheader } from '@mui/material';

import FloatScrollBox from 'Components/Atoms/FloatScrollBox';
import useAgent from 'Components/Hooks/Agent';
import useTranslation from 'Components/Hooks/Translate';
import { sessionSideWidth } from 'Utils/constants';
import { AppError } from 'Utils/error';

interface FileShareBoxProps {
  onClose: () => void;
  sdkState: boolean;
}

const FileShareBox = (props: FileShareBoxProps) => {
  const agent = useAgent();
  const t = useTranslation('Session');

  async function sendImageDocHandler(files: FileList | null) {
    if (!props.sdkState) {
      return;
    }
    if (!files || files.length === 0) {
      return;
    }
    if (files.length > 1) {
      return;
    }

    switch (files[0].type) {
      case 'image/png':
      case 'image/gif':
      case 'image/jpeg':
      case 'application/pdf':
        await agent?.pushDocument(files[0]);
        return;
      default:
        throw new AppError(
          `Unsupported file format: ${files[0].type}`,
          'Biz',
          'SessionDocumentPush',
          'SendFile',
          '01'
        );
    }
  }

  return (
    <FloatScrollBox
      sx={{
        height: '100%',
        width: `${sessionSideWidth}px`,
        backgroundColor: (t) => t.palette.grey[200],
      }}
    >
      <ListSubheader
        component="div"
        sx={{
          boxShadow: '#cbcbcb 0 1px 3px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <IconButton
          sx={{ height: 'max-content' }}
          onClick={() => {
            props.onClose();
          }}
        >
          <CloseIcon
            sx={{ fontSize: 25, color: '#505050', verticalAlign: 'middle' }}
          />
        </IconButton>
        {t('controller.attach')}
      </ListSubheader>

      <Box sx={{ p: 1, my: 2, mx: 1 }}>
        <Button
          size="large"
          component="label"
          title="Local file sharing"
          fullWidth
        >
          <AttachFile />
          {t('attachFile.localFile')}
          <input
            type="file"
            hidden
            onChange={(event) => sendImageDocHandler(event.target.files)}
            accept=".png,.jpeg,.jpg,.gif,.pdf"
          />
        </Button>
      </Box>
      <Box
        sx={{ maxWidth: sessionSideWidth, bgcolor: 'background.paper', m: 2 }}
      >
        {/* <nav aria-label="folders">
            <List>
              <Divider variant="middle" />
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary="File 1" />
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary="File 2" />
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary="File 3" />
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
              <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="File 4" />
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
            </List>
          </nav> */}
      </Box>
    </FloatScrollBox>
  );
};

export default FileShareBox;
