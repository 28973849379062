import { RealtimeChannel } from '@supabase/supabase-js-new';
import { ChannelContext } from 'Components/Hooks/Channel';
import useOperator from 'Components/Hooks/Operator';
import useJobs from 'Components/Hooks/Queries/Jobs';
import React, { ReactNode, useMemo, useState } from 'react';
import { supabaseV2Rc } from 'Utils/supabase';

export type OperatorChannelConfig = {
  config: {
    presence: { key: string };
  };
};

// オペレータの所属するジョブごとにチャネルを作成し、コンテキストにセットする用のProvider
const ChannelProvider = (props: { children?: ReactNode }) => {
  const [channels, setChannels] = useState<RealtimeChannel[] | null>(null);
  const operatorId = useOperator()?.id;

  const jobsResult = useJobs(operatorId, {
    refetchOnWindowFocus: false,
    enabled: operatorId !== undefined,
  });
  if (jobsResult.error) throw jobsResult.error;

  const jobIds = useMemo(
    () => jobsResult.data?.map((x) => x.id),
    [jobsResult.data]
  );

  React.useEffect(() => {
    if (!operatorId || !jobIds) return;

    // ジョブ毎にチャネル作成
    const localChannels = jobIds.map((job) =>
      supabaseV2Rc.channel(job).subscribe()
    );

    setChannels(localChannels);

    return () => localChannels.forEach((x) => x.unsubscribe());
  }, [operatorId, jobIds]);

  return (
    <ChannelContext.Provider value={channels}>
      {props.children}
    </ChannelContext.Provider>
  );
};
export default ChannelProvider;
