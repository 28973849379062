import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import useContactReasonCategories, {
  useDeleteContactReasonCategories,
  useInsertContactReasonCategory,
  useUpdateContactReasonCategory,
} from 'Components/Hooks/Queries/ContactReasonCategories';
import useTranslation from 'Components/Hooks/Translate';
import { SettingsTableConfig } from 'Components/Organisms/Settings/SettingsConfig';
import SettingsTable from 'Components/Organisms/Settings/SettingsTable';
import { useMemo, useCallback } from 'react';
import { AppError } from 'Utils/error';
import i18nYup from 'Utils/i18nYup';
import { components } from 'Utils/Permission';
import supabase, { ContactReasonCategory, Job } from 'Utils/supabase';

import ContactReasonCategoryForm from './ContactReasonCategoryForm';

const ContactReasonCategoryTable = (props: { table: SettingsTableConfig }) => {
  const result = useContactReasonCategories({ refetchOnWindowFocus: false });
  if (result.isError) {
    throw new AppError(
      result.error,
      'Sys',
      'SettingsContactReasonCategory',
      'ReadMany',
      '01'
    );
  }

  return (
    <SettingsTable
      table={props.table}
      items={result.data ?? []}
      isLoading={result.isFetching}
    />
  );
};

const ContactReasonCategoryBreadcrumb = (props: { id: string }) => (
  <>
    {useContactReasonCategories({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }).data?.find((x) => x.id === props.id)?.name ?? ''}
  </>
);
const useContactReasonCategoriesConfig: () => SettingsTableConfig = () => {
  const t = useTranslation('Settings');
  const insertItem = useInsertContactReasonCategory();
  const updateItem = useUpdateContactReasonCategory();
  const deleteItems = useDeleteContactReasonCategories();
  const loadToItem = useCallback(async (item: any) => {
    // ジョブ一覧取得
    const jobsResult = await supabase.from<Job>('jobs').select().order('name');
    if (jobsResult.error != null) {
      throw new AppError(
        jobsResult.error,
        'Sys',
        'SettingsContactReasonCategory',
        'ReadOne',
        '04'
      );
    }
    item.jobs = jobsResult.data.map((x) => ({
      id: x.id,
      displayName: x.name,
    }));
  }, []);

  return useMemo<SettingsTableConfig>(
    () => ({
      name: 'contact-reason-categories',
      resourceName: components.ContactReasonCategory,
      displayName: t('contact_reason_categories.name'),
      icon: <CategoryOutlinedIcon />,
      columns: [
        {
          field: 'name',
          headerName: t('contact_reason_categories.field.name'),
          flex: 1,
          minWidth: 200,
        },
        {
          field: 'jobs',
          headerName: t('contact_reason_categories.field.job'),
          width: 200,
          valueGetter: (params) => params.value.name,
          link: (item: any) => `jobs/${item.jobId}`,
        },
        {
          field: 'createdAt',
          headerName: t('common.field.createdAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
        {
          field: 'updatedAt',
          headerName: t('common.field.modifiedAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
      ],
      getItem: async (id) => {
        const result = await supabase
          .from<ContactReasonCategory>('contact_reason_categories')
          .select()
          .eq('id', id)
          .maybeSingle();
        if (result.error != null) {
          throw new AppError(
            result.error,
            'Sys',
            'SettingsContactReasonCategory',
            'ReadOne',
            '01'
          );
        }
        if (result.data == null) {
          throw new AppError(
            `Not found category id: ${id}`,
            'Biz',
            'SettingsContactReasonCategory',
            'ReadOne',
            '02'
          );
        }
        await loadToItem(result.data);
        return result.data;
      },
      getDefault: async () => {
        const item = { name: '' };
        await loadToItem(item);
        return item;
      },
      renderList: (table) => <ContactReasonCategoryTable table={table} />,
      renderItem: (isNew, item, form) => (
        <ContactReasonCategoryForm isNew={isNew} item={item} form={form} />
      ),
      renderBreadcrumb: (id) => <ContactReasonCategoryBreadcrumb id={id} />,
      validation: i18nYup.object({
        name: i18nYup
          .string()
          .label(t('contact_reason_categories.field.name'))
          .required()
          .max(255),
        jobId: i18nYup
          .string()
          .label(t('contact_reason_categories.field.job'))
          .required(),
      }),
      insertItem: async (item: any) => {
        await insertItem.mutateAsync(item);
      },
      updateItem: async (item: any) => {
        await updateItem.mutateAsync(item);
      },
      deleteItems: async (items: any[]) => {
        await deleteItems.mutateAsync(items);
      },
    }),
    [t, loadToItem, insertItem, updateItem, deleteItems]
  );
};

export default useContactReasonCategoriesConfig;
