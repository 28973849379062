import { Button, Link, TextField, Typography, Stack, Box } from '@mui/material';
import useLoading from 'Components/Hooks/Loading';
import useTranslation from 'Components/Hooks/Translate';
import logo from 'Images/Optipass.png';
import { useState } from 'react';
import { AppError } from 'Utils/error';
import supabase from 'Utils/supabase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const t = useTranslation('Auth');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const { load } = useLoading();

  const login = async () => {
    if (!email) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
    if (!password) {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
    }
    if (!email || !password) {
      return;
    }

    const { error } = await load(
      async () => await supabase.auth.signIn({ email, password })
    );
    if (error != null) {
      if (error.status === 400)
        throw new AppError(error, 'Biz', 'ProfileLogin', 'Login', '01');
      else throw new AppError(error, 'Sys', 'ProfileLogin', 'Login', '02');
    }

    // 認証情報などを再読み込みさせるため、useMoveToではなく再ロードさせる
    window.location.href = '/';
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: 'center',
        width: '400px',
      }}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={(event) => event.preventDefault()}
      >
        <Stack spacing={3} alignItems="center">
          <img
            src={logo}
            alt="Optipass"
            style={{ width: '320px', height: '320px' }}
          />
          <Stack spacing={2} sx={{ width: '190px' }}>
            <TextField
              id="email"
              label={t('login.Email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="standard"
              error={errorEmail}
              helperText={errorEmail ? t('login.EmailRequired') : ''}
            />
            <TextField
              type="password"
              id="password"
              label={t('common.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="standard"
              error={errorPassword}
              helperText={errorPassword ? t('login.PasswordRequired') : ''}
            />
          </Stack>
          <Stack spacing={1} sx={{ width: '215px' }}>
            <Button type="submit" onClick={login}>
              <Typography variant="body2">{t('login.signin')}</Typography>
            </Button>
            <Typography>
              <Link href="/forgot">{t('login.forgot')}</Link>
            </Typography>
          </Stack>
        </Stack>
      </form>
      <Box sx={{ mx: 'auto', pt: 2 }}>
        <Typography variant="caption">
          {t('login.termsOfServiceConfirmFormer')}
        </Typography>
        <Link
          variant="caption"
          href={t('login.termsOfServiceURL')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('login.termsOfService')}
        </Link>
        <Typography variant="caption">
          {t('login.termsOfServiceConfirmLatter')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
