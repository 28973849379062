import { BaseFilter, FacetValue } from './FacetValueBase';

export type CTAFacetValuePayload = { CTAs: string[] };
export class CTAFacetValue extends FacetValue<CTAFacetValuePayload> {
  constructor(payload: CTAFacetValuePayload) {
    super('CTAFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.CTAs.length === 0) {
      return undefined;
    } else {
      return {
        member: `Click.elementCta`,
        operator: 'equals',
        values: this.payload.CTAs,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.CTAs.length === 0;
  }
}
