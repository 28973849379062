import { BaseFilter, FacetValue } from './FacetValueBase';

export type TransitionFacetValuePayload = { pagePathCount?: number[] };
export class TransitionFacetValue extends FacetValue<TransitionFacetValuePayload> {
  constructor(payload: TransitionFacetValuePayload) {
    super('TransitionFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.pagePathCount) {
      return {
        or: [
          {
            and: [
              {
                member: `ViewSession.pagePathCount`,
                operator: 'gte',
                values: [this.payload.pagePathCount[0].toString()],
              },
              {
                member: `ViewSession.pagePathCount`,
                operator: 'lt',
                values: [this.payload.pagePathCount[1].toString()],
              },
            ],
          },
        ],
      };
    }
  }

  isDefault(): boolean {
    return !this.payload.pagePathCount;
  }
}
