import { BaseFilter, FacetValue } from './FacetValueBase';

export type SessionTimeFacetValuePayload = { sessionTime?: number[] };
export class SessionTimeFacetValue extends FacetValue<SessionTimeFacetValuePayload> {
  constructor(payload: SessionTimeFacetValuePayload) {
    super('SessionTimeFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.sessionTime) {
      return {
        or: [
          {
            and: [
              {
                member: `ViewSession.sessionTime`,
                operator: 'gte',
                values: [(this.payload.sessionTime[0] * 60).toString()],
              },
              {
                member: `ViewSession.sessionTime`,
                operator: 'lt',
                values: [(this.payload.sessionTime[1] * 60).toString()],
              },
            ],
          },
        ],
      };
    }
  }

  isDefault(): boolean {
    return !this.payload.sessionTime;
  }
}
