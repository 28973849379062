import useOperator from 'Components/Hooks/Operator';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import supabase, {
  Dashboard,
  Job,
  JobDashboard,
  UserProfile,
} from 'Utils/supabase';
import { definitions } from 'Utils/types';

export type DashboardWithJob = Dashboard & {
  jobs: Job[];
};

const key = 'Dashboards';

const useDashboards = (
  options?: Omit<UseQueryOptions<DashboardWithJob[]>, 'queryKey' | 'queryFn'>
) => {
  const operator = useOperator();
  return useQuery<DashboardWithJob[]>(
    key,
    async () => {
      if (!operator) throw new Error('Unexpected undefined operator object');
      console.info('Query: ' + [key]);

      const userId = operator.id;
      const user = await supabase
        .from<UserProfile>('user_profiles')
        .select()
        .eq('id', userId);
      if (!user.data || user.error) throw user.error;

      const defaultDashboardId = user.data[0].defaultDashboardId;
      const { data, error } = await supabase
        .from<Dashboard>('dashboards')
        .select()
        .order('name');
      // console.table(data);
      if (!data || error) throw error;
      // 重複はない

      const myDashboards = getMyDashboards(data, userId, defaultDashboardId);
      // ジョブ&ダッシュボードリレーションを取得
      const jds = await supabase.from<JobDashboard>('jobs_dashboards').select();
      if (jds.error) throw jds.error;
      // ジョブ情報を取得
      const jobs = await supabase.from<Job>('jobs').select();
      if (jobs.error) throw jobs.error;
      // ジョブ情報を追加
      const dashboards = myDashboards.map((d) => {
        const { ...other } = d;
        return {
          jobs: jobs.data.filter((j) =>
            jds.data.some((jd) => jd.jobId === j.id && jd.dashboardId === d.id)
          ),
          default: d.id === defaultDashboardId,
          ...other,
        };
      }) as DashboardWithJob[];
      return dashboards;
    },
    options
  );
};
export default useDashboards;

export const getMyDashboards = (
  data: Dashboard[],
  userId: string,
  defaultDashboardId?: string
) => {
  // 自分が作成したものを上に並べる
  const myDashboards = data.filter((u) => u.userId === userId);
  const otherDashboards = data.filter((u) => u.userId !== userId);
  myDashboards.push(...otherDashboards);

  const dashboardList: Dashboard[] = [];

  myDashboards.forEach((d) => {
    if (d.id !== defaultDashboardId) {
      dashboardList.push(d);
    } else {
      //デフォルトダッシュボードを一番上に表示する。
      dashboardList.unshift(d);
    }
  });
  return dashboardList;
};

export const useInsertDashboard = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: {
      name: string;
      jobIds: string[];
      isDefault: boolean;
      userId: string;
    }) => {
      const { userId, ...data } = item;
      const { data: dbData, error: dbError } = await dbAccessor.insertDashboard(
        data,
        userId
      );
      console.info('insertDashboard', dbData);
      if (dbError != null || !dbData) throw dbError;
      if (data.jobIds.length >= 1) {
        const { error: jdError } = await dbAccessor.insertJobDashboard(
          data.jobIds,
          dbData?.[0].id
        );
        console.info('insertJobDashboard', dbData);
        if (jdError != null) throw jdError;
      }
      return dbData;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
      // トランザクションがいまいちなので、エラー時も再取得
      onError: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useUpdateDashboard = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: {
      name: string;
      jobIds: string[];
      isDefault: boolean;
      id: string;
      userId: string;
    }) => {
      const { userId, ...data } = item;
      const { data: dbData, error: dbError } = await dbAccessor.updateDashboard(
        data,
        userId
      );

      if (dbError != null) throw dbError;
      const { error: jdError } = await dbAccessor.updateJobDashboard(
        data.jobIds,
        dbData[0].id
      );

      if (jdError != null) throw jdError;
      return dbData;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
      // トランザクションがいまいちなので、エラー時も再取得
      onError: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useDeleteDashboards = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (itemIds: string[]) => {
      const result = await supabase
        .from('dashboards')
        .delete()
        .in('id', itemIds);
      console.info('deletedashboard', result);

      if (result.error != null || result.data.length === 0) throw result.error;
      return result.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

type UserProfileEx = Omit<
  definitions['user_profiles'],
  'defaultDashboardId'
> & {
  defaultDashboardId?: string | null;
};
type DashboardWithDefault = Dashboard & {
  default?: boolean;
};

export type DashboardAddProfile = {
  name: string;
  jobIds: string[];
  isDefault: boolean;
};
export type DashboardEditProfile = DashboardAddProfile & {
  id: string;
};

const dbAccessor = {
  insertDashboard: async (data: DashboardAddProfile, userId: string) => {
    //insertを一番上に移動。疑問：insert reternしていたけどしなくした。だいじょうぶ？
    const { data: dashboard, error } = await supabase
      .from<DashboardWithDefault>('dashboards')
      .insert([{ name: data.name }]);

    if (data.isDefault) {
      //user_profiles.defaultdashboardIdを当該IDで書き換える
      const { error: upError } = await supabase
        .from<UserProfileEx>('user_profiles')
        .update({
          defaultDashboardId: dashboard?.[0].id,
        })
        .match({ id: userId });

      if (upError && (upError as any).length !== 0) {
        return Promise.resolve({ data: dashboard, error: upError });
      }
    }
    return { data: dashboard, error };
  },
  insertJobDashboard: async (jobIds: string[], dashboardId: string) => {
    return await supabase.from<JobDashboard>('jobs_dashboards').insert(
      jobIds.map((jid) => {
        return {
          dashboardId,
          jobId: jid,
        };
      })
    );
  },
  updateDashboard: async (data: DashboardEditProfile, userId: string) => {
    const { name, isDefault, id } = data;
    //isDefault Onであれば、user_profiles defaultDashboardId にidを設定。
    if (isDefault) {
      const { data: upData, error } = await supabase
        .from<UserProfileEx>('user_profiles')
        .update({
          defaultDashboardId: id,
        })
        .match({ id: userId });

      if (!upData || error) {
        return Promise.resolve({ data: null, error });
      }
    } else {
      //TODO: !isDefault でなおかつ、デフォルトダッシュボード設定がされていた場合は解除する
      const { data: profiles, error: userError } = await supabase
        .from<UserProfileEx>('user_profiles')
        .select()
        .eq('id', userId.toString())
        .single();
      if (userError) {
        throw userError;
      }

      if (profiles.defaultDashboardId === id) {
        const { data: upData, error } = await supabase
          .from<UserProfileEx>('user_profiles')
          .update({
            defaultDashboardId: null,
          })
          .match({ id: userId });

        if (!upData || error) {
          return Promise.resolve({ data: null, error });
        }
      }
    }

    //defaultフィールドはなくなったのでアップデート対象から削除
    return await supabase
      .from<DashboardWithDefault>('dashboards')
      .update({ name })
      .match({ id });
  },
  updateJobDashboard: async (jobIds: string[], dashboardId: string) => {
    const { data, error } = await supabase
      .from<JobDashboard>('jobs_dashboards')
      .delete()
      .match({ dashboardId });

    if (!data || error) {
      return Promise.resolve({ data, error });
    }

    if (jobIds.length <= 0) {
      return Promise.resolve({ data: [], error: null });
    } else {
      return await supabase.from<JobDashboard>('jobs_dashboards').insert(
        jobIds.map((jid) => {
          return {
            dashboardId,
            jobId: jid,
          };
        })
      );
    }
  },
};

// export const useDashboard = (id: string, refetchInterval?: number) =>
//   useQuery(
//     ['dashboards', id],
//     async () => {
//       const { data, error } = await supabase
//         .from<Dashboard>('dashboards')
//         .select()
//         .eq('id', id)
//         .single();
//       if (error) throw error;
//       return data;
//     },
//     {
//       refetchOnMount: refetchInterval == null,
//       refetchInterval: refetchInterval,
//     }
//   );
