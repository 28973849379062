// eslint-disable-next-line @typescript-eslint/no-var-requires

import { UserInfo } from '../../common/common-types';

export default class ContextDataController {
  protected readonly cdpConnector = require('rudder-sdk-js');
  protected readonly userActionEvName = 'actions';

  /**
   * Start Collecting Context Data
   *
   * @param token - The session token to connect CDP
   */
  protected initialize(dataServer: string, token: string) {
    return new Promise<void>((resolve) => {
      this.cdpConnector.load(token, dataServer);
      this.cdpConnector.ready(() => {
        resolve();
      });
    });
  }

  /**
   * Wrapper method for identify api
   * can be called to register call reason, too
   * @param data
   * @returns
   */
  protected registerUserInfo(data: UserInfo) {
    return new Promise<void>((resolve) => {
      this.cdpConnector.identify(data.userId, data.traits, data.options, () => {
        resolve();
      });
    });
  }
}
