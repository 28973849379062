import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AppBar, Box, styled, Toolbar } from '@mui/material';
import FloatScrollBox from 'Components/Atoms/FloatScrollBox';
import SidePaneControllButton from 'Components/Atoms/SidePaneControllButton';
import useDashboards from 'Components/Hooks/Queries/Dashboards';
import useFacets from 'Components/Hooks/Queries/Facets';
import useGadgets from 'Components/Hooks/Queries/Gadgets';
import useViews from 'Components/Hooks/Queries/Views';
import DashboardSelector from 'Components/Molecules/Analytics/DashboardSelector';
import ViewSelector from 'Components/Molecules/Analytics/ViewSelector';
import ResponsiveDrawerContainer from 'Components/Molecules/Global/ResponsiveDrawerContainer';
import AnalyticsFacetPane from 'Components/Organisms/Analytics/AnalyticsFacetPane';
import AnalyticsGadgetPane from 'Components/Organisms/Analytics/AnalyticsGadgetPane';
import AnalyticsHeader from 'Components/Organisms/Analytics/AnalyticsHeader';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { assertNotNull } from 'Utils/guard';
import { View } from 'Utils/supabase';

const drawerWidth = 280;

const SelectorBox = styled(Box)(() => ({
  maxWidth: '300px',
  minWidth: '150px',
  height: '50px',
  marginRight: 8,
}));

const Analytics = () => {
  // url関連
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { dashboardId, viewId } = useParams<{
    dashboardId?: string;
    viewId?: string;
  }>();
  const isEditingLayout = pathname.endsWith('edit-layout');
  const isEditFacets = pathname.endsWith('edit-facets');
  const isEditGadgets = pathname.endsWith('edit-gadgets');

  // dashboards
  const dashboards = useDashboards({ refetchOnWindowFocus: false });
  const dashboard =
    dashboards.data?.find((d) => d.id === dashboardId) ?? dashboards.data?.[0];
  // views
  const views = useViews(dashboard?.id, {
    enabled: dashboard != null,
    refetchOnWindowFocus: false,
  });
  const [view, setView] = useState<View | undefined>();

  // ダッシュボードとビューのロードが終わったタイミングで1つめを選択
  useEffect(() => {
    if (isEditFacets || isEditGadgets || isEditingLayout) return;
    if (dashboards.isLoading || views.isLoading) return;
    // 設定画面から戻ってきたときに正しく動作する様、ローカルステートを優先
    const v =
      views.data?.find((d) => d.id === (view?.id ?? viewId)) ?? views.data?.[0];
    setView(v);
    // URLが異なる場合はURLを更新
    if (dashboardId !== dashboard?.id || viewId !== v?.id) {
      navigate((dashboard?.id ?? '') + (v == null ? '' : '/' + v.id), {
        replace: true,
      });
    }
  }, [
    dashboardId,
    dashboard,
    viewId,
    view,
    views.data,
    dashboards.isLoading,
    views.isLoading,
    pathname,
    isEditFacets,
    isEditGadgets,
    isEditingLayout,
    navigate,
  ]); // 依存が多すぎる複雑なEffectなので何とかしたい

  // アクティブダッシュボードのガジェット・ファセット・ビューを取得
  const gadgets = useGadgets(dashboard?.id, {
    enabled: dashboard != null,
    refetchOnWindowFocus: false,
  });
  const facets = useFacets(dashboard?.id, {
    enabled: dashboard != null,
    refetchOnWindowFocus: false,
  });

  const [openSide, setOpenSide] = React.useState(true);

  return (
    <Box width={1} height={1}>
      <ResponsiveDrawerContainer
        anchor="left"
        activeDrawer={openSide}
        drawers={[
          {
            name: true,
            content: () => (
              <Box width={drawerWidth}>
                <AnalyticsFacetPane
                  facets={facets.data?.map((f) => f.facet) ?? []}
                  readonly={isEditingLayout}
                  disabled={!facets.data}
                  onClickEditFacet={() => {
                    assertNotNull(dashboardId);
                    navigate(dashboardId + '/edit-facets');
                  }}
                />
              </Box>
            ),
          },
        ]}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            height: 1,
            width: 1,
          }}
        >
          <AppBar
            sx={{
              boxShadow: 'none',
              zIndex: 1,
              position: 'relative',
              backgroundColor: '#fff0',
            }}
          >
            <Toolbar>
              <AnalyticsHeader
                onEditGadgets={() => {
                  assertNotNull(dashboardId);
                  navigate(dashboardId + '/edit-gadgets');
                }}
              >
                <SelectorBox>
                  <DashboardSelector
                    disabled={isEditingLayout || !dashboards}
                    dashboards={dashboards.data ?? []}
                    currentDashbordId={dashboard?.id}
                    onChangeDashboard={(dashboard) => {
                      setView(undefined);
                      navigate(dashboard?.id ?? '', { replace: true });
                    }}
                  />
                </SelectorBox>
                <SelectorBox>
                  <ViewSelector
                    currentDashboard={dashboard}
                    views={views.data}
                    currentView={view}
                    setCurrentView={(v) => {
                      if (v == null) return; // 空の選択は選択に影響させない
                      setView(v);
                      navigate(
                        (dashboard?.id ?? '') + (v == null ? '' : '/' + v.id),
                        { replace: true }
                      );
                    }}
                    disabled={isEditingLayout || !dashboards}
                  />
                </SelectorBox>
              </AnalyticsHeader>
            </Toolbar>
          </AppBar>

          <Box sx={{ gridRow: 2, width: 1, height: 1, position: 'relative' }}>
            <FloatScrollBox sx={{ width: 1, height: 1, position: 'absolute' }}>
              <AnalyticsGadgetPane
                dashboardGadgets={gadgets.data ?? []}
                loading={gadgets.isLoading}
              />
            </FloatScrollBox>
          </Box>
        </Box>

        <SidePaneControllButton
          open={openSide}
          setOpen={setOpenSide}
          openedLeftPosition={324}
          closedLeftPosition={80}
          openIcon={<FilterAltIcon />}
          closeIcon={<ArrowBackIcon />}
        />
      </ResponsiveDrawerContainer>
    </Box>
  );
};

export default Analytics;
