import { Tooltip, Fab, Box, FabProps, TooltipProps } from '@mui/material';
import { NavIconButton } from 'Components/Molecules/Global/GlobalNavigation';
import { ReactNode } from 'react';

type TooltipFabProps = {
  title?: string;
  children: ReactNode;
  tooltipProps?: Omit<TooltipProps, 'title'>;
  active?: boolean;
  menuButton?: boolean;
} & FabProps;

const TooltipFab = (props: TooltipFabProps) => {
  const {
    children,
    title,
    color,
    active,
    menuButton,
    tooltipProps,
    ...fabProps
  } = props;
  const { placement, arrow, ...tooltipProps2 } = tooltipProps ?? {};
  return (
    <Tooltip
      title={title ?? ''}
      placement={placement ?? 'top'}
      arrow={arrow ?? true}
      {...tooltipProps2}
    >
      <Box>
        {!menuButton ? (
          <Fab color={color ?? 'primary'} {...fabProps}>
            {children}
          </Fab>
        ) : (
          <NavIconButton active={active} {...fabProps} sx={{ m: 0 }}>
            {children}
          </NavIconButton>
        )}
      </Box>
    </Tooltip>
  );
};
export default TooltipFab;
