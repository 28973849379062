import { TextField, Stack } from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import SelectMultiple from 'Components/Atoms/SelectMultiple';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';
import * as reactHookForm from 'react-hook-form';
import { UserProfile } from 'Utils/supabase';

const UserForm = (props: {
  isNew: boolean;
  item: UserProfile & {
    roles: { id: string; displayName: string }[];
    usersRoleIds: string[];
  };
  form: reactHookForm.UseFormReturn<any, any>;
}) => {
  const t = useTranslation('Settings');
  const {
    register,
    formState: { errors },
  } = props.form;

  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>(
    props.item?.usersRoleIds.slice() ?? []
  );

  const onChangeRole = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    const selectedIds: string[] =
      typeof value === 'string' ? value.split(',') : value;
    setSelectedRoleIds(selectedIds);
    props.form.setValue('usersRoleIds', selectedIds);
  };

  return (
    <>
      <Stack spacing={3}>
        {props.isNew === false && (
          <TextField
            label={t('users.field.id')}
            inputProps={{ readOnly: true }}
            {...register('id')}
          />
        )}
        <TextField
          required
          label={t('users.field.name')}
          error={'name' in errors}
          helperText={errors.name?.message}
          {...register('name')}
        />

        {props.isNew && (
          <TextField
            required
            label={t('users.field.email')}
            error={'email' in errors}
            helperText={errors.email?.message}
            {...register('email')}
          />
        )}
        <Can
          crud="update"
          componentName="role"
          no={
            <SelectMultiple
              caption={t('users.field.role')}
              items={props.item?.roles ?? []}
              selectedItemIds={selectedRoleIds}
              onChange={onChangeRole}
              disabled={true}
            ></SelectMultiple>
          }
        >
          <SelectMultiple
            caption={t('users.field.role')}
            items={props.item?.roles ?? []}
            selectedItemIds={selectedRoleIds}
            onChange={onChangeRole}
            disabled={false}
          ></SelectMultiple>
        </Can>
      </Stack>
    </>
  );
};

export default UserForm;
