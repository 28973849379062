import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { assertNotNull } from 'Utils/guard';
import supabase, { DashboardFacet, Facet } from 'Utils/supabase';

const key = 'Dashboard Facets';

const useFacets = (
  dashboardId?: string,
  options?: Omit<UseQueryOptions<DashboardFacet[]>, 'queryKey' | 'queryFn'>
) => {
  const keys = [key, dashboardId ?? ''];
  return useQuery<DashboardFacet[]>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      const { data, error } = await supabase
        .from<DashboardFacet>('dashboard_facets')
        .select()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .eq('dashboardId', dashboardId!);
      if (!data || error) throw error;
      return data;
    },
    options
  );
};
export default useFacets;

export const useInsertFacets = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: { dashboardId: string; facets: Facet[] }) => {
      const result = await supabase
        .from<DashboardFacet>('dashboard_facets')
        .insert(
          item.facets.map((facet) => ({
            dashboardId: item.dashboardId,
            facet,
          }))
        );
      if (result.error) throw result.error;
      assertNotNull(result.data);
      return result.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([key, variables.dashboardId]);
        queryClient.invalidateQueries([key, '']);
      },
    }
  );
};

export const useDeleteFacets = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: { dashboardId: string; facets: Facet[] }) => {
      const result = await supabase
        .from<DashboardFacet>('dashboard_facets')
        .delete()
        .eq('dashboardId', item.dashboardId)
        .in('facet', item.facets);
      if (result.error) throw result.error;
      assertNotNull(result.data);
      return result.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([key, variables.dashboardId]);
        queryClient.invalidateQueries([key, '']);
      },
    }
  );
};
