/* eslint-disable @typescript-eslint/no-unused-vars */
// import { width } from '@mui/system';

import {
  ContactReason,
  Dashboard,
  Facet,
  Gadget,
  View,
} from '../../common/common-types';

const operatorIds = ['id1', 'id2', 'id3'];

export const dataDashboard: Dashboard[] = [
  {
    id: '0001',
    name: 'Webサイトへのアクセス・KPI',
    userId: operatorIds[0],
    jobIds: ['hogejob1'],
    gadgets: [
      { id: 'A001', x: 0, y: 0, width: 12, height: 3 },
      { id: 'A002', x: 0, y: 3, width: 12, height: 3 },
      { id: 'A003', x: 0, y: 6, width: 12, height: 3 },
      { id: 'A013', x: 0, y: 9, width: 12, height: 3 },
      { id: 'A018', x: 0, y: 12, width: 12, height: 3 },
      { id: 'A035', x: 0, y: 15, width: 12, height: 3 },
      { id: 'A038', x: 0, y: 18, width: 12, height: 3 },
      { id: 'C001', x: 0, y: 21, width: 12, height: 3 },
      { id: 'C005', x: 0, y: 24, width: 12, height: 3 },
      { id: 'D001', x: 0, y: 27, width: 12, height: 3 },
      { id: 'D002', x: 0, y: 30, width: 12, height: 3 },
      { id: 'D003', x: 0, y: 33, width: 12, height: 3 },
    ],
    facets: [{ id: '0001' }, { id: '0002' }, { id: '0003' }, { id: '0005' }],
    viewIds: ['0001'],
    default: true,
  },
  {
    id: '0002',
    name: '生産性とコールリーズン',
    userId: operatorIds[0],
    jobIds: ['hogejob2'],
    gadgets: [
      { id: 'A013', x: 0, y: 0, width: 12, height: 3 },
      { id: 'A002', x: 0, y: 3, width: 12, height: 3 },
      { id: 'A003', x: 0, y: 6, width: 12, height: 3 },
    ],
    facets: [{ id: '0002' }, { id: '0003' }],
    viewIds: ['0002', '0003'],
    default: true,
  },
  {
    id: '0003',
    name: '生産性とページ遷移',
    userId: operatorIds[0],
    jobIds: ['hogejob1', 'hogejob2'],
    gadgets: [
      { id: 'A001', x: 0, y: 0, width: 12, height: 3 },
      { id: 'C001', x: 0, y: 3, width: 12, height: 3 },
    ],
    //1->5 5->3
    facets: [{ id: '0005' }, { id: '0003' }],
    viewIds: ['0004'],
    default: false,
  },
];

export const dataView: View[] = [
  {
    id: '0001',
    name: 'View_1',
    userId: operatorIds[0],
  },
  {
    id: '0002',
    name: 'View_2',
    userId: operatorIds[0],
  },
  {
    id: '0003',
    name: 'View_3',
    userId: operatorIds[0],
  },
  {
    id: '0004',
    name: 'View_4',
    userId: operatorIds[0],
  },
];

export const dataFacet: Facet[] = [
  {
    id: '0001',
    name: '担当者',
    type: 'checkbox',
    query: () => {
      return Promise.resolve([
        {
          key: '0001',
          value: 'Hanako Yamada',
        },
        {
          key: '0002',
          value: 'Taro Yamada',
        },
        {
          key: '0003',
          value: 'Ichiro Sato',
        },
        {
          key: '0004',
          value: 'Keiko Suzuki',
        },
        {
          key: '0005',
          value: 'Koji Sato',
        },
      ]);
    },
  },
  {
    id: '0002',
    name: '開始-終了日',
    type: 'datetimepicker',
  },
  {
    id: '0003',
    name: 'ステータス',
    type: 'checkbox',
    query: () => {
      return Promise.resolve([
        {
          key: '0001',
          value: '解決済み',
        },
        {
          key: '0002',
          value: 'オープン',
        },
      ]);
    },
  },
  {
    id: '0004',
    name: '通話（ビデオ）通話有無',
    type: 'checkbox',
    query: () => {
      return Promise.resolve([
        {
          key: '0001',
          value: '有',
        },
      ]);
    },
  },
  {
    id: '0005',
    name: 'コンタクトリーズン',
    type: 'checkbox',
    query: () => {
      return Promise.resolve([
        {
          key: '0001',
          value: '契約変更',
        },
        {
          key: '0002',
          value: '新規契約',
        },
        {
          key: '0003',
          value: '契約内容確認',
        },
        {
          key: '0004',
          value: 'ご質問',
        },
        {
          key: '0005',
          value: '苦情',
        },
        {
          key: '0006',
          value: 'その他',
        },
      ]);
    },
  },
];

export const dataGadget: Gadget[] = [
  {
    id: 'A001',
    name: 'コールリーズンごとのページ遷移',
    category: 'chart',
    type: 'sankey',
    description: 'お客様のコールリーズンごとのページ遷移情報を確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },

  {
    id: 'A002',
    name: 'CTAごとのページ遷移',
    type: 'sankey',
    category: 'chart',
    description:
      'クリックされたCTA(Call to Action)ごとの、ページ遷移情報の取得により、どのような導線が効果的か、もしくは改善が必要かを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A003',
    name: '離脱ページごとのページ遷移',
    type: 'sankey',
    category: 'chart',
    description:
      '問い合わせに至らず離脱した離脱ページごとの、ページ遷移情報の取得により、どのような導線が効果的か、もしくは改善が必要かを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A004',
    name: '検索キーワードごとのページ遷移',
    type: 'sankey',
    category: 'chart',
    description: '特定の検索ワードごとのサイト内の動きを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A005',
    name: '担当者の生産性',
    type: 'table',
    category: 'chart',
    description:
      '各担当者の扱ったケースと平均処理時間（担当者・扱ったケース数・平均セッション時間）を確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A006',
    name: 'グループの生産性',
    type: 'table',
    category: 'chart',
    description: '放棄呼率・平均セッション時間・平均通話時間などを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A007',
    name: '特定のページの訪問者の問い合わせ',
    type: 'table',
    category: 'chart',
    description:
      '特定のページを訪問する人がどのような問い合わせ内容を抱えているのかを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A008',
    name: '離脱ページ​',
    type: 'table',
    category: 'chart',
    description: 'どこで離脱することが多いのかを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A009',
    name: 'FAQページ訪問の有無​',
    type: 'pie',
    category: 'chart',
    description: 'FAQがどれほど活用されているかを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A010',
    name: ' 検索の有無​',
    type: 'pie',
    category: 'chart',
    description: '検索がどれほど活用されているかを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A011',
    name: '検索キーワード​',
    type: 'area',
    category: 'chart',
    description:
      'どのようなワードが検索されているか、特定のワードごとのサイト内の動きを確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A012',
    name: 'ヒートマップ​',
    type: 'heatmap',
    category: 'chart',
    description:
      'ページ内のヒートマップ（ページ内のどの位置がよく見られているのか、アクションが多いのか）を確認',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A013',
    name: 'タイムライン-CTA​',
    type: 'line',
    category: 'chart',
    description: 'data-op-ctaが設定されているエレメントのクリック数',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A014',
    name: 'タイムライン-キーワード',
    type: 'line',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A015',
    name: 'タイムライン-グループ',
    type: 'line',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A016',
    name: 'タイムライン-ステータス（未解決/解決済）',
    type: 'line',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A017',
    name: 'タイムライン-チャネル（コブラ/ビデオ/音声通話）',
    type: 'line',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A018',
    name: 'タイムライン-コンタクトリーズン',
    type: 'line',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A019',
    name: 'タイムライン-担当者',
    type: 'line',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A020',
    name: 'ヒートマップ-CTA',
    type: 'heatmap',
    category: 'chart',
    description: 'data-op-ctaが設定されているエレメントのクリック数',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A021',
    name: 'ヒートマップ-キーワード',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A022',
    name: 'ヒートマップ-グループ',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A023',
    name: 'ヒートマップ-ステータス（未解決/解決済）',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A024',
    name: 'ヒートマップ-チャネル（コブラ/ビデオ/音声通話）',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A025',
    name: 'ヒートマップ-コンタクトリーズン',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A026',
    name: 'ヒートマップ-担当者',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A027',
    name: '同時発生トピックコードグラフ',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A028',
    name: '同時発生トピックコードサンバースト',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A029',
    name: '同時トピック二部グラフ',
    type: 'heatmap',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A030',
    name: '散布図-連絡先',
    type: 'scatter',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A031',
    name: '棒グラフ-キーワード',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A032',
    name: '棒グラフ-グループ',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A033',
    name: '棒グラフ-ステータス（未解決/解決済）',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A034',
    name: '棒グラフ-チャネル（コブラ/ビデオ/音声通話）',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A035',
    name: '棒グラフ-日別のコンタクトリーズン',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A036',
    name: '棒グラフ-担当者',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A037',
    name: '棒グラフ-日別のCTA',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'A038',
    name: '棒グラフ-日別のユーザ数',
    type: 'bar',
    category: 'chart',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'B001',
    name: 'グループの生産性',
    type: 'table',
    category: 'table',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'B002',
    name: '同時発生トピック',
    type: 'table',
    category: 'table',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'B003',
    name: '担当者の生産性',
    type: 'table',
    category: 'table',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C001',
    name: '解決済',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C002',
    name: '平均処理時間',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C003',
    name: '平均解決時間',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C004',
    name: '平均一次対応時間',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C005',
    name: '未解決',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C006',
    name: '未割当',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C007',
    name: 'アクションなし',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C008',
    name: 'エスカレーション',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C009',
    name: '平均セッション時間',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'C009',
    name: '平均セッション時間',
    type: 'number',
    category: 'kpi',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'D001',
    name: 'ユーザーエージェント',
    type: 'pie',
    category: 'pie',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'D002',
    name: 'ユーザーロケール',
    type: 'pie',
    category: 'pie',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
  {
    id: 'D003',
    name: 'クリックエレメント',
    type: 'pie',
    category: 'pie',
    description: '',
    query: (params: any[]) => {
      return Promise.resolve();
    },
  },
];

export const dataContactReason: ContactReason[] = [
  {
    id: '0001',
    name: '契約変更',
  },
  {
    id: '0002',
    name: '苦情',
  },
  {
    id: '0003',
    name: 'その他',
  },
];
