import i18n from 'i18next';
import Cookies from 'js-cookie';
import enAccount from 'Locales/en/Account.json';
import enAnalytics from 'Locales/en/Analytics.json';
import enAuth from 'Locales/en/Auth.json';
import enError from 'Locales/en/Error.json';
import enFacet from 'Locales/en/Facet.json';
import enGadget from 'Locales/en/Gadget.json';
import enGadgetTable from 'Locales/en/GadgetTable.json';
import enGlobal from 'Locales/en/Global.json';
import enSession from 'Locales/en/Session.json';
import enSettings from 'Locales/en/Settings.json';
import enSummary from 'Locales/en/Summary.json';
import jaAccount from 'Locales/ja/Account.json';
import jaAnalytics from 'Locales/ja/Analytics.json';
import jaAuth from 'Locales/ja/Auth.json';
import jaError from 'Locales/ja/Error.json';
import jaFacet from 'Locales/ja/Facet.json';
import jaGadget from 'Locales/ja/Gadget.json';
import jaGadgetTable from 'Locales/ja/GadgetTable.json';
import jaGlobal from 'Locales/ja/Global.json';
import jaSession from 'Locales/ja/Session.json';
import jaSettings from 'Locales/ja/Settings.json';
import jaSummary from 'Locales/ja/Summary.json';
import { initReactI18next } from 'react-i18next';

import browserLocale from './browserLocale';

// eslint-disable-next-line @typescript-eslint/naming-convention
const COOKIE_LANG = 'op-lang'; // cookieの読み込み場所が、useLanguageと分散しているので何とかしたい
const locale = Cookies.get(COOKIE_LANG) ?? browserLocale;

i18n.use(initReactI18next).init({
  resources: {
    en: {
      Global: enGlobal,
      Gadget: enGadget,
      GadgetTable: enGadgetTable,
      Facet: enFacet,
      Auth: enAuth,
      Session: enSession,
      Analytics: enAnalytics,
      Settings: enSettings,
      Account: enAccount,
      Summary: enSummary,
      Error: enError,
    },
    ja: {
      Global: jaGlobal,
      Gadget: jaGadget,
      GadgetTable: jaGadgetTable,
      Facet: jaFacet,
      Auth: jaAuth,
      Session: jaSession,
      Analytics: jaAnalytics,
      Settings: jaSettings,
      Account: jaAccount,
      Summary: jaSummary,
      Error: jaError,
    },
  },
  lng: locale,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
