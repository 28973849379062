import useAutoScaleChild from 'Components/Hooks/AutoScaleChild';

// 子要素全体が親要素に含まれるよう自動スケールするコンテナ
const AutoScaleContainer = (props: {
  children: React.ReactNode;
  maxScale?: number;
}) => {
  const { setAutoScaleChild } = useAutoScaleChild(props.maxScale);

  return (
    <div>
      <div
        ref={(r) => {
          if (r != null) {
            setAutoScaleChild(r);
          }
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default AutoScaleContainer;
