import useLocalFacetValues from 'Components/Hooks/LocalFacetValues';
import { useQuery, UseQueryOptions } from 'react-query';
import { ChannelFacetValue } from 'Utils/FacetValue/ChannelFacetValue';
import { ContactReasonFacetValue } from 'Utils/FacetValue/ContactReasonFacetValue';
import { CTAFacetValue } from 'Utils/FacetValue/CTAFacetValue';
import { DateTimeRangeFacetValue } from 'Utils/FacetValue/DateTimeRangeFacetValue';
import { InquiryStayingTimeFacetValue } from 'Utils/FacetValue/InquiryStayingTimeFacetValue';
import { InquiryTransitionFacetValue } from 'Utils/FacetValue/InquiryTransitionFacetValue';
import { JobFacetValue } from 'Utils/FacetValue/JobFacetValue';
import { LocaleFacetValue } from 'Utils/FacetValue/LocaleFacetValue';
import { NoteFacetValue } from 'Utils/FacetValue/NoteFacetValue';
import { OperatorFacetValue } from 'Utils/FacetValue/OperatorFacetValue';
import { PathFacetValue } from 'Utils/FacetValue/PathFacetValue';
import { SessionTimeFacetValue } from 'Utils/FacetValue/SessionTimeFacetValue';
import { StayingTimeFacetValue } from 'Utils/FacetValue/StayingTimeFacetValue';
import { TransitionFacetValue } from 'Utils/FacetValue/TransitionFacetValue';
import { assertNotNull } from 'Utils/guard';
import supabase, { ViewFacetValue } from 'Utils/supabase';
import { definitions } from 'Utils/types';

import { useDeleteMany, useInsertMany } from './Mutation';

// eslint-disable-next-line @typescript-eslint/naming-convention
type facetTypes = definitions['dashboard_facets']['facet'];

const key = 'view_facet_values';

export const useFacetValues = (
  viewId?: string,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>
) => {
  const { updateAll } = useLocalFacetValues();
  return useQuery(
    [key, viewId ?? ''],
    async () => {
      const { data, error } = await supabase
        .from<ViewFacetValue>('view_facet_values')
        .select()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .eq('viewId', viewId!);
      if (!data || error) throw error;

      const facetValueObjects = data.map((d) =>
        getFacetValueClass(d.value, d.facet)
      );
      // console.log(facetValueObjects);
      // 内部コンテキストを更新
      updateAll(facetValueObjects);

      return facetValueObjects;
    },
    options as any
  );
};

export const getFacetValueClass = (value: unknown, facet: facetTypes) => {
  const payload = JSON.parse(value as string);
  switch (facet) {
    case 'ContactReasonFacet':
      return new ContactReasonFacetValue(payload);
    case 'DateTimeRangeFacet':
      return new DateTimeRangeFacetValue(payload);
    case 'JobFacet':
      return new JobFacetValue(payload);
    case 'OperatorFacet':
      return new OperatorFacetValue(payload);
    case 'PathFacet':
      return new PathFacetValue(payload);
    case 'ChannelFacet':
      return new ChannelFacetValue(payload);
    case 'NoteFacet':
      return new NoteFacetValue(payload);
    case 'LocaleFacet':
      return new LocaleFacetValue(payload);
    case 'InquiryStayingTimeFacet':
      return new InquiryStayingTimeFacetValue(payload);
    case 'InquiryTransitionFacet':
      return new InquiryTransitionFacetValue(payload);
    case 'StayingTimeFacet':
      return new StayingTimeFacetValue(payload);
    case 'SessionTimeFacet':
      return new SessionTimeFacetValue(payload);
    case 'TransitionFacet':
      return new TransitionFacetValue(payload);
    case 'CTAFacet':
      return new CTAFacetValue(payload);
    default:
      throw new Error('Could not found facet');
  }
};

export const useInsertFacetValues = () => {
  const { values } = useLocalFacetValues();
  const mutation = useInsertMany<ViewFacetValue>(key, ['view_facet_values']);

  const mutateAsync = async (viewId: string) => {
    // 内部コンテキストからデータを取得して更新
    await mutation.mutateAsync(
      Object.entries(values).map(([_facet, value]) => {
        return {
          viewId,
          facet: value.type,
          value: JSON.stringify(value.payload),
        };
      })
    );
  };

  return {
    ...mutation,
    mutateAsync,
  };
};

export const useDeleteFacetValues = (viewId?: string) => {
  const { updateAll } = useLocalFacetValues();
  const mutation = useDeleteMany<ViewFacetValue>(key, [
    'view_facet_values',
    viewId ?? '',
  ]);

  const mutateAsync = async () => {
    assertNotNull(viewId);
    await mutation.mutateAsync({ viewId });
    // 内部コンテキストを更新
    updateAll([]);
  };

  return {
    ...mutation,
    mutateAsync,
  };
};
