import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SpeedIcon from '@mui/icons-material/Speed';
import StopIcon from '@mui/icons-material/Stop';
import {
  Box,
  Stack,
  Slider,
  IconButton,
  Button,
  ButtonGroup,
  Tooltip,
  ClickAwayListener,
  Typography,
} from '@mui/material';
import Loading from 'Components/Atoms/Loading';
import useRrwebPlayer from 'Components/Hooks/RrwebPlayer';
import useTranslation from 'Components/Hooks/Translate';
import { forwardRef, useState, useMemo, useImperativeHandle } from 'react';

// 操作パネル付きrrwebプレイヤー
// eslint-disable-next-line react/display-name
const Player = forwardRef<RrwebPlayerHandles, RrwebPlayerProps>(
  (props, ref) => {
    // コンポーネント全体にかかるdiv
    const [targetElement, setTargetElement] = useState<HTMLElement>();
    const [openSpeed, setOpenSpeed] = useState(false);
    const t = useTranslation('Session');
    const rrwebPlayer = useRrwebPlayer(targetElement, props.customerId, true);
    const pages = rrwebPlayer.pages ?? [];

    // 外部にseekを公開
    useImperativeHandle(ref, () => ({ seek: rrwebPlayer.seek }));

    // 最適化
    const playerDiv = useMemo(
      () => <div ref={(elm) => setTargetElement(elm ?? undefined)}></div>,
      []
    );

    const speedButtons = (
      <Box>
        <ButtonGroup variant="text">
          {[1, 2, 4, 8, 16, 32].map((x) => (
            <Button
              key={x}
              onClick={() => rrwebPlayer.setSpeed(x)}
              sx={{
                color: rrwebPlayer.speed === x ? '#aaa' : '#fff',
                borderColor: '#aaa0 !important',
              }}
            >
              x{x}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );

    return (
      <Box
        sx={{ height: 1, display: 'grid', gridTemplateRows: '1fr auto auto' }}
      >
        <Box
          sx={{
            position: 'relative',
            height: 1,
            backgroundColor: '#505050',
            borderStyle: 'solid',
          }}
        >
          {playerDiv}
          {rrwebPlayer.isLoading && <Loading />}
        </Box>
        <Box gridRow={2} sx={{ backgroundColor: '#777', pt: 1, px: 1 }}>
          <Slider
            size="small"
            sx={{
              py: 1,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '& .MuiSlider-mark': { width: '4px', height: '4px' },
            }}
            min={rrwebPlayer.startTimestamp}
            max={rrwebPlayer.endTimestamp}
            onChange={(_, value) => rrwebPlayer.seek(value as number)}
            value={rrwebPlayer.originalTimestamp}
            valueLabelDisplay="auto"
            marks={pages.map((x) => ({ value: x.originalTimestamp }))}
            valueLabelFormat={(value) => (
              <Stack>
                <Box>
                  {t('controller.time')}:{new Date(value).toLocaleString()}
                </Box>
              </Stack>
            )}
            disabled={rrwebPlayer.canSeek === false}
          />
        </Box>
        <Box
          gridRow={3}
          display="grid"
          gridTemplateColumns="1fr auto"
          sx={{ backgroundColor: '#777' }}
        >
          {
            // 操作パネルなど
          }
          <Stack direction="row" spacing={0} alignItems="center">
            <IconButton
              onClick={() => {
                const rangeBefore = pages
                  .filter(
                    (val) =>
                      val.originalTimestamp < rrwebPlayer.originalTimestamp
                  )
                  .map((x) => x.originalTimestamp);
                const latestPoint = Math.max(...rangeBefore);
                rrwebPlayer.seek(latestPoint);
              }}
            >
              <SkipPreviousIcon sx={{ color: '#fff' }} />
            </IconButton>
            {rrwebPlayer.canPlay && (
              <IconButton
                disabled={rrwebPlayer.isLoading}
                onClick={() => rrwebPlayer.play()}
              >
                <PlayArrowIcon sx={{ color: '#fff' }} />
              </IconButton>
            )}
            {rrwebPlayer.isPlaying && (
              <IconButton
                disabled={rrwebPlayer.isLoading}
                onClick={() => rrwebPlayer.pause()}
              >
                <StopIcon sx={{ color: '#fff' }} />
              </IconButton>
            )}
            <IconButton
              onClick={() => {
                const rangeAfter = pages
                  .filter(
                    (val) =>
                      val.originalTimestamp > rrwebPlayer.originalTimestamp
                  )
                  .map((x) => x.originalTimestamp);
                const nextPoint = Math.min(...rangeAfter);
                rrwebPlayer.seek(nextPoint);
              }}
            >
              <SkipNextIcon sx={{ color: '#fff' }} />
            </IconButton>

            {rrwebPlayer.originalTimestamp && (
              <Box sx={{ px: 1, py: 0.5 }}>
                <Typography
                  color={'primary.contrastText'}
                  variant={'body2'}
                  sx={{ display: 'table-caption' }}
                >
                  {new Date(rrwebPlayer.originalTimestamp).toLocaleString()}
                </Typography>
              </Box>
            )}
          </Stack>

          <ClickAwayListener onClickAway={() => setOpenSpeed(false)}>
            <Tooltip
              title={speedButtons}
              onClose={() => setOpenSpeed(false)}
              open={openSpeed}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button
                variant="text"
                onClick={() => setOpenSpeed(true)}
                sx={{ color: '#fff', gridColumn: 2 }}
              >
                {
                  rrwebPlayer.speed !== 1 ? (
                    `x ${rrwebPlayer.speed}`
                  ) : (
                    <SpeedIcon />
                  )
                  // : t('controller.speed')
                }
              </Button>
            </Tooltip>
          </ClickAwayListener>
        </Box>
      </Box>
    );
  }
);

export default Player;

export interface RrwebPlayerProps {
  customerId: string;
  onStatusChanged?: (status: RecordPlayerStatus) => void;
}

// 状態変化の通知用
export interface RecordPlayerStatus {
  originalTimestamp: number;
  originalDateTime: Date;
  isPlaying: boolean;
  isLoading: boolean;
  isContentLoading: boolean;
}

// 公開したいメソッドの定義
export interface RrwebPlayerHandles {
  seek(timestamp: number): void;
}
