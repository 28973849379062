import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import useLoading from 'Components/Hooks/Loading';
import useTranslation from 'Components/Hooks/Translate';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { AppError } from 'Utils/error';
import i18nYup from 'Utils/i18nYup';
import supabase from 'Utils/supabase';

const Register = () => {
  const t = useTranslation('Auth');
  const [searchParams] = useSearchParams();
  const [errMsg, setErrMsg] = useState('');
  const { load, isLoading } = useLoading();

  const updateUser = async (password: string | undefined) => {
    const { error } = await supabase.auth.update({ password });
    if (error != null) {
      throw new AppError(error, 'Sys', 'ProfileUser', 'UpdateOne', '01');
    }
  };

  useEffect(() => {
    if (Number(searchParams.get('error_code')) >= 400) {
      const errorDescription = searchParams.get('error_description');

      // エラー表示が必要なものはここで変換する
      switch (errorDescription) {
        case 'Confirmation+Token+not+found':
          setErrMsg(t('register.ConfirmationTokenNotFoundErr'));
          break;
        default:
          setErrMsg(t('register.supabaseUnknownErr'));
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PasswordForm = () => {
    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm<{ password: string; confirmPassword: string }>({
      resolver: yupResolver(
        i18nYup.object({
          password: i18nYup
            .string()
            .label(t('common.password'))
            .required()
            .min(6),
          confirmPassword: i18nYup
            .string()
            .label(t('common.confirmPassword'))
            .required()
            .min(6)
            // 一致チェック
            .oneOf(
              [i18nYup.ref('password'), null],
              t('common.passwordMustMatch')
            ),
        })
      ),
    });

    return (
      <>
        <Stack spacing={3}>
          <Typography variant="h3">{t('register.pleasefill')}</Typography>
          <TextField
            type={'password'}
            required
            label={t('common.password')}
            error={'password' in errors}
            variant="standard"
            helperText={errors.password?.message}
            {...register('password')}
          />
          <TextField
            type={'password'}
            required
            label={t('common.confirmPassword')}
            error={'confirmPassword' in errors}
            variant="standard"
            helperText={errors.confirmPassword?.message}
            {...register('confirmPassword')}
          />

          <Button
            size="large"
            disabled={isLoading}
            onClick={handleSubmit(async (item) => {
              await load(async () => await updateUser(item.password));
              window.location.href = '/';
            })}
          >
            <Typography style={{ textTransform: 'none' }}>
              {t('common.send')}
            </Typography>
          </Button>
        </Stack>
      </>
    );
  };

  const DisplayError = () => (
    <>
      <Typography variant="h1">{errMsg}</Typography>
      <Typography>{t('register.pleaseAskAdmin')}</Typography>
    </>
  );

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: 'center',
        width: '330px',
      }}
    >
      {errMsg ? <DisplayError /> : <PasswordForm />}
    </div>
  );
};

export default Register;
