/* eslint-disable react-hooks/exhaustive-deps*/
import { useChannelContext } from 'Components/Hooks/Channel';
import useOperator from 'Components/Hooks/Operator';
import {
  StatusContext,
  OperatorTrackPayload,
} from 'Components/Hooks/OperatorStatus';
import { useWaitIncommingCall } from 'Components/Hooks/WaitIncommingCall';
import Cookies from 'js-cookie';
import Enumerable from 'linq';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { assertNotNull } from 'Utils/guard';

// eslint-disable-next-line @typescript-eslint/naming-convention
const STATUS_COOKIE_KEY = '_op_status';

export const StatusProvider = (props: { children?: ReactNode }) => {
  const [currentTrack, setCurrentTrack] = useState<OperatorTrackPayload>(
    // Cookieからステータスデータを読み出し
    JSON.parse(Cookies.get(STATUS_COOKIE_KEY) ?? 'null') ?? {
      status: 'online',
      timestamp: 0,
    }
  );
  const currentTrackRef = useRef(currentTrack);
  const operator = useOperator();
  const channels = useChannelContext();
  const offCounter = useRef(0);

  useEffect(() => {
    if (operator && channels) {
      const currentOffCounter = offCounter.current;

      // 新規プレゼンスデータとローカルデータを比較して、新しければローカルデータを更新する
      channels[0]?.on('presence', { event: 'sync' }, () => {
        if (offCounter.current !== currentOffCounter) return;
        assertNotNull(channels);

        // Objectから配列の値に変換
        const presenceArrayArray = Object.values(channels[0].presenceState());
        const presenceArray = presenceArrayArray.flat(); // 1次元配列化

        // オペレータで結合し、最新のタイムスタンプのトラックデータを取得
        const latestTrack = Enumerable.from(presenceArray)
          .select(
            (x) =>
              x as {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                presence_ref: string;
                operatorId: string;
                timestamp: number;
              }
          )
          .where((x) => x.operatorId === operator?.id)
          .groupBy((x) => x.operatorId)
          .select((x) => x.maxBy((y) => y.timestamp ?? 0))
          .singleOrDefault();

        // 現在のトラックデータが古ければステータスが更新されているとみなす
        if (
          latestTrack &&
          latestTrack.timestamp > currentTrackRef.current.timestamp
        ) {
          // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
          const { presence_ref, ...latestTrackData } = latestTrack;
          // ローカルステートの更新
          currentTrackRef.current = latestTrackData as OperatorTrackPayload;
          setCurrentTrack(currentTrackRef.current);
          // Cookieを更新する
          Cookies.set(
            STATUS_COOKIE_KEY,
            JSON.stringify(currentTrackRef.current)
          );
        }
      });

      // 初期ステータスを送信する
      channels.forEach((channel) => {
        channel.track({
          ...currentTrackRef.current,
          operatorId: operator.id,
        } as OperatorTrackPayload);
      });

      return () => {
        // supabase2の正式リリースからoffが消えたため、無効化方法を変更
        offCounter.current++;
        // channels?.[0]?.off('presence', { event: 'sync' });
      };
    }
  }, [operator, channels]);

  return (
    <StatusContext.Provider value={currentTrack.status ?? 'offline'}>
      <WrapperWaitIncommingCall>{props.children}</WrapperWaitIncommingCall>
    </StatusContext.Provider>
  );
};

// 着信の待機を行うためのコンポーネント
// フックス内でstatusコンテキストを利用しているためコンポーネントを分離
export const WrapperWaitIncommingCall = (props: { children?: ReactNode }) => {
  const channels = useChannelContext();
  const { startWating, stopWaiting } = useWaitIncommingCall(channels);

  useEffect(() => {
    if (channels != null) {
      // TODO: ビデオ・コブラの実行権限がない場合はリッスンさせない
      startWating();

      return () => stopWaiting();
    }
  }, [channels]);

  return <>{props.children}</>;
};
