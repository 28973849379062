import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import supabase, { Role, RolePermision } from 'Utils/supabase';
import { definitions } from 'Utils/types';

const key = 'Roles';

export const useRoles = (
  options?: Omit<UseQueryOptions<Role[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery<Role[]>(
    key,
    async () => {
      const { data, error } = await supabase
        .from<Role>('roles')
        .select()
        .order('roleName');
      if (error != null) throw error;
      return data;
    },
    options
  );

export const useInsertRole = () => {
  const queryClient = useQueryClient();
  return useMutation(async (item: any) => insertOrUpdate(true, item), {
    onSuccess: () => queryClient.invalidateQueries(key),
    // トランザクションがいまいちなので、エラー時も再取得
    onError: () => queryClient.invalidateQueries(key),
  });
};

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  return useMutation(async (item: any) => insertOrUpdate(false, item), {
    onSuccess: () => queryClient.invalidateQueries(key),
    // トランザクションがいまいちなので、エラー時も再取得
    onError: () => queryClient.invalidateQueries(key),
  });
};

export const useDeleteRoles = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (itemIds: string[]) => {
      const rolePermissions = await supabase
        .from<definitions['role_permissions']>('role_permissions')
        .delete()
        .in('roleId', itemIds);
      if (rolePermissions.error) throw rolePermissions.error;

      const usersRoles = await supabase
        .from<definitions['users_roles']>('users_roles')
        .delete()
        .in('roleId', itemIds);
      if (usersRoles.error) throw usersRoles.error;

      const roles = await supabase
        .from<definitions['roles']>('roles')
        .delete()
        .in('id', itemIds);
      if (roles.error != null) throw roles.error;

      return roles.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

const insertOrUpdate = async (isNew: boolean, item: any) => {
  // ロールの保存
  const rolesResult = isNew
    ? await supabase.from<Role>('roles').insert({
        roleName: item.roleName,
      })
    : await supabase
        .from<Role>('roles')
        .update({
          roleName: item.roleName,
        })
        .eq('id', item.id ?? '');
  if (rolesResult.error != null) throw rolesResult.error;
  const itemId = rolesResult.data[0].id;

  // 以前の権限関連の取得
  const rolePermResult = await supabase
    .from<RolePermision>('role_permissions')
    .select()
    .match({ roleId: itemId });
  if (rolePermResult.error != null) throw rolePermResult.error;
  const oldRolePermIds = (rolePermResult.data ?? []).map((x) => x.permissionId);

  // 追加されている権限を追加
  for (const addPermId of item.rolePermIds.filter(
    (x: any) => oldRolePermIds.includes(x) === false
  )) {
    const roleUserInsertResult = await supabase
      .from<RolePermision>('role_permissions')
      .insert({ roleId: itemId, permissionId: addPermId });
    if (roleUserInsertResult.error != null) throw roleUserInsertResult.error;
  }

  // 削除されている権限を削除
  for (const removePermId of oldRolePermIds.filter(
    (x) => item.rolePermIds.includes(x) === false
  )) {
    const roleUserRemoveResult = await supabase
      .from<RolePermision>('role_permissions')
      .delete()
      .match({ roleId: itemId, permissionId: removePermId });
    if (roleUserRemoveResult.error != null) throw roleUserRemoveResult.error;
  }

  return itemId;
};
