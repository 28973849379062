import { AppError } from './error';
import { Permission, Permit } from './Permission';
import supabase from './supabase';
import { definitions } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
class authProvider {
  public static async getIdentity() {
    // 未認証ユーザの場合はNullを返す
    const user = supabase.auth.user();
    if (user == null) return null;

    const { data, error } = await supabase
      .from<definitions['user_profiles']>('user_profiles')
      .select('id, name')
      .match({ id: user.id })
      .maybeSingle();

    if (error != null)
      throw new AppError(error, 'Sys', 'ProfileUser', 'ReadOne', '01');
    if (data == null)
      throw new AppError(
        `Not found user id: ${user.id}`,
        'Biz',
        'ProfileUser',
        'ReadOne',
        '01'
      );

    return {
      id: data.id,
      name: data.name,
    };
  }

  public static async getPermissions() {
    // 未認証ユーザの場合はNullを返す
    const session = supabase.auth.session();
    if (session == null || session.user == null) return null;

    const { data, error } = await supabase.rpc<Permit>('get_permissions');
    if (error != null)
      throw new AppError(error, 'Sys', 'ProfilePermission', 'ReadOne', '01');
    if (data == null)
      throw new AppError(
        'Not found permission',
        'Biz',
        'ProfilePermission',
        'ReadOne',
        '02'
      );
    return new Permission(data);
  }

  public static async loginWithGoogle() {
    const { error } = await supabase.auth.signIn({
      provider: 'google',
    });
    if (error) {
      throw error;
    }
    return undefined;
  }

  public static async loginWithMS() {
    return undefined;
  }
}

export default authProvider;
