import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import { Icon, Paper, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

const Panel = (props: {
  icon: ReactElement;
  title: string;
  content: string;
  isLoading?: boolean;
}) => (
  <Paper sx={{ m: 1, py: 2, px: 4, width: '13rem' }} elevation={3}>
    <style>
      {`@keyframes animation{
          0%{ opacity: 0.2 }
          50%{ opacity: 1 }
          100%{ opacity: 0.2 }
        }`}
    </style>
    <Stack>
      <Icon
        sx={{ mx: 'auto', height: 'auto' }}
        fontSize="large"
        color="primary"
      >
        {props.icon}
      </Icon>
      <Typography align="center" variant="caption">
        {props.title}
      </Typography>
      {props.isLoading ? (
        <HourglassTopOutlinedIcon
          color="primary"
          sx={{
            width: '40px',
            height: 'auto',
            animation: 'animation 3s infinite',
            mx: 'auto',
            mt: 4,
          }}
        />
      ) : (
        <Typography align="center" variant="h2" sx={{ pt: 4, pb: 1 }}>
          {props.content}
        </Typography>
      )}
    </Stack>
  </Paper>
);

export default Panel;
