import { customEvent } from '@rrweb/types';

export enum Role {
  agent = 'agent',
  customer = 'customer',
  viewer = 'viewer',
}

export type OptipassSDKConfig = {
  eventGatewayServer: string;
  job: string;
  iid: string;
  role: Role;
  alias?: string;
  // telephoneNumber?: string;
  // telephoneUrl?: string;
  // useWebCall?: boolean;
  // includeShortCodeInLink?: boolean;
  // useVideo?: boolean;
  // canChangeUseVideo?: boolean;
  // useAudio?: boolean;
  // canChangeUseAudio?: boolean;
  // useCobrowse?: boolean;
  // canChangeUseCobrowse?: boolean;
  enableVideoRecording?: boolean;
};

//#region Types for Co-browsing

export enum ControlType {
  canvasUpdate = 'canvas-update',
  canvasDelete = 'canvas-delete',
  updateRoomMembersNum = 'update-room-members-num',
  requestFullSnapshot = 'request-full-snapshot',
  documentSharing = 'document-sharing',
  documentParepared = 'document-prepared',
  controlRequest = 'control-request',
  controlClick = 'control-click',
  controlDblClick = 'control-dblclick',
  controlRightClick = 'control-rightclick',
  controlMouseOver = 'control-mouse-over',
  controlMouseOut = 'control-mouse-out',
  controlScroll = 'control-scroll',
  controlFormChange = 'control-form-change',
  requestEndSession = 'request-end-session',
}

export type CanvasUpdateEvent = {
  type: ControlType.canvasUpdate;
  payload: string;
};

export type CanvasDeleteEvent = {
  type: ControlType.canvasDelete;
};

export type UpdateRoomMembersNum = {
  type: ControlType.updateRoomMembersNum;
  payload: number;
};

export type RequestFullSnapshotEvent = {
  type: ControlType.requestFullSnapshot;
};

export type ControlRequestEvent = {
  type: ControlType.controlRequest;
};

export type ControlClickEvent = {
  type: ControlType.controlClick;
  id: number;
};

export type ControlDblClickEvent = {
  type: ControlType.controlDblClick;
  id: number;
};

export type ControlRightClickEvent = {
  type: ControlType.controlRightClick;
  id: number;
};

export type ControlMouseOverEvent = {
  type: ControlType.controlMouseOver;
  id: number;
};

export type ControlMouseOutEvent = {
  type: ControlType.controlMouseOut;
  id: number;
};

export type ControlScrollEvent = {
  type: ControlType.controlScroll;
  id: number;
  x: number;
  y: number;
};

export type ControlFormChangeEvent = {
  type: ControlType.controlFormChange;
  id: number;
  value: string; // | number | boolean;
};

export type DocumentPreparedEvent = {
  type: ControlType.documentParepared;
  pageNum: number;
};

export type DocumentSharingEvent = {
  type: ControlType.documentSharing;
  payload: EncodeResult;
};

export type RequestEndSessionEvent = {
  type: ControlType.requestEndSession;
};

export type OptipassControlEvents =
  | CanvasUpdateEvent
  | CanvasDeleteEvent
  | DocumentPreparedEvent
  | DocumentSharingEvent
  | UpdateRoomMembersNum
  | RequestFullSnapshotEvent
  | ControlRequestEvent
  | ControlClickEvent
  | ControlMouseOverEvent
  | ControlMouseOutEvent
  | ControlDblClickEvent
  | ControlRightClickEvent
  | ControlScrollEvent
  | ControlFormChangeEvent
  | RequestEndSessionEvent;

export const enum CustomRecordEvent {
  windowUnload = 'window-unload',
  approveControl = 'approve-control',
  rejectControl = 'reject-cotrol',
  requestDocumentPage = 'request-document-page',
  notifyDestoryDocument = 'notify-destory-document',
  notifyBlobUrl = 'notify-bloburl',
  scrollBarOn = 'scroll-bar-on',
  endSession = 'end-session',
}

export type CustomRecordEventWithPayload =
  | (customEvent<undefined> & {
      data: {
        tag:
          | CustomRecordEvent.windowUnload
          | CustomRecordEvent.approveControl
          | CustomRecordEvent.rejectControl
          | CustomRecordEvent.endSession;
      };
    })
  | (customEvent<number> & {
      data: {
        tag: CustomRecordEvent.requestDocumentPage;
      };
    })
  | (customEvent<{
      md5: string;
      blobUrl: string;
    }> & {
      data: {
        tag: CustomRecordEvent.notifyBlobUrl;
      };
    })
  | (customEvent<boolean> & {
      data: {
        tag: CustomRecordEvent.scrollBarOn;
      };
    })
  | (customEvent<string[]> & {
      data: {
        tag: CustomRecordEvent.notifyDestoryDocument;
      };
    });

export type EncodeResult = {
  type: 'url' | 'image' | 'pdf' | 'unknown';
  docId: number;
  data: string | File;
  pageNum: number;
  pageCount: number;
};

export const enum EncodeResultError {
  unsupportedFile = 'unsupported-file',
  multiPdf = 'multipdf',
  unknownError = 'unknown-error',
}
//#endregion

//#region Types for Context Data

export type CDPEvents =
  | MouseMoveEvent
  | ClickEvent
  | VideoPlayEvent
  | VideoPauseEvent
  | VideoSeekEvent
  | InputEvent
  | SearchEvent
  | PageHideEvent
  | PageFocusEvent
  | PageBlurEvent;

export type EventSourceElement = {
  id: string;
  className: string;
  top: number;
  left: number;
};

export type EventSourceBase = {
  element: EventSourceElement;
};

export type MouseMoveEvent = {
  type: 'mousemove';
  content: {
    positions: {
      x: number;
      y: number;
      timeOffset: number;
    }[];
    source: number; // Incremental Source Type of rrweb
  };
};

export type ClickEvent = {
  type: 'click';
  content: EventSourceBase;
};

export type VideoPlayEvent = {
  type: 'videoplay';
  content: {
    currentTime?: number;
  } & EventSourceBase;
};

export type VideoPauseEvent = {
  type: 'videopause';
  content: {
    currentTime?: number;
  } & EventSourceBase;
};

export type VideoSeekEvent = {
  type: 'videoseek';
  content: {
    currentTime?: number;
  } & EventSourceBase;
};

export type InputEvent = {
  type: 'input';
  content: {
    isChecked: boolean;
    text: string;
    type: string;
    formName: string;
    userTriggered: boolean;
  } & EventSourceBase;
};

export type SearchEvent = {
  type: 'search';
  content: {
    text: string;
    type: string;
    formName: string;
  } & EventSourceBase;
};

export type PageHideEvent = {
  type: 'pagehide';
  content: Record<string, never>;
};

export type PageFocusEvent = {
  type: 'pagefocus';
  content: Record<string, never>;
};

export type PageBlurEvent = {
  type: 'pageblur';
  content: Record<string, never>;
};

export type SessionTypeExistence = {
  cobrowse: boolean;
  video: boolean;
  call: boolean;
};

export type UserInfo = {
  userId?: string;
  traits?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    age?: number;
    email?: string;
    phone?: string;
    address?: any;
    birthday?: Date;
    company?: any;
    createdAt?: Date;
    description?: string;
    denger?: string;
    title?: string;
    username?: string;
    website?: string;
    avatar?: string;
  };
  options?: {
    contactReason?: string;
    operator?: string;
    memo?: string;
    sessionType?: {
      cobrowse: string;
      video: string;
      call: string;
    };
    sessionAlias?: string;
  };
};

export type DashboardGadget = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

export type DashboardFacet = {
  id: string;
};

export type Dashboard = {
  id: string;
  name: string;
  jobIds: string[];
  userId: string;
  gadgets: DashboardGadget[];
  facets: DashboardFacet[];
  viewIds: string[];
  default: boolean;
};

export type View = {
  id: string;
  name: string;
  userId: string;
};

export type FacetType =
  | 'textbox'
  | 'checkbox'
  | 'searchbox'
  | 'datepicker'
  | 'datetimepicker'
  | 'dropdown';

export type MutateFilterHandler = (mutatedVals: any[]) => void;

export type FacetFilter = {
  key: string;
  value: string;
};

export type Facet = {
  id: string;
  name: string;
  type: FacetType;
  query?: () => Promise<FacetFilter[]>;
  handlers?: MutateFilterHandler[];
};

export type GraphType =
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'table'
  | 'number'
  | 'scatter'
  | 'heatmap'
  | 'sankey';

export type GraphCategory = 'chart' | 'table' | 'kpi' | 'pie';

export type Gadget = {
  id: string;
  name: string;
  category: GraphCategory;
  type: GraphType;
  description: string;
  query?: (params: any[]) => Promise<any>;
};

export type ContactReason = {
  id: string;
  name: string;
};
//#endregion
