import { OperatorInfoContext } from 'Components/Hooks/Operator';
import { PermissionContext } from 'Components/Hooks/Permission';
import React, { ReactNode, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OperatorInfo from 'Utils/OperatorInfo';
import { Permission } from 'Utils/Permission';
import supabase, { supabaseEnv } from 'Utils/supabase';

const PermissionProvider = (props: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [operatorInfo, setOperatorInfo] = React.useState<OperatorInfo>();
  const [permission, setPermission] = React.useState<Permission>();

  // useEffect内での onAuthStateChange だと間に合わないイベントがあり、useStateで初回のみ取得している。
  // 解放はuseEffectで行う。
  const [authListener] = useState(() =>
    supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_IN') {
        // サインイン検出時必要な情報をセット
        fetchOperatorContext();
        fetchPermissionContext();
      } else if (event === 'PASSWORD_RECOVERY') {
        // パスワードリカバリー時は registerページに遷移
        //（以下で使用しているhooks系パラメータは、マウント時の物しか取れていないので注意）
        const token = searchParams.get(supabaseEnv.RESET_TOKEN_KEY_NAME) ?? '';
        const state: { [key: string]: string } = {};
        state[supabaseEnv.RESET_TOKEN_KEY_NAME] = token;
        navigate('/register', { state });
      }
    })
  );

  const fetchOperatorContext = async () => {
    let operator = await OperatorInfo.load();
    if (operator == null) {
      // Nullの場合は未認証ユーザなのでゲストとして認識
      operator = OperatorInfo.createGuest();
    }
    setOperatorInfo(operator);
    return operator;
  };

  const fetchPermissionContext = async () => {
    let permission = await Permission.load();
    if (permission == null) {
      // Nullの場合は未認証ユーザなので、権限無しのゲストととして認識
      permission = Permission.createGuest();
    }
    setPermission(permission);
    return permission;
  };

  React.useEffect(() => {
    // ローカルにセッション情報がない場合
    const tokenSt = localStorage.getItem('supabase.auth.token');
    if (!tokenSt) {
      console.info('guest access');
      setOperatorInfo(OperatorInfo.createGuest());
      setPermission(Permission.createGuest());
    }

    return () => {
      authListener.data?.unsubscribe(); // onAuthStateChange の解除
    };
  }, [authListener.data]);

  return (
    <OperatorInfoContext.Provider value={operatorInfo}>
      <PermissionContext.Provider value={permission}>
        {props.children}
      </PermissionContext.Provider>
    </OperatorInfoContext.Provider>
  );
};

export default PermissionProvider;
