import Enumerable from 'linq';

import useCustomer from '../Customer';
import usePageHistories from '../Queries/PageHistories';

export const useInterestedPage = () => {
  const customerId = useCustomer()?.userId;
  const pageHistories = usePageHistories(customerId ?? '', true, {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
  });

  const data =
    pageHistories.data == null || pageHistories.data.length === 0
      ? undefined
      : Enumerable.from(pageHistories.data) // ページ一覧の
          .groupBy((x) => x.path) // pathでグループ化して
          .maxBy((x) => x.sum((y) => y.tracks.length)) // トラック数が最大の
          .toArray()[0].title; // pathを返す

  return { ...pageHistories, data };
};
