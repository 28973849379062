import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import LinkBehavior from 'Components/Atoms/LinkBehavior';
import useTranslation from 'Components/Hooks/Translate';
import ResponsiveDrawerContainer from 'Components/Molecules/Global/ResponsiveDrawerContainer';
import { useParams } from 'react-router-dom';

import { SettingsConfig } from './SettingsConfig';
import SettingsItem from './SettingsItem';

/**
 *
 * @param props
 * @returns トップメニューやサイドメニューを含む、設定画面のレイアウトコンポーネントを返却します
 */
const SettingsLayout = (props: { config: SettingsConfig }) => {
  // // ドロワーのレスポンシブ開閉
  // const isMiniSize = useMediaQuery((t: Theme) => t.breakpoints.down('md'));
  // const [isOpenMenu, setIsOpenMenu] = useState(isMiniSize === false);
  // useEffect(() => setIsOpenMenu(isMiniSize === false), [isMiniSize]);

  const t = useTranslation('Settings');

  // URLパラメータから選択テーブル取得
  const params = useParams<{
    settingTableId: string;
    settingItemId?: string;
  }>();
  const selectedTable = props.config.tables.find(
    (x) => x.name === params.settingTableId
  );
  if (selectedTable == null) return <></>;

  return (
    <ResponsiveDrawerContainer
      activeDrawer={true}
      // isMinimum={isOpenMenu === false}
      // minimumBreakpoint="md"
      anchor="left"
      drawers={[
        {
          // 履歴
          name: true,
          content: () => (
            <List
              sx={{
                background: (t) => t.palette.grey[100],
                height: 1,
              }}
              subheader={
                <ListSubheader component="div" sx={{ background: '#0000' }}>
                  {t('common.settingItems')}
                </ListSubheader>
              }
            >
              {props.config.tables.map((item) => (
                <Can
                  crud="read"
                  componentName={item.resourceName}
                  key={item.name}
                >
                  <ListItemButton
                    component={LinkBehavior}
                    href={item.name}
                    sx={{
                      minHeight: 48,
                      justifyContent: 'initial',
                      px: 2.5,
                      backgroundColor: (t) =>
                        params.settingTableId === item.name
                          ? t.palette.grey[300]
                          : 'none',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center',
                        color: (t) => t.palette.secondary.contrastText,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.displayName}
                      sx={{
                        color: (t) => t.palette.secondary.contrastText,
                      }}
                    />
                  </ListItemButton>
                </Can>
              ))}
            </List>
          ),
        },
      ]}
    >
      <Box sx={{ gridColumn: 2, height: 1 }}>
        {selectedTable.renderList?.(selectedTable)}
        <SettingsItem table={selectedTable}></SettingsItem>
      </Box>
    </ResponsiveDrawerContainer>
  );
};

export default SettingsLayout;
