/* eslint-disable react-hooks/exhaustive-deps */
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import FloatScrollBox from 'Components/Atoms/FloatScrollBox';
import useTranslation from 'Components/Hooks/Translate';
import AnalyticsFacet from 'Components/Molecules/Analytics/AnalyticsFacet';
import { Facet } from 'Utils/supabase';

interface AnalyticsFacetPaneProps {
  facets: Facet[];
  readonly: boolean;
  disabled: boolean;
  onClickEditFacet: () => void;
}

const AnalyticsFacetPane = ({
  facets,
  readonly,
  disabled,
  onClickEditFacet,
}: AnalyticsFacetPaneProps) => {
  const t = useTranslation('Analytics');

  const facetComps = facets?.map((f) => <AnalyticsFacet key={f} facet={f} />);

  return (
    <Box>
      <FloatScrollBox
        sx={{
          position: 'absolute',
          padding: 2,
          width: 1,
          height: 1,
          backgroundColor: (t) => t.palette.grey[100],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="subtitle2">
            {t('dashboard.gadgetitems')}
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <IconButton
              onClick={() =>
                document.dispatchEvent(new CustomEvent('resetAll'))
              }
              disabled={disabled}
            >
              <FilterAltOffIcon />
            </IconButton>
            <Can componentName="dashboard" crud="update">
              <IconButton
                disabled={disabled}
                onClick={() => onClickEditFacet()}
              >
                <SettingsOutlinedIcon />
              </IconButton>
            </Can>
          </Box>
        </Box>
        {facetComps ?? (
          <Typography variant="body1">{t('dashboard.nofacet')}</Typography>
        )}
      </FloatScrollBox>
      {readonly && (
        <Box
          sx={{
            width: 1,
            height: 1,
            position: 'absolute',
            backgroundColor: (t) => t.palette.grey[100],
            opacity: '0.8',
          }}
        />
      )}
    </Box>
  );
};

export default AnalyticsFacetPane;
