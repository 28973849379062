import {
  Box,
  Breakpoint,
  Drawer,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

// const defaultWidth = 240;

export type ResponsiveDrawerContainerItem<T> = {
  name: NonNullable<T>;
  content: () => ReactNode;
};

export type ResponsiveDrawerContainerProps<T> = {
  children: ReactNode;
  anchor: 'left' | 'right';

  activeDrawer: T;
  drawers: ResponsiveDrawerContainerItem<T>[];

  temporaryBreakpoint?: Breakpoint; // undefined の時は一時表示にならない

  isMinimum?: boolean; // undefined の時は minimumBreakpoint に応じて自動調整
  minimumWidth?: number;
  minimumBreakpoint?: Breakpoint; // undefined の時は小さくならない

  onClose?: () => void;
};

/** 横方向のレスポンシブ対応のドロワー表示可能なコンテナ */
const ResponsiveDrawerContainer = <T,>(
  props: ResponsiveDrawerContainerProps<T>
) => {
  // ドロワーの表示がtemporaryになるサイズかどうか
  const isTempSizeEnabled = props.temporaryBreakpoint != null;
  const isTempSize =
    useMediaQuery((t: Theme) =>
      t.breakpoints.down(props.temporaryBreakpoint ?? 'md')
    ) && isTempSizeEnabled;

  // ドロワーの表示がminimumになるサイズかどうか
  const isMiniSize =
    useMediaQuery((t: Theme) =>
      t.breakpoints.down(props.minimumBreakpoint ?? 'md')
    ) && props.minimumBreakpoint != null;
  const isMinimum = props.isMinimum ?? isMiniSize; // 指定があればそっちを優先

  const drawer = props.drawers.find((x) => x.name === props.activeDrawer);

  // ドロワー幅を監視してWidthを取得
  const resizeObserver = useRef<ResizeObserver>();
  const [drawerContentDiv, setDrawerDiv] = useState<HTMLDivElement | null>(
    null
  );
  const [drawerContentWidth, setDrawerContentWidth] = useState<number>(0);
  useEffect(() => {
    if (drawerContentDiv != null) {
      resizeObserver.current = new ResizeObserver((_entries) => {
        if ((drawerContentDiv?.clientWidth ?? 0) === 0) return;
        setDrawerContentWidth(drawerContentDiv.clientWidth);
      });
      resizeObserver.current.observe(drawerContentDiv);
    }
    return () => {
      if (resizeObserver.current != null) {
        resizeObserver.current.disconnect();
      }
    };
  }, [drawerContentDiv]);

  // ドロワーの幅がセットされるまではコンテンツ部の幅アニメーションを抑制
  const [isAnimeEnabled, setIsAnimeEnabled] = useState(false);
  useEffect(() => {
    if (drawerContentWidth > 0) {
      // 少し待機してからアニメーションは有効にする（ちょっといまいちな実装）
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 100));
        setIsAnimeEnabled(true);
      })();
    }
  }, [drawerContentWidth]);

  // 閉じる時いきなり消えるのを抑制
  const [keepDrawerName, setKeepDrawerName] = useState<T>();
  const keepDrawer = props.drawers.find((x) => x.name === keepDrawerName);
  useEffect(() => {
    if (drawer != null) {
      setKeepDrawerName(drawer.name);
    }
  }, [drawer]);

  const drawerWidth = isMinimum ? props.minimumWidth ?? 64 : drawerContentWidth;

  return (
    // このBox内にドロワー表示するためrelativeにする。
    <Box sx={{ width: 1, height: 1, position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          display: 'grid',
          width: 1,
          height: 1,
          gridTemplateColumns: 'auto 1fr auto',
          overflowX: 'hidden',
        }}
      >
        {props.anchor === 'left' && ( // 左の余白
          <WidthAnimationBox
            enabled={isAnimeEnabled}
            display={isTempSize ? 'none' : 'block'}
            gridColumn={1}
            width={drawer != null ? drawerWidth : 0}
          />
        )}

        <Box sx={{ gridColumn: 2, height: 1 }}>{props.children}</Box>

        {props.anchor === 'right' && ( // 右の余白
          <WidthAnimationBox
            enabled={isAnimeEnabled}
            display={isTempSize ? 'none' : 'block'}
            gridColumn={3}
            width={drawer != null ? drawerWidth : 0}
          />
        )}
      </Box>

      <Drawer
        open={drawer != null}
        anchor={props.anchor}
        onClose={() => props.onClose?.()}
        variant={isTempSize ? 'temporary' : 'persistent'}
        SlideProps={{
          mountOnEnter: true,
          // unmountOnExit: true, // いきなり飛び出る描画が入ってしまうため true にしない
        }}
        PaperProps={
          isTempSize
            ? undefined
            : {
                sx: {
                  position: 'absolute',
                  zIndex: 0,
                  boxShadow: 2,
                  borderWidth: 0,
                },
              }
        }
      >
        <WidthAnimationBox
          enabled={isAnimeEnabled}
          sx={{
            height: '100%',
            width: drawerWidth,
            position: 'relative',
            overflowX: 'hidden',
          }}
        >
          <div
            style={{
              height: '100%',
              marginRight: -10000,
              position: 'absolute',
            }}
            ref={(v) => {
              if (v != null) {
                setDrawerDiv(v);
              }
            }}
          >
            {(drawer ?? keepDrawer)?.content()}
          </div>
        </WidthAnimationBox>
      </Drawer>
    </Box>
  );
};

export default ResponsiveDrawerContainer;

/** ドロワーと同じ速度で幅調整するボックス */
const WidthAnimationBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'enabled',
})<{ enabled?: boolean }>((props) => ({
  transition:
    props.enabled ?? true
      ? props.theme.transitions.create(['width'], {
          easing: props.theme.transitions.easing.easeInOut,
          duration: props.theme.transitions.duration.leavingScreen,
          // drawerはenter時、本当はenteringScreenを適用したい
        })
      : undefined,
}));
