import { useQuery, UseQueryOptions } from 'react-query';
import supabase, { PermissionMaster } from 'Utils/supabase';

// パーミッションマスターデータを取得するHooks
export const usePermissionMasters = (
  options?: Omit<UseQueryOptions<PermissionMaster[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery<PermissionMaster[]>(
    ['Permission Master'],
    async () => {
      const { data, error } = await supabase
        .from<PermissionMaster>('permissions')
        .select();
      if (error) throw error;
      return data;
    },
    options
  );
