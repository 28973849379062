import Enumerable from 'linq';

import useCustomer from '../Customer';
import useTrackHistories from '../Queries/TrackHistories';

export const useFrequentSearchWord = () => {
  const customerId = useCustomer()?.userId;
  const trackHistories = useTrackHistories(customerId ?? '', {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  const data = (() => {
    if (trackHistories.data == null || trackHistories.data.length === 0)
      return null;

    const searchedWords = trackHistories.data.filter(
      (x) => x.element.type === 'seachtype' && x.element.text !== ''
    );
    console.debug({ searchedWords });
    if (searchedWords.length === 0) return null;

    return (
      Enumerable.from(searchedWords)
        .select((x) => x.element.text)
        //検索textをグルーピングしてユニーク化 例：{"tako","tako","ika","tako","ika"} ->{"tako","ika"}
        .groupBy((x) => x)
        //各検索文字列の検索回数を抽出 例：{["tako",3],["ika",2]}
        .select((x) => ({ text: x.key(), count: x.count() }))
        //検索回数でグルーピングし、最大回数を抽出 例：3
        .groupBy((x) => x.count)
        //最大回数を持つ検索文字列を抽出 例：tako
        .maxBy((x) => x.key())
        .select((x) => x.text)
        //配列化
        .toArray()
    );
  })();
  return {
    ...trackHistories,
    data: data,
  };
};
