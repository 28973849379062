import Cookies from 'js-cookie';
import yupJp from 'Locales/ja/yup';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import browserLocale from 'Utils/browserLocale';
import * as i18nYup from 'yup';
import yupEn from 'yup/lib/locale';

// eslint-disable-next-line @typescript-eslint/naming-convention
const COOKIE_LANG = 'op-lang';

const useLocale = () => {
  const query = useQuery<string>(['Selected Language'], {
    enabled: false,
    initialData: Cookies.get(COOKIE_LANG) ?? browserLocale,
  });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return query.data!;
};
export default useLocale;

export const useUpdateLocale = () => {
  const queryClient = useQueryClient();
  const t = useTranslation();

  return (value: string) => {
    queryClient.setQueryData<string>(['Selected Language'], value);

    // yupのロケール変更。（すでに画面に出ているメッセージ系は直ちに反映されない。再度のバリデーションで反映される）
    if (value === 'ja') {
      i18nYup.setLocale(yupJp);
    } else {
      i18nYup.setLocale(yupEn);
    }

    // 翻訳のロケール変更。
    t.i18n.changeLanguage(value);

    // 設定値を保存
    Cookies.set(COOKIE_LANG, value);
  };
};
