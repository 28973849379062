import { useQuery, UseQueryOptions } from 'react-query';
import { AppError } from 'Utils/error';
import supabase, { SessionHistory } from 'Utils/supabase';

const useSessionStartTime = (
  iid: string,
  options?: Omit<UseQueryOptions<number>, 'queryKey' | 'queryFn'>
) =>
  useQuery(
    ['SessionStartTime', iid],
    async () => {
      const { data, error } = await supabase
        .from<SessionHistory>('session_histories')
        .select()
        .match({ iid })
        .maybeSingle();
      if (error != null)
        throw new AppError(
          error,
          'Sys',
          'SessionConsumerSummary',
          'ReadOne',
          '01',
          '01'
        );
      if (data == null)
        throw new AppError(
          `Not found iid: ${iid}`,
          'Sys',
          'SessionConsumerSummary',
          'ReadOne',
          '02',
          '01'
        );

      return new Date(data.startedAt + 'Z').getTime();
    },
    options
  );

export default useSessionStartTime;
