//import { Permission } from '../../../Utils/Permission';
//import {} from '../../Can';
// interface CanParams {
//   componentName: string;
//   crud: string;
//   perform: string;
//   yes?: Function;
//   no?: () => null;
// }

import usePermission from 'Components/Hooks/Permission';
import { Components, components } from 'Utils/Permission';

const Can = (props: {
  crud: string;
  componentName?: Components | Components[];
  no?: JSX.Element;
  children: JSX.Element;
}) => {
  const no = props.no ?? null;
  const permission = usePermission();
  const check = () => {
    // 配列に成形
    const componentNames = (() => {
      if (props.componentName == null) return [components.InvalidComponent];
      if (typeof props.componentName === 'string') return [props.componentName];
      else return props.componentName;
    })();

    //return true;
    switch (props.crud) {
      case 'read':
        return componentNames.some((comp) => permission?.hasRead(comp));
      case 'create':
        return componentNames.some((comp) => permission?.hasCreate(comp));
      case 'update':
        return componentNames.some((comp) => permission?.hasEdit(comp));
      case 'delete':
        return componentNames.some((comp) => permission?.hasDelete(comp));
    }
  };
  if (!check()) {
    return no;
  }
  return props.children;
};

export default Can;
