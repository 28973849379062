import { BinaryFilter } from '@cubejs-client/core';
import moment from 'moment';
import { convertFromPeriodExp } from 'Utils/PeriodConverter';

import { BaseFilter, FacetValue } from './FacetValueBase';

export type RangeChoosePeriod = {
  type:
    | 'today'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'thisYear'
    | 'lastYear'
    | 'custom'
    | 'none';
  range?: { start: Date; end: Date };
};

export type DateTimeRangeFacetValuePayload = RangeChoosePeriod;
export class DateTimeRangeFacetValue extends FacetValue<DateTimeRangeFacetValuePayload> {
  constructor(payload: DateTimeRangeFacetValuePayload) {
    super('DateTimeRangeFacet', payload);
  }

  arrangeDateRange = (
    tableName: string,
    startDt: Date | undefined,
    endDt: Date | undefined
  ): BinaryFilter => {
    const format = 'YYYY-MM-DD';
    return {
      member:
        tableName.indexOf('.') !== -1
          ? `${tableName}`
          : `${tableName}.receivedAt`,
      operator: 'inDateRange',
      values: [
        moment(startDt).format(format).toString(),
        moment(endDt).format(format).toString(),
      ],
    };
  };

  filter(tableName?: string): BaseFilter | undefined {
    if (this.payload.type === 'none') {
      return undefined;
    } else if (this.payload.type === 'custom') {
      if (!this.payload.range) throw new Error('unexpected range undefined');
      const startDt = this.payload.range.start;
      const endDt = this.payload.range.end;
      return this.arrangeDateRange(tableName ?? 'Users', startDt, endDt);
    } else {
      const [startDt, endDt] = convertFromPeriodExp(this.payload.type);
      return this.arrangeDateRange(tableName ?? 'Users', startDt, endDt);
    }
  }

  isDefault(): boolean {
    return this.payload.type === 'none';
  }
}
