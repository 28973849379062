import { Typography } from '@mui/material';
import GadgetFlame from 'Components/Atoms/GadgetFlame';
import { useGetGadgetType } from 'Components/Hooks/AccessGadgetFacetMaster';
import useGetGadgetProfile from 'Components/Hooks/GetGadgetProfile';
import React, { useMemo, memo } from 'react';
import { Gadget } from 'Utils/supabase';

import GranularitySwitcher from './GranularitySwitcher';
import PercentageSwitcher from './PercentageSwitcher';

interface AnalyticsGadgetProps {
  gadget: Gadget;
  onChange?: (format: string) => void;
}
// eslint-disable-next-line react/display-name
const AnalyticsGadget = memo(
  (props: AnalyticsGadgetProps) => {
    const getGadgetProfile = useGetGadgetProfile();
    const getGadgetType = useGetGadgetType();
    const { gadget } = props;
    const gadgetType = getGadgetType(gadget);
    if (!gadgetType) throw new Error('Could not find any gadget type.');
    const gadgetName = getGadgetProfile(gadget).name;

    // レンダリングのたびに生成されるのを防ぐためのメモ化
    const GadgetComponent = useMemo(
      () =>
        React.lazy(() => import(`../../Atoms/Gadgets/${gadgetType}/${gadget}`)),
      [gadgetType, gadget]
    );
    return (
      <GadgetFlame
        name={gadgetName}
        title={
          <>
            <Typography
              variant="caption"
              noWrap
              sx={{ color: (t) => t.palette.grey[600] }}
            >
              {gadgetName}
            </Typography>
            <GranularitySwitcher gadget={gadget} />
            <PercentageSwitcher gadget={gadget} />
          </>
        }
      >
        <GadgetComponent />
      </GadgetFlame>
    );
  },
  (prev, next) => prev.gadget === next.gadget
);

export default AnalyticsGadget;
