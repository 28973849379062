import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { Box, Button, Typography, Stack } from '@mui/material';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import Can from 'Components/Atoms/AccessControl/Can';
import useConfirmDelete from 'Components/Hooks/ConfirmDelete';
import useLoading from 'Components/Hooks/Loading';
import { useNotify } from 'Components/Hooks/Notify';
import useDashboards, {
  useDeleteDashboards,
} from 'Components/Hooks/Queries/Dashboards';
import useTranslation from 'Components/Hooks/Translate';
import DashboardTable from 'Components/Molecules/Analytics/DashboardTable';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppError } from 'Utils/error';
import { components } from 'Utils/Permission';

const Dashboards = () => {
  const navigate = useNavigate();
  const t = useTranslation('Analytics');
  const notify = useNotify();
  const confirmDelete = useConfirmDelete();

  const dashboards = useDashboards({ refetchOnWindowFocus: false });
  const deleteDashboards = useDeleteDashboards();

  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  useLoading(deleteDashboards.isLoading); //ロード中を表示

  const deleteSelectedItems = async () => {
    if (await confirmDelete()) {
      try {
        await deleteDashboards.mutateAsync(
          selectionModel.map((x) => x.toString())
        );
        notify(t('notify.delete'), 'success');
      } catch (e) {
        throw new AppError(e, 'Sys', 'AnalyticsDashboard', 'DeleteMany', '01');
      }
    }
  };

  return (
    <>
      <Box
        sx={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}
      >
        <Box sx={{ gridRow: 1, px: 6, pt: 3 }}>
          <Stack
            direction={'row'}
            spacing={2}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography>{t('dashboardadmin.title')}</Typography>
            <Stack direction={'row'} spacing={2}>
              {' '}
              <Can componentName={components.Dashboard} crud="create">
                <Button
                  variant="shadow"
                  startIcon={<LibraryAddOutlinedIcon />}
                  size="large"
                  onClick={() => navigate('create')}
                >
                  {t('dashboardadmin.add')}
                </Button>
              </Can>
              <Can componentName={components.Dashboard} crud="delete">
                <Button
                  startIcon={<DeleteOutlinedIcon />}
                  disabled={selectionModel.length === 0}
                  onClick={deleteSelectedItems}
                  color="warning"
                >
                  {t('dashboardedit.delete')}
                </Button>
              </Can>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ gridRow: 2, px: 6, py: 4 }}>
          <DashboardTable
            dashboards={dashboards.data ?? []}
            isLoading={dashboards.isLoading}
            onRequestEdit={async (id: string) => navigate(id)}
            setSelectionModel={(select: GridRowId[]) => {
              setSelectionModel(select);
            }}
          />
        </Box>
      </Box>
    </>
  );
};
export default Dashboards;
