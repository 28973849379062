import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useElapsedTimeString } from 'Components/Hooks/ElapsedTimeString';
import { useElapsedTime } from 'Components/Hooks/SummaryQueries/ElapsedTime';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const ElapsedTime = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useElapsedTime();
  const content = useElapsedTimeString(-data);

  return (
    <Panel
      icon={<AccessTimeIcon />}
      title={t('ElapsedTime.title')}
      content={`${content.value}${content.unit}`}
      isLoading={isLoading}
    />
  );
};

export default ElapsedTime;
