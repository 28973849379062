import { Box, FormControl } from '@mui/material';
import { ReactNode } from 'react';

import AnalyticsMenu from './AnalyticsMenu';

interface AnalyticsHeaderProps {
  onEditGadgets: () => void;
  children: ReactNode;
}

const AnalyticsHeader = ({ onEditGadgets, children }: AnalyticsHeaderProps) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      height: '65px',
      alignItems: 'center',
    }}
  >
    <FormControl
      fullWidth
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mt: '11px',
        width: 'max-content',
      }}
    >
      {children}
    </FormControl>
    <AnalyticsMenu onEditGadgets={onEditGadgets} />
  </Box>
);

export default AnalyticsHeader;
