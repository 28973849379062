/** 文字列を元に色を生成（同じ文字列では毎回同じ色になる） */
export default function colors(string: string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = (hash >> (i * 8)) & 0xff;
  const s = 0.43 * 100;
  const l = 0.65 * 100;
  const color = `hsl(${h}, ${s}%, ${l}%)`;
  return color;
}
