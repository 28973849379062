import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { AppError } from 'Utils/error';
import { assertNotNull } from 'Utils/guard';
import supabase, { DashboardGadget, Gadget } from 'Utils/supabase';

const key = 'dashboard_gadgets';

const useGadgets = (
  dashboardId?: string,
  options?: Omit<UseQueryOptions<DashboardGadget[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery<DashboardGadget[]>(
    [key, dashboardId ?? ''],
    async () => {
      const { data, error } = await supabase
        .from<DashboardGadget>('dashboard_gadgets')
        .select()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .eq('dashboardId', dashboardId!);
      if (!data || error) throw error;
      return data;
    },
    options
  );
export default useGadgets;

export const useUpsertGadget = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: {
      dashboardId: string;
      gadget: Gadget;
      width: number;
      height: number;
      x: number;
      y: number;
    }) => {
      const { data, error } = await supabase
        .from<DashboardGadget>('dashboard_gadgets')
        .upsert(item)
        .single();
      if (error) throw error;
      assertNotNull(data);
      return data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([key, variables.dashboardId]);
        queryClient.invalidateQueries([key, '']);
      },
    }
  );
};

export const useDeleteGadget = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: { dashboardId: string; gadget: Gadget }) => {
      const { data, error } = await supabase
        .from<DashboardGadget>('dashboard_gadgets')
        .delete()
        .match(item)
        .maybeSingle();
      if (error != null)
        throw new AppError(
          error,
          'Sys',
          'AnalyticsDashboardGadget',
          'DeleteOne',
          '01'
        );
      if (data == null)
        throw new AppError(
          error,
          'Sys',
          'AnalyticsDashboardGadget',
          'DeleteOne',
          '02'
        );
      return data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([key, variables.dashboardId]);
        queryClient.invalidateQueries([key, '']);
      },
    }
  );
};
