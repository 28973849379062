import { assign, createMachine, interpret, StateMachine } from '@xstate/fsm';
import jwtDecode from 'jwt-decode';
import { Handler } from 'mitt';
import { io, Socket } from 'socket.io-client';

import '../styles/common.css';
import {
  SdkContext,
  SdkEvent,
  sdkMachine,
  SdkState,
  StartInitEvent,
} from '../machines/sdk-machine';

import { OptipassSDKConfig } from './common-types';
import CallbackEvent, { CallbackEvents } from './sdkEmitter';

enum SDKError {
  noToken = 'Token is not specified',
  invalidToken = 'Token is invalid',
  serverError = 'Server returns error',
  invalidRole = 'Role is invalid',
}

export default class OptipassSDK {
  protected socket?: Socket;
  private service: StateMachine.Service<SdkContext, SdkEvent, SdkState>;

  /**
   * OptipassSDK class contructor
   */
  constructor() {
    this.service = this.createSdkMachine();
    this.setCallbackFunctions();
    this.service.start();
  }

  get eventListeners() {
    return CallbackEvent.instance.all;
  }

  /**
   * Start session
   *
   * @param token - Session token which provided by API Gateway
   */
  startSession(token?: string): boolean {
    let ret = false;
    if (this.isSessionActive()) throw new Error('Session is ongoing!');
    if (token) {
      const config = this.validateToken(token);
      if (config) {
        this.service.send({ type: 'START_INIT', token, config });
        ret = true;
      } else {
        this.service.send({
          type: 'INIT_FAILURE',
          error: SDKError.invalidToken,
        });
      }
    } else {
      this.service.send({ type: 'INIT_FAILURE', error: SDKError.noToken });
    }
    return ret;
  }

  /**
   * End session
   */
  endSession(): void {
    this.service.send('DISCONNECT');
  }

  /**
   * SDK state and properties will be reset
   */
  resetSdk(): void {
    this.service.send('RESET');
  }

  /**
   * Returns if the session is active
   */
  isSessionActive(): boolean {
    return (
      this.service.state.matches('agent') ||
      this.service.state.matches('customer') ||
      this.service.state.matches('initializing')
    );
  }

  /**
   * Returns SDK State
   */
  getState(): string {
    return this.service.state.value;
  }

  /**
   * Returns SDK config
   */
  getConfig(): OptipassSDKConfig | undefined {
    return this.service.state.context.config;
  }

  on(type: keyof CallbackEvents, handler: Handler<unknown>) {
    CallbackEvent.instance.on(type, handler);
  }

  off(type: keyof CallbackEvents, handler: Handler<unknown>) {
    CallbackEvent.instance.off(type, handler);
  }

  /**
   * Returns JWT Token
   */
  protected getToken(): string | undefined {
    return this.service.state.context.token;
  }

  protected initialize(
    _context: SdkContext,
    event: SdkEvent,
    query?: { [key: string]: any }
  ): {
    socket: Socket;
    config: OptipassSDKConfig;
    token: string;
  } {
    const startInitEvent = event as StartInitEvent;
    const config = startInitEvent.config as OptipassSDKConfig;
    const token = startInitEvent.token as string;

    this.socket = io(`${config.eventGatewayServer}/${config.job}`, {
      path: '/event-gateway',
      auth: {
        token,
      },
      autoConnect: false,
      transports: ['websocket'],
      query,
    });

    this.socket.on('connect', () => {
      console.log('connected');
      switch (config.role) {
        case 'agent':
          this.service.send('START_AGENT');
          break;
        case 'customer':
          this.service.send('START_CUSTOMER');
          break;
        case 'viewer':
          break;
        default:
          this.service.send({
            type: 'INIT_FAILURE',
            error: SDKError.invalidRole,
          });
      }
    });

    this.socket.on('connect_error', (error: Error) => {
      this.service.send({
        type: 'INIT_FAILURE',
        error: SDKError.serverError,
        serverError: error.message,
      });
    });

    return { socket: this.socket, config, token };
  }

  protected failedOrDisconnect(context: SdkContext) {
    context.socket?.disconnect();
    context.socket = undefined;
  }

  private createSdkMachine() {
    const sdkMachineProps = {
      actions: {
        initialize: assign(this.initialize.bind(this)),
        connect: this.connect.bind(this),
        failed: this.failedOrDisconnect.bind(this),
        disconnect: this.failedOrDisconnect.bind(this),
        reset: assign(this.reset.bind(this)),
      },
    };
    const machine = createMachine<SdkContext, SdkEvent, SdkState>(
      sdkMachine,
      sdkMachineProps
    );
    return interpret(machine);
  }

  private connect(context: SdkContext) {
    context.socket?.open();
  }

  private reset(context: SdkContext) {
    return {
      debug: context.debug,
      token: undefined,
      config: undefined,
      socket: undefined,
    };
  }

  // private setDebugLevel(context: SdkContext, event: SdkEvent) {
  //   const startInitEvent = event as StartInitEvent;
  //   const debug = startInitEvent.debug;
  //   return assign({ debug });
  // }

  private validateToken(token: string) {
    const config = jwtDecode<OptipassSDKConfig>(token);
    if (config.eventGatewayServer && config.job && config.iid && config.role) {
      return config;
    } else {
      return null;
    }
  }

  /**
   * Returns callback function
   */
  private setCallbackFunctions() {
    this.service.subscribe((state) => {
      if (state.changed) {
        CallbackEvent.instance.emit('sdk-state-changed', {
          state: state.value,
        });
        switch (true) {
          case state.matches('idle'):
            CallbackEvent.instance.emit('sdk-state-idle');
            break;
          case state.matches('initializing'):
            CallbackEvent.instance.emit('sdk-state-initializing');
            break;
          case state.matches('agent'):
            CallbackEvent.instance.emit('sdk-state-agent');
            break;
          case state.matches('customer'):
            CallbackEvent.instance.emit('sdk-state-customer');
            break;
          case state.matches('failed'):
            CallbackEvent.instance.emit('sdk-state-failed');
            break;
          case state.matches('disconnected'):
            CallbackEvent.instance.emit('sdk-state-disconnected');
            break;
        }
      }
    });
  }
}
