import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import { IconButton, Stack, Tooltip, styled } from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import useTranslation from 'Components/Hooks/Translate';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { assertNotNull } from 'Utils/guard';

interface AnalyticsMenuProps {
  onEditGadgets: () => void;
}

const GadgetMenuButton = styled(IconButton)(({ theme }) => ({
  boxShadow: theme.shadows[2],
  border: 'solid #ddd 1px',
}));

const AnalyticsMenu = (props: AnalyticsMenuProps) => {
  const t = useTranslation('Analytics');
  const navigate = useNavigate();

  const params = useParams<{ dashboardId?: string }>();
  const isEditingLayout = useLocation().pathname.endsWith('edit-layout');

  const moveToDashboards = () => navigate('/analytics/dashboards');
  const startLayoutEdit = () => {
    assertNotNull(params.dashboardId);
    navigate(params.dashboardId + '/edit-layout');
  };
  const endLayoutEdit = () => {
    assertNotNull(params.dashboardId);
    navigate(params.dashboardId);
  };
  const editGadgets = () => {
    props.onEditGadgets();
  };

  return (
    <Stack direction="row" spacing={2} sx={{ ml: 'auto' }}>
      {isEditingLayout ? (
        <Tooltip title={t('menu.layouteditOff') ?? ''}>
          <GadgetMenuButton onClick={endLayoutEdit} color="primary">
            <AutoAwesomeMosaicIcon />
          </GadgetMenuButton>
        </Tooltip>
      ) : (
        <Can componentName="dashboard" crud="update">
          <Tooltip title={t('menu.layouteditOn') ?? ''}>
            <GadgetMenuButton onClick={startLayoutEdit} color="primary">
              <AutoAwesomeMosaicOutlinedIcon />
            </GadgetMenuButton>
          </Tooltip>
        </Can>
      )}
      <Can componentName="dashboard" crud="update">
        <Tooltip title={t('menu.gadgetsedit') ?? ''}>
          <GadgetMenuButton onClick={editGadgets} color="primary">
            <AssessmentOutlinedIcon />
          </GadgetMenuButton>
        </Tooltip>
      </Can>
      <Tooltip title={t('menu.dashboardedit') ?? ''}>
        <GadgetMenuButton onClick={moveToDashboards} color="primary">
          <BuildOutlinedIcon />
        </GadgetMenuButton>
      </Tooltip>
    </Stack>
  );
};

export default AnalyticsMenu;
