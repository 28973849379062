import { AccessControl } from 'accesscontrol';

import authProvider from './authProvider';
import { definitions } from './types';

export type Permit = Pick<
  definitions['permissions'],
  'object' | 'action' | 'scope'
>;

// const assertionを使って、リテラル型として扱う
export const components = {
  User: 'user',
  Role: 'role',
  Job: 'job',
  ContactReason: 'contact_reason',
  Dashboard: 'dashboard',
  ContactReasonCategory: 'contact_reason_category',
  Cobrowse: 'cobrowse',
  View: 'view',
  VoiceCall: 'voice_call',
  VideoCall: 'video_call',
  SessionHistory: 'session_history',
  InvalidComponent: 'invalid_component',
} as const;
export type Components = typeof components[keyof typeof components];

export class Permission {
  private _isGuest: boolean;
  private _accessControl: AccessControl;

  constructor(param?: Permit[], isGuest?: boolean) {
    this._isGuest = isGuest ?? false;

    const grantList = new Array<{
      role: string;
      resource: string;
      action: string;
      attributes: string;
    }>();

    if (!isGuest && param) {
      param.forEach((prm) => {
        //ロール名は任意
        grantList.push({
          role: 'role',
          resource: prm.object,
          action: prm.action + ':' + prm.scope,
          attributes: '*',
        });
      });
    }

    //権限を割り当て。ゲストユーザーの場合は権限なし。
    this._accessControl = new AccessControl(grantList);
    // console.log(this._accessControl);
    // console.log(grantList);
    if (!param?.length) {
      //ロールが１つもないユーザー(新規作成されたばかりのユーザーを含む)には空のroleを付与
      this._accessControl.setGrants({ role: {} });
    }
  }

  static createGuest() {
    return new Permission(undefined, true);
  }

  static async load() {
    return await authProvider.getPermissions();
  }

  hasRead(componentName: Components) {
    return (
      this._accessControl.can('role').readOwn(componentName).granted ||
      this._accessControl.can('role').readAny(componentName).granted
    );
  }
  hasEdit(componentName: Components) {
    return (
      this._accessControl.can('role').updateOwn(componentName).granted ||
      this._accessControl.can('role').updateAny(componentName).granted
    );
  }
  hasCreate(componentName: Components) {
    return (
      this._accessControl.can('role').createOwn(componentName).granted ||
      this._accessControl.can('role').createOwn(componentName).granted
    );
  }
  hasDelete(componentName: Components) {
    return (
      this._accessControl.can('role').deleteOwn(componentName).granted ||
      this._accessControl.can('role').deleteAny(componentName).granted
    );
  }

  isGuest() {
    return this._isGuest;
  }
}
