import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/** conditionがtrueになるタイミングで再度初期値をセットする useStateの拡張hooks */
export function useStateLazy<S>(
  props: {
    initialState: S;
    condition: boolean;
  }
  //  initedEffect?: (value: S) => void
): [S, Dispatch<SetStateAction<S>>, boolean] {
  const { initialState, condition } = props;
  const [isFirst, setIsFirst] = useState(true);
  const result = useState<S>(initialState);
  const func = result[1];
  useEffect(() => {
    if (condition && isFirst) {
      setIsFirst(false);
      func(initialState);
      //      initedEffect?.(initialState);
    }
  }, [condition, isFirst, initialState, func]);
  //}, [condition, isFirst, initialState, func, initedEffect]);
  return [result[0], result[1], isFirst === false]; // 第三引数は、初期化済みかどうかが入る
}

// /** conditionがtrueになるタイミングで再度初期値をセットする useStateの拡張hooks */
// export function useStateWithChange<S>(
//   initialState: S,
//   dependency: any
// ): [S, Dispatch<SetStateAction<S>>] {
//   // const [isFirst, setIsFirst] = useState(true);
//   const result = useState<S>(initialState);
//   const func = result[1];
//   const prev = usePrevious(dependency);
//   useEffect(() => {
//     if (prev !== dependency) {
//       func(initialState);
//     }
//   }, [dependency, initialState, func]);
//   return result;
// }

// /** conditionがtrueであれば値をセットしなおす useStateの拡張hooks */
// export function useStateWithCondition<S>(
//   state: S,
//   overwriteCondition: boolean
// ): [S, Dispatch<SetStateAction<S>>] {
//   const result = useState<S>(state);
//   const func = result[1];
//   useEffect(() => {
//     if (overwriteCondition) {
//       func(state);
//     }
//   }, [state, func]);
//   return result;
// }

// // Hook
// export function usePrevious<T>(value: T) {
//   // The ref object is a generic container whose current property is mutable ...
//   // ... and can hold any value, similar to an instance property on a class
//   const ref = useRef<T>();
//   // Store current value in ref
//   useEffect(() => {
//     ref.current = value;
//   }, [value]); // Only re-run if value changes
//   // Return previous value (happens before update in useEffect above)
//   return ref.current;
// }
