import useTranslation from './Translate';

/**
 * タイムスタンプ値を分・時間・日表記に直すHooks
 */
export const useElapsedTimeString = (ts: number) => {
  const t = useTranslation('Global');
  const elapsedMinutes = Math.floor(ts / 60000);

  let value: number | undefined = 0;
  let unit = '';
  if (elapsedMinutes < 60) {
    value = elapsedMinutes;
    unit = t('elapsedTime.minute');
  } else if (elapsedMinutes < 1440) {
    value = Math.floor(elapsedMinutes / 60);
    unit = t('elapsedTime.hour');
  } else if (elapsedMinutes >= 1440) {
    value = Math.floor(elapsedMinutes / 1440);
    unit = t('elapsedTime.day');
  } else {
    value = undefined;
    unit = 'no data';
  }

  return { value: value, unit: unit };
};
