import {
  TextField,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

// 編集フォームの実装
const ContactReasonForm = (props: {
  isNew: boolean;
  item: any;
  form: UseFormReturn<any, any>;
}) => {
  const t = useTranslation('Settings');
  const {
    register,
    formState: { errors },
  } = props.form;

  const [selectedJobId, setSelectedJobId] = useState<string>(
    props.item?.jobId ?? ''
  );

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(
    props.item?.contactReasonCategoryId ?? ''
  );

  const onChangeJob = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    const jobId = value;
    setSelectedJobId(jobId);
    props.form.setValue('jobId', jobId);
    setSelectedCategoryId('');
    props.form.setValue('contactReasonCategoryId', '');
  };

  const onChangeCategory = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    const caregoryId = value;
    setSelectedCategoryId(caregoryId);
    props.form.setValue('contactReasonCategoryId', caregoryId);
  };

  return (
    <>
      <Stack spacing={3}>
        {props.isNew === false && (
          <TextField
            label={t('contact_reasons.field.id')}
            inputProps={{ readOnly: true }}
            {...register('id')}
          />
        )}
        <TextField
          required
          label={t('contact_reasons.field.name')}
          error={'name' in errors}
          helperText={errors.name?.message}
          {...register('name')}
        />

        <FormControl required fullWidth error={'jobId' in errors}>
          <InputLabel>{t('contact_reasons.field.job')}</InputLabel>
          <Select
            label={t('contact_reasons.field.job')}
            value={selectedJobId}
            onChange={onChangeJob}
          >
            {(props.item?.jobs ?? []).map((x: any) => (
              <MenuItem key={x.id} value={x.id}>
                {x.displayName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.jobId?.message}</FormHelperText>
        </FormControl>

        <FormControl
          required
          fullWidth
          error={'contactReasonCategoryId' in errors}
        >
          <InputLabel>
            {t('contact_reasons.field.contactReasonCategory')}
          </InputLabel>
          <Select
            label={t('contact_reasons.field.contactReasonCategory')}
            value={selectedCategoryId}
            onChange={onChangeCategory}
          >
            {(props.item?.categories ?? [])
              .filter((x: any) => x.jobId === selectedJobId)
              .map((x: any) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.displayName}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            {errors.contactReasonCategoryId?.message}
          </FormHelperText>
        </FormControl>
      </Stack>
    </>
  );
};

export default ContactReasonForm;
