import { JanusJS } from 'janus-gateway';

export type TrackArray = Array<{
  type: string;
  mid: string;
  capture: {
    deviceId: { exact: string };
  };
}>;

// 型が最新ではないのでDIY
export type ExtendedPluginHandle = JanusJS.PluginHandle & {
  replaceTracks: (props: {
    tracks: TrackArray;
    success: () => void;
    error: (err: Error) => void;
  }) => void;
  muteAudio: (mid: string) => void;
  unmuteAudio: (mid: string) => void;
  muteVideo: (mid: string) => void;
  unmuteVideo: (mid: string) => void;
};

export type ClientParams = {
  url: string;
  accessToken: string;
  aclToken: string;
  roomName: string;
  sessionHandler: JanusJS.Janus;
  onlocaltrack?: (track: MediaStreamTrack, on: boolean) => void;
  onremotetrack?: (track: MediaStreamTrack, mid: string, on: boolean) => void;
  ondata?: (data: string, publisher: string) => void;
  ondataopen?: () => void;
};

export default class BaseClient {
  protected url: string;
  protected accessToken: string;
  protected roomName: string;
  protected aclToken: string;

  protected sessionHandler?: JanusJS.Janus;
  protected pluginHandler?: ExtendedPluginHandle;

  protected dataChannelOptions?: RTCDataChannelInit;
  protected success?: (handle: ExtendedPluginHandle) => void;
  protected error?: (error: string) => void;
  protected consentDialog?: (on: boolean) => void;
  protected webrtcState?: (isConnected: boolean) => void;
  protected iceState?: (
    state: 'connected' | 'failed' | 'disconnected' | 'closed'
  ) => void;
  protected mediaState?: (
    medium: 'audio' | 'video',
    receiving: boolean,
    mid?: number
  ) => void;
  protected slowLink?: (uplink: boolean, lost: number, mid: string) => void;
  protected onlocaltrack?: (track: MediaStreamTrack, on: boolean) => void;
  protected onremotetrack?: (
    track: MediaStreamTrack,
    mid: string,
    on: boolean
  ) => void;
  protected ondataopen?: () => void;
  protected ondata?: (data: string, publisher: string) => void;
  protected oncleanup?: () => void;
  protected ondetached?: () => void;

  constructor(props: ClientParams) {
    this.url = props.url;
    this.accessToken = props.accessToken;
    this.roomName = props.roomName;
    this.aclToken = props.aclToken;
    this.sessionHandler = props.sessionHandler;
    this.onremotetrack = props.onremotetrack;
    this.onlocaltrack = props.onlocaltrack;
    this.ondata = props.ondata;
    this.ondataopen = props.ondataopen;
  }

  async initialize() {
    await this.createPluginHandler();
  }

  protected onmessage(msg: JanusJS.Message, _jsep?: JanusJS.JSEP) {
    console.log('onmessage', msg);
  }

  private async createPluginHandler() {
    return await new Promise<void>((resolve, reject) => {
      this.sessionHandler?.attach({
        plugin: 'janus.plugin.videoroom',
        success: (pluginHandle) => {
          // Plugin attached! 'pluginHandle' is our handle
          this.pluginHandler = pluginHandle as ExtendedPluginHandle;
          resolve();
        },
        error: (err) => {
          // Couldn't attach to the plugin
          reject(err);
        },
        // e.g., Darken the screen if on=true (getUserMedia incoming), restore it otherwise
        consentDialog: this.consentDialog,
        // We got a message/event (msg) from the plugin
        // If jsep is not null, this involves a WebRTC negotiation
        onmessage: this.onmessage.bind(this),
        ondata: (data: string, publisher: string) => {
          this.ondata?.(data, publisher);
          // console.log('baseClient.ondata', publisher, data);
        },
        //  (data: any) => {
        //   // 予期されるメッセージ
        //   console.log('ondata', data);
        // },

        ondataopen: () => {
          this.ondataopen?.();
          // console.log('baseClient.ondata', publisher, data);
        },

        // ondataopen: (data: any, data2: any) => {
        //   console.log('ondataopen', data, data2, this.ondata != null);
        // },
        // A local track to display has just been added (getUserMedia worked!) or removed
        onlocaltrack: (track, added) => {
          // this.handleMediaStreamTrack(track, added);
          this.onlocaltrack?.(track, added);
        },
        // A remote track (working PeerConnection!) with a specific mid has just been added or removed
        onremotetrack: (track, mid, added) => {
          // this.handleMediaStreamTrack(track, added);
          this.onremotetrack?.(track, mid, added);
        },
        // PeerConnection with the plugin closed, clean the UI
        // The plugin handle is still valid so we can create a new one
        oncleanup: this.oncleanup,
        // Connection with the plugin closed, get rid of its features
        // The plugin handle is not valid anymore
        ondetached: this.ondetached,
        iceState: (state) => console.log('iceState', state),
      });
    });
  }
}
