import {
  BinaryFilter,
  LogicalOrFilter,
  UnaryFilter,
} from '@cubejs-client/core';
import { Facet } from 'Utils/supabase';

export type BaseFilter = UnaryFilter | BinaryFilter | LogicalOrFilter;
export class FacetValue<T> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  constructor(private _type: Facet, private _payload: T) {}

  get type() {
    return this._type;
  }

  get payload() {
    return this._payload;
  }
  set payload(val: T) {
    this._payload = val;
  }

  filter(_tableName?: string): BaseFilter | undefined {
    return undefined;
  }

  isDefault(): boolean {
    return true;
  }
}
