import { Box, Stack } from '@mui/material';
import { ActionLogTrackData } from 'Components/Molecules/Sessions/ActionLogTrack';
import { useCallback, useEffect, useRef, useState } from 'react';

import ActionLogPane from './ActionLogPane';
import Player, { RrwebPlayerHandles } from './Player';

const HistorySession = (props: { customerId: string }) => {
  const [openPlayer, setOpenPlayer] = useState(false);
  const player = useRef<RrwebPlayerHandles>(null);
  const playerInitialTime = useRef<number>();

  // プレイヤー作成時に再生位置を指定
  useEffect(() => {
    if (openPlayer && playerInitialTime.current != null) {
      player.current?.seek(playerInitialTime.current);
    }
  }, [openPlayer]);

  const onTrackClick = useCallback((v: ActionLogTrackData) => {
    setOpenPlayer(true);
    playerInitialTime.current = v.originalTimestamp - 1000; // 1秒前
    player.current?.seek(playerInitialTime.current);
  }, []);

  return (
    <Stack direction={'row'} sx={{ height: '100%' }}>
      <ActionLogPane
        setOpenPlayer={(v) => {
          setOpenPlayer(v);
          if (v) {
            playerInitialTime.current = 0;
            player.current?.seek(playerInitialTime.current);
          }
        }}
        customerId={props.customerId}
        onTrackClick={onTrackClick}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Player customerId={props.customerId} ref={player} />
      </Box>
    </Stack>
  );
};

export default HistorySession;
