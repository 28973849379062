import { jaJP, enUS } from '@mui/x-data-grid';

import useLocale from './Locale';

const useLocaleDataGrid = () => {
  const locale = useLocale();
  if (locale === 'ja') {
    return jaJP.components.MuiDataGrid.defaultProps.localeText;
  } else {
    return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
};

export default useLocaleDataGrid;
