import ErrorBoundary from 'Components/Atoms/ErrorBoundary';
import Loading from 'Components/Atoms/Loading';
import React, { Suspense, useMemo } from 'react';
import { Facet } from 'Utils/supabase';

export interface AnalyticsFacetProps {
  facet: Facet;
}
const AnalyticsFacet = (props: AnalyticsFacetProps) => {
  const { facet } = props;

  // レンダリングのたびに生成されるのを防ぐためのメモ化
  const FacetComponent = useMemo(
    () => React.lazy(() => import(`../../Atoms/Facets/${facet}`)),
    [facet]
  );

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <FacetComponent facet={facet} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default AnalyticsFacet;
