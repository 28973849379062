import { BaseFilter, FacetValue } from './FacetValueBase';

export type SessionType =
  | 'cobrowse'
  | 'video'
  | 'call'
  | 'cobrowseCall'
  | 'cobrowseVideo'
  | 'other';
export type ChannelFacetValuePayload = {
  sessionTypes: SessionType[];
};
export class ChannelFacetValue extends FacetValue<ChannelFacetValuePayload> {
  constructor(payload: ChannelFacetValuePayload) {
    super('ChannelFacet', payload);
  }

  static convertXAxisName(values: string[]): SessionType {
    const exp = values.slice(0, 3).join('');

    switch (exp) {
      case '111':
        return 'cobrowseVideo';
      case '110':
        return 'cobrowseCall';
      case '011':
        return 'video';
      case '010':
        return 'call';
      case '100':
        return 'cobrowse';
      default:
        return 'other';
    }
  }

  convertSessionTypeExistence(type: SessionType) {
    switch (type) {
      case 'cobrowse':
        return {
          cobrowse: true,
          call: false,
          video: false,
        };
      case 'call':
        return {
          cobrowse: false,
          call: true,
          video: false,
        };
      case 'video':
        return {
          cobrowse: false,
          call: true,
          video: true,
        };
      case 'cobrowseCall':
        return {
          cobrowse: true,
          call: true,
          video: false,
        };
      case 'cobrowseVideo':
        return {
          cobrowse: true,
          call: true,
          video: true,
        };
      default:
        throw new Error('Could not found any SessionType');
    }
  }

  filter(): BaseFilter | undefined {
    if (this.payload.sessionTypes.length === 0) {
      return undefined;
    }

    const types = this.payload.sessionTypes.map(
      this.convertSessionTypeExistence
    );

    return {
      or: types.map((t) => {
        return {
          and: [
            {
              member: `Users.contextSessionTypeCobrowse`,
              operator: t.cobrowse ? 'equals' : 'notEquals',
              values: ['1'],
            },
            {
              member: `Users.contextSessionTypeCall`,
              operator: t.call ? 'equals' : 'notEquals',
              values: ['1'],
            },
            {
              member: `Users.contextSessionTypeVideo`,
              operator: t.video ? 'equals' : 'notEquals',
              values: ['1'],
            },
          ],
        };
      }),
    };
  }

  isDefault(): boolean {
    return this.payload.sessionTypes.length === 0;
  }
}
