import useCustomer from '../Customer';
import useTrackHistories from '../Queries/TrackHistories';

export const useInputNum = () => {
  const customerId = useCustomer()?.userId;
  const pageHistories = useTrackHistories(customerId ?? '', {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });
  const data =
    pageHistories.data == null
      ? null
      : pageHistories.data?.filter((t) => t.event === 'input').length;

  return {
    ...pageHistories,
    data,
  };
};
