import { useQuery } from 'react-query';
import supabase from 'Utils/supabase';
import { SessionHistory } from 'Utils/supabase';

type SessionHistories = SessionHistory & {
  customerData: { name: string; value: string }[];
  iid: string;
};

const useCustomerInputDatas = (iid?: string, jobId?: string) => {
  return useQuery(
    'customer_input_data',
    async () => {
      const { data, error } = await supabase
        .from<SessionHistories>('session_histories')
        .select(`customerData`)
        .match({ iid, jobId });
      if (error) throw error;
      return data;
    },
    {
      enabled: iid != null,
      refetchOnMount: false,
    }
  );
};

export default useCustomerInputDatas;
