import { BaseFilter, FacetValue } from './FacetValueBase';

export type NoteFacetValuePayload = { text: string };
export class NoteFacetValue extends FacetValue<NoteFacetValuePayload> {
  constructor(payload: NoteFacetValuePayload) {
    super('NoteFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.text === '') {
      return undefined;
    } else {
      return {
        member: `Users.contextMemo`,
        operator: 'contains',
        // eslint-disable-next-line no-irregular-whitespace
        values: this.payload.text.split(/ |　/g),
      };
    }
  }

  isDefault(): boolean {
    return this.payload.text === '';
  }
}
