import { BaseFilter, FacetValue } from './FacetValueBase';

export type PathFacetValuePayload = { paths: string[] };
export class PathFacetValue extends FacetValue<PathFacetValuePayload> {
  constructor(payload: PathFacetValuePayload) {
    super('PathFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.paths.length === 0) {
      return undefined;
    } else {
      const pageNames = this.payload.paths.map((p) => {
        const splited = p.split('/');
        if (splited.length <= 1) {
          return '';
        } else {
          //return splited[1];
          return p;
        }
      });

      return {
        or: new Array(10).fill('').map((v, i) => {
          return {
            member: `ViewPath.step${i + 1}`,
            operator: 'equals',
            values: pageNames,
          };
        }),
      };
    }
  }

  isDefault(): boolean {
    return this.payload.paths.length === 0;
  }
}
