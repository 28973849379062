import {
  Container,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import SelectMultiple from 'Components/Atoms/SelectMultiple';
import useTranslation from 'Components/Hooks/Translate';
import React from 'react';
import { Controller, UseFormReturn, useFormState } from 'react-hook-form';
import { Job } from 'Utils/supabase';

import NoReadPermission from '../Settings/NoReadPermission';

// ダッシュボード設定モーダルの中身のフォームを定義
const DashboardConfigForm = (props: { form: UseFormReturn<any, any> }) => {
  const t = useTranslation('Analytics');
  const { register, control } = props.form;
  // エラーはこちらから取得することで、階層の違うモーダルに伝達させる。
  const { errors } = useFormState({ control });

  const [selectedJobIds, setSelectedJobIds] = React.useState<string[]>(
    props.form.getValues('selectedJobIds') ?? []
  );

  const onChangeJob = (event: { target: { value: string[] } }) => {
    setSelectedJobIds(event.target.value);
    props.form.setValue('selectedJobIds', event.target.value);
  };

  return (
    <Can
      componentName="dashboard"
      crud="read"
      no={<NoReadPermission displayName={t('dashboardadd.title')} />}
    >
      <Container sx={{ pt: 4, pb: 3 }}>
        <Stack spacing={3}>
          <TextField
            required
            label={t('dashboardadd.title')}
            error={'name' in errors}
            helperText={errors.name?.message}
            {...register('name')}
          />

          <SelectMultiple
            caption={t('dashboardedit.sharetojob')}
            onChange={onChangeJob}
            items={((props.form.getValues('jobs') as Job[]) ?? []).map((x) => ({
              id: x.id,
              displayName: x.name,
            }))}
            selectedItemIds={selectedJobIds}
          ></SelectMultiple>

          <Controller
            control={control}
            name="default"
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={props.form.getValues('default')}
                      {...field}
                    />
                  }
                  label={t('dashboardadd.default').toString()}
                />
              </>
            )}
          />
        </Stack>
      </Container>
    </Can>
  );
};
export default DashboardConfigForm;
