import dataProvider from 'Utils/dataProvider';
import { assertNotNull } from 'Utils/guard';

import useAgent from './Agent';
import { useUpdateIid } from './Iid';

export const useAccessToken = () => {
  const updateIid = useUpdateIid();
  const agent = useAgent();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const getAccessToken = async (job_id: string, alias: string) => {
    assertNotNull(agent);
    const { iid, userId } = await getIIDByAlias(job_id, alias);
    agent.getContextDataAccessor.setUserInfo({
      ...agent.getContextDataAccessor.getUserInfo(),
      userId,
    });

    if (!iid) {
      throw new Error('Customer is not found!');
    }
    return await httpPostRequest(`/users/sessions`, {
      job: job_id,
      iid: iid,
      expire: 3600,
    });
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const getIIDByAlias = async (job_id: string, alias: string) => {
    const { iid, userId } = await httpPostRequest(`/users/search`, {
      alias,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      job_id,
    });

    // IIDを保存
    updateIid(iid);

    return { iid, userId };
  };
  const httpPostRequest = async (url: string, body: object) => {
    const result = await dataProvider.post(url, body);
    return result?.data;
  };

  return getAccessToken;
};
