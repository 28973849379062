import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import useUserProfiles, {
  useInsertUserProfile,
  useUpdateUserProfile,
  useDeleteUserProfiles,
} from 'Components/Hooks/Queries/UserProfiles';
import useTranslation from 'Components/Hooks/Translate';
import { SettingsTableConfig } from 'Components/Organisms/Settings/SettingsConfig';
import SettingsTable from 'Components/Organisms/Settings/SettingsTable';
import { useCallback, useMemo } from 'react';
import { AppError } from 'Utils/error';
import i18nYup from 'Utils/i18nYup';
import { components } from 'Utils/Permission';
import supabase, { Role, UserProfile, UsersRole } from 'Utils/supabase';

import UserForm from './UserForm';
const UserProfileTable = (props: { table: SettingsTableConfig }) => {
  const result = useUserProfiles({ refetchOnWindowFocus: false });

  if (result.isError) {
    throw new AppError(result.error, 'Sys', 'SettingsUser', 'ReadMany', '01');
  }

  return (
    <SettingsTable
      table={props.table}
      items={result.data ?? []}
      isLoading={result.isFetching}
    />
  );
};

const UserProfileBreadcrumb = (props: { id: string }) => (
  <>
    {useUserProfiles({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }).data?.find((x) => x.id === props.id)?.name ?? ''}
  </>
);

export const useUsersConfig: () => SettingsTableConfig = () => {
  const t = useTranslation('Settings');
  const insertItem = useInsertUserProfile();
  const updateItem = useUpdateUserProfile();
  const deleteItems = useDeleteUserProfiles();

  const loadToItem = useCallback(async (item: any) => {
    // ロール一覧取得
    const roleResult = await supabase
      .from<Role>('roles')
      .select()
      .order('roleName');
    if (roleResult.error != null)
      throw new AppError(
        roleResult.error,
        'Sys',
        'SettingsUser',
        'ReadOne',
        '03'
      );

    item.roles = roleResult.data.map((x) => ({
      id: x.id,
      displayName: x.roleName,
    }));

    // ユーザが保持しているロール取得
    if (item.id) {
      const usersRolesResult = await supabase
        .from<UsersRole>('users_roles')
        .select()
        .match({ userId: item.id });
      if (usersRolesResult.error)
        throw new AppError(
          roleResult.error,
          'Sys',
          'SettingsUser',
          'ReadOne',
          '04'
        );

      item.usersRoleIds = usersRolesResult.data.map((x) => x.roleId);
    } else {
      item.usersRoleIds = [];
    }
  }, []);

  return useMemo<SettingsTableConfig>(
    () => ({
      name: 'users',
      resourceName: components.User,
      displayName: t('users.name'),
      icon: <PersonOutlineOutlinedIcon />,
      columns: [
        {
          field: 'name',
          headerName: t('users.field.name'),
          minWidth: 200,
        },
        {
          field: 'isAdmin',
          headerName: t('users.field.isAdmin'),
          type: 'boolean',
          width: 70,
        },
        {
          field: 'jobs',
          headerName: t('users.field.job'),
          flex: 1,
          width: 200,
          valueGetter: (params) =>
            params.value.map((x: any) => x.name).join(', '),
        },
        {
          field: 'createdAt',
          headerName: t('common.field.createdAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
        {
          field: 'updatedAt',
          headerName: t('common.field.modifiedAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
      ],
      getItem: async (id) => {
        const result = await supabase
          .from<UserProfile>('user_profiles')
          .select()
          .eq('id', id)
          .maybeSingle();
        if (result.error != null) {
          throw new AppError(
            result.error,
            'Sys',
            'SettingsUser',
            'ReadOne',
            '01'
          );
        }
        if (result.data == null) {
          throw new AppError(
            `Not found user id: ${id}`,
            'Biz',
            'SettingsUser',
            'ReadOne',
            '02'
          );
        }

        // TODO: 保存されないのでバリデーション対策で適当に入れてしまっている
        (result.data as any).email = 'test@test.test';

        await loadToItem(result.data);
        return result.data;
      },
      getDefault: async () => {
        const item = {
          name: '',
          email: '',
          userRoleIds: [],
        };
        await loadToItem(item);
        return item;
      },
      renderList: (table) => <UserProfileTable table={table} />,
      renderItem: (isNew, item, form) => (
        <UserForm isNew={isNew} item={item} form={form} />
      ),
      renderBreadcrumb: (id) => <UserProfileBreadcrumb id={id} />,
      validation: i18nYup.object({
        name: i18nYup.string().label(t('users.field.name')).required().max(255),
        email: i18nYup
          .string()
          .label(t('users.field.email'))
          .required()
          .email(),
      }),
      insertItem: async (item: any) => {
        try {
          await insertItem.mutateAsync(item);
        } catch (e: any) {
          // TODO: APIGW側のエラーコードを見直す必要がある（今はとりあえずメッセージでエラー種別を判定）
          if (e.response?.data?.message === 'license limit')
            throw new AppError(e, 'Biz', 'SettingsUser', 'CreateOne', '02');
          else if (e.response?.data?.status === 422)
            throw new AppError(e, 'Biz', 'SettingsUser', 'CreateOne', '03');
          else throw new AppError(e, 'Sys', 'SettingsUser', 'CreateOne', '01');
        }
      },
      updateItem: async (item: any) => {
        try {
          await updateItem.mutateAsync(item);
        } catch (e: any) {
          throw new AppError(e, 'Sys', 'SettingsUser', 'UpdateOne', '01');
        }
      },
      deleteItems: async (items: any[]) => {
        try {
          await deleteItems.mutateAsync(items);
        } catch (e: any) {
          // TODO: APIGW側のエラーコード設計がまだなのでとりあえず対応
          if (e.response?.status === 400)
            throw new AppError(e, 'Biz', 'SettingsUser', 'DeleteOne', '03');
          else throw new AppError(e, 'Sys', 'SettingsUser', 'DeleteOne', '01');
        }
      },
    }),
    [t, insertItem, updateItem, deleteItems, loadToItem]
  );
};
