import useCustomer from '../Customer';
import useIid from '../Iid';
import usePageHistories from '../Queries/PageHistories';
import useSessionStartTime from '../SessionStartTime';

export const useSessionStartPage = () => {
  const customerId = useCustomer()?.userId;
  const iid = useIid();
  const sessionStartTime = useSessionStartTime(iid ?? '', {
    enabled: iid != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });
  const pageHistories = usePageHistories(customerId ?? '', false, {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  const data = (() => {
    if (
      pageHistories.data == null ||
      pageHistories.data.length === 0 ||
      sessionStartTime.data == null
    )
      return undefined;

    for (let i = 0; i < pageHistories.data.length; i++) {
      const p = pageHistories.data[i];

      if (
        p.originalTimestamp <= sessionStartTime.data &&
        sessionStartTime.data <= pageHistories.data[i + 1]?.originalTimestamp
      )
        return p.title;
    }

    return pageHistories.data[pageHistories.data.length - 1]?.title;
  })();

  return {
    ...pageHistories,
    data,
  };
};
