import { TimeDimensionGranularity } from '@cubejs-client/core';
import { useQuery, useQueryClient } from 'react-query';
import { assertNotNull } from 'Utils/guard';
import { Gadget } from 'Utils/supabase';

// ブラウザローカルで保持するファセットの値をステート管理する。
// react-query（キャッシュ機能のみ）を使用し、変更時の部分更新を可能にしている。
//TimeDimensionGranularity

export const useGadgetGuranularities = (gadget: Gadget) => {
  const query = useQuery<TimeDimensionGranularity>(
    ['Granularity Value Context', gadget],
    {
      initialData: 'day',
      enabled: false,
    }
  );

  assertNotNull(query.data); // 初期データとキャッシュだけで動くので必ずデータはある
  return query.data;
};

export const useUpdateGadgetGuranularities = () => {
  const queryClient = useQueryClient();

  return (gadget: Gadget, value: TimeDimensionGranularity) =>
    queryClient.setQueryData<TimeDimensionGranularity>(
      ['Granularity Value Context', gadget],
      value
    );
};

export const useSankeyPercentages = (gadget: Gadget) => {
  const query = useQuery<number>(['Sankey Percentage Context', gadget], {
    initialData: 100,
    enabled: false,
  });

  assertNotNull(query.data);
  return query.data;
};

export const useUpdateSankeyGadgetPercentage = () => {
  const queryClient = useQueryClient();

  return (gadget: Gadget, value: number) =>
    queryClient.setQueryData<number>(
      ['Sankey Percentage Context', gadget],
      value
    );
};

export const useSankeyManualLimits = (gadget: Gadget) => {
  const query = useQuery<number>(['Sankey ManualValue Context', gadget], {
    initialData: 1,
    enabled: false,
  });

  assertNotNull(query.data);
  return query.data;
};

export const useUpdateSankeyManualLimit = () => {
  const queryClient = useQueryClient();

  return (gadget: Gadget, value: number) =>
    queryClient.setQueryData<number>(
      ['Sankey ManualValue Context', gadget],
      value
    );
};
