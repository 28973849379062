import { forwardRef } from 'react';

/** MUIのボタン系とaタグを連結する。 */
// eslint-disable-next-line react/display-name
const LinkBehaviorBlank = forwardRef<
  HTMLAnchorElement,
  JSX.IntrinsicElements['a'] // aタグのprops型
>((props, ref) => {
  const { href, children, ...other } = props;
  return (
    <a ref={ref} href={href} target="_blank" rel="noreferrer" {...other}>
      {children}
    </a>
  );
});
export default LinkBehaviorBlank;
