import { yupResolver } from '@hookform/resolvers/yup';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import DialogTitleWithClose from 'Components/Atoms/DialogTitleWithClose';
import useLoading from 'Components/Hooks/Loading';
import { useNotify } from 'Components/Hooks/Notify';
import useOperator from 'Components/Hooks/Operator';
import {
  useInsertDashboard,
  useUpdateDashboard,
} from 'Components/Hooks/Queries/Dashboards';
import useTranslation from 'Components/Hooks/Translate';
import DashboardConfigForm from 'Components/Organisms/Analytics/DashboardConfigForm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AppError } from 'Utils/error';
import i18nYup from 'Utils/i18nYup';
import supabase, {
  Job,
  JobDashboard,
  Dashboard as SupabaseDashboard,
  UserProfile,
} from 'Utils/supabase';

// ダッシュボード編集ページ
const Dashboard = () => {
  const navigate = useNavigate();
  const t = useTranslation('Analytics');
  const { dashboardId } = useParams<{ dashboardId?: string }>();
  const isNew = dashboardId === 'create';
  const operator = useOperator();
  const notify = useNotify();
  const insert = useInsertDashboard();
  const update = useUpdateDashboard();
  const { load } = useLoading(insert.isLoading || update.isLoading);
  const [open, setOpen] = useState(false);

  const form = useForm({
    resolver: yupResolver(
      i18nYup.object({
        name: i18nYup.string().label(t('dashboardadd.title')).required(),
      })
    ),
  });

  useEffect(() => {
    if (dashboardId == null) {
      setOpen(false);
    } else {
      load(async () => {
        if (isNew) {
          // 新規
          form.setValue('name', '');
          form.setValue('default', false);
          form.setValue('selectedJobIds', []);
          // 全ジョブ情報取得
          const { data: jobs, error: jobsError } = await supabase
            .from<Job>('jobs')
            .select();
          if (jobsError)
            throw new AppError(
              jobsError,
              'Sys',
              'AnalyticsDashboard',
              'ReadOne',
              '05'
            );
          form.setValue('jobs', jobs);
        } else {
          try {
            //user_profilesの取得。デフォルトダッシュボードかどうかの判断用
            const { data: profiles, error: userError } = await supabase
              .from<UserProfile>('user_profiles')
              .select()
              .eq('id', operator?.id)
              .single();
            if (userError)
              throw new AppError(
                userError,
                'Sys',
                'AnalyticsDashboard',
                'ReadOne',
                '03'
              );

            // ダッシュボード情報取得
            const { data: dashboard, error: dashboardError } = await supabase
              .from<SupabaseDashboard>('dashboards')
              .select()
              .eq('id', dashboardId)
              .maybeSingle();
            if (dashboardError)
              throw new AppError(
                dashboardError,
                'Sys',
                'AnalyticsDashboard',
                'ReadOne',
                '01'
              );
            if (dashboard == null)
              throw new AppError(
                userError,
                'Sys',
                'AnalyticsDashboard',
                'ReadOne',
                '02'
              );

            // 関連ジョブ取得
            const { data: jobsDashes, error: jobsDashesError } = await supabase
              .from<JobDashboard>('jobs_dashboards')
              .select()
              .eq('dashboardId', dashboard.id);
            if (jobsDashesError)
              throw new AppError(
                jobsDashesError,
                'Sys',
                'AnalyticsDashboard',
                'ReadOne',
                '04'
              );
            const selectedJobIds = jobsDashes.map((x) => x.jobId);

            // フォームに値をセット
            form.setValue('name', dashboard.name);
            form.setValue('default', false);
            profiles.defaultDashboardId === dashboardId
              ? form.setValue('default', true)
              : form.setValue('default', false);
            form.setValue('selectedJobIds', selectedJobIds);

            // 全ジョブ情報取得
            const { data: jobs, error: jobsError } = await supabase
              .from<Job>('jobs')
              .select();
            if (jobsError)
              throw new AppError(
                jobsError,
                'Sys',
                'AnalyticsDashboard',
                'ReadOne',
                '05'
              );
            form.setValue('jobs', jobs);
          } catch (e) {
            navigate('', { replace: true }); //読み込みエラー時はURLを一覧に戻す
            throw e;
          }
        }
        setOpen(true);
      });
    }
  }, [dashboardId, form, isNew, load, operator?.id, navigate]);

  return (
    <Dialog open={open}>
      <DialogTitleWithClose onClose={() => navigate('')}>
        {isNew ? t('dashboardadmin.add') : t('dashboardadmin.edit')}
      </DialogTitleWithClose>
      <DialogContent>
        <DashboardConfigForm form={form} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate('')} variant="shadow">
          {t('dashboardedit.cancel')}
        </Button>
        <Can componentName="dashboard" crud="update">
          <Button
            startIcon={<SaveOutlinedIcon />}
            onClick={async () => {
              if (await form.trigger()) {
                // 保存する
                // TODO:ダッシュボードのdefaultに関して仕様調整中のよう。とりあえずそのまま整理
                const data = {
                  name: form.getValues('name'),
                  jobIds: form.getValues('selectedJobIds'),
                  isDefault: form.getValues('default'),
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  id: dashboardId!, // ← 本当はよろしくないが処理上問題ないのでとりあえずOK
                };
                if (isNew) {
                  // 新規
                  await insert.mutateAsync({
                    userId: operator?.id || '',
                    ...data,
                  });
                  notify(t('notify.add'), 'success');
                } else {
                  // // 更新
                  await update.mutateAsync({
                    userId: operator?.id || '',
                    ...data,
                  });
                  notify(t('notify.edit'), 'success');
                }
                navigate('');
              }
            }}
          >
            {t('dashboardedit.save')}
          </Button>
        </Can>
      </DialogActions>
    </Dialog>
  );
};

export default Dashboard;
