import { Dialog } from '@mui/material';
import { DialogPropsWithoutOpen } from 'Components/Hooks/Modal';
import React, { useState } from 'react';

const ConfirmModal = () => {
  const [open, setOpen] = React.useState(false);

  const [dialogProps, setDialogProps] = useState<DialogPropsWithoutOpen>({});

  const handleOpen = (ev: Event) => {
    const actualEv = ev as CustomEvent<DialogPropsWithoutOpen>;
    setDialogProps(actualEv.detail);
    setOpen(true);
  };

  const handleClose = (_ev: Event) => setOpen(false);

  React.useEffect(() => {
    window.addEventListener('openModalDialog', handleOpen);
    window.addEventListener('closeModalDialog', handleClose);
    return () => {
      window.removeEventListener('openModalDialog', handleOpen);
      window.removeEventListener('closeModalDialog', handleClose);
    };
  }, []);

  return <Dialog open={open} {...dialogProps} />;
};

export default ConfirmModal;
