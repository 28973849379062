import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Agent } from '@optipass/sdk/lib/agent/agent';
import TooltipFab from 'Components/Atoms/TooltipFab';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';

const EndSessionButton = (props: { agent?: Agent }) => {
  const t = useTranslation('Session');

  // 離脱メニューのアンカー。アンカーがセットされているときにメニューが出る。
  const [lLeaveAnchor, setLeaveAnchor] = useState<null | HTMLElement>(null);

  return (
    <>
      <TooltipFab
        title={t('controller.endSession')}
        onClick={(e) => setLeaveAnchor(e.currentTarget)}
        color="warning"
        size={'small'}
      >
        <LogoutOutlinedIcon />
      </TooltipFab>
      <Menu
        open={lLeaveAnchor != null}
        onClose={() => setLeaveAnchor(null)}
        anchorEl={lLeaveAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ transform: 'translate(-8px,-8px)' }}
      >
        {/* <MenuItem
          onClick={() => {
            setRoomId('');
            props.agent?.endSession();
          }}
        >
          <ListItemIcon>
            <LogoutOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText> {t('controller.disconnect')}</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            props.agent?.requestEndSession();
          }}
        >
          <ListItemIcon>
            <PowerOffIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText> {t('controller.disconnect')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default EndSessionButton;
