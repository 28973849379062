import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import { useFrequentSearchWord } from 'Components/Hooks/SummaryQueries/FrequentSearchWord';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const FrequentSearchWord = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useFrequentSearchWord();

  return (
    <Panel
      icon={<ManageSearchOutlinedIcon />}
      title={t('FrequentSearchWord.title')}
      content={data?.toString() ?? ''}
      isLoading={isLoading}
    />
  );
};

export default FrequentSearchWord;
