import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import supabase, { ContactReason } from 'Utils/supabase';
import { definitions } from 'Utils/types';

export type ContactReasonWithCategory = definitions['contact_reasons'] & {
  category: definitions['contact_reason_categories'];
} & {
  job: definitions['jobs'];
};

const key = 'Contact Reasons';

const useContactReasons = (
  jobId?: string,
  options?: Omit<
    UseQueryOptions<ContactReasonWithCategory[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const keys = [key];
  return useQuery<ContactReasonWithCategory[]>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      let query = supabase
        .from<ContactReasonWithCategory>('contact_reasons')
        .select(
          '*, jobs:jobId(name), category:contact_reason_categories (*),job:jobs (*)'
        )
        .order('name');
      if (jobId != null) {
        query = query.match({ jobId });
      }
      const result = await query;
      if (result.error != null) throw result.error;
      return result.data;
    },
    options
  );
};
export default useContactReasons;

export const useInsertContactReason = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: any) => {
      const result = await supabase
        .from<ContactReason>('contact_reasons')
        .insert({
          name: item.name,
          jobId: item.jobId,
          contactReasonCategoryId: item.contactReasonCategoryId,
        });
      if (result.error != null) throw result.error;
      return result.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useUpdateContactReason = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: any) => {
      const result = await supabase
        .from<ContactReason>('contact_reasons')
        .update({
          name: item.name,
          jobId: item.jobId,
          contactReasonCategoryId: item.contactReasonCategoryId,
        })
        .eq('id', item.id);
      if (result.error != null) throw result.error;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useDeleteContactReasons = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (itemIds: string[]) => {
      const result = await supabase
        .from('contact_reasons')
        .delete()
        .in('id', itemIds);
      if (result.error != null) throw result.error;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};
