import { useNotify } from './Notify';

//TODO:エラーコードを正式に定義する
const errorTypes = {
  Unknown: '0000',
  PostgresError: '1111',
  CubeFetchError: '1112',
};

export const useHandleError = () => {
  const notify = useNotify();

  //エラーコードに対応するenumを定義し、外からcodeとして指定する
  //eからメッセージ等を取得する際は、eの型安全に注意すること
  return (
    errorType: keyof typeof errorTypes,
    e: any,
    doNotify = false,
    _data?: any
  ) => {
    console.error(errorType, e);
    if (doNotify) {
      //ToDo:エラーコードから辞書ファイルを引き、Nofityメッセージを生成
      //errorCode_errorName
      notify(errorTypes[errorType] + '_' + errorType, 'error');
    }
  };
};
