import StarBorderIcon from '@mui/icons-material/StarBorder';
import { DataGrid, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import useLocaleDataGrid from 'Components/Hooks/LocaleDataGrid';
import useTranslation from 'Components/Hooks/Translate';
import GridCellExpand from 'Components/Molecules/Global/GridCellExpand';
import { Dashboard, Job } from 'Utils/supabase';

interface DashboardTableProps {
  dashboards: Dashboard[];
  onRequestEdit: (dashboardId: string) => void;
  isLoading: boolean;
  setSelectionModel: (select: GridRowId[]) => void;
}

function renderCellExpand(params: GridRenderCellParams<string>) {
  return (
    <GridCellExpand
      value={params.value || ''}
      width={params.colDef.computedWidth}
    />
  );
}

const DashboardTable = (props: DashboardTableProps) => {
  const t = useTranslation('Analytics');
  const localeDataGrid = useLocaleDataGrid();
  return (
    <>
      <DataGrid
        disableSelectionOnClick
        rows={props.dashboards}
        checkboxSelection
        columns={[
          {
            field: 'name',
            headerName: t('dashboardtable.title'),
            type: 'string',
            flex: 1,
          },
          {
            field: 'jobs',
            headerName: t('dashboardtable.share'),
            type: 'string',
            flex: 2,
            sortable: false,
            // ダッシュボードに関連するジョブリストをチップで列挙
            renderCell: renderCellExpand,
            valueGetter: (params) =>
              params.value.map((x: Job) => x.name).join(', '),
          },
          {
            field: 'default',
            headerName: t('dashboardtable.default'),
            renderCell: (params) => params.value && <StarBorderIcon />,
            minWidth: 100,
          },
        ]}
        onSelectionModelChange={(newSelectionModel) => {
          props.setSelectionModel(newSelectionModel);
        }}
        onRowClick={(event) => props.onRequestEdit(event.id.toString())}
        localeText={localeDataGrid}
        loading={props.isLoading}
        disableColumnFilter
        disableColumnSelector
      />
    </>
  );
};
export default DashboardTable;
