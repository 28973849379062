import {
  Box,
  Button,
  ButtonProps,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

type ListItemWithButtonImageProps = {
  image?: JSX.Element;
  primaryText?: React.ReactNode;
  secondaryText?: React.ReactNode;
  buttonLabel?: string;
} & ButtonProps;

const ListItemWithButtonImage = (props: ListItemWithButtonImageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { image, primaryText, secondaryText, buttonLabel, sx, ...buttonProps } =
    props;
  return (
    <ListItem
      sx={{
        backgroundColor: (t) => t.palette.background.paper,
        my: 2.5,
        mx: '5%',
        p: 2.5,
        borderRadius: '5px',
        width: '90%',
        boxShadow: 2,
      }}
    >
      {props.image}
      <Box sx={{ width: '100%', mr: 15 }}>
        <ListItemText
          primary={props.primaryText}
          secondary={props.secondaryText}
          sx={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '&>.MuiListItemText-primary': {
              fontWeight: 600,
            },
          }}
        />
      </Box>
      {props.buttonLabel && (
        <ListItemSecondaryAction>
          <Button
            {...buttonProps}
            sx={{
              fontWeight: 'bold',
              right: '50px',
              ...sx,
            }}
          >
            {props.buttonLabel}
          </Button>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default ListItemWithButtonImage;
