import usePermission from 'Components/Hooks/Permission';
import useDashboards from 'Components/Hooks/Queries/Dashboards';
import useViews from 'Components/Hooks/Queries/Views';
import useTranslation from 'Components/Hooks/Translate';
import GlobalNavigationLayout from 'Components/Organisms/Global/GlobalNavigationLayout';
import TopLevelLayout from 'Components/Organisms/Global/TopLevelLayout';
import { SettingsConfig } from 'Components/Organisms/Settings/SettingsConfig';
import SettingsLayout from 'Components/Organisms/Settings/SettingsLayout';
import Analytics from 'Pages/Analytics/Analytics';
import Dashboard from 'Pages/Analytics/Dashboard';
import Dashboards from 'Pages/Analytics/Dashboards';
import EditFacet from 'Pages/Analytics/EditFacet';
import EditGadget from 'Pages/Analytics/EditGadget';
import Debug from 'Pages/Debug';
import Forgot from 'Pages/Forgot';
import Login from 'Pages/Login';
import Register from 'Pages/Register';
import Session from 'Pages/Session/Session';
import SessionHistories from 'Pages/SessionHistories';
import SessionHistory from 'Pages/SessionHistory';
import useContactReasonCategoriesConfig from 'Pages/Settings/ContactReasonCategoriesConfig';
import useContactReasonsConfig from 'Pages/Settings/ContactReasonsConfig';
import useJobsConfig from 'Pages/Settings/JobsConfig';
import useRolesConfig from 'Pages/Settings/RolesConfig';
import { useUsersConfig } from 'Pages/Settings/UsersConfig';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

const RouteProvider = () => useRoutes(useRouteConfig());
export default RouteProvider;

// 突然なぜか型エラーになるようになってしまったのでいったん型付けを解除
//export const useRouteConfig = (): (BreadcrumbsRoute & RouteObject)[] => {
export const useRouteConfig = () => {
  const t = useTranslation('Global');
  const settingConfigs: SettingsConfig = {
    tables: [
      useUsersConfig(),
      useJobsConfig(),
      useContactReasonCategoriesConfig(),
      useContactReasonsConfig(),
      useRolesConfig(),
    ],
  };

  // 各ページへのアクセス権
  const perm = usePermission();
  const isGuest = perm?.isGuest() ?? true;
  const hasSession = perm?.hasRead('cobrowse') || perm?.hasRead('video_call');
  const hasAnalytics = perm?.hasRead('dashboard') || perm?.hasRead('view');
  const hasHistory = perm?.hasRead('session_history');
  const hasSettings =
    perm?.hasRead('user') ||
    perm?.hasRead('job') ||
    perm?.hasRead('contact_reason') ||
    perm?.hasRead('contact_reason_category') ||
    perm?.hasRead('role');

  return [
    {
      // トップレベルレイアウト
      element: (
        <TopLevelLayout>
          <Outlet />
        </TopLevelLayout>
      ),
      children: [
        ...(isGuest
          ? [
              {
                path: 'login',
                breadcrumb: t('breadcrumbs.login'),
                element: <Login />,
              },
            ]
          : []),
        {
          path: 'forgot',
          breadcrumb: t('breadcrumbs.forgot'),
          element: <Forgot />,
        },
        {
          path: 'register',
          breadcrumb: t('breadcrumbs.register'),
          element: <Register />,
        },
        {
          // グローバルナビゲーションレイアウト
          element: (
            <GlobalNavigationLayout>
              <Outlet />
            </GlobalNavigationLayout>
          ),
          children: [
            {
              // index は 権限に応じてリダイレクト先を決定
              index: true,
              element:
                hasSession || hasAnalytics || hasSettings || hasHistory ? (
                  <Navigate
                    to={(() => {
                      if (hasSession) return 'session';
                      if (hasAnalytics) return 'analytics';
                      if (hasSettings) return 'settings';
                      if (hasHistory) return 'session-history';
                      return '';
                    })()}
                    replace
                  />
                ) : (
                  <></>
                ),
            },
            {
              // その他は index にリダイレクト
              path: '*',
              element: <Navigate to="" replace />,
            },

            ...(hasSession
              ? [
                  {
                    path: 'session',
                    breadcrumb: t('breadcrumbs.session'),
                    element: <Session />,
                  },
                ]
              : []),
            ...(hasHistory
              ? [
                  {
                    path: 'session-histories',
                    breadcrumb: t('breadcrumbs.session-histories'),
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <SessionHistories />,
                      },
                      {
                        path: ':sessionId',
                        element: <SessionHistory />,
                        breadcrumb: ({ match }: any) => match.params.sessionId,
                      },
                    ],
                  },
                ]
              : []),
            ...(hasAnalytics
              ? [
                  {
                    path: 'analytics',
                    breadcrumb: t('breadcrumbs.analytics'),
                    children: [
                      {
                        path: 'dashboards',
                        breadcrumb: t('breadcrumbs.dashboards'),
                        element: (
                          <>
                            <Dashboards />
                            <Dashboard />
                            <Outlet />
                          </>
                        ),
                        children: [
                          {
                            path: ':dashboardId',
                            breadcrumb: ({ match }: any) => {
                              if (match.params.dashboardId === 'create') {
                                return <>{t('breadcrumbs.addNew')}</>;
                              }
                              return (
                                <DashboardBreadcrumb
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  id={match.params.dashboardId!}
                                />
                              );
                            },
                            element: <></>,
                          },
                        ],
                      },
                      {
                        breadcrumb: t('breadcrumbs.analytics'),
                        element: (
                          <>
                            <Analytics />
                            <EditFacet />
                            <EditGadget />
                            <Outlet />
                          </>
                        ),
                        children: [
                          {
                            index: true, // リダイレクトは Analytics 内で行っている。
                            element: <></>,
                          },
                          {
                            path: ':dashboardId',
                            breadcrumb: ({ match }: any) => (
                              <DashboardBreadcrumb
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                id={match.params.dashboardId!}
                              />
                            ),
                            children: [
                              {
                                index: true,
                                element: <></>,
                              },
                              {
                                path: ':viewId',
                                breadcrumb: ({ match }: any) => (
                                  <ViewBreadcrumb
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    dashboardId={match.params.dashboardId!}
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    viewId={match.params.viewId!}
                                  />
                                ),
                                element: <></>,
                              },
                              {
                                path: 'edit-layout',
                                breadcrumb: t('breadcrumbs.edit-layout'),
                                element: <></>,
                              },
                              {
                                path: 'edit-facets',
                                breadcrumb: t('breadcrumbs.edit-facets'),
                                element: <></>,
                              },
                              {
                                path: 'edit-gadgets',
                                breadcrumb: t('breadcrumbs.edit-gadgets'),
                                element: <></>,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]
              : []),
            ...(hasSettings
              ? [
                  {
                    path: 'settings',
                    breadcrumb: t('breadcrumbs.settings'),
                    element: (
                      <>
                        <SettingsLayout config={settingConfigs} />
                        <Outlet />
                      </>
                    ),
                    // 設定画面のレンダリングは SettingsLayout で完結しているため、childrenはルーティングとパンくずのみ定義
                    children: [
                      {
                        // indexはリダイレクト
                        index: true,
                        element: <Navigate to="users" replace />,
                      },
                      {
                        path: ':settingTableId',
                        children: [
                          {
                            index: true,
                            breadcrumb: ({ match }: any) => (
                              <>
                                {
                                  settingConfigs.tables.find(
                                    (x) =>
                                      x.name === match.params.settingTableId
                                  )?.displayName
                                }
                              </>
                            ),
                            element: <></>,
                          },
                          {
                            path: ':settingItemId',
                            breadcrumb: ({ match }: any) => {
                              if (match.params.settingItemId === 'create') {
                                return <>{t('breadcrumbs.addNew')}</>;
                              }
                              return (
                                <>
                                  {settingConfigs.tables
                                    .find(
                                      (x) =>
                                        x.name === match.params.settingTableId
                                    )
                                    ?.renderBreadcrumb(
                                      match.params.settingItemId
                                    ) ?? match.params.settingItemId}
                                </>
                              );
                            },
                            element: <></>,
                          },
                        ],
                      },
                    ],
                  },
                ]
              : []),
          ],
        },
      ],
    },
    {
      path: 'debug',
      element: <Debug />,
    },
  ];
};

const DashboardBreadcrumb = (props: { id: string }) => (
  <>
    {useDashboards({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }).data?.find((x) => x.id === props.id)?.name ?? ''}
  </>
);
const ViewBreadcrumb = (props: { dashboardId: string; viewId: string }) => (
  <>
    {useViews(props.dashboardId, {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }).data?.find((x) => x.id === props.viewId)?.name ?? ''}
  </>
);
