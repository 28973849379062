/* eslint-disable react-hooks/exhaustive-deps*/

import { RealtimeChannel } from '@supabase/supabase-js-new';
import Push from 'push.js';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { assertNotNull } from 'Utils/guard';
import supabase from 'Utils/supabase';

import { useAccessToken } from './AccessToken';
import useOperator from './Operator';
import { useStatusContext } from './OperatorStatus';
import useJobs from './Queries/Jobs';
import useTranslation from './Translate';
import { useVideoOperations } from './VideoParams';
import { useUpdateWaitCalls, useWaitCalls, WaitCall } from './WatingCalls';

// 引数に渡されたチャネルリストに対し、着信を待機し待ち呼へ登録するHooks
export const useWaitIncommingCall = (channels: RealtimeChannel[] | null) => {
  const status = useStatusContext();
  const waitCalls = useWaitCalls();
  const updateWaitCalls = useUpdateWaitCalls();
  const navigate = useNavigate();
  const videoOperations = useVideoOperations();
  const getAccessToken = useAccessToken();
  const offCounter = useRef(0);
  const t = useTranslation('Session');

  const operator = useOperator();
  const jobsResult = useJobs(operator?.id, {
    enabled: operator !== undefined,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  if (jobsResult.error) throw jobsResult.error;

  const jobs = jobsResult.data?.map((x) => x.id);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  type receive_session = {
    jobId: string;
    shortCode: string;
  };

  const pickUpCall = useCallback(async () => {
    if (!channels) {
      return;
    }
    assertNotNull(operator);
    assertNotNull(jobs);
    const { data, error } = await supabase.rpc<receive_session>(
      'receive_session',
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        operator_id: operator.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        job_ids: jobs.join(','),
      }
    );
    if (error) throw error;
    if (data && data.length >= 1) {
      const { jobId, shortCode } = data[0];
      // JWTトークンを取得し、ルームIDとする
      const { key } = await getAccessToken(jobId, shortCode ?? '');
      videoOperations.join(key, false, false);

      // セッション画面に遷移させる
      navigate(`/session?job=${jobId}&alias=${shortCode}`, {
        state: { autoStart: true },
      });
    } else {
      // データが無ければ再度待ち呼を取得
      updateWaitCalls(generateWaitCalls(channels));
    }
  }, [channels, status, waitCalls, jobs]);

  useEffect(() => {
    // 自分のステータスがonlineか、待ち呼が1つ以上あれば通知を表示
    if (channels && status === 'online' && waitCalls.length >= 1) {
      const oldest = waitCalls[0];
      const job = jobsResult.data?.find((x) => x.id === oldest.jobId);

      assertNotNull(operator);
      assertNotNull(jobsResult.data);

      // 通知の表示
      Push.create(t('call.incomingCall'), {
        body: `Job: ${job?.name ?? ''}`,
        onClick: pickUpCall,
      });
    }
    // 待ち呼がなければ通知を削除
    else {
      Push.clear();
    }
  }, [channels, status, waitCalls]);

  const startWating = useCallback(() => {
    assertNotNull(channels);
    const currentOffCounter = offCounter.current;

    channels.forEach((x) => {
      // カスタマーのジョインを待機
      x.on('presence', { event: 'join' }, async () => {
        if (offCounter.current !== currentOffCounter) return;
        // 待ち呼を最新化
        updateWaitCalls(generateWaitCalls(channels));
      })
        // カスタマーのリーブを待機
        .on('presence', { event: 'leave' }, () => {
          if (offCounter.current !== currentOffCounter) return;
          // 待ち呼を最新化
          updateWaitCalls(generateWaitCalls(channels));
        });
    });
  }, [channels]);

  const stopWaiting = useCallback(() => {
    assertNotNull(channels);
    // supabase2の正式リリースからoffが消えたため、無効化方法を変更
    offCounter.current++;
    // channels.forEach((x) => {
    //   x.off('presence', { event: 'join' }).off('presence', {
    //     event: 'leave',
    //   });
    // });
  }, [channels]);

  return { startWating, stopWaiting, pickUpCall };
};

// チャネル内にあるプレセンスデータを待ち呼データへ変換する関数
const generateWaitCalls = (channels: RealtimeChannel[]) =>
  channels
    .map((ch) =>
      Object.entries(ch.presenceState())
        .filter(
          ([, presences]) =>
            // ペイロードに"iid"キーを持つオブジェクトをカスタマーとしてカウント
            (presences[0] as { iid?: string })?.iid !== undefined
        )
        .map(
          ([, presences]) =>
            ({
              iid: (presences[0] as { iid?: string }).iid,
              jobId: ch.topic.replace('realtime:', ''),
            } as WaitCall)
        )
    )
    .flat();
