import { useQuery, useQueryClient } from 'react-query';
import { assertNotNull } from 'Utils/guard';

// TODO: スニペット側の型と合わせる必要がある
export type WaitCall = {
  iid: string;
  jobId?: string;
};

// 待ち呼の登録用リスト
export const useWaitCalls = () => {
  const query = useQuery<WaitCall[]>(['Wait Calls'], {
    initialData: [],
    enabled: false,
  });

  if (query.error) throw query.error;
  assertNotNull(query.data);

  return query.data;
};

export const useUpdateWaitCalls = () => {
  const queryClient = useQueryClient();

  return (array: WaitCall[]) =>
    queryClient.setQueryData<WaitCall[]>(['Wait Calls'], array);
};
