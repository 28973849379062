import { Box, Typography } from '@mui/material';

import Loading from 'Components/Atoms/Loading';
import useTranslation from 'Components/Hooks/Translate';
import AnalyticsGadget from 'Components/Molecules/Analytics/AnalyticsGadget';
import { memo, useEffect, useRef, useState } from 'react';
import ReactGridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import { useLocation } from 'react-router-dom';
import { AppError } from 'Utils/error';
import { assertNotNull } from 'Utils/guard';
import supabase, { DashboardGadget } from 'Utils/supabase';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const dbAccessor = {
  updateGadgetLayout: async (withNewLayout: DashboardGadget) => {
    const { x, y, width, height, id } = withNewLayout;
    return await supabase
      .from<DashboardGadget>('dashboard_gadgets')
      .update({ x, y, width, height })
      .eq('id', id)
      .maybeSingle();
  },
};
interface AnalyticsGadgetPaneProps {
  loading?: boolean;
  dashboardGadgets: DashboardGadget[];
}

// eslint-disable-next-line react/display-name
const AnalyticsGadgetPane = memo((props: AnalyticsGadgetPaneProps) => {
  const t = useTranslation('Analytics');
  const isEditLayoutMode = useLocation().pathname.endsWith('edit-layout');

  // サイズ変更イベント監視と通知
  const [divElement, setDivElement] = useState<HTMLDivElement | null>(null);
  const resizeObserver = useRef<ResizeObserver>();
  useEffect(() => {
    if (divElement != null) {
      resizeObserver.current = new ResizeObserver((_entries) => {
        window.dispatchEvent(new Event('resize')); // WidthProviderにレイアウトを再計算させる
      });
      resizeObserver.current.observe(divElement);
    }
    return () => resizeObserver.current?.disconnect(); // 監視終了
  }, [divElement]);

  const createLayout = (dg: DashboardGadget, _idx: number) => {
    return {
      i: dg.id,
      x: dg.x,
      y: dg.y,
      w: dg.width,
      h: dg.height,
      moved: false,
      static: !isEditLayoutMode,
    };
  };

  const updateGadgetLayouts = async (layouts: ReactGridLayout.Layout[]) => {
    if (!isEditLayoutMode) return;

    await Promise.all(
      layouts.map((l) =>
        (async () => {
          const gadgetId = l.i;
          const objDashGadget = props.dashboardGadgets?.find(
            (dg) => dg.id === gadgetId
          );
          assertNotNull(objDashGadget);

          objDashGadget.x = l.x;
          objDashGadget.y = l.y;
          objDashGadget.width = l.w;
          objDashGadget.height = l.h;

          const { data, error } = await dbAccessor.updateGadgetLayout(
            objDashGadget
          );

          if (error != null)
            throw new AppError(
              error,
              'Sys',
              'AnalyticsDashboardLayout',
              'UpdateOne',
              '01'
            );
          if (data == null)
            throw new AppError(
              `Not found gadget: ${objDashGadget.gadget} in dashboard: ${objDashGadget.dashboardId}`,
              'Biz',
              'AnalyticsDashboardLayout',
              'UpdateOne',
              '02'
            );
          return data;
        })()
      )
    );
  };

  if (props.loading) {
    return (
      <Box p={2}>
        <Loading />
      </Box>
    );
  } else if (props.dashboardGadgets.length === 0) {
    return (
      <Box p={2}>
        <Typography variant="body1">{t('dashboard.nogadget')}</Typography>
      </Box>
    );
  } else {
    return (
      <Box p={2} sx={{ width: 1 }}>
        <div ref={setDivElement}>
          <ResponsiveReactGridLayout
            className="layout"
            measureBeforeMount
            onLayoutChange={updateGadgetLayouts}
            layouts={{
              lg: props.dashboardGadgets.map(createLayout),
            }}
            useCSSTransforms={true}
            // style={{
            //   border: 'solid 10px',
            // }}
          >
            {props.dashboardGadgets.map((dg) => (
              <div key={dg.id}>
                <AnalyticsGadget key={dg.id} gadget={dg.gadget} />
              </div>
            ))}
          </ResponsiveReactGridLayout>
        </div>
      </Box>
    );
  }
});

export default AnalyticsGadgetPane;
