import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

/** MUIのボタン系とreact router v6のlinkを連結する。 */
// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return <Link ref={ref} to={href} {...other} />;
});
export default LinkBehavior;

// how to use
// <ListItemButton component={LinkBehavior} href={item.name}/>
