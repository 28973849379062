import { BaseFilter, FacetValue } from './FacetValueBase';

export type OperatorFacetValuePayload = { operatorIds: string[] };
export class OperatorFacetValue extends FacetValue<OperatorFacetValuePayload> {
  constructor(payload: OperatorFacetValuePayload) {
    super('OperatorFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.operatorIds.length === 0) {
      return undefined;
    } else {
      return {
        member: `SupabaseSessionHistories.operatorId`,
        operator: 'equals',
        values: this.payload.operatorIds,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.operatorIds.length === 0;
  }
}
