import authProvider from './authProvider';

export default class OperatorInfo {
  private static readonly guestId = '';
  private static readonly guestName = 'guest';

  private _id: string;
  private _name: string;

  constructor(id: string, name: string) {
    this._id = id;
    this._name = name;
  }

  get id() {
    return this._id;
  }
  get name() {
    return this._name;
  }

  static createGuest() {
    return new OperatorInfo(this.guestId, this.guestName);
  }

  static async load() {
    const info = await authProvider.getIdentity();
    if (info != null) return new OperatorInfo(info.id, info.name ?? '');
    else return null;
  }
}
