import axios from 'axios';
import { JanusJS } from 'janus-gateway';
import Cookies from 'js-cookie';
import NoSleep from 'nosleep.js';

import Janus from './modules/janus';
import PubClient from './modules/pubClient';
import SubClient, { Publisher, Track } from './modules/subClient';

type OnLocalTrackFn = (track: MediaStreamTrack, on: boolean) => void;
type OnRemoteTrackFn = (
  track: MediaStreamTrack,
  mid: string,
  on: boolean
) => void;

export type OptipassVideoTrack = Track;

export type OptipassVideoSDKProps = {
  jwt: string;
  controlServerUrl: string;
  displayName?: string;
  feedId?: string;
  onlocaltrack: OnLocalTrackFn;
  onremotetrack: OnRemoteTrackFn;
  onChangePublishersState?: () => void;
  onTrackChanged?: () => void;
};

export type OptipassVideoState = {
  isVideoMuted: boolean;
  isAudioMuted: boolean;
  isWideCamera: boolean;
  displayName: string; // videoのステータスを使うと更新が面倒そうなのでステート管理で実装
};
export type OptipassVideoPublishersState = {
  [publisherId: string]: OptipassVideoState;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OP_VIDEO_RECONNECT = 'op-video-reconnect';

export default class OptipassVideoSDK {
  public audioInputDevices = new Set<InputDeviceInfo>();
  public audioOutputDevices = new Set<InputDeviceInfo>();
  public videoInputDevices = new Set<InputDeviceInfo>();
  public publishers = new Map<string, Publisher>();

  public oncleanup?: () => void;
  public ondetached?: () => void;

  public consentDialog?: (on: boolean) => void;

  protected sessionHandler?: JanusJS.Janus;

  private jwt: string;
  private controlServerUrl: string;
  private displayName?: string;
  private feedId?: string;

  private pub?: PubClient;
  private sub?: SubClient;

  private tracks = new Array<OptipassVideoTrack>();

  private onlocaltrack: OnLocalTrackFn;
  private onremotetrack: OnRemoteTrackFn;
  private onChangePublishersState?: () => void;
  private onTrackChanged?: () => void;

  private noSleep = new NoSleep();

  // 同じroomの全員のstateをここで管理しておき、変更時に外部に通知する。(supabase.presenceと同様の仕様)
  private publishersState: OptipassVideoPublishersState = {};
  // 自分のステート
  private state: OptipassVideoState = {
    isAudioMuted: false,
    isVideoMuted: false,
    isWideCamera: false,
    displayName: '',
  };

  constructor(props: OptipassVideoSDKProps) {
    this.jwt = props.jwt;
    this.controlServerUrl = props.controlServerUrl;
    this.displayName = props.displayName;
    this.feedId = props.feedId;
    this.onlocaltrack = props.onlocaltrack;
    this.onremotetrack = props.onremotetrack;
    this.onChangePublishersState = props.onChangePublishersState;
    this.onTrackChanged = props.onTrackChanged;

    this.classInit()
      .then(() => console.log('OptipassVideoSDK has been initialized'))
      .catch((e: string) => console.log(e));

    document.addEventListener('click', this.enableNoSleep.bind(this), false);

    if (this.isReconnectEnabled()) {
      this.initialize().then(() => {
        console.log('OptipassVideoSDK reconnecting...');
        this.startPublish().then(() => {
          console.log('OptipassVideoSDK reconnected');
        });
      });
    }
  }

  static attachMediaStream(
    track: MediaStreamTrack,
    element: HTMLVideoElement | HTMLAudioElement
  ) {
    Janus.attachMediaStream(element, new MediaStream([track]));
  }

  public getTracks() {
    return JSON.parse(JSON.stringify(this.tracks)) as Array<OptipassVideoTrack>;
  }

  async initialize(): Promise<void> {
    const props = await this.getVideoToken();

    const { url, accessToken } = props;
    await this.createSessionHandler({ url, accessToken });

    this.pub = new PubClient({
      ...props,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sessionHandler: this.sessionHandler!,
      onlocaltrack: this.onlocaltrackPrivate.bind(this),
      ondataopen: this.ondataopen.bind(this),
    });
    await this.pub?.initialize();
    this.pub.onPublishersUpdate = this.onPublishersUpdate.bind(this);
    this.pub.onPublishersLeaving = this.onPublishersLeaving.bind(this);
    this.sub = new SubClient({
      ...props,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sessionHandler: this.sessionHandler!,
      onremotetrack: this.onremotetrackPrivate.bind(this),
      ondata: this.ondata.bind(this),
    });
    await this.sub?.initialize();
    this.sub.onUpdateTracks = this.onTrackChangedPrivate.bind(this);
  }

  onPublishersUpdate(publishers: Array<Publisher>) {
    const pubToSub = [];
    for (const p of publishers) {
      if (!this.publishers.has(p.id) && p.id !== this.pub?.publisherId) {
        this.publishers.set(p.id, p);
        pubToSub.push(p);
      }
    }
    if (pubToSub.length > 0)
      this.startSubscribe(pubToSub)
        .then(() => {
          // do nothing
        })
        .catch(() => {
          // do nothing
        });
  }

  onPublishersLeaving(id: string) {
    this.endSubscribe([id]);
    this.publishers.delete(id);
  }

  onTrackChangedPrivate(tracks: Array<Track>) {
    this.tracks = tracks;
    this.onTrackChanged?.();

    // publishersStateの調整と通知
    let changed = false;
    for (const statePubId of Object.keys(this.publishersState)) {
      if (tracks.some((x) => x.feed_id === statePubId) === false) {
        delete this.publishersState[statePubId];
        changed = true;
      }
    }
    if (changed) {
      this.onChangePublishersState?.();
    }
  }

  isReconnectEnabled(): boolean {
    return false;
    //return Cookies.get(OP_VIDEO_RECONNECT) ? true : false;
  }

  async getDeviceList() {
    const audioInputDevices = new Set<InputDeviceInfo>();
    const audioOutputDevices = new Set<InputDeviceInfo>();
    const videoInputDevices = new Set<InputDeviceInfo>();

    const listDevices = await new Promise<Array<InputDeviceInfo>>(
      (resolve, reject) => {
        try {
          Janus.listDevices((devices) => resolve(devices));
        } catch (e) {
          reject(e);
        }
      }
    );

    for (const device of listDevices) {
      switch (device.kind) {
        case 'audioinput':
          audioInputDevices.add(device);
          break;
        case 'audiooutput':
          audioOutputDevices.add(device);
          break;
        case 'videoinput':
          videoInputDevices.add(device);
          break;
      }
    }

    this.audioInputDevices = audioInputDevices;
    this.audioOutputDevices = audioOutputDevices;
    this.videoInputDevices = videoInputDevices;

    return {
      audioInputDevices,
      audioOutputDevices,
      videoInputDevices,
    };
  }

  async changeDevice(props: { audio?: string; video?: string }) {
    const newTrackArr = [];
    const { audio, video } = props;
    if (audio)
      newTrackArr.push({
        type: 'audio',
        mid: '0',
        capture: { deviceId: { exact: audio } },
      });
    if (video)
      newTrackArr.push({
        type: 'video',
        mid: '1',
        capture: { deviceId: { exact: video } },
      });
    if (newTrackArr.length > 0) await this.pub?.replaceTracks(newTrackArr);
  }

  async startPublish(props?: {
    audio?: boolean | { deviceId: string };
    video?: boolean | { deviceId: string };
  }) {
    await this.pub?.startPublish({
      audio: props?.audio ?? true,
      video: props?.video ?? true,
      display: this.displayName,
      id: this.feedId,
    });
  }

  async endPublish() {
    await this.pub?.endPublish();
  }

  async startSubscribe(publishers: Array<Publisher>) {
    await this.sub?.startSubscribe(publishers);
  }

  async endSubscribe(ids: Array<string>) {
    await this.sub?.endSubscribe(ids);
  }

  mute() {
    this.pub?.muteAudio();
    this.pub?.muteVideo();
    const state = this.getState();
    state.isAudioMuted = true;
    state.isVideoMuted = true;
    this.setState(this.state);
  }

  unmute() {
    this.pub?.unmuteAudio();
    this.pub?.unmuteVideo();
    const state = this.getState();
    state.isAudioMuted = false;
    state.isVideoMuted = false;
    this.setState(state);
  }

  muteAudio() {
    this.pub?.muteAudio();
    const state = this.getState();
    state.isAudioMuted = true;
    this.setState(state);
  }

  unmuteAudio() {
    this.pub?.unmuteAudio();
    const state = this.getState();
    state.isAudioMuted = false;
    this.setState(state);
  }

  muteVideo() {
    this.pub?.muteVideo();
    const state = this.getState();
    state.isVideoMuted = true;
    this.setState(state);
  }

  unmuteVideo() {
    this.pub?.unmuteVideo();
    const state = this.getState();
    state.isVideoMuted = false;
    this.setState(state);
  }

  async hangup() {
    Cookies.remove(OP_VIDEO_RECONNECT);
    await this.endPublish();
    await this.endSubscribe(Array.from(this.publishers.keys()));
    this.publishers.clear();
    this.noSleep.disable();
  }

  async destroy() {
    return new Promise<void>((resolve, reject) => {
      this.sessionHandler?.destroy({
        success: () => resolve(),
        error: (e: string) => reject(e),
      });
    });
  }

  async destroyRoom() {
    const { data } = await axios.post<{
      url: string;
      accessToken: string;
      aclToken: string;
      roomName: string;
    }>(this.controlServerUrl + '/destroy', null, {
      headers: {
        authorization: this.jwt,
      },
    });
    return data;
  }

  getPublishersState = () => JSON.parse(JSON.stringify(this.publishersState));
  public async setState(state: OptipassVideoState) {
    this.state = JSON.parse(JSON.stringify(state)); // ローカルにステートを保持
    await this.sendState();
  }

  getState = () => JSON.parse(JSON.stringify(this.state)) as OptipassVideoState;

  setWideCamera(isWide: boolean) {
    const state = this.getState();
    state.isWideCamera = isWide;
    this.setState(state);
  }
  setDisplayName(displayName: string) {
    const state = this.getState();
    state.displayName = displayName;
    this.setState(state);
  }

  private async classInit() {
    await new Promise<void>((resolve) => {
      Janus.init({
        debug: true,
        callback: () => {
          console.log('Janus.init done!');
          resolve();
        },
      });
    });
  }

  private async createSessionHandler(props: {
    url: string;
    accessToken: string;
  }) {
    await new Promise<void>((resolve, reject) => {
      this.sessionHandler = new Janus({
        server: [
          props.url.replace('http', 'ws').replace('/janus', ''), // ws(s)://domain
          props.url, // http(s)://domain/janus
        ],
        success: () => resolve(),
        error: (err) => reject(err),
        destroyed: () => {
          // do nothing
        },
        token: props.accessToken,
        // destroyOnUnload: false,
        // withCredentials: true,
      });
    });
  }

  private async getVideoToken() {
    if (this.isReconnectEnabled()) {
      const cache: {
        url: string;
        accessToken: string;
        aclToken: string;
        roomName: string;
      } = JSON.parse(Cookies.get(OP_VIDEO_RECONNECT) || '{}');
      if (cache.url && cache.accessToken && cache.aclToken && cache.roomName)
        return cache;
    }

    const { data } = await axios.post<{
      url: string;
      accessToken: string;
      aclToken: string;
      roomName: string;
    }>(this.controlServerUrl + '/create', null, {
      headers: {
        authorization: this.jwt,
      },
    });

    Cookies.set(OP_VIDEO_RECONNECT, JSON.stringify(data));
    return data;
  }

  private enableNoSleep() {
    document.removeEventListener('click', this.enableNoSleep, false);
    this.noSleep.enable();
  }

  private async sendState() {
    if (this.pub == null) return;
    const sendData = JSON.stringify({ type: 'state', value: this.state });
    return await this.pub.sendData(sendData); // 全員に変更を通知
  }
  private async sendRequestState() {
    if (this.pub == null) return;
    const sendData = JSON.stringify({ type: 'requestState' });
    return await this.pub.sendData(sendData); // 全員にステート送信を要求
  }

  private ondata = (data: string, publisher: string) => {
    console.log('ondata', publisher, data);
    const obj = JSON.parse(data);
    const type = obj.type;
    if (type === 'requestState') {
      this.sendState();
    } else if (type === 'state') {
      this.publishersState[publisher] = (obj.value ?? {}) as OptipassVideoState;
    }
    this.onChangePublishersState?.();
  };

  private onlocaltrackPrivate: OnLocalTrackFn = (track, on) => {
    this.onlocaltrack?.(track, on);
  };
  private onremotetrackPrivate: OnRemoteTrackFn = (track, mid, on) => {
    this.onremotetrack?.(track, mid, on);
  };

  private ondataopen = () => {
    (async () => {
      // TODO:直後のデータ送信が上手くいかない。とりあえず開発を進めるために1秒待って送信
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await this.sendState();
      await this.sendRequestState();
    })();
  };
}
