import { List } from '@mui/material';
import useGetGadgetProfile from 'Components/Hooks/GetGadgetProfile';
import useTranslation from 'Components/Hooks/Translate';
import GadgetImage from 'Components/Molecules/Global/GadgetImage';
import ListItemWithButtonImage from 'Components/Molecules/ListItemWithButtonImage';
import { Gadget } from 'Utils/supabase';

interface GadgetListProps {
  alreadyExistings: Gadget[];
  gadgets: Gadget[];
  onClickAdd: (gadget: Gadget) => void;
  onClickRemove: (gadget: Gadget) => void;
}
const GadgetList = ({
  alreadyExistings,
  gadgets,
  onClickAdd,
  onClickRemove,
}: GadgetListProps) => {
  const t = useTranslation('Analytics');
  const getGadgetProfile = useGetGadgetProfile();

  const rows = gadgets.map((gadget) => {
    const isAlreadyExist = !!alreadyExistings.find((g) => g === gadget);
    const unimplemented = !getGadgetProfile(gadget).implemented;
    const label = () => {
      if (unimplemented) {
        return t('gadgetlist.unimplemented');
      } else if (isAlreadyExist) {
        return t('gadgetlist.remove');
      } else {
        return t('gadgetlist.add');
      }
    };
    return (
      <ListItemWithButtonImage
        key={gadget}
        image={<GadgetImage gadget={gadget} />}
        primaryText={getGadgetProfile(gadget).name}
        secondaryText={getGadgetProfile(gadget).description}
        disabled={unimplemented}
        onClick={
          isAlreadyExist
            ? () => onClickRemove(gadget)
            : () => onClickAdd(gadget)
        }
        variant={isAlreadyExist ? 'outlined' : 'contained'}
        buttonLabel={label()}
      />
    );
  });
  return (
    <List sx={{ height: 'calc(100% - 48px)', overflow: 'auto', width: '100%' }}>
      {rows}
    </List>
  );
};

export default GadgetList;
