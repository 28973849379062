import Cookies from 'js-cookie';
import yupJp from 'Locales/ja/yup';
import * as i18nYup from 'yup';

import browserLocale from './browserLocale';

const cookieLang = 'op-lang'; // cookieの読み込み場所が、useLanguageと分散しているので何とかしたい
const locale = Cookies.get(cookieLang) ?? browserLocale;

if (locale === 'ja') {
  i18nYup.setLocale(yupJp);
}

export default i18nYup;
