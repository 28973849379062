import axios from 'axios';
import { env } from 'env';

import supabase from './supabase';

// eslint-disable-next-line @typescript-eslint/naming-convention
class dataProvider {
  public static async post(url: string, data?: any) {
    const session = supabase.auth.session();
    const token = session?.access_token || '';

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return await axios.post(`${env.REACT_APP_API_GATEWAY!}${url}`, data, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        apikey: env.REACT_APP_API_SUPABASE_ANON_KEY!,
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async get(url: string) {
    const session = supabase.auth.session();
    const token = session?.access_token || '';

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return await axios.get(`${env.REACT_APP_API_GATEWAY!}${url}`, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        apikey: env.REACT_APP_API_SUPABASE_ANON_KEY!,
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async delete(url: string) {
    const session = supabase.auth.session();
    const token = session?.access_token || '';

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return await axios.delete(`${env.REACT_APP_API_GATEWAY!}${url}`, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        apikey: env.REACT_APP_API_SUPABASE_ANON_KEY!,
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default dataProvider;
