import { useTranslation as useOriginalTranslation } from 'react-i18next';

export type LngDictionaryNamespace =
  | 'Global'
  | 'Auth'
  | 'Session'
  | 'Analytics'
  | 'Settings'
  | 'Account'
  | 'Gadget'
  | 'GadgetTable'
  | 'Facet'
  | 'Summary'
  | 'Error';
const useTranslation = (namespace: LngDictionaryNamespace) => {
  const { t } = useOriginalTranslation(namespace);
  return t;
};

export default useTranslation;
