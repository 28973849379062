import { eventWithTime } from '@rrweb/types';
import { createMachine, interpret, StateMachine } from '@xstate/fsm';
import axios from 'axios';
import { EventType, IncrementalSource } from 'rrweb';

import { Socket } from 'socket.io-client';

import {
  ControlType,
  CustomRecordEvent,
  CustomRecordEventWithPayload,
  EncodeResult,
  OptipassControlEvents,
  OptipassSDKConfig,
  RequestEndSessionEvent,
} from '../common/common-types';
import OptipassSDK from '../common/sdkbase';
import EventEmitter from '../common/sdkEmitter';
import agentMachine from '../machines/agent-machine';
import { SdkContext, SdkEvent } from '../machines/sdk-machine';
import Player from '../modules/cobrowse/player';
import ContextDataAccessor from '../modules/contextData/contextDataAccessor';
import DocumentController from '../modules/documentSharing/documentController';
import Drawer from '../modules/drawing/drawer';

import {
  AgentContext,
  AgentEvent,
  AgentState,
  DrawConfig,
  WindowSize,
} from './types';

export class Agent extends OptipassSDK {
  /**Agent state machine */
  private agentService?: StateMachine.Service<
    AgentContext,
    AgentEvent,
    AgentState
  >;
  /**Web Stream Player */
  private player?: Player;
  private drawer?: Drawer;
  private remoteWindowSize: WindowSize;
  private viewerRatio: number;

  /**Context data accessor */
  private contextDataAccessor: ContextDataAccessor;

  /**Image/Document push controller */
  private documentController?: DocumentController;

  constructor() {
    super();
    this.viewerRatio = 1.0;
    this.remoteWindowSize = { height: 0, width: 0 };
    this.contextDataAccessor = new ContextDataAccessor();
  }

  /**
   * Returns context data accessor
   */
  get getContextDataAccessor(): ContextDataAccessor {
    return this.contextDataAccessor;
  }

  /**
   * Returns current Agent mode
   */
  get getMode(): string | undefined {
    return this.agentService?.state.value;
  }

  /**
   * Set drawing config
   */
  set drawConfig(config: DrawConfig) {
    if (this.drawer) this.drawer.drawConfig = config;
  }

  /**
   * Change Agent mode - view, control, drawing, spotlight
   * @param mode available session string
   */
  changeMode(mode: string): void {
    const modeStr = mode.toUpperCase();
    this.agentService?.send(`START_${modeStr}`);
  }

  /**
   * Start view mode
   */
  startView(): void {
    this.agentService?.send('START_VIEW');
  }

  /**
   * Start control mode
   */
  startControl(): void {
    this.agentService?.send('START_CONTROL');
  }

  /**
   * Start spotlight mode
   */
  startSpotlight(): void {
    this.agentService?.send('START_SPOTLIGHT');
  }

  /**
   * Start drawing mode
   */
  startDrawing(): void {
    this.agentService?.send('START_DRAWING');
  }

  /**
   * End Agent session
   */
  endSession(): void {
    this.agentService?.send('TERMINATE');
    super.endSession();
  }

  /**
   * Send end session requset to customer
   */
  requestEndSession(): void {
    const event: RequestEndSessionEvent = {
      type: ControlType.requestEndSession,
    };
    this.sendControlEvent(event);
  }

  /**
   * Start Accessing ContextData
   *
   * @param token - The session token to connect CDP
   */
  startAccessContext(dataServer: string, token: string) {
    return this.contextDataAccessor.start(dataServer, token);
  }

  pushDocument(
    doc: string | File,
    onSent?: (doc: EncodeResult, agentBlobUri: string) => void
  ) {
    return this.documentController?.pushDocument(doc, onSent);
  }

  requestDocument(
    pageIdx: number,
    onSent?: (doc: EncodeResult, agentBlobUri: string) => void
  ) {
    return this.documentController?.requestDocument(pageIdx, onSent);
  }

  resetDrawingContent() {
    this.drawer?.resetContent();
  }

  protected initialize(
    context: SdkContext,
    event: SdkEvent
  ): {
    socket: Socket;
    config: OptipassSDKConfig;
    token: string;
  } {
    // Call Super's initialize
    const operatorId =
      this.contextDataAccessor?.getUserInfo()?.options?.operator;
    const config = super.initialize(context, event, {
      ...(operatorId != null ? { operatorId: operatorId } : {}),
    });

    // Set Event Listeners for websocket
    this.socket?.on('webstream', this.receiveEventHandler.bind(this));

    // Create Agent Service
    this.agentService = this.createAgentMachine();
    this.agentService.subscribe((state) => {
      if (state.changed) {
        EventEmitter.instance.emit('agent-mode-changed', {
          state: state.value,
        });
      }
    });

    (async () => {
      // Init Controller
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { default: Controller } = await import(
        '../modules/control/controller'
      );
      const controller = new Controller(this.sendControlEvent.bind(this));

      // Init Player
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { default: Player } = await import('../modules/cobrowse/player');
      this.player = new Player(controller, this.requestFullSnapshot.bind(this));

      // Init Drawer
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { default: Drawer } = await import('../modules/drawing/drawer');
      this.drawer = new Drawer(this.sendControlEvent.bind(this));
      this.player?.getWrapper().appendChild(this.drawer.glassPane);

      // Init Doucment Controller
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { default: DocumentController } = await import(
        '../modules/documentSharing/documentController'
      );
      this.documentController = new DocumentController(
        this.httpPostRequest.bind(this),
        this.sendControlEvent.bind(this)
      );
    })();

    // Start Service
    this.agentService.start();

    // Request full snapshot
    this.requestFullSnapshot();

    return config;
  }

  private receiveEventHandler(event: eventWithTime) {
    switch (event.type) {
      case EventType.FullSnapshot:
        if (this.documentController?.hasBlobUrlSet()) {
          event = this.documentController.replaceCustomerBlob(event);
        }
        this.drawer?.resetContent();
        break;
      case EventType.IncrementalSnapshot:
        switch (event.data.source) {
          case IncrementalSource.Mutation:
            if (this.documentController?.hasBlobUrlSet()) {
              event = this.documentController.replaceCustomerBlob(event);
            }
            break;
          case IncrementalSource.Scroll:
            this.drawer?.resetContent();
            break;
          case IncrementalSource.ViewportResize:
            this.requestFullSnapshot();
            this.setRemoteWindowSize({
              width: event.data.width,
              height: event.data.height,
            });
            break;
        }
        break;
      case EventType.Meta:
        this.setRemoteWindowSize({
          width: event.data.width,
          height: event.data.height,
        });
        break;
      case EventType.Custom:
        this.handelCustomEvent(event as CustomRecordEventWithPayload);
        break;
    }
    this.player?.addEvent(event);
  }

  private handelCustomEvent(event: CustomRecordEventWithPayload) {
    switch (event.data.tag) {
      case CustomRecordEvent.approveControl:
        this.agentService?.send('CONTROL_APPROVE');
        break;
      case CustomRecordEvent.rejectControl:
        this.agentService?.send('CONTROL_REJECT');
        break;
      case CustomRecordEvent.windowUnload:
        EventEmitter.instance.emit('agent-customer-unload');
        break;
      case CustomRecordEvent.requestDocumentPage:
        this.documentController?.requestDocument(event.data.payload);
        break;
      case CustomRecordEvent.notifyBlobUrl:
        this.documentController?.registerBlobUrl(
          event.data.payload.md5,
          undefined,
          event.data.payload.blobUrl
        );
        break;
      case CustomRecordEvent.notifyDestoryDocument:
        this.documentController?.removeBlobUrlSetOf(event.data.payload);
        break;
      case CustomRecordEvent.scrollBarOn:
        if (this.player) this.player.remoteScrollBar = event.data.payload;
        break;
      case CustomRecordEvent.endSession:
        this.endSession();
        break;
    }
  }

  private createAgentMachine(): StateMachine.Service<
    AgentContext,
    AgentEvent,
    AgentState
  > {
    const agentMachineProps = {
      actions: {
        requestControl: this.requestControl.bind(this),
        entryControl: this.entryControl.bind(this),
        exitControl: this.exitControl.bind(this),
        entrySpotlight: this.entrySpotlight.bind(this),
        exitSpotlight: this.exitSpotlight.bind(this),
        entryDrawing: this.entryDrawing.bind(this),
        exitDrawing: this.exitDrawing.bind(this),
        terminate: this.terminate.bind(this),
      },
    };
    return interpret(
      createMachine<AgentContext, AgentEvent, AgentState>(
        agentMachine,
        agentMachineProps
      )
    );
  }

  private sendControlEvent(event: OptipassControlEvents): void {
    switch (event.type) {
      case ControlType.controlClick:
      case ControlType.controlDblClick:
      case ControlType.controlRightClick:
      case ControlType.controlMouseOver:
      case ControlType.controlMouseOut:
      case ControlType.controlFormChange:
      case ControlType.controlScroll:
        if (!this.agentService?.state.matches('control')) {
          return;
        }
    }
    this.socket?.emit('control', event);
  }

  private httpPostRequest(data: FormData) {
    const path = '/fileService';
    const headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'content-type': 'multipart/form-data',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Access-Control-Allow-Origin': '*',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: 'Bearer ' + this.getToken(),
    };

    const config = this.getConfig();
    const url = config ? config.eventGatewayServer + path : path;
    return axios.post(url, data, { headers: headers });
  }

  private requestControl() {
    this.sendControlEvent({ type: ControlType.controlRequest });
  }

  private requestFullSnapshot() {
    this.sendControlEvent({
      type: ControlType.requestFullSnapshot,
    });
  }

  private entryControl(_context: AgentContext, _event: AgentEvent) {
    this.player?.start();
  }

  private exitControl(_context: AgentContext, _event: AgentEvent) {
    this.player?.end();
  }

  private entrySpotlight(_context: AgentContext, _event: AgentEvent) {
    return;
  }

  private exitSpotlight(_context: AgentContext, _event: AgentEvent) {
    return;
  }

  private entryDrawing(_context: AgentContext, _event: AgentEvent) {
    this.drawer?.start(this.remoteWindowSize);
  }

  private exitDrawing(_context: AgentContext, _event: AgentEvent) {
    this.drawer?.end();
  }

  private terminate(_context: AgentContext, _event: AgentEvent) {
    this.player?.resetRemoteView();
  }

  private setRemoteWindowSize(size: WindowSize) {
    if (this.remoteWindowSize !== size) {
      this.remoteWindowSize = size;
      this.drawer?.resize(this.remoteWindowSize);
      EventEmitter.instance.emit('agent-remote-window-size-changed', {
        ...this.remoteWindowSize,
        ratio: this.viewerRatio,
      });
    }
  }
}
