import useCustomer from '../Customer';
import usePageHistories, {
  usePageHistoriesDistinctUserByAnonymousId,
} from '../Queries/PageHistories';

export const useReturnVisitsNum = () => {
  const customerId = useCustomer()?.userId;
  const pageHistories = usePageHistories(customerId ?? '', false, {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  //指定したuserIdのanonymousIdを取得
  const anonumousId = pageHistories.data?.[0]?.anonymousId;

  const rvHistories = usePageHistoriesDistinctUserByAnonymousId(
    anonumousId ?? '',
    false,
    {
      enabled: anonumousId != null,
      refetchOnMount: false,
      refetchInterval: 10000,
    }
  );

  const data = (() => {
    if (rvHistories.data == null) return undefined;

    return rvHistories.data;
  })();

  return {
    ...rvHistories,
    data,
  };
};
