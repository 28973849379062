import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import supabase, { Job, JobsUser } from 'Utils/supabase';

const key = 'Jobs';

const useJobs = (
  userId?: string,
  options?: Omit<UseQueryOptions<Job[]>, 'queryKey' | 'queryFn'>
) => {
  const keys = [key, userId ?? ''];
  return useQuery<Job[]>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      const result = await supabase
        .from<Job>('jobs')
        .select('*, jobs_users(userId)')
        .order('name');
      if (result.error != null) throw result.error;

      // userIdでフィルタ
      return result.data.filter(
        (x: any): boolean =>
          userId == null ||
          (x.jobs_users ?? []).some((y: any) => y.userId === userId)
      );
    },
    options
  );
};
export default useJobs;

export const useInsertJob = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: any) => {
      const itemId = await insertOrUpdate(true, item);
      //キーを生成して返す
      const { data: primaryKey } = await supabase.rpc<string>(
        'jobs_api_reset_key_primary',
        {
          _id: itemId,
        }
      );
      const { data: secondaryKey } = await supabase.rpc<string>(
        'jobs_api_reset_key_secondary',
        {
          _id: itemId,
        }
      );
      return {
        primaryKey: primaryKey as unknown as string,
        secondaryKey: secondaryKey as unknown as string,
      };
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
      // トランザクションがいまいちなので、エラー時も再取得
      onError: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useUpdateJob = () => {
  const queryClient = useQueryClient();
  return useMutation(async (item: any) => insertOrUpdate(false, item), {
    onSuccess: () => queryClient.invalidateQueries(key),
    // トランザクションがいまいちなので、エラー時も再取得
    onError: () => queryClient.invalidateQueries(key),
  });
};

export const useDeleteJobs = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (itemIds: string[]) => {
      const result = await supabase.from('jobs').delete().in('id', itemIds);

      if (result.error != null || result.data.length === 0) throw result.error;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

const insertOrUpdate = async (isNew: boolean, item: any) => {
  // ジョブの保存
  const jobsResult = isNew
    ? await supabase.from<Job>('jobs').insert({
        name: item.name,
      })
    : await supabase
        .from<Job>('jobs')
        .update({
          name: item.name,
          useWebCall: item.useWebCall,
          includeShortCodeInLink: item.includeShortCodeInLink,
          telephoneNumber: item.telephoneNumber,
          telephoneUrl: item.telephoneUrl,
          useVideo: item.useVideo,
          canChangeUseVideo: item.canChangeUseVideo,
          useAudio: item.useAudio,
          canChangeUseAudio: item.canChangeUseAudio,
          useCobrowse: item.useCobrowse,
          canChangeUseCobrowse: item.canChangeUseCobrowse,
          enableVideoRecording: item.enableVideoRecording,
          supportCanvas: item.supportCanvas,
          supportFrame: item.supportFrame,
        })
        .eq('id', item.id);
  console.info('job update', jobsResult);
  if (jobsResult.error != null) throw jobsResult.error;
  const itemId = jobsResult.data[0].id;

  // 以前のユーザー関連の取得
  const jobUsersResult = await supabase
    .from<JobsUser>('jobs_users')
    .select()
    .match({ jobId: itemId });
  if (jobUsersResult.error != null) throw jobUsersResult.error;
  const oldJobUserIds = (jobUsersResult.data ?? []).map((x) => x.userId);

  // 追加されているユーザーを追加
  for (const addUserId of item.jobUserIds.filter(
    (x: any) => oldJobUserIds.includes(x) === false
  )) {
    const jobUserInsertResult = await supabase
      .from<JobsUser>('jobs_users')
      .insert({ jobId: itemId, userId: addUserId });
    if (jobUserInsertResult.error != null) throw jobUserInsertResult.error;
  }

  // 削除されているユーザーを削除
  for (const removeUserId of oldJobUserIds.filter(
    (x) => item.jobUserIds.includes(x) === false
  )) {
    const jobsUsersInsertResult = await supabase
      .from<JobsUser>('jobs_users')
      .delete()
      .match({ jobId: itemId, userId: removeUserId });
    if (jobsUsersInsertResult.error != null) throw jobsUsersInsertResult.error;
  }

  return itemId;
};
