import {
  MutationFunction,
  QueryKey,
  useMutation,
  useQueryClient,
} from 'react-query';
import { assertNotNull } from 'Utils/guard';
import supabase from 'Utils/supabase';

// 汎用的なMutationフック
export const useGeneralMutation = <TZData, TVariables>(
  mutateFn: MutationFunction<TZData, TVariables>, // 更新処理用関数
  queryKey: QueryKey // useQueryで指定したキー
) => {
  const queryClient = useQueryClient();

  return useMutation(mutateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};

// Insert
export const useInsert = <T,>(tablename: string, queryKey: QueryKey) =>
  useGeneralMutation(async (value: Partial<T>) => {
    const { data, error } = await supabase
      .from<T>(tablename)
      .insert(value)
      .single();

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);

export const useInsertMany = <T,>(tablename: string, queryKey: QueryKey) =>
  useGeneralMutation(async (values: Partial<T>[]) => {
    const { data, error } = await supabase.from<T>(tablename).insert(values);

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);

// Upsert
export const useUpsert = <T,>(tablename: string, queryKey: QueryKey) =>
  useGeneralMutation(async (value: Partial<T>) => {
    const { data, error } = await supabase
      .from<T>(tablename)
      .upsert(value)
      .single();

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);

export const useUpsertMany = <T,>(tablename: string, queryKey: QueryKey) =>
  useGeneralMutation(async (values: Partial<T>[]) => {
    const { data, error } = await supabase.from<T>(tablename).upsert(values);

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);

// Update
export const useUpdate = <T,>(
  tablename: string,
  queryKey: QueryKey,
  matchKey: keyof T
) =>
  useGeneralMutation(async (value: Partial<T>) => {
    const { data, error } = await supabase
      .from<T>(tablename)
      .update(value)
      .match({ [matchKey]: value[matchKey] })
      .single();

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);

// Delete
export const useDelete = <T,>(tablename: string, queryKey: QueryKey) =>
  useGeneralMutation(async (value: Record<string, unknown>) => {
    const { data, error } = await supabase
      .from<T>(tablename)
      .delete()
      .match(value)
      .single();

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);

export const useDeleteMany = <T,>(tablename: string, queryKey: QueryKey) =>
  useGeneralMutation(async (value: Record<string, unknown>) => {
    const { data, error } = await supabase
      .from<T>(tablename)
      .delete()
      .match(value);

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);

// DeleteManyの派生形。特定のキーに対して " in [配列] "による条件指定を行う
export const useDeleteManyByKey = <T,>(
  tablename: string,
  queryKey: QueryKey,
  matchKey: keyof T
) =>
  useGeneralMutation(async (values: T[typeof matchKey][]) => {
    const { data, error } = await supabase
      .from<T>(tablename)
      .delete()
      .in(matchKey, values);

    if (error) throw error;
    assertNotNull(data);

    return data;
  }, queryKey);
