import {
  TextField,
  Stack,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Box,
} from '@mui/material';
import Can from 'Components/Atoms/AccessControl/Can';
import LabeledCheckbox from 'Components/Atoms/LabeledCheckbox';
import SelectMultiple from 'Components/Atoms/SelectMultiple';
import useConfirm from 'Components/Hooks/Confirm';
import useLoading from 'Components/Hooks/Loading';
import useTranslation from 'Components/Hooks/Translate';
import * as React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import supabase from 'Utils/supabase';

// 編集フォームの実装
const JobForm = (props: {
  isNew: boolean;
  item: any;
  form: UseFormReturn<any, any>;
}) => {
  const t = useTranslation('Settings');
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = props.form;

  watch(); // チェック状態による閉会のために変更のたびにrender

  const confirm = useConfirm();
  const { load } = useLoading();

  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>(
    props.item?.jobUserIds ?? []
  );

  const onChangeUser = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    const selectedIds: string[] =
      typeof value === 'string' ? value.split(',') : value;
    setSelectedUserIds(selectedIds);
    props.form.setValue('jobUserIds', selectedIds);
  };

  const onResetApiKey = async (isPrimary: boolean) => {
    if (
      await confirm({
        caption: t('jobs.message.alert.title'),
        message: t('jobs.message.alert.text'),
        canCancel: true,
      })
    ) {
      const to = isPrimary ? 'primary' : 'secondary';

      const { data } = await load(
        async () =>
          await supabase.rpc<string>(`jobs_api_reset_key_${to}`, {
            _id: props.item.id,
          })
      );

      if (data === null) {
        await confirm({
          caption: t('jobs.message.newKeyError'),
          message: t('jobs.message.newKeyErrorMessage'),
          dialogProps: {
            fullWidth: true,
            maxWidth: 'md',
          },
        });
      } else {
        await confirm({
          caption: t('jobs.message.newKey'),
          message: data,
          dialogProps: {
            fullWidth: true,
            maxWidth: 'md',
          },
        });
      }
    }
  };

  return (
    <>
      <Stack spacing={3}>
        {props.isNew === false && (
          <TextField
            label={t('jobs.field.id')}
            inputProps={{ readOnly: true }}
            {...register('id')}
          />
        )}
        <TextField
          required
          label={t('jobs.field.name')}
          error={'name' in errors}
          helperText={errors.name?.message}
          {...register('name')}
        />

        <SelectMultiple
          caption={t('jobs.field.users')}
          items={props.item?.userProfiles ?? []}
          selectedItemIds={selectedUserIds}
          onChange={onChangeUser}
          disabled={false}
        ></SelectMultiple>

        <Controller
          name="useWebCall"
          control={control}
          render={({ field }) => (
            <>
              <FormControl>
                <FormLabel sx={{ mt: 2 }}>
                  {t('jobs.message.useWebCallDescription')}
                </FormLabel>
                <RadioGroup row>
                  <FormControlLabel
                    {...field}
                    control={<Radio />}
                    label={t('jobs.field.webCall')}
                    checked={field.value === true}
                    onChange={() => {
                      field.onChange(true);
                      props.form.setValue('useTelephoneLink', false);
                    }}
                  />
                  <FormControlLabel
                    {...field}
                    control={<Radio />}
                    label={t('jobs.field.legacyPhone')}
                    checked={field.value !== true}
                    onChange={() => field.onChange(false)}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        />

        {props.form.getValues().useWebCall === false && (
          <FormGroup>
            <Controller
              name="useTelephoneLink"
              control={control}
              render={({ field }) => (
                <LabeledCheckbox
                  label={t('jobs.field.useTelephoneLink') + ''}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                    if (event.target.checked === false) {
                      props.form.setValue('telephoneNumber', '');
                    }
                  }}
                />
              )}
            />

            {props.form.getValues().useTelephoneLink && (
              <>
                <TextField
                  required
                  label={t('jobs.field.telephoneNumber') + ''}
                  placeholder="0123-456-789"
                  error={'telephoneNumber' in errors}
                  helperText={errors.telephoneNumber?.message}
                  sx={{ mt: 1, mb: 2 }}
                  {...register('telephoneNumber')}
                />

                <Controller
                  name="includeShortCodeInLink"
                  control={control}
                  render={({ field }) => (
                    <LabeledCheckbox
                      label={t('jobs.field.includeShorCodeInLink') + ''}
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  )}
                />

                {props.form.getValues().includeShortCodeInLink && (
                  <TextField
                    label={t('jobs.field.telephoneUrl')}
                    placeholder="tel://0123-456-789...{}"
                    error={'telephoneUrl' in errors}
                    helperText={errors.telephoneUrl?.message}
                    sx={{ mt: 1, mb: 1 }}
                    {...register('telephoneUrl')}
                  />
                )}
              </>
            )}
          </FormGroup>
        )}

        <FormGroup>
          {props.form.getValues().useWebCall && (
            <>
              <Controller
                name="enableVideoRecording"
                control={control}
                render={({ field }) => (
                  <LabeledCheckbox
                    label={t('jobs.field.enableVideoRecording') + ''}
                    checked={field.value}
                    onChange={(event) => field.onChange(event.target.checked)}
                  ></LabeledCheckbox>
                )}
              />

              <Controller
                name="useVideo"
                control={control}
                render={({ field }) => (
                  <LabeledCheckbox
                    label={t('jobs.field.useVideo') + ''}
                    checked={field.value}
                    onChange={(event) => field.onChange(event.target.checked)}
                  ></LabeledCheckbox>
                )}
              />

              <Controller
                name="canChangeUseVideo"
                control={control}
                render={({ field }) => (
                  <LabeledCheckbox
                    label={t('jobs.field.canChangeUseVideo') + ''}
                    checked={field.value}
                    onChange={(event) => field.onChange(event.target.checked)}
                  ></LabeledCheckbox>
                )}
              />

              {/*
                オーディオ無しだとショートコード連携になってしまうため、急遽リリースのため項目を除外
              <Controller
                name="useAudio"
                control={control}
                render={({ field }) => (
                  <LabeledCheckbox
                    label={t('jobs.field.useAudio') + ''}
                    checked={field.value}
                    onChange={(event) => field.onChange(event.target.checked)}
                  ></LabeledCheckbox>
                )}
              /> 

              <Controller
                name="canChangeUseAudio"
                control={control}
                render={({ field }) => (
                  <LabeledCheckbox
                    label={t('jobs.field.canChangeUseAudio') + ''}
                    checked={field.value}
                    onChange={(event) => field.onChange(event.target.checked)}
                  ></LabeledCheckbox>
                )}
              />
              */}
            </>
          )}

          {/* 
            コブラウズは必ず有効にしないと、Session historiesが残らないため、急遽リリースのため項目を除外
           <Controller
            name="useCobrowse"
            control={control}
            render={({ field }) => (
              <LabeledCheckbox
                label={t('jobs.field.useCobrowse') + ''}
                checked={field.value}
                onChange={(event) => field.onChange(event.target.checked)}
              ></LabeledCheckbox>
            )}
          />

          <Controller
            name="canChangeUseCobrowse"
            control={control}
            render={({ field }) => (
              <LabeledCheckbox
                label={t('jobs.field.canChangeUseCobrowse') + ''}
                checked={field.value}
                onChange={(event) => field.onChange(event.target.checked)}
              ></LabeledCheckbox>
            )}
          /> */}
        </FormGroup>

        {/*
        常に有効状態で一旦リリースするため設定画面の項目をコメントアウト
        <FormGroup>
          <FormLabel sx={{ mt: 2 }}>画面共有詳細</FormLabel>
          <Controller
            name="supportFrame"
            control={control}
            render={({ field }) => (
              <LabeledCheckbox
                label={'フレームをサポート'}
                checked={field.value}
                onChange={(event) => field.onChange(event.target.checked)}
              ></LabeledCheckbox>
            )}
          />

          <Controller
            name="supportCanvas"
            control={control}
            render={({ field }) => (
              <LabeledCheckbox
                label={'キャンバスをサポート'}
                checked={field.value !== 0}
                onChange={(event) =>
                  field.onChange(event.target.checked ? 10 : 0)
                }
              ></LabeledCheckbox>
            )}
          />
        </FormGroup> */}

        {props.isNew === false && (
          <Can crud="update" componentName="job">
            <Box>
              <Stack sx={{ mt: 2 }} spacing={1}>
                <Button variant="outlined" onClick={() => onResetApiKey(true)}>
                  {t('jobs.field.apiKey')}
                </Button>
                <Button variant="outlined" onClick={() => onResetApiKey(false)}>
                  {t('jobs.field.apikeySecondary')}
                </Button>
              </Stack>
            </Box>
          </Can>
        )}
      </Stack>
    </>
  );
};

export default JobForm;
