import { Check } from '@mui/icons-material';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import useLocale, { useUpdateLocale } from 'Components/Hooks/Locale';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';

const LanguageMenu = () => {
  const t = useTranslation('Global');
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const locale = useLocale();
  const updateLocale = useUpdateLocale();

  const setLanguage = (value: string) => {
    updateLocale(value);
    setAnchor(null);
  };

  const languages = ['en', 'ja'];
  const selectedLanguage = languages.includes(locale) ? locale : 'en';

  return (
    <>
      <Tooltip
        title={t('languages.change').toString()}
        placement="bottom"
        arrow
      >
        <IconButton
          id="language-menu-button"
          onClick={(event) => setAnchor(event.currentTarget)}
          sx={{
            ...(anchor != null ? {} : { zIndex: Number.MAX_SAFE_INTEGER }),
          }}
        >
          <GTranslateOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Menu
        open={anchor != null}
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
      >
        {languages.map((x) => (
          <MenuItem
            key={x}
            id={'language-menu-'}
            onClick={() => setLanguage(x)}
          >
            {x === selectedLanguage ? (
              <>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                {t('languages.' + x)}
              </>
            ) : (
              <ListItemText inset>{t('languages.' + x)}</ListItemText>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageMenu;
