import {
  FormControl,
  IconButton,
  Autocomplete,
  AutocompleteProps,
  Stack,
} from '@mui/material';
import React from 'react';

type AutocompleteWithButtonProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType<any> = 'div'
> = {
  variant?: 'filled' | 'standard' | 'outlined';
  onClickIcon?: () => void;
  iconButton?: React.ReactNode;
  width?: number;
} & AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>;

const AutocompleteWithButton = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType<any> = 'div'
>(
  props: AutocompleteWithButtonProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >
) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    variant,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClickIcon,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    iconButton,
    ChipProps,
    width,
    sx,
    ...autoCompleteProps
  } = props;

  return (
    <FormControl
      fullWidth
      variant={props.variant || 'filled'}
      sx={{
        width: width,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          border: '1px solid #ccc',
          backgroundColor: '#fff',
          borderRadius: 1,
          height: '41px',
        }}
      >
        <Autocomplete
          {...autoCompleteProps}
          {...ChipProps?.component}
          sx={{
            height: 39,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '& .MuiTextField-root': {
              backgroundColor: 'rgba(255, 255, 255, 0)',
            },
            flexGrow: 1,
            ...sx,
          }}
        />
        {props.iconButton && props.onClickIcon && (
          <IconButton disabled={props.disabled} onClick={props.onClickIcon}>
            {props.iconButton}
          </IconButton>
        )}
      </Stack>
    </FormControl>
  );
};
export default AutocompleteWithButton;
