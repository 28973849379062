/* eslint-disable @typescript-eslint/naming-convention */
import mitt, { Emitter } from 'mitt';

type BasicCallbackEvents = {
  '*': unknown;
  'sdk-state-idle': unknown;
  'sdk-state-initializing': unknown;
  'sdk-state-agent': unknown;
  'sdk-state-customer': unknown;
  'sdk-state-failed': unknown;
  'sdk-state-disconnected': unknown;
  'sdk-state-changed': {
    state: string;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
type AgentCallbackEvents = {
  'agent-remote-window-size-changed': {
    height: number;
    width: number;
    ratio: number;
  };
  'agent-mode-changed': {
    state: 'control' | 'spotlight' | 'drawing' | 'terminated';
  };
  'agent-customer-unload': unknown;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomerCallbackEvents = {
  'customer-update-room-members-num': number;
};

type WebCallCallbackEvents = {
  'webcall-answered': unknown;
  'webcall-created': unknown;
  'webcall-dtmfreceived': {
    tone: string;
    duration: number;
  };
  'webcall-hangup': unknown;
  'webcall-hold': {
    held: boolean;
  };
  'webcall-received': unknown;
  'webcall-message-received': {
    message: string;
  };
  'webcall-registered': unknown;
  'webcall-server-connect': unknown;
  'webcall-server-disconnect': {
    error: Error;
  };
  'webcall-unregistered': unknown;
};

export type CallbackEvents = BasicCallbackEvents &
  AgentCallbackEvents &
  CustomerCallbackEvents &
  WebCallCallbackEvents;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Mitt extends Emitter<CallbackEvents> {}
class Mitt {
  constructor() {
    Object.assign(this, mitt<CallbackEvents>());
  }
}

export default class EventEmitter extends Mitt {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static _instance: EventEmitter;

  // constructor is private because this class is singleton
  private constructor() {
    super();
  }

  public static get instance(): EventEmitter {
    if (!this._instance) {
      this._instance = new EventEmitter();
    }
    return this._instance;
  }
}
