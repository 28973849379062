import { useQuery, useQueryClient } from 'react-query';

const useIid = () => {
  const query = useQuery<string>(['Session Iid'], {
    enabled: false,
  });

  return query.data;
};
export default useIid;

export const useUpdateIid = () => {
  const queryClient = useQueryClient();

  return (iid: string) =>
    queryClient.setQueryData<string>(['Session Iid'], iid);
};
