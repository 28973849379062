import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import { Box, Stack } from '@mui/material';
import TooltipFab from 'Components/Atoms/TooltipFab';
import { useAccessToken } from 'Components/Hooks/AccessToken';
import useAgent from 'Components/Hooks/Agent';
import { useChannelContext } from 'Components/Hooks/Channel';
import { useNotify } from 'Components/Hooks/Notify';
import { useChangeStatus } from 'Components/Hooks/OperatorStatus';
import useTranslation from 'Components/Hooks/Translate';
import useVideoParams from 'Components/Hooks/VideoParams';
import EndSessionButton from 'Components/Molecules/Sessions/EndSessionButton';
import BeforeSession from 'Components/Organisms/Sessions/BeforeSession';
import CobrowseSession from 'Components/Organisms/Sessions/CobrowseSession';
import ContactReasonPane from 'Components/Organisms/Sessions/ContactReasonPane';
import HistorySession from 'Components/Organisms/Sessions/HistorySession';
import { SessionGlobalMenuType } from 'Components/Organisms/Sessions/SessionGlobalMenu';
import Summary from 'Components/Organisms/Sessions/Summary';
import TabMenu from 'Components/Organisms/Sessions/TabMenu';
import { env } from 'env';
import VideoCall from 'Pages/VideoCall';
import VideoProvider from 'Providers/VideoProvider';
import { useEffect, useState } from 'react';
import { AppError } from 'Utils/error';
import { Job } from 'Utils/supabase';

export type LeftPaneType = SessionGlobalMenuType;
export type RightPaneType = 'drawingPalette' | 'fileSharing' | undefined;

/** セッション画面のレイアウトを処理する */
// eslint-disable-next-line @typescript-eslint/naming-convention
export default function Session() {
  const notify = useNotify();
  const t = useTranslation('Session');
  const channels = useChannelContext();
  const changeStatus = useChangeStatus(channels);
  const videoRoomParams = useVideoParams();

  // セッション中の情報を保持
  const agent = useAgent();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const customerId = agent?.getContextDataAccessor.getUserInfo()?.userId!;
  const [sdkState, setSdkState] = useState(false);
  const [job, setJob] = useState<Job>();
  const [alias, setAlias] = useState('');

  // コンタクトリーズン送信の有無
  const [sentContactReasons, setSentContactReasons] = useState(true);

  // アクセストークン取得用のHooks
  const getAccessToken = useAccessToken();

  //コンタクトリーズンモーダル
  const [contactReasonModal, setContactReasonModal] = useState(false);

  // 接続
  function onConnect(job: Job, alias: string) {
    if (!agent) {
      notify(t('notification.test'), 'warning');
      throw new Error();
    }
    if (!sdkState) {
      (async () => {
        agent.on('sdk-state-changed', (ev: unknown) => {
          const status = (ev as any).state as string;
          setSdkState(agent.isSessionActive());
          if (status === 'agent') {
            agent.on('sdk-state-disconnected', () => agent?.resetSdk());
          }
          // 自分のステータスを通話開始・終了をトリガーとして更新
          agent.isSessionActive()
            ? changeStatus('incall')
            : changeStatus('busy');
        });
        try {
          const res = await getAccessToken(job.id, alias);
          console.debug(res);
          setJob(job);
          setAlias(alias);
          agent.startSession(res.key);
          changeStatus('incall');
          setSentContactReasons(false);
        } catch (e: any) {
          if (e.response.status === 404) {
            throw new AppError(
              e,
              'Biz',
              'SessionStart',
              'GenerateAccessToken',
              '01'
            );
          } else {
            throw new AppError(
              e,
              'Sys',
              'SessionStart',
              'GenerateAccessToken',
              '04'
            );
          }
        }
      })();
    }
  }

  // セッション終了時にコンタクトリーズンが未送信の場合にモーダルを開く
  useEffect(() => {
    !sdkState && !sentContactReasons && alias
      ? setContactReasonModal(true)
      : setContactReasonModal(false);
  }, [sdkState, sentContactReasons, alias]);

  return (
    <>
      <Box sx={{ height: 1, position: 'relative' }}>
        {!sdkState ? (
          <>
            <BeforeSession onConnect={onConnect} />
            {alias && !sentContactReasons ? (
              <ContactReasonPane
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                jobId={job!.id}
                contactReasonModal={contactReasonModal}
                setContactReasonModal={setContactReasonModal}
                setSentContactReasons={setSentContactReasons}
                afterSession={true}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            sx={{
              height: 1,
              display: 'grid',
              gridTemplateColumns: videoRoomParams.roomId ? '1fr 250px' : '1fr',
            }}
          >
            <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
              <TabMenu
                tabDatas={[
                  {
                    label: t('menu.summary'),
                    content: <Summary job={job} alias={alias} />,
                  },
                  {
                    label: t('menu.history'),
                    content: <HistorySession customerId={customerId} />,
                  },
                  {
                    label: t('menu.cobrowse'),
                    content: (
                      <CobrowseSession sdkState={sdkState} agent={agent} />
                    ),
                  },
                ]}
                centered={true}
              />

              <Stack
                sx={{ position: 'absolute', right: '8px', my: 0.5 }}
                direction={'row'}
                spacing={1}
              >
                {/* コンタクトリーズンボタン */}
                <TooltipFab
                  title={t('menu.contactReason')}
                  onClick={() => setContactReasonModal(true)}
                  size={'small'}
                >
                  <SpeakerNotesOutlinedIcon />
                </TooltipFab>
                <ContactReasonPane
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  jobId={job!.id}
                  contactReasonModal={contactReasonModal}
                  setContactReasonModal={setContactReasonModal}
                  setSentContactReasons={setSentContactReasons}
                />

                {/* セッション終了ボタン */}
                <EndSessionButton agent={agent} />
              </Stack>
            </Box>
            {videoRoomParams.roomId && (
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  marginTop: '50px',
                  height: 'calc(100% - 50px)',
                  width: '250px',
                }}
              >
                <VideoProvider controlServerUrl={env.REACT_APP_VIDEO_CONTROL}>
                  <VideoCall />
                </VideoProvider>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}
