import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Box, Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import useTranslation from 'Components/Hooks/Translate';
import useVideoParams, {
  useVideoOperations,
} from 'Components/Hooks/VideoParams';
import VideoPresenter from 'Components/Molecules/Sessions/VideoPresenter';
import { useVideo } from 'Providers/VideoProvider';
import { useState } from 'react';
// import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
// import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined'; // narrow
// import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';           // wide
// import SwitchVideoOutlinedIcon from '@mui/icons-material/SwitchVideoOutlined';         // mirror

const VideoCall = () => {
  const video = useVideo();
  const t = useTranslation('Session');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const videoOperations = useVideoOperations();
  const videoRoomParams = useVideoParams();

  const changeMic = (deviceId: string) => {
    videoOperations.setAudioDevice(deviceId);
    handleClose();
  };
  const changeCamera = (deviceId: string) => {
    videoOperations.setVideoDevice(deviceId);
    handleClose();
  };

  return (
    <Box display="grid" gridTemplateRows="1fr auto auto" height={1} p={1}>
      <Stack spacing={1} sx={{ height: 1 }} gridRow={1}>
        {video.remoteVideos.map((x) => {
          const feedId = video.tracks.find((y) => y.mid === x.mid)?.feed_id;
          const state = feedId == null ? undefined : video.remoteStatus[feedId];
          return (
            <Box sx={{ width: 1 }} key={x.id}>
              <VideoPresenter
                track={x}
                wide={state?.isWideCamera ?? false}
                isVideoMuted={state?.isVideoMuted}
                isAudioMuted={state?.isAudioMuted ? true : undefined}
              />
            </Box>
          );
        })}
      </Stack>

      {video.localVideo && (
        <Box sx={{ width: 1, mt: 1 }} gridRow={2}>
          {videoRoomParams.isVideoMute === false && (
            <VideoPresenter
              track={video.localVideo}
              mirror={true}
              wide={false}
            />
          )}
        </Box>
      )}

      <Box
        display="grid"
        gridTemplateColumns="1fr auto"
        gridRow={3}
        sx={{
          mt: 'auto',
          pt: 1,
        }}
      >
        <Stack direction="row" spacing={1} gridColumn={1}>
          {videoRoomParams.isAudioMute ? (
            <IconButton onClick={() => videoOperations.unmuteAudio()}>
              <MicOffOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => videoOperations.muteAudio()}>
              <MicOutlinedIcon />
            </IconButton>
          )}
          {videoRoomParams.isVideoMute ? (
            <IconButton onClick={() => videoOperations.unmuteVideo()}>
              <VideocamOffOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => videoOperations.muteVideo()}>
              <VideocamOutlinedIcon />
            </IconButton>
          )}
        </Stack>

        <IconButton onClick={handleClick} sx={{ gridColumn: 2 }}>
          <SettingsOutlinedIcon />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled>{t('videocall.microphone')}</MenuItem>
          {Array.from(video.sdk?.audioInputDevices ?? []).map((x) => (
            <MenuItem
              key={x.deviceId}
              onClick={() => changeMic(x.deviceId)}
              selected={x.deviceId === videoRoomParams.audioDevice}
            >
              {x.label}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem disabled>{t('videocall.camera')}</MenuItem>
          {Array.from(video.sdk?.videoInputDevices ?? []).map((x) => (
            <MenuItem
              key={x.deviceId}
              onClick={() => changeCamera(x.deviceId)}
              selected={x.deviceId === videoRoomParams.videoDevice}
            >
              {x.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default VideoCall;
