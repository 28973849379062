import { useCallback, useEffect, useState } from 'react';

/** 画面全体にローディング表示をするためのフック
 *
 * 以下のように使用すると、非同期処理中ローディングが表示される。
 * const response = await load( async () => await fetch() );
 */

const useLoading = (isLoading?: boolean) => {
  const isLoadingBoolean = isLoading ?? false;
  useEffect(() => {
    if (isLoadingBoolean) {
      window.dispatchEvent(new CustomEvent('loading-start'));
      return () => {
        window.dispatchEvent(new CustomEvent('loading-end'));
      };
    }
  }, [isLoadingBoolean]);

  const [isLoadingPrivate, setIsLoading] = useState(false);
  const f = useCallback(async <T,>(func: () => Promise<T>) => {
    window.dispatchEvent(new CustomEvent('loading-start'));
    setIsLoading(true);
    try {
      return await func();
    } finally {
      window.dispatchEvent(new CustomEvent('loading-end'));
      setIsLoading(false); //TODO アンマウントされていたら呼ばない
    }
  }, []);

  return { load: f, isLoading: isLoadingPrivate || isLoadingBoolean };
};

export default useLoading;
