import { Box } from '@mui/material';
import { Agent } from '@optipass/sdk/lib/agent/agent';
import AutoScaleContainer from 'Components/Atoms/AutoScaleContainer';
import { useVideoOperations } from 'Components/Hooks/VideoParams';
import ResponsiveDrawerContainer from 'Components/Molecules/Global/ResponsiveDrawerContainer';
import CobrowsMenu, {
  CobrowsChangeMode,
} from 'Components/Molecules/Sessions/CobrowsMenu';
import { RightPaneType } from 'Pages/Session/Session';
import { useRef, useState } from 'react';

import DrawingPalette from './DrawingPalette';
import FileShareBox from './FileShareBox';

const CobrowseSession = (props: { sdkState: boolean; agent?: Agent }) => {
  const [cobrowsMode, setCobrowsMode] = useState<CobrowsChangeMode>('view');
  const [rightPane, setRightPane] = useState<RightPaneType>(undefined);
  // ドロワーの開閉状況を保持
  const mainElement = useRef<HTMLDivElement>(null);
  const videoOperations = useVideoOperations();

  return (
    <ResponsiveDrawerContainer
      activeDrawer={rightPane}
      onClose={() => setRightPane(undefined)}
      anchor="right"
      drawers={[
        {
          // 描画モード
          name: 'drawingPalette',
          content: () => (
            <DrawingPalette
              onClose={() => {
                setRightPane(undefined);
                setCobrowsMode('view');
              }}
            />
          ),
        },
        {
          // ファイル共有
          name: 'fileSharing',
          content: () => (
            <FileShareBox
              onClose={() => setRightPane(undefined)}
              sdkState={props.sdkState}
            />
          ),
        },
      ]}
    >
      <Box // メインビュー
        sx={{
          display: 'grid',
          height: 1,
          gridTemplateRows: '1fr auto',
          backgroundColor: 'secondary.main',
        }}
        ref={mainElement}
      >
        <Box gridRow={1} mt={1} mx={1}>
          <AutoScaleContainer>
            {/* コブラウズ枠 */}
            <div id="next-assist-viewer" />
          </AutoScaleContainer>
        </Box>

        <Box gridRow={2}>
          <CobrowsMenu // コブラウズ用メニュー
            mode={cobrowsMode}
            setMode={(mode) => {
              props.agent?.changeMode(mode);
              setCobrowsMode(mode);
              if (mode === 'drawing') {
                setRightPane('drawingPalette');
              } else if (rightPane === 'drawingPalette') {
                setRightPane(undefined);
              }
            }}
            openFileSharing={rightPane === 'fileSharing'}
            setOpenFileSharing={(v) => {
              setRightPane(v ? 'fileSharing' : undefined);
            }}
            onLeave={() => {
              props.agent?.requestEndSession();
            }}
            onDisconnect={() => {
              videoOperations.exit();
              props.agent?.endSession();
            }}
          />
        </Box>
      </Box>
    </ResponsiveDrawerContainer>
  );
};

export default CobrowseSession;
