import cubejs from '@cubejs-client/core';
import { env } from 'env';

import supabase from './supabase';

const cubejsApi = cubejs(
  async () => {
    const session = supabase.auth.session();
    return session?.access_token || '';
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    apiUrl: env.REACT_APP_CUBEJS_API_URL!,
    //apiUrl: 'https://stats.dev.optipass.xyz/cubejs-api/v1',
  }
);

export default cubejsApi;
