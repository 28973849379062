import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import * as React from 'react';

interface DropDownData {
  key: string;
  value: string;
}

interface DropDownProps {
  id: string;
  label?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  size?: 'small' | 'medium';
  defaultKey?: string;
  data: DropDownData[];
  disabled: boolean;
  autoWidth?: boolean;
  onChange?: (data?: DropDownData) => void;
}
const DropDown = (props: DropDownProps) => {
  const [selected, setSelected] = React.useState(props.defaultKey || '');

  React.useEffect(() => {
    setSelected(props.defaultKey || '');
  }, [props.defaultKey]);

  const onChange = (event: any) => {
    const key = event.target.value;
    setSelected(key);
    const selectedData = props.data.find((d) => d.key === key);
    props.onChange?.(selectedData);
  };

  return (
    <FormControl fullWidth variant={props.variant || 'filled'}>
      <InputLabel id={props.id}>
        <Typography variant="h6">{props.label}</Typography>
      </InputLabel>
      <Select
        disabled={props.disabled}
        labelId={props.id}
        value={selected}
        label={props.label}
        onChange={onChange}
        size={props.size}
        autoWidth={props.autoWidth}
        sx={{ background: (t) => t.palette.background.default }}
      >
        {props.data.map((d) => {
          return (
            <MenuItem key={d.key} value={d.key}>
              <Typography variant="body1">{d.value}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default DropDown;
