/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  Stack,
  Typography,
} from '@mui/material';
import DialogTitleWithClose from 'Components/Atoms/DialogTitleWithClose';
import { useGetAllFacets } from 'Components/Hooks/AccessGadgetFacetMaster';
import useGetFacetProfile from 'Components/Hooks/GetFacetProfile';
import useLoading from 'Components/Hooks/Loading';
import { useNotify } from 'Components/Hooks/Notify';
import useFacets, {
  useDeleteFacets,
  useInsertFacets,
} from 'Components/Hooks/Queries/Facets';
import { useFacetsInUse, useViewsFacets } from 'Components/Hooks/Queries/Views';
import { useStateLazy } from 'Components/Hooks/StateLazy';
import useTranslation from 'Components/Hooks/Translate';
import React, { ComponentProps } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { assertNotNull } from 'Utils/guard';
import { Facet } from 'Utils/supabase';

const EditFacet = () => {
  const isOpen = useLocation().pathname.endsWith('edit-facets');
  return (
    <Dialog open={isOpen} maxWidth="lg">
      {/* 開閉によってmount/unmountされるので別コンポーネントで実装 */}
      <EditFacetModalContents />
    </Dialog>
  );
};

export default EditFacet;

const EditFacetModalContents = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const t = useTranslation('Analytics');
  const tf = useTranslation('Facet');
  // URLからパラメータ取得
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dashboardId = useParams<{ dashboardId?: string }>().dashboardId!;
  const facetsInUse = useFacetsInUse(dashboardId, {
    enabled: dashboardId != null,
    refetchOnWindowFocus: false,
  }).data;
  //dashboardIdから、viewsを取得
  const viewsFacets = useViewsFacets(dashboardId, {
    enabled: dashboardId != null,
    refetchOnWindowFocus: false,
  }).data;
  // Query系
  const facets = useFacets(dashboardId, { refetchOnWindowFocus: false });
  const insertFacets = useInsertFacets();
  const deleteFacets = useDeleteFacets();
  const [selectedFacets, setSelectedFacets] = useStateLazy<Facet[]>({
    initialState: facets.data?.map((x) => x.facet) ?? [],
    condition: facets.isFetched,
  });

  useLoading(
    facets.isLoading || insertFacets.isLoading || deleteFacets.isLoading //ローディング表示
  );

  const getFacetProfile = useGetFacetProfile();
  const masterFacets = useGetAllFacets()();

  const UsableFacetList = () => {
    const rows = masterFacets
      .map((facet) => {
        const implemented = getFacetProfile(facet).implemented;
        if (!implemented) return undefined;
        if (selectedFacets.includes(facet)) return undefined;
        return (
          <div key={facet}>
            <Button
              onClick={() => {
                setSelectedFacets(
                  selectedFacets.find((f) => f === facet)
                    ? selectedFacets
                    : [...selectedFacets, facet]
                );
              }}
              variant="shadow"
              startIcon={<AddIcon />}
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                my: 0.5,
                py: 1,
              }}
            >
              {getFacetProfile(facet).name}
            </Button>
          </div>
        );
      })
      .filter((facet) => facet);
    return (
      <List component="nav" aria-label="mailbox folders">
        {rows}
      </List>
    );
  };

  const save = async () => {
    assertNotNull(facets.data);
    const defaultFacets = facets.data.map((x) => x.facet);
    // 追加分を計算して実行
    const ifs = selectedFacets.filter(
      (x) => defaultFacets.find((y) => y === x) == null
    );
    if (ifs.length > 0) {
      await insertFacets.mutateAsync({ dashboardId, facets: ifs });
    }
    // 削除分を計算して実行
    const dfs = defaultFacets.filter(
      (x) => selectedFacets.find((y) => y === x) == null
    );
    if (dfs.length > 0) {
      await deleteFacets.mutateAsync({ dashboardId, facets: dfs });
    }
    navigate(dashboardId);
  };

  return (
    <>
      <DialogTitleWithClose onClose={() => navigate(dashboardId)}>
        {t('facets.edit.title')}
      </DialogTitleWithClose>
      <DialogContent>
        <Stack direction="row" sx={{ width: 1, height: 'calc(100vh - 300px)' }}>
          <HalfWidthBoxWithTitle
            title={t('facets.edit.available')}
            sx={{ backgroundColor: (t) => t.palette.secondary.main }}
          >
            <UsableFacetList />
          </HalfWidthBoxWithTitle>
          <Box sx={{ width: '4%', alignSelf: 'center' }}>
            <DoubleArrowIcon
              sx={{ width: '100%', color: (t) => t.palette.grey[400] }}
            />
          </Box>
          <HalfWidthBoxWithTitle
            title={t('facets.edit.selected')}
            sx={{ backgroundColor: '#ebebeb' }}
          >
            <List component="nav" aria-label="mailbox folders">
              {selectedFacets.map((facet) => (
                <Button
                  key={facet}
                  onClick={() => {
                    //このタイミングで、利用中のfacetがクリックされていたら、エラーではじく。
                    if (facetsInUse?.some((x) => x.facet === facet)) {
                      let viewNames = viewsFacets
                        ?.filter((view) =>
                          view?.facet?.find((f) => f.facet === facet)
                        )
                        .map((x) => x.name)
                        .join(', ');
                      if (viewNames) viewNames = '(' + viewNames + ')';
                      return notify(
                        tf(facet + '.name') +
                          t('notify.cancelDeleteFacet') +
                          viewNames,
                        'warning'
                      );
                    }
                    setSelectedFacets(
                      selectedFacets.filter((f) => f !== facet)
                    );
                  }}
                  variant="shadow"
                  color="secondary"
                  startIcon={<CloseIcon />}
                  sx={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    my: 0.5,
                    py: 1,
                  }}
                >
                  {getFacetProfile(facet).name}
                </Button>
              ))}
            </List>
          </HalfWidthBoxWithTitle>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="shadow"
          color="secondary"
          onClick={() => navigate(dashboardId)}
        >
          {t('dashboardedit.cancel')}
        </Button>
        <Button onClick={save} startIcon={<SaveOutlinedIcon />}>
          {t('dashboardedit.save')}
        </Button>
      </DialogActions>
    </>
  );
};

interface HalfWidthBoxWithTitleProps {
  children: React.ReactNode;
  title?: string;
  sx: ComponentProps<typeof Box>['sx'];
}
const HalfWidthBoxWithTitle = (props: HalfWidthBoxWithTitleProps) => {
  return (
    <Box
      sx={{
        width: '45%',
        mt: 1,
        mb: 1,
        mx: '1%',
        backgroundColor: '#ebebeb',
        display: 'flex',
        flexDirection: 'column',
        ...props.sx,
      }}
    >
      <Typography sx={{ ml: 3, fontSize: '1rem', mt: 2 }}>
        <b>{props.title}</b>
      </Typography>
      <Box
        sx={{
          margin: '5px',
          height: '100%',
          padding: '0px 20px 10px',
          overflow: 'auto',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
