import { DialogProps } from '@mui/material';
import { useCallback, useRef } from 'react';

export type DialogPropsWithoutOpen = Omit<DialogProps, 'open'>;

const useModal = <T,>() => {
  const resolve = useRef<(value: T) => void>();
  const close = useCallback((result: T) => {
    // 閉じるメッセージを送信
    window.dispatchEvent(new CustomEvent('closeModalDialog'));
    // プロミスを閉じる
    resolve.current?.(result);
  }, []);
  const open = useCallback(
    (dialogProps: DialogPropsWithoutOpen) =>
      new Promise<T>((r) => {
        resolve.current = r;
        // ダイアログ表示メッセージ送信
        window.dispatchEvent(
          new CustomEvent<DialogPropsWithoutOpen>('openModalDialog', {
            detail: { ...dialogProps },
          })
        );
      }),
    []
  );
  return { open, close };
};

export default useModal;
