import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Typography,
} from '@mui/material';
import { ComponentProps } from 'react';

// CheckboxProps と FormControlLabel のpropsを融合しつつ、独自のパラメータを持たせる
export type LabeledCheckboxProps = {
  label: string | number | React.ReactElement;
  labelVariant?: ComponentProps<typeof Typography>['variant'];
  nowrap?: boolean;
  labelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
} & CheckboxProps;

const LabeledCheckbox = (props: LabeledCheckboxProps) => {
  // propsを分解
  // eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
  let { label, labelProps, labelVariant, nowrap, ...checkBoxProps } = props;
  // eslint-disable-next-line prefer-const
  let { sx: checkBoxSx, ...checkBoxProps2 } = checkBoxProps;

  // smallの場合は文字サイズなども調整
  if (checkBoxProps.size === 'small') {
    labelVariant = labelVariant ?? checkBoxProps.size;
    checkBoxSx = Object.assign(
      {
        margin: '-5px -5px -5px 0px', //'4px 4px 4px 9px',
      },
      checkBoxSx
    );
  }

  return (
    <FormControlLabel
      control={<Checkbox sx={checkBoxSx} {...checkBoxProps2} />}
      label={
        labelVariant == null && nowrap !== true ? (
          label
        ) : (
          <Typography
            variant={labelVariant as any}
            sx={nowrap ? { whiteSpace: 'nowrap' } : undefined}
          >
            {label}
          </Typography>
        )
      }
      {...(props.labelProps ?? {})}
    />
  );
};

export default LabeledCheckbox;
