import { eventWithTime } from '@rrweb/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Replayer } from 'rrweb';
import { AppError } from 'Utils/error';
import supabase from 'Utils/supabase';

import useAutoScaleChild from './AutoScaleChild';
import usePageHistories from './Queries/PageHistories';

// const insertStyleRules = [
//   `
// .${'op-block'} {
//   background: rgb(204, 204, 204);
// }
// `,
//   `
// .op-hide-scrollbar::-webkit-scrollbar {
//   display: none;
// }
// `,
//   `
// .op-hide-scrollbar {
//   -ms-overflow-style: none;
//   scrollbar-width: none;
// }
//       `,
// ];

// rrwebの録画された複数のjsonを再生するロジックを提供
const useRrwebPlayer = (
  targetElement?: HTMLElement,
  customerId?: string,
  startWidhPlay?: boolean
) => {
  // 外部への公開用ステータスを保持
  const [isLoading, setIsLoading] = useState(true);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(startWidhPlay ?? false);
  const [isNoData, setIsNoData] = useState(true);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [endTimestamp, setEndTimestamp] = useState(0);
  const [playSpeed, setPlaySpeed] = useState(1);
  const [originalTimestamp, setOriginalTimestamp] = useState(0);

  // 内部処理用ステータスを保持
  const isPlayingRef = useRef(startWidhPlay ?? false);
  const playSpeedRef = useRef(1);
  const startTimestampRef = useRef(0);
  const endTimestampRef = useRef(0);

  // 取得済みのページデータを保持（内部データも一度ロードしたらキャッシュ）
  const pageDatas = useRef<RecordPageData[]>([]);

  // 再生中のページ
  const currentPage = useRef<RecordPageData>();
  // 再生中ページの開始タイムスタンプ
  const originalTimestampRef = useRef(0);
  const reserverTimestampRef = useRef(0); // 予約
  const contentLoadingCounter = useRef(0);
  // 再生用クラス
  const replayer = useRef<Replayer | undefined>();
  const targetElementRef = useRef<Element>();

  // ページ情報取得
  const pageHistories = usePageHistories(customerId ?? '', false, {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  // プレイヤー部分を targetElement に合わせて自動スケール
  const { setAutoScaleChild } = useAutoScaleChild();

  const keepTarget = useRef<Element>(); // target変更時のcleanup用

  const lastFetchPageData = useRef(0);

  // 再生処理
  const play = useCallback(() => {
    if (isPlayingRef.current) return;
    if (replayer.current == null) return;
    if (currentPage.current == null) return;
    const offset =
      originalTimestampRef.current - currentPage.current.startTimestamp;
    replayer.current.play(offset);
    isPlayingRef.current = true;
    setIsPlaying(true);
  }, []);

  // 停止処理
  const pause = useCallback(() => {
    if (isPlayingRef.current === false) return;
    if (replayer.current == null) return;
    replayer.current.pause();
    isPlayingRef.current = false;
    setIsPlaying(false);
  }, []);

  // 速度調整処理
  const setSpeed = useCallback((ratio: number) => {
    if (playSpeedRef.current !== ratio) {
      playSpeedRef.current = ratio;
      setPlaySpeed(ratio);
      if (replayer.current != null) {
        replayer.current.setConfig({ speed: ratio });
      }
    }
  }, []);

  // 次のページを取得
  const getNextPage = useCallback((pageData: RecordPageData) => {
    const nextIndex =
      pageDatas.current.findIndex((x) => x.name === pageData.name) + 1;
    return nextIndex >= pageDatas.current.length
      ? undefined
      : pageDatas.current[nextIndex];
  }, []);

  // データがまだなければページデータ更新
  const updatePageDataIfNotExists = useCallback(
    (name: string, pageData: eventWithTime[]) => {
      const index = pageDatas.current.findIndex((x) => x.name === name);
      if (index === -1 || pageDatas.current[index].data != null) return;
      const newDatas = pageDatas.current.slice();
      newDatas[index] = Object.assign(newDatas[index], { data: pageData });
      pageDatas.current = newDatas;
    },
    []
  );

  // シーク中のタイマーによる時間設定を回避するためフラグを記録。
  const nowSeeking = useRef(false);

  // 再生時間を変更
  const seekOriginalTimestamp = useCallback(
    async (originalTimestamp: number) => {
      try {
        nowSeeking.current = true;

        // 生成より早く時間指定された場合はそれを記録しておく。
        if (customerId == null || targetElement == null) {
          reserverTimestampRef.current = originalTimestamp;
          return;
        }

        //範囲内に収める
        originalTimestamp = Math.min(
          endTimestampRef.current,
          originalTimestamp
        );
        originalTimestamp = Math.max(
          startTimestampRef.current,
          originalTimestamp
        );

        originalTimestampRef.current = originalTimestamp;
        setOriginalTimestamp(originalTimestamp);

        if (pageDatas.current.length === 0) return;

        // 指定されたタイムスタンプがどのページに該当するかを計算し、取得していなければ取得
        const page = pageDatas.current
          .slice()
          .reverse()
          .find((x) => originalTimestamp >= x.startTimestamp);
        if (page == null) {
          console.info('Out of the unexpected range');
          return;
        }

        // ロードされていなければロードする
        let pageData = page.data;
        if (pageData == null) {
          // ローディング表示
          contentLoadingCounter.current++;
          const counter = contentLoadingCounter.current;
          setIsContentLoading(true);
          // ページ情報をロード
          pageData = await getRecordPageData(customerId, page.name);
          // まだロードされていなければ代入する。(ロードが非同期のため)
          updatePageDataIfNotExists(page.name, pageData);
          // 他のコンテンツ読み込みが始まっていたらここで終了
          if (contentLoadingCounter.current !== counter) return;
          setIsContentLoading(false);
        }

        // 次の分をロードしておく（結果は待たない）
        const next = getNextPage(page);
        if (next != null && next.data == null) {
          (async () => {
            const pageData2 = await getRecordPageData(customerId, next.name);
            // まだロードされていなければ代入する。(ロードが非同期のため)
            const next2 = getNextPage(page);
            updatePageDataIfNotExists(next2?.name ?? 'dummy', pageData2);
          })();
        }

        //以前とページが違う場合はプレイヤー再生成
        if (page.name !== currentPage.current?.name) {
          if (replayer.current != null) {
            replayer.current.pause();
            replayer.current = undefined;
          }
          if (keepTarget.current != null) {
            keepTarget.current.innerHTML = '';
          }
          keepTarget.current = targetElement;

          // 新しい子要素を自動スケール機能に渡す
          const el = document.createElement('div');
          targetElement.innerHTML = '';
          targetElement.appendChild(el);
          setAutoScaleChild(el);

          currentPage.current = page;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          replayer.current = new Replayer(pageData!, {
            root: el,
            speed: playSpeedRef.current,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            UNSAFE_replayCanvas: true,
          });
        }

        // 再生位置の設定
        if (replayer.current != null && currentPage.current != null) {
          const offset =
            originalTimestampRef.current - currentPage.current.startTimestamp;
          if (isPlayingRef.current) {
            replayer.current.play(offset);
          } else {
            replayer.current.pause(offset);
          }
        }
      } finally {
        nowSeeking.current = false;
      }
    },
    [
      customerId,
      targetElement,
      getNextPage,
      updatePageDataIfNotExists,
      setAutoScaleChild,
    ]
  );

  const interval = 200; //0.2s
  const reloadPagesInterval = 10000; //10s
  useEffect(() => {
    // 一定間隔で、現在の再生位置を取得して計算
    // 再生範囲に無い場合はカーソルだけ進めて読み込み処理を動かす
    let unmounted = false;
    const intervalId = setInterval(() => {
      if (unmounted) return;
      if (replayer.current == null) return;
      if (isPlayingRef.current === false) return;
      if (nowSeeking.current) return;

      // 一定時間経過していたらリスト再読み込み
      if (
        lastFetchPageData.current + reloadPagesInterval <
          new Date().getTime() &&
        customerId != null
      ) {
        (async () => {
          lastFetchPageData.current = Number.MAX_SAFE_INTEGER; // 取得終了後から一定時間後になるように、一旦大きな値を代入
          let pageData: RecordPageData[];
          try {
            pageData = await getRecordPages(customerId);
            if (!unmounted) {
              pageDatas.current = pageData;
              const start = Math.min(...pageData.map((x) => x.startTimestamp));
              const end = Math.max(...pageData.map((x) => x.endTimestamp));
              startTimestampRef.current = start;
              endTimestampRef.current = end;
              setStartTimestamp(startTimestampRef.current);
              setEndTimestamp(endTimestampRef.current);
            }
          } finally {
            if (!unmounted) {
              lastFetchPageData.current = new Date().getTime(); // 取得終了後に時間を再設定
            }
          }
        })();
      }

      const currentPageCurrent = currentPage.current;
      if (currentPageCurrent != null) {
        const current =
          replayer.current.getCurrentTime() + currentPageCurrent.startTimestamp;
        if (current >= currentPageCurrent.endTimestamp) {
          // 現在のページの末尾を超えていたら時刻は手動で調整
          originalTimestampRef.current += interval * playSpeedRef.current;
          setOriginalTimestamp(originalTimestampRef.current); // ← TODO:厳密には時間狂いそう
          // 次のページに踏み込んでいたらseek
          const next = getNextPage(currentPageCurrent);
          if (next != null) {
            if (originalTimestampRef.current >= next.startTimestamp) {
              seekOriginalTimestamp(originalTimestampRef.current);
            }
          } else {
            // 全体の最大を超えていたらそこを最大とする
            originalTimestampRef.current = currentPageCurrent.endTimestamp;
            setOriginalTimestamp(originalTimestampRef.current);
          }
        } else {
          originalTimestampRef.current = current;
          setOriginalTimestamp(current);
        }
      }
    }, interval); // 一定間隔で実行
    return () => {
      unmounted = true;
      clearInterval(intervalId);
    };
  }, [customerId, seekOriginalTimestamp, getNextPage]);

  // クリーンアップ
  const cleanup = useCallback(() => {
    if (replayer.current != null) {
      replayer.current.pause();
      replayer.current = undefined;
    }
    if (keepTarget.current != null) {
      keepTarget.current.innerHTML = '';
    }
    keepTarget.current = targetElement;

    currentPage.current = undefined;
    isPlayingRef.current = startWidhPlay ?? false;
    setIsPlaying(startWidhPlay ?? false);
    pageDatas.current = [];
    currentPage.current = undefined;
    startTimestampRef.current = 0;
    setStartTimestamp(0);
    endTimestampRef.current = 0;
    setEndTimestamp(0);
    originalTimestampRef.current = 0;
    setOriginalTimestamp(0);
    setIsLoading(true);
    setIsContentLoading(false);
    setIsNoData(true);
  }, [targetElement, startWidhPlay]);

  useEffect(() => {
    // クリーンアップ
    cleanup();
    targetElementRef.current = targetElement;

    let unmounted = false;
    if (customerId != null && targetElement != null) {
      (async () => {
        // ページ情報のリストを取得
        let pageData: RecordPageData[] = [];
        try {
          pageData = await getRecordPages(customerId);
        } catch (e: AppError | any) {
          if (!unmounted) {
            startTimestampRef.current = 0;
            endTimestampRef.current = 0;
            setStartTimestamp(startTimestampRef.current);
            setEndTimestamp(endTimestampRef.current);
          }
          throw e;
        } finally {
          if (!unmounted) {
            setIsLoading(false);
          }
        }

        if (pageData.length === 0) return;
        if (!unmounted) {
          lastFetchPageData.current = new Date().getTime();
          pageDatas.current = pageData;
          // 先頭と末尾の時間を取得
          const start = Math.min(...pageData.map((x) => x.startTimestamp));
          const end = Math.max(...pageData.map((x) => x.endTimestamp));
          startTimestampRef.current = start;
          endTimestampRef.current = end;
          setStartTimestamp(startTimestampRef.current);
          setEndTimestamp(endTimestampRef.current);
          setIsNoData(false);

          // 予約された再生時間を反映
          let time = reserverTimestampRef.current;
          if (time < start) time = start;
          if (time > end) time = end;
          reserverTimestampRef.current = 0;

          // 最初のデータ取得を非同期で実行
          seekOriginalTimestamp(time);
        }
      })();
    } else {
      setIsLoading(false); // 読むデータがない場合はloadingにはしない
    }
    return () => {
      unmounted = true;
    };
  }, [customerId, targetElement, seekOriginalTimestamp, cleanup]);

  const isAllLoading = isLoading || pageHistories.isLoading;
  return {
    isLoading: isAllLoading,
    isContentLoading,
    isPlaying,
    isNoData,
    canPlay:
      isAllLoading === false && isNoData === false && isPlaying === false,
    canSeek: isAllLoading === false && isNoData === false,
    startTimestamp,
    endTimestamp,
    originalTimestamp,
    speed: playSpeed,
    pages: pageHistories.data,
    play,
    pause,
    seek: seekOriginalTimestamp,
    setSpeed,
  };
};

export default useRrwebPlayer;

type RecordPageData = {
  name: string;
  startTimestamp: number;
  endTimestamp: number;
  data?: eventWithTime[];
};

// ファイル名を一覧から取得
const getRecordPages = async (customerId: string) => {
  const result = await supabase.storage.from('recordings').list(customerId);
  if (result.error)
    throw new AppError(
      result.error,
      'Sys',
      'SessionRecording',
      'ReadMany',
      '01'
    );
  if ((result.data ?? []).length === 0)
    throw new AppError(
      'Not found any recording files',
      'Sys',
      'SessionRecording',
      'ReadMany',
      '02'
    );

  // 型変換して並び変えて返却
  return (result.data ?? [])
    .filter((x) => x.name.match(/^\d+-\d+\.json$/))
    .map<RecordPageData>((x) => ({
      name: x.name,
      startTimestamp: Number(x.name.split('-')[0]),
      endTimestamp: Number(x.name.split('-')[1].split('.')[0]),
    }))
    .sort((x, y) => x.startTimestamp - y.startTimestamp);
};

// ページデータを取得
const getRecordPageData = async (customerId: string, name: string) => {
  const fileResult = await supabase.storage
    .from('recordings')
    .download(`${customerId}/${name}`);
  if (fileResult.error) throw fileResult.error;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return JSON.parse(await fileResult.data!.text()) as eventWithTime[];
};
