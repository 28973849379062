import { Button, Stack, Typography } from '@mui/material';

const Debug = () => (
  <>
    <Stack spacing={2} m={2}>
      <Typography variant="h1">Display Debug</Typography>

      {/* ボタン */}

      <Typography variant="h2">Color and Button Variants</Typography>
      {[
        undefined,
        'primary',
        'secondary',
        'accent',
        'success',
        'info',
        'warning',
        'error',
      ].map((color) => (
        <>
          <Stack spacing={2} direction="row">
            <Typography variant="h3" sx={{ width: 100 }}>
              {color ?? 'default'}
            </Typography>
            {['text', 'outlined', 'contained', 'shadow'].map((variant) => (
              <Button
                key={variant}
                variant={variant as any}
                color={color as any}
                sx={{ width: 150 }}
              >
                {variant}
              </Button>
            ))}
          </Stack>
        </>
      ))}

      {/* タイポグラフィ */}
      <Typography variant="h2">Typography</Typography>

      <Typography variant="h1">h1. Heading</Typography>
      <Typography variant="h2">h2. Heading</Typography>
      <Typography variant="h3">h3. Heading</Typography>
      <Typography variant="h4">h4. Heading</Typography>
      <Typography variant="h5">h5. Heading</Typography>
      <Typography variant="h6">h6. Heading</Typography>
      <Typography variant="subtitle1">
        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Quos blanditiis tenetur
      </Typography>
      <Typography variant="subtitle2">
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Quos blanditiis tenetur
      </Typography>
      <Typography variant="body1">
        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur unde suscipit, quam beatae rerum inventore
        consectetur, neque doloribus, cupiditate numquam dignissimos laborum
        fugiat deleniti? Eum quasi quidem quibusdam.
      </Typography>
      <Typography variant="body2">
        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur unde suscipit, quam beatae rerum inventore
        consectetur, neque doloribus, cupiditate numquam dignissimos laborum
        fugiat deleniti? Eum quasi quidem quibusdam.{' '}
      </Typography>
      <Typography variant="button">button text</Typography>
      <Typography variant="caption">caption text</Typography>
      <Typography variant="overline">overline text</Typography>
      <Typography variant="small">small text</Typography>
    </Stack>
  </>
);
export default Debug;
