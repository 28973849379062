import { Stack, Box, Tab } from '@mui/material';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import * as React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>{children}</Box>
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'aria-controls': `tabpanel-${index}`,
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type tabDatas = {
  label: string;
  content: JSX.Element;
}[];

const TabMenu = (
  props: {
    tabDatas: tabDatas;
  } & TabsProps
) => {
  const { tabDatas, ...tabProps } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack sx={{ width: '100%', height: '100%', position: 'absolute' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          {...tabProps}
        >
          {tabDatas.map((data, index) => (
            <Tab label={data.label} {...a11yProps(index)} key={data.label} />
          ))}
        </Tabs>
      </Box>
      {tabDatas.map((data, index) => (
        <TabPanel value={value} index={index} key={data.label}>
          {data.content}
        </TabPanel>
      ))}
    </Stack>
  );
};

export default TabMenu;
