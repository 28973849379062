import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useChannelContext } from 'Components/Hooks/Channel';
import useOperator from 'Components/Hooks/Operator';
import {
  useChangeStatus,
  useStatusContext,
} from 'Components/Hooks/OperatorStatus';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';
import colors from 'Utils/colors';
import { AppError } from 'Utils/error';
import { generateInitial } from 'Utils/string';
import supabase from 'Utils/supabase';

const StyledStatusMenuIcon = styled(ListItemIcon)(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& .MuiSvgIcon-root': {
    padding: '4px',
    borderRadius: '40px',
    color: theme.palette.common.white,
  },
}));

const statusColors = {
  online: 'success.light',
  incall: 'error.light',
  busy: '#FFD700',
  offline: '#A52A2A',
};

const UserMenu = () => {
  const t = useTranslation('Auth');
  const operator = useOperator();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const status = useStatusContext();
  const channels = useChannelContext();
  const changeStatus = useChangeStatus(channels);

  if ((operator?.id ?? '') === '') return <></>; // ゲストはアイコンを表示しない

  return (
    <>
      <Tooltip
        title={t('menu.userAccount').toString()}
        placement="bottom"
        arrow
      >
        <IconButton onClick={(event) => setAnchor(event.currentTarget)}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            sx={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '& .MuiBadge-badge': {
                backgroundColor: statusColors[status],
                color: statusColors[status],
                boxShadow: `0 0 0 2px #fff`,
                width: 12,
                height: 12,
                borderRadius: 6,
              },
            }}
          >
            {operator ? (
              <Avatar
                sx={{
                  bgcolor: colors(operator.id),
                  width: 32,
                  height: 32,
                  fontSize: '1rem',
                }}
              >
                {generateInitial(operator.name)}
              </Avatar>
            ) : (
              <AccountCircleOutlinedIcon />
            )}
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        open={anchor != null}
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
      >
        <MenuItem disabled>
          <ListItemIcon>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{operator?.name}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchor(null);
            changeStatus('online');
          }}
        >
          <StyledStatusMenuIcon>
            <HeadsetMicIcon sx={{ backgroundColor: 'success.light' }} />
          </StyledStatusMenuIcon>
          <ListItemText>{t('status.online')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchor(null);
            changeStatus('busy');
          }}
        >
          <StyledStatusMenuIcon>
            <StopCircleOutlinedIcon
              sx={{
                // backgroundColor: 'warning.light',
                backgroundColor: statusColors['busy'],
              }}
            />
          </StyledStatusMenuIcon>
          <ListItemText>{t('status.busy')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchor(null);
            changeStatus('offline');
          }}
        >
          <StyledStatusMenuIcon>
            <LocalCafeOutlinedIcon
              sx={{
                // backgroundColor: 'accent.light',
                backgroundColor: statusColors['offline'],
              }}
            />
          </StyledStatusMenuIcon>
          <ListItemText>{t('status.offline')}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={async () => {
            setAnchor(null);
            const { error } = await supabase.auth.signOut();
            if (error)
              throw new AppError(error, 'Sys', 'ProfileLogout', 'Logout', '01');
            window.location.href = '/login'; // リフレッシュも兼ねブラウザ遷移
          }}
        >
          <ListItemIcon>
            <MeetingRoomIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('usermenu.logout')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
