import { JanusJS } from 'janus-gateway';

import BaseClient, { TrackArray } from './baseClient';
import { ClientParams } from './baseClient';
import { Publisher } from './subClient';

export default class PubClient extends BaseClient {
  public onPublishersUpdate?: (publishers: Array<Publisher>) => void;
  public onPublishersLeaving?: (id: string) => void;
  public publisherId?: string;

  private onUnpublished?: () => void;

  constructor(props: ClientParams) {
    super(props);
  }

  async startPublish(props: {
    id?: string;
    display?: string;
    audio: boolean | { deviceId: string };
    video: boolean | { deviceId: string };
  }) {
    console.log('startPublish', props);
    const { id, display, audio, video } = props;
    const jsep = await this.createOffer({ audio, video });
    this.pluginHandler?.send({
      message: {
        request: 'joinandconfigure',
        ptype: 'publisher',
        room: this.roomName,
        token: this.aclToken,
        id,
        display,
      },
      jsep,
    });
  }

  sendData(data: string) {
    return new Promise<void>((resolve, reject) => {
      this.pluginHandler?.data({
        text: data,
        error: function (e: any) {
          reject(e);
        },
        success: function () {
          resolve();
        },
      });
    });
  }

  async pausePublish() {
    console.log('pausePublish');
    this.pluginHandler?.send({
      message: {
        request: 'pause',
      },
    });
  }

  async resumePublish() {
    console.log('resumePublish');
    this.pluginHandler?.send({
      message: {
        request: 'start',
      },
    });
  }

  async endPublish() {
    console.log('endPublish');
    return new Promise<void>((resolve, reject) => {
      this.onUnpublished = () => resolve();
      this.pluginHandler?.send({
        error: (e: string) => reject(e),
        message: {
          request: 'unpublish',
        },
      });
    });
  }

  muteAudio() {
    this.pluginHandler?.muteAudio('0');
  }

  muteVideo() {
    this.pluginHandler?.muteVideo('1');
  }

  unmuteAudio() {
    this.pluginHandler?.unmuteAudio('0');
  }

  unmuteVideo() {
    this.pluginHandler?.unmuteVideo('1');
  }

  async replaceTracks(tracks: TrackArray) {
    const replaceTracks = async (tracks: TrackArray) =>
      new Promise<void>((resolve, reject) => {
        this.pluginHandler?.replaceTracks({
          tracks,
          success: () => resolve(),
          error: (e) => reject(e),
        });
      });
    await replaceTracks(tracks);
  }

  protected onmessage(msg: JanusJS.Message, jsep?: JanusJS.JSEP) {
    super.onmessage(msg, jsep);
    if (msg['videoroom'] === 'joined') {
      this.publisherId = msg['id'];
    }
    if (msg['publishers']) {
      this.onPublishersUpdate?.(msg['publishers']);
    }
    if (msg['unpublished'] || msg['leaving']) {
      if (msg['unpublished'] === 'ok') {
        this.pluginHandler?.hangup();
        this.onUnpublished?.();
      } else {
        this.onPublishersLeaving?.(msg['unpublished'] ?? msg['leaving']);
      }
    }
    if (jsep) {
      this.pluginHandler?.handleRemoteJsep({ jsep });
    }
  }

  private async createOffer(props: {
    audio: boolean | { deviceId: string };
    video: boolean | { deviceId: string };
  }) {
    const { audio, video } = props;
    return new Promise<JanusJS.JSEP>((resolve, reject) => {
      this.pluginHandler?.createOffer({
        media: {
          audio,
          video,
          audioSend: true,
          audioRecv: false,
          videoSend: true,
          videoRecv: false,
          failIfNoAudio: true,
          failIfNoVideo: true,
          data: true,
        },
        success: (jsep: JanusJS.JSEP) => resolve(jsep),
        error: (err) => reject(err),
      });
    });
  }
}
