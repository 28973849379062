import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Panel from 'Components/Atoms/Panel';
import { useInterestedPage } from 'Components/Hooks/SummaryQueries/InterestedPage';
import useTranslation from 'Components/Hooks/Translate';

const InterestedPage = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useInterestedPage();

  return (
    <Panel
      icon={<FavoriteBorderOutlinedIcon />}
      title={t('InterestedPage.title')}
      content={data?.toString() ?? ''}
      isLoading={isLoading}
    />
  );
};

export default InterestedPage;
