import useCustomer from '../Customer';
import useTrackHistories from '../Queries/TrackHistories';

export const useCTANum = () => {
  const customerId = useCustomer()?.userId;
  const trackHistories = useTrackHistories(customerId ?? '', {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  return {
    ...trackHistories,
    data: trackHistories.data?.filter(
      (h) => h.event === 'click' && h.element.cta
    )?.length,
  };
};
