import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { AppError } from 'Utils/error';
import supabase, { View } from 'Utils/supabase';
import { definitions } from 'Utils/types';

import { useUpsert } from './Mutation';

const key = 'views';
export type ViewFacets = definitions['views'] & { facet: any[] };
const useViews = (
  dashboardId?: string,
  options?: Omit<UseQueryOptions<View[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery(
    [key, dashboardId ?? ''],
    async () => {
      const { data, error } = await supabase
        .from<View>('views')
        .select()
        .match({ dashboardId });
      if (!data || error) throw error;
      return data;
    },
    options
  );
export default useViews;

export const useViewsFacets = (
  dashboardId?: string,
  options?: Omit<UseQueryOptions<ViewFacets[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery(
    ['views_facets', dashboardId ?? ''],
    async () => {
      const { data, error } = await supabase
        .from<ViewFacets>('views')
        .select('*,facet:view_facet_values(facet)')
        .match({ dashboardId });
      if (!data || error) throw error;
      return data;
    },
    options
  );
export const useFacetsInUse = (
  dashboardId?: string,
  options?: Omit<UseQueryOptions<any[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery(
    ['facet_list', dashboardId ?? ''],
    async () => {
      const { data, error } = await supabase.rpc<string>('get_used_facets', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dashboard_id: dashboardId,
      });
      if (!data || error) throw error;
      return data;
    },
    options
  );

export const useUpsertView = () => useUpsert<View>('views', key);
export const useDeleteView = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const { data, error } = await supabase
        .from<View>('views')
        .delete()
        .eq('id', id)
        .maybeSingle();

      if (error != null)
        throw new AppError(error, 'Sys', 'AnalyticsView', 'DeleteOne', '01');
      if (data == null)
        throw new AppError(
          `Not found view id: ${id}`,
          'Sys',
          'AnalyticsView',
          'DeleteOne',
          '02'
        );
      return data;
    },
    {
      onSuccess: (data) =>
        queryClient.invalidateQueries([key, data.dashboardId]),
    }
  );
};
