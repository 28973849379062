/* eslint-disable @typescript-eslint/naming-convention */
import { Box, styled } from '@mui/material';

/** クライアント領域を変更しない浮いたスクロールバースタイル */
const FloatScrollBox = styled(Box)({
  overflow: 'overlay',
  '&::-webkit-scrollbar': { width: '7px' },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
  },
  overflowX: 'hidden',
});
export default FloatScrollBox;
