/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ContactReason,
  Dashboard,
  Facet,
  Gadget,
  UserInfo,
  View,
} from '../../common/common-types';

import ContextDataController from './contextDataController';
import {
  dataDashboard,
  dataView,
  dataFacet,
  dataGadget,
  dataContactReason,
} from './testData';

export default class ContextDataAccessor extends ContextDataController {
  private userInfo?: UserInfo;

  constructor(userInfo?: UserInfo) {
    super();
    this.userInfo = userInfo;
  }

  /**
   * Start Collecting Context Data
   *
   * @param token - The session token to connect CDP
   */
  start(dataServer: string, token: string) {
    return super.initialize(dataServer, token);
  }

  setUserInfo(data: UserInfo) {
    this.userInfo = data;
  }

  getUserInfo() {
    return this.userInfo;
  }

  /**
   * Register Multiple Call Reasons
   * @param contactReason
   */
  // 複数登録はBI表示側の処理が複雑になるので、現段階では対応しない
  // registerContactReasons(
  //   contactReason: { contactReasonCode: string; memo?: string }[]
  // ) {
  //   contactReasons.forEach((reason) =>
  //     this.registerContactReason(reason.contactReasonCode, reason.memo)
  //   );
  // }

  /**
   * Register A Single Call Reason
   * @param contactReason
   * @param memo
   * @returns Promise
   */
  registerContactReason(contactReason: string, memo?: string) {
    if (!this.userInfo) {
      this.userInfo = {};
    }

    const options = this.userInfo.options || {};
    options.contactReason = contactReason;
    options.memo = memo;

    this.userInfo.options = options;

    return this.registerUserInfo(this.userInfo);
  }

  /**
   * Get All My Dashboards
   * @returns
   */
  getOperatorDashboards(operatorIds: string[]): Promise<Dashboard[]> {
    // 本来はoperaterIdsでresを絞り込む
    const res = dataDashboard;
    return Promise.resolve(res);
  }

  /**
   * Get All My Views
   * @returns
   */
  getOperatorViews(operatorIds: string[]): Promise<View[]> {
    //本来はoperatorIdsでresを絞り込む
    const res = dataView;
    return Promise.resolve(res);
  }

  /**
   * Get Gadgets
   * @returns
   */
  getDashboardGadgets(dashboardId: string): Promise<Gadget[]> {
    const dashboard = dataDashboard.find((e) => {
      return e.id === dashboardId;
    });
    const gadgets = dashboard ? dashboard.gadgets : [];
    //res = ['0002', '0004', '0005', '0008'],
    const res = dataGadget.filter((v) => {
      for (const g of gadgets) {
        if (g.id === v.id) return v;
      }
    });
    //[{id;0002 ...},{id:0004,...},{id:0005}]
    return Promise.resolve(res);
  }

  getDashboardFacets(dashboardId: string): Promise<Facet[]> {
    const dashboard = dataDashboard.find((d) => {
      return d.id === dashboardId;
    });
    const facets = dashboard ? dashboard.facets : [];
    const res = dataFacet.filter((v) => {
      for (const f of facets) {
        if (f.id === v.id) return v;
      }
    });
    return Promise.resolve(res);
  }

  getDashboard(id: string): Promise<Dashboard | undefined> {
    const res = dataDashboard.find((d) => {
      return d.id === id;
    });
    return Promise.resolve(res);
  }

  /**
   * Get All Gadgets
   * @returns
   */
  getAllGadgets(category?: 'chart' | 'table' | 'kpi'): Promise<Gadget[]> {
    if (category) {
      return Promise.resolve(dataGadget.filter((g) => g.category === category));
    } else {
      return Promise.resolve(dataGadget);
    }
  }
  /**
   * Get All Facets
   * @returns
   */
  getAllFacets(): Promise<Facet[]> {
    return Promise.resolve(dataFacet);
  }

  /**
   * Get All Gadgets
   * @returns
   */
  getContactReasons(): Promise<ContactReason[]> {
    return Promise.resolve(dataContactReason);
  }
}
