import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import useAgent from 'Components/Hooks/Agent';
import useConfirm from 'Components/Hooks/Confirm';
import useTranslation from 'Components/Hooks/Translate';

// セッションがアクティブの際に画面遷移してよいか尋ねるモーダルを出すためのHooks
// TODO: 本当は画面遷移にフックさせたいが、ReactRouter-v6だと方法がないのでとりあえずこの実装

export const useConfirmDisconnectSession = () => {
  const agent = useAgent();
  const confirm = useConfirm();
  const t = useTranslation('Global');

  return async () => {
    if (agent?.isSessionActive()) {
      if (
        await confirm({
          message: t('confirm.disconnectWithMoveMessage'),
          approveMessage: t('confirm.disconnectWithMoveApprove'),
          approveColor: 'warning',
          approveIcon: <LogoutOutlinedIcon />,
          canCancel: true,
        })
      ) {
        // 現在のセッションを終了する。
        agent?.requestEndSession();
        agent?.resetSdk();

        return true;
      } else {
        return false;
      }
    }

    return true;
  };
};
