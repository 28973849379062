import KeyboardIcon from '@mui/icons-material/Keyboard';
import MouseIcon from '@mui/icons-material/Mouse';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SearchIcon from '@mui/icons-material/Search';
import WebIcon from '@mui/icons-material/Web';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Stack,
  Typography,
} from '@mui/material';

import { TrackHistory } from 'Components/Hooks/Queries/TrackHistories';

type PartiallyPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
// ページ訪問時アイテム用にelementをオプションにしたTrackHistory型を定義
export type ActionLogTrackData = PartiallyPartial<TrackHistory, 'element'>;

interface ActionLogTrackProps {
  data: ActionLogTrackData;
  onClick?: () => void;
}

/** アクションログの単一のトラック要素を表現する */
const ActionLogTrack = (props: ActionLogTrackProps) => {
  const icon = (ev: string) => {
    switch (ev) {
      case 'landing':
        return <WebIcon color="primary" />;
      case 'click':
        return <MouseIcon color="primary" />;
      case 'input':
        return <KeyboardIcon color="primary" />;
      case 'search':
        return <SearchIcon color="primary" />;
      case 'video':
        return <PlayCircleFilledIcon color="primary" />;
      default:
        return;
    }
  };

  const date = new Date(props.data.originalTimestamp);
  const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  // 表示名を取得
  const el = props.data.element;
  const label =
    el == null
      ? ''
      : [
          // 優先順
          el.cta,
          props.data.event === 'search' ? el.type : '',
          el.name,
          el.id,
          el.placeholder,
          el.className,
          el.tagName,
          el.type,
        ].find((x) => x !== '') ?? '';
  const text = el?.text ?? '';

  const tooltip = el != null && (label !== '' || text !== '') && (
    <Stack>
      {label !== '' && (
        <Typography align="center" variant="caption">
          {label}
        </Typography>
      )}
      {text !== '' && (
        <Typography align="center" variant="caption">
          {text}
        </Typography>
      )}
    </Stack>
  );

  return (
    <>
      <Divider component="li" />
      <Tooltip
        title={tooltip}
        placement="right"
        arrow
        open={tooltip === false ? false : undefined}
      >
        <ListItemButton
          onClick={props.onClick}
          sx={{ pl: 4, bgcolor: '#fff' }}
          dense
        >
          <ListItemIcon>{icon(props.data.event)}</ListItemIcon>
          <ListItemText primary={props.data.event} secondary={time} />
          <ListItemText
            secondary={
              <Typography align="center" noWrap>
                {label}
              </Typography>
            }
            sx={{ width: 80 }}
          />
        </ListItemButton>
      </Tooltip>
    </>
  );
};

export default ActionLogTrack;
