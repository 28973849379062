/* eslint-disable @typescript-eslint/naming-convention */

// エラー種別
export const AppErrorTypes = {
  Sys: 'SYS',
  Biz: 'BIZ',
  Sec: 'SEC',
  Unk: 'UNK',
};

// カテゴリ
export const AppErrorCategories = {
  SettingsUser: '000',
  SettingsJob: '001',
  SettingsRole: '002',
  SettingsContactReason: '003',
  SettingsContactReasonCategory: '004',
  SettingsPasswordUpdate: '005',
  ProfileUser: '100',
  ProfilePermission: '101',
  ProfileLogin: '102',
  ProfileLogout: '103',
  ProfileStatusUpdate: '104',
  SessionOperatorCall: '200',
  SessionStart: '201',
  SessionEnd: '202',
  SessionCobrowse: '203',
  SessionConsumerSummary: '204',
  SessionConsumerPageHistory: '205',
  SessionConsumerTrackHistory: '206',
  SessionConsumerRecording: '207',
  SessionAudioCall: '208',
  SessionVideoCall: '209',
  SessionRecording: '210',
  SessionContactReason: '211',
  SessionDocumentPush: '212',
  AnalyticsDashboard: '300',
  AnalyticsDashboardLayout: '301',
  AnalyticsDashboardGadget: '302',
  AnalyticsDashboardFacet: '303',
  AnalyticsGadget: '304',
  AnalyticsFacet: '305',
  AnalyticsView: '306',
  AnalyticsViewFacetValue: '307',
  SessionHistory: '400',
};

// 処理
export const AppErrorProcesses = {
  ReadOne: '01',
  ReadMany: '02',
  CreateOne: '03',
  CreateMany: '04',
  UpdateOne: '05',
  UpdateMany: '06',
  DeleteOne: '07',
  DeleteMany: '08',
  Login: '09',
  Logout: '10',
  GenerateAccessToken: '11',
  NotifyIncommingCall: '12',
  Start: '13',
  Pend: '14',
  Stop: '15',
  Device: '16',
  SendFile: '17',
};

export class AppError extends Error {
  constructor(
    private _error: any,
    private _type: keyof typeof AppErrorTypes,
    private _category: keyof typeof AppErrorCategories,
    private _process: keyof typeof AppErrorProcesses,
    private _kinds: string,
    private _branch?: string
  ) {
    const message = `${AppErrorTypes[_type]}-${AppErrorCategories[_category]}-${
      AppErrorProcesses[_process]
    }-${_kinds}${_branch != null ? '-' + _branch : ''}`;
    super(message);
    console.error(message);
  }

  get type() {
    return this._type;
  }
  get category() {
    return this._category;
  }
  get process() {
    return this._process;
  }
  get kinds() {
    return this._kinds;
  }
  get branch() {
    return this._branch;
  }
  get error() {
    return this._error;
  }
}
