import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useSearchNum } from 'Components/Hooks/SummaryQueries/SearchNum';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const SearchNum = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useSearchNum();

  return (
    <Panel
      icon={<SearchOutlinedIcon />}
      title={t('SearchNum.title')}
      content={(data?.toString() ?? '') + t('SearchNum.unit')}
      isLoading={isLoading}
    />
  );
};

export default SearchNum;
