import { JanusJS } from 'janus-gateway';

import BaseClient from './baseClient';
import { ClientParams } from './baseClient';

export interface Publisher {
  id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  audio_codec: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  video_codec: string;
  streams: Array<{
    type: 'audio' | 'video';
    mindex: number;
    mid: string;
    codec: string;
    fec: boolean;
  }>;
}

export type Track = {
  mindex: number;
  mid: string;
  type: 'audio' | 'video' | 'data';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  feed_id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  feed_mid: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  feed_display: string;
  send: boolean;
  ready: boolean;
  active: boolean;
};

export default class SubClient extends BaseClient {
  public onUpdateTracks?: (tracks: Array<Track>) => void;

  private isSubscribing = false;

  constructor(props: ClientParams) {
    super(props);
  }

  async startSubscribe(publishers: Array<Publisher>) {
    const streams: Array<{ feed: string }> = [];
    for (const feed of publishers) {
      streams.push({ feed: feed.id });
    }
    if (streams.length > 0) {
      return new Promise<void>((resolve, reject) => {
        if (this.isSubscribing) {
          this.pluginHandler?.send({
            success: () => resolve(),
            error: (e: string) => reject(e),
            message: {
              request: 'subscribe',
              streams,
            },
          });
        } else {
          this.isSubscribing = true;
          this.pluginHandler?.send({
            success: () => resolve(),
            error: (e: string) => reject(e),
            message: {
              request: 'join',
              ptype: 'subscriber',
              room: this.roomName,
              token: this.aclToken,
              streams,
            },
          });
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  async endSubscribe(ids: Array<string>) {
    return new Promise<void>((resolve, reject) => {
      const streams: Array<{ feed: string }> = [];
      ids.forEach((id) => {
        streams.push({
          feed: id,
        });
      });
      if (streams.length > 0) {
        this.pluginHandler?.send({
          message: {
            request: 'unsubscribe',
            streams,
          },
          success: () => resolve(),
          error: (e: string) => reject(e),
        });
      }
    });
  }

  protected onmessage(msg: JanusJS.Message, jsep?: JanusJS.JSEP) {
    super.onmessage(msg, jsep);
    if (msg['videoroom'] === 'attached' || msg['videoroom'] === 'updated') {
      const streams = msg['streams'] as Array<Track>;
      if (streams)
        this.onUpdateTracks?.(streams.filter((track) => track.active));
    }
    if (jsep) {
      this.createAnswer(jsep)
        .catch()
        .then((answer) => {
          const body = { request: 'start', room: this.roomName };
          this.pluginHandler?.send({ message: body, jsep: answer });
        });
    }
  }

  private async createAnswer(jsep: JanusJS.JSEP) {
    return new Promise<JanusJS.JSEP>((resolve, reject) => {
      this.pluginHandler?.createAnswer({
        jsep,
        tracks: [{ type: 'data' }],
        success: (answer: JanusJS.JSEP) => resolve(answer),
        error: (error: Error) => reject(error),
      });
    });
  }
}
