import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import { IconButton, styled, Button, ListSubheader } from '@mui/material';
import { DrawConfig } from '@optipass/sdk/src/agent/types';
import FloatScrollBox from 'Components/Atoms/FloatScrollBox';
import useAgent from 'Components/Hooks/Agent';
import useTranslation from 'Components/Hooks/Translate';
import { CirclePicker, ColorResult } from 'react-color';
import { sessionSideWidth } from 'Utils/constants';

interface DrawingPaletteProps {
  onClose: () => void;
}

const DrawingPalette = (props: DrawingPaletteProps) => {
  const agent = useAgent();
  const t = useTranslation('Session');

  const DrawerCotents = styled('div')({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& > div': {
      margin: 'auto !important',
    },
  });
  const resetDrawing = () => {
    agent?.resetDrawingContent();
  };
  const onChangeColor = (color: ColorResult) => {
    const config: DrawConfig = {
      type: 'pen',
      color: color.hex,
      width: 5,
    };
    ((config: DrawConfig) => {
      if (agent) {
        agent.drawConfig = config;
      }
    })(config);
  };

  return (
    <>
      <FloatScrollBox
        sx={{
          height: '100%',
          width: `${sessionSideWidth}px`,
          backgroundColor: (t) => t.palette.grey[200],
        }}
      >
        <ListSubheader
          component="div"
          sx={{
            boxShadow: '#cbcbcb 0 1px 3px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ height: 'max-content' }}
            onClick={() => {
              props.onClose();
            }}
          >
            <CloseIcon sx={{ fontSize: 25, color: '#505050' }} />
          </IconButton>
          {t('controller.pallet')}
        </ListSubheader>

        <DrawerCotents sx={{ p: 2 }}>
          <CirclePicker onChange={onChangeColor} />
          <Button
            size="large"
            title={t('controller.reset')}
            onClick={resetDrawing}
            fullWidth
          >
            <Delete />
            {t('controller.reset')}
          </Button>
        </DrawerCotents>
      </FloatScrollBox>
    </>
  );
};

export default DrawingPalette;
