// このコードは web-ui、snippet で同じコードを使用しています。

import OptipassVideoSDK, {
  OptipassVideoPublishersState,
  OptipassVideoTrack,
} from '@optipass/video-sdk';
import useVideoParams from 'Components/Hooks/VideoParams';
import AudioPresenter from 'Components/Molecules/Sessions/AudioPresenter';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AppError } from 'Utils/error';

export type DeviceList = {
  audioInputDevices: Set<InputDeviceInfo>;
  audioOutputDevices: Set<InputDeviceInfo>;
  videoInputDevices: Set<InputDeviceInfo>;
};

export type MediaStreamTrackWithMid = MediaStreamTrack & {
  mid: string;
};

export type VideoStateType = {
  sdk?: OptipassVideoSDK;
  localVideo?: MediaStreamTrack;
  localAudio?: MediaStreamTrack;
  remoteVideos: Array<MediaStreamTrackWithMid>;
  remoteStatus: OptipassVideoPublishersState;
  tracks: Array<OptipassVideoTrack>;
};

const videoStateInitial: VideoStateType = {
  remoteVideos: [],
  remoteStatus: {},
  tracks: [],
};
const VideoStateContext = createContext<VideoStateType>(videoStateInitial);

export const useVideo = () => useContext(VideoStateContext);

const VideoProvider = (props: {
  controlServerUrl: string;
  children: ReactNode;
}) => {
  const myVideoTrack = useRef<MediaStreamTrack | undefined>(undefined);
  const myAudioTrack = useRef<MediaStreamTrack | undefined>(undefined);
  const otherTracks = useRef<MediaStreamTrackWithMid[]>([]);
  const otherStatus = useRef<OptipassVideoPublishersState>({});
  const tracks = useRef<OptipassVideoTrack[]>([]);
  const sdk = useRef<OptipassVideoSDK | undefined>(undefined);
  const [state, setState] = useState<VideoStateType>(videoStateInitial);
  const videoParams = useVideoParams();
  const videoParamsRef = useRef(videoParams);
  useEffect(() => {
    videoParamsRef.current = videoParams; // useEffect内で使いたいパラメータがあるためref化
  }, [videoParams]);

  const updateState = useCallback(() => {
    setState({
      sdk: sdk.current,
      localAudio: myAudioTrack.current,
      localVideo: myVideoTrack.current,
      remoteVideos: otherTracks.current.filter((x) => x.kind === 'video'),
      remoteStatus: otherStatus.current,
      tracks: tracks.current,
    });
  }, []);

  const controlServerUrl = props.controlServerUrl;

  useEffect(() => {
    if (videoParams.roomId == null) return; // 部屋がない場合は何もしない
    (async () => {
      sdk.current = new OptipassVideoSDK({
        jwt: videoParams.roomId ?? '',
        controlServerUrl: controlServerUrl,
        // displayName: operator?.id ?? '',
        onlocaltrack: (track: MediaStreamTrack, on: boolean) => {
          console.info('VideoProvider: onlocaltrack', track);
          if (track.kind === 'video') {
            if (on) {
              myVideoTrack.current = track;
            } else {
              myVideoTrack.current = undefined;
            }
          } else if (track.kind === 'audio') {
            if (on) {
              myAudioTrack.current = track;
            } else {
              myAudioTrack.current = undefined;
            }
          }
          updateState();
        },
        onremotetrack: (track: MediaStreamTrack, mid: string, on: boolean) => {
          console.info('VideoProvider: onremotetrack', track, mid);
          const index = otherTracks.current.findIndex((x) => x.id === track.id);
          if (on) {
            if (index === -1) {
              otherTracks.current.push(Object.assign(track, { mid }));
            }
          } else {
            otherTracks.current.splice(index, 1); // indexの要素を削除
          }
          updateState();
        },
        onChangePublishersState: () => {
          otherStatus.current = sdk.current?.getPublishersState() ?? {};
          updateState();
        },
        onTrackChanged: () => {
          tracks.current = sdk.current?.getTracks() ?? [];
          updateState();
        },
      });

      console.info('VideoProvider: initialize', videoParams.roomId);
      await sdk.current.initialize();

      const deviceList = await sdk.current.getDeviceList();
      if (Array.from(deviceList.videoInputDevices).length < 1) {
        throw new AppError(
          'No video device available',
          'Biz',
          'SessionVideoCall',
          'Device',
          '03'
        );
      }
      if (Array.from(deviceList.audioInputDevices).length < 1) {
        throw new AppError(
          'No audio device available',
          'Biz',
          'SessionVideoCall',
          'Device',
          '04'
        );
      }

      const video =
        videoParamsRef.current.videoDevice == null
          ? undefined
          : { deviceId: videoParamsRef.current.videoDevice };
      const audio =
        videoParamsRef.current.audioDevice == null
          ? { deviceId: 'communications' } // マイクは通信デバイスを優先
          : { deviceId: videoParamsRef.current.audioDevice };
      await sdk.current.startPublish({ video, audio });

      // ミュート状態を再制御
      if (videoParamsRef.current.isAudioMute === true) sdk.current.muteAudio();
      if (videoParamsRef.current.isVideoMute === true) sdk.current.muteVideo();

      updateState();
    })();

    return () => {
      if (sdk.current != null) {
        const keepSdk = sdk.current;
        sdk.current = undefined;
        (async () => {
          await keepSdk.hangup();
          await keepSdk.destroy();
          await keepSdk.destroyRoom();
        })();
      }
    };
  }, [updateState, videoParams.roomId, controlServerUrl]); //, operator?.id]);

  const audios = otherTracks.current.filter((x) => x.kind === 'audio');

  // console.debug('Update Videos', state);
  // console.debug('Update Audios', audios);
  // console.debug('sdk.tracks', sdk.current?.tracks);

  return (
    <VideoStateContext.Provider value={state}>
      {props.children}
      {audios.map((x) => (
        <AudioPresenter
          track={x}
          key={x.id}
          // deviceId={playerDeviceId !== '' ? playerDeviceId : undefined}
        />
      ))}
    </VideoStateContext.Provider>
  );
};
export default VideoProvider;
