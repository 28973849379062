import { BaseFilter, FacetValue } from './FacetValueBase';

export type ContactReasonFacetValuePayload = { reasons: string[] };
export class ContactReasonFacetValue extends FacetValue<ContactReasonFacetValuePayload> {
  constructor(payload: ContactReasonFacetValuePayload) {
    super('ContactReasonFacet', payload);
  }

  filter(): BaseFilter | undefined {
    if (this.payload.reasons.length === 0) {
      return undefined;
    } else {
      return {
        member: `Users.contextContactReason`,
        operator: 'equals',
        values: this.payload.reasons,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.reasons.length === 0;
  }
}
