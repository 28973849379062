import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MouseOutlinedIcon from '@mui/icons-material/MouseOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Stack, Box } from '@mui/material';
import TooltipFab from 'Components/Atoms/TooltipFab';
import useTranslation from 'Components/Hooks/Translate';

export type CobrowsChangeMode = 'view' | 'control' | 'drawing';

interface SessionMainProps {
  mode: CobrowsChangeMode;
  setMode: (value: CobrowsChangeMode) => void;
  openFileSharing: boolean;
  setOpenFileSharing: (value: boolean) => void;
  onLeave: () => void;
  onDisconnect: () => void;
}

const SessionMain = (props: SessionMainProps) => {
  const t = useTranslation('Session');
  const changeMode = (newMode: CobrowsChangeMode) => {
    if (props.mode === newMode) return;
    props.setMode(newMode);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          width: 'max-content',
          my: 1,
          mx: 'auto',
        }}
      >
        {/* 中央のコントロール */}
        <Stack
          direction="row"
          spacing={1}
          sx={{
            py: 1,
            px: 3,
            backgroundColor: 'primary.main',
            borderRadius: 100,
            boxShadow:
              '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <TooltipFab
            title={t('controller.view')}
            onClick={() => changeMode('view')}
            active={props.mode === 'view'}
            menuButton
          >
            <VisibilityOutlinedIcon fontSize="medium" />
          </TooltipFab>

          <TooltipFab
            title={t('controller.control')}
            onClick={() => changeMode('control')}
            active={props.mode === 'control'}
            menuButton
          >
            <MouseOutlinedIcon fontSize="medium" />
          </TooltipFab>

          <TooltipFab
            title={t('controller.drawing')}
            onClick={() => {
              props.setOpenFileSharing(false);
              changeMode(props.mode !== 'drawing' ? 'drawing' : 'view');
            }}
            active={props.mode === 'drawing'}
            menuButton
          >
            <EditOutlinedIcon fontSize="medium" />
          </TooltipFab>
        </Stack>
        <TooltipFab
          title={t('controller.attach')}
          color={props.openFileSharing ? 'accent' : 'primary'}
          onClick={() => {
            if (!props.openFileSharing && props.mode === 'drawing')
              changeMode('view');
            props.setOpenFileSharing(!props.openFileSharing);
          }}
        >
          <AttachFileIcon fontSize="medium" />
        </TooltipFab>
      </Stack>

      {/* 右端のコントロール */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          mr: 1,
        }}
      ></Stack>
    </Box>
  );
};

export default SessionMain;
