import useCustomer from '../Customer';
import usePageHistories from '../Queries/PageHistories';

export const useViewdPagesNum = () => {
  const customerId = useCustomer()?.userId;
  const pageHistories = usePageHistories(customerId ?? '', false, {
    enabled: customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  return {
    ...pageHistories,
    data: Array.from(new Set(pageHistories.data?.map((p) => p.path) ?? []))
      .length,
  };
};
