import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import dataProvider from 'Utils/dataProvider';
import supabase, { UserProfile, UsersRole } from 'Utils/supabase';

const key = 'User Profiles';

const useUserProfiles = (
  options?: Omit<UseQueryOptions<UserProfile[]>, 'queryKey' | 'queryFn'>
) => {
  const keys = [key];
  return useQuery<UserProfile[]>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      const result = await supabase
        .from<UserProfile>('user_profiles')
        .select('*, jobs(name)')
        .order('name');
      if (result.error != null) throw result.error;

      return result.data;
    },
    options
  );
};
export default useUserProfiles;

export const useInsertUserProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: { name: string; email: string; usersRoleIds: string[] }) =>
      await dataProvider.post('/users/management', {
        email: item.email,
        name: item.name,
        roleIds: item.usersRoleIds,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: { id: string; name: string; usersRoleIds: string[] }) => {
      const result = await supabase
        .from('user_profiles')
        .update({
          name: item.name,
        })
        .eq('id', item.id);
      if (result.error != null) throw result.error;

      // 以前の権限関連の取得
      const userRolesResult = await supabase
        .from<UsersRole>('users_roles')
        .select()
        .match({ userId: item.id });
      if (userRolesResult.error != null) throw userRolesResult.error;
      const oldUsersRoleIds = (userRolesResult.data ?? []).map((x) => x.roleId);

      // 追加されている権限を追加
      for (const addRoleId of item.usersRoleIds.filter(
        (x: any) => oldUsersRoleIds.includes(x) === false
      )) {
        const usersRoleInsertResult = await supabase
          .from<UsersRole>('users_roles')
          .insert({ roleId: addRoleId, userId: item.id });
        if (usersRoleInsertResult.error != null)
          throw usersRoleInsertResult.error;
      }

      // 削除されているユーザーを削除
      for (const removeRoleId of oldUsersRoleIds.filter(
        (x) => item.usersRoleIds.includes(x) === false
      )) {
        const usersRoleRmoveResult = await supabase
          .from<UsersRole>('users_roles')
          .delete()
          .match({ roleId: removeRoleId, userId: item.id });
        if (usersRoleRmoveResult.error != null)
          throw usersRoleRmoveResult.error;
      }

      return result.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useDeleteUserProfiles = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (itemIds: string[]) => {
      await Promise.all(
        itemIds.map((id) => dataProvider.delete(`/users/management/${id}`))
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
      // トランザクションがいまいちなので、エラー時も再取得
      onError: () => queryClient.invalidateQueries(key),
    }
  );
};
