import { Box, Link, styled } from '@mui/material';
import Breadcrumbs from 'Components/Molecules/Global/Breadcrumbs';
import logo from 'Images/logo.png';
import logoText from 'Images/Optipass-text.png';

import LanguageMenu from './LanguageMenu';
import UserMenu from './UserMenu';

const LogoImage = styled('img')(() => ({
  width: '24px',
  height: '24px',
  marginTop: '4px',
  marginLeft: '8px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    animation: 'rotate-anime 10000ms linear infinite',
  },
}));
const TitleImage = styled('img')(() => ({
  marginLeft: '16px',
  marginBottom: '0px',
  height: '20px',
}));

const TopLevelHeader = () => (
  <Box
    sx={{
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '2px',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Link underline="hover" color="inherit" href="/">
        <style>
          {`@keyframes rotate-anime{
              0%   { transform: rotate(0); }
              100% { transform: rotate(-360deg); }
          }`}
        </style>
        <LogoImage src={logo} alt="Optipass" />
        <TitleImage src={logoText} alt="Optipass" />
      </Link>
      <Box ml={2}>
        <Breadcrumbs />
      </Box>
    </Box>
    <Box>
      <LanguageMenu />
      <UserMenu />
    </Box>
  </Box>
);

export default TopLevelHeader;
