import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useReturnVisitsNum } from 'Components/Hooks/SummaryQueries/ReturnVisitsNum';
import useTranslation from 'Components/Hooks/Translate';

import Panel from '../Panel';

const ViewdPageNum = () => {
  const t = useTranslation('Summary');
  const { data, isLoading } = useReturnVisitsNum();

  return (
    <Panel
      icon={<BookmarkIcon />}
      title={t('ReturnVisitsNum.title')}
      content={(data?.toString() ?? '') + t('ReturnVisitsNum.unit')}
      isLoading={isLoading}
    />
  );
};

export default ViewdPageNum;
