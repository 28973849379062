// TODO: 定数は設定値に近いため Utils に置かないことを推奨。
// sessionSideWidth は MUI theme のカスタム値に入れるとよさそう。

/**
 * BI表示設定できるガジェット個数の最大値
 */
export const gadgetsLimit = 20;

/**
 * セッション画面サイドバー幅
 */
export const sessionSideWidth = 280;
