import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { RangeChoosePeriod } from './FacetValue/DateTimeRangeFacetValue';

export const convertFromPeriodExp = (props: RangeChoosePeriod['type']) => {
  let [startDt, endDt] = [new Date(), new Date()];
  startDt.setHours(0, 0, 0, 0);
  endDt.setHours(23, 59, 59, 999);

  const yesterday = () => {
    startDt.setDate(startDt.getDate() - 1);
    endDt.setDate(endDt.getDate() - 1);
  };

  const thisWeek = () => {
    startDt = moment(startDt).startOf('isoWeek').toDate();
    endDt = moment(endDt).endOf('isoWeek').toDate();
  };

  const lastWeek = () => {
    startDt = moment(startDt).subtract(1, 'weeks').startOf('isoWeek').toDate();
    endDt = moment(endDt).subtract(1, 'week').endOf('isoWeek').toDate();
  };

  const thisMonth = () => {
    startDt.setDate(1);
    endDt.setMonth(endDt.getMonth() + 1);
    endDt.setDate(0);
  };

  const lastMonth = () => {
    startDt.setMonth(startDt.getMonth() - 1);
    startDt.setDate(1);
    endDt.setDate(0);
  };

  const thisYear = () => {
    startDt.setMonth(0);
    startDt.setDate(1);
    endDt.setFullYear(endDt.getFullYear() + 1);
    endDt.setMonth(0);
    endDt.setDate(0);
  };

  const lastYear = () => {
    startDt.setFullYear(startDt.getFullYear() - 1);
    startDt.setMonth(0);
    startDt.setDate(1);
    endDt.setMonth(0);
    endDt.setDate(0);
  };

  const handleChange = (selectedChoice: RangeChoosePeriod['type']) => {
    switch (selectedChoice) {
      case 'today':
        break;
      case 'yesterday':
        yesterday();
        break;
      case 'thisWeek':
        thisWeek();
        break;
      case 'lastWeek':
        lastWeek();
        break;
      case 'thisMonth':
        thisMonth();
        break;
      case 'lastMonth':
        lastMonth();
        break;
      case 'thisYear':
        thisYear();
        break;
      case 'lastYear':
        lastYear();
        break;
      default:
    }
  };
  handleChange(props);

  return [startDt, endDt];
};
