import { EventObject } from '@xstate/fsm';
import { Socket } from 'socket.io-client';

import { OptipassSDKConfig } from '../common/common-types';

/* eslint-disable @typescript-eslint/naming-convention */
export const sdkMachine = {
  id: 'sdk',
  initial: 'idle',
  context: {
    debug: 0,
    config: undefined,
    token: undefined,
    socket: undefined,
  },
  states: {
    idle: {
      on: {
        START_INIT: {
          target: 'initializing',
          actions: ['initialize', 'connect'],
        },
        INIT_FAILURE: {
          target: 'failed',
          actions: ['failed'],
        },
      },
    },
    initializing: {
      on: {
        START_AGENT: {
          target: 'agent',
        },
        START_CUSTOMER: {
          target: 'customer',
        },
        INIT_FAILURE: {
          target: 'failed',
          actions: ['failed'],
        },
        DISCONNECT: {
          target: 'disconnected',
          actions: ['disconnect'],
        },
      },
    },
    agent: {
      on: {
        DISCONNECT: {
          target: 'disconnected',
          actions: ['disconnect'],
        },
      },
    },
    customer: {
      on: {
        DISCONNECT: {
          target: 'disconnected',
          actions: ['disconnect'],
        },
      },
    },
    failed: {
      on: {
        RESET: {
          target: 'idle',
          actions: ['reset'],
        },
      },
    },
    disconnected: {
      on: {
        RESET: {
          target: 'idle',
          actions: ['reset'],
        },
      },
    },
  },
};

export type SdkContext = {
  debug: number;
  token?: string;
  config?: OptipassSDKConfig;
  socket?: Socket;
};

export type StartInitEvent = {
  type: 'START_INIT';
  debug: number;
  token: string;
  config: OptipassSDKConfig;
};
type ResetEvent = { type: 'RESET' };
type InitSuccessAgentEvent = { type: 'START_AGENT' };
type InitSuccessCustomerEvent = { type: 'START_CUSTOMER' };
type InitFailureEvent = {
  type: 'INIT_FAILURE';
  error: string;
  serverError?: string;
};
type DisconnectEvent = { type: 'DISCONNECT' };
export type SdkEvent =
  | EventObject
  | StartInitEvent
  | ResetEvent
  | InitSuccessAgentEvent
  | InitSuccessCustomerEvent
  | InitFailureEvent
  | DisconnectEvent;

export type SdkState =
  | {
      value: 'idle';
      context: SdkContext & {
        debug: number;
        token: undefined;
        config: undefined;
        socket: undefined;
      };
    }
  | {
      value: 'failed';
      context: SdkContext & {
        debug: number;
        token: string;
        config: OptipassSDKConfig;
        socket: Socket;
      };
    }
  | {
      value: 'initializing';
      context: SdkContext & {
        debug: number;
        token: string;
        config: OptipassSDKConfig;
        socket: Socket;
      };
    }
  | {
      value: 'agent';
      context: SdkContext & {
        debug: number;
        token: string;
        config: OptipassSDKConfig;
        socket: Socket;
      };
    }
  | {
      value: 'customer';
      context: SdkContext & {
        debug: number;
        token: string;
        config: OptipassSDKConfig;
        socket: Socket;
      };
    }
  | {
      value: 'disconnected';
      context: SdkContext & {
        debug: number;
        token: string;
        config: OptipassSDKConfig;
        socket: Socket;
      };
    };
