import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import GadgetFlame from 'Components/Atoms/GadgetFlame';
import CTANum from 'Components/Atoms/Summaries/CTANum';
import DeviceInfo from 'Components/Atoms/Summaries/DeviceInfo';
import ElapsedTime from 'Components/Atoms/Summaries/ElapsedTime';
import FrequentSearchWord from 'Components/Atoms/Summaries/FrequentSearchWord';
import InputNum from 'Components/Atoms/Summaries/InputNum';
import InterestedPage from 'Components/Atoms/Summaries/InterestedPage';
import ReturnVisitsNum from 'Components/Atoms/Summaries/ReturnVisitsNum';
import SearchNum from 'Components/Atoms/Summaries/SearchNum';
import SessionStartPage from 'Components/Atoms/Summaries/SessionStartPage';
import ViewdPageNum from 'Components/Atoms/Summaries/ViewedPageNum';
import useIid from 'Components/Hooks/Iid';
import useTranslation from 'Components/Hooks/Translate';
import { Job } from 'Utils/supabase';

import useCustomerInputDatas from '../../Hooks/Queries/CustomerInputDatas';
// import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
// import Panel from 'Components/Atoms/Panel';

const Summary = (props: { job?: Job; alias: string }) => {
  const t = useTranslation('Session');
  const iid = useIid();
  const { data } = useCustomerInputDatas(iid ?? '', props.job?.id);
  const customerData = data?.[0]?.customerData;

  return (
    <>
      <Box sx={{ overflow: 'auto', maxWidth: '1190px', mx: 'auto' }}>
        {/* セッション基本情報 */}
        <Stack direction="row" justifyContent="space-evenly" sx={{ my: 5 }}>
          <Stack justifyContent="center">
            <Typography align="center" variant="caption">
              {t('controller.job')}
            </Typography>
            <Typography align="center" variant="h3">
              {props.job?.name}
            </Typography>
          </Stack>
          <Stack justifyContent="center">
            <Typography align="center" variant="caption">
              {t('controller.alias')}
            </Typography>
            <Typography align="center" variant="h3">
              {props.alias}
            </Typography>
          </Stack>
        </Stack>
        {/* パネルセクション */}
        <Box sx={{ p: 1 }}>
          <Stack direction="row" justifyContent="center" flexWrap={'wrap'}>
            <ElapsedTime />
            <ViewdPageNum />
            <SessionStartPage />
            <CTANum />
            <InterestedPage />
            <ReturnVisitsNum />
            <InputNum />
            <DeviceInfo />
            <SearchNum />
            <FrequentSearchWord />
          </Stack>
        </Box>

        {/* 詳細情報 */}
        <Box sx={{ m: 5 }}>
          {/* お客様入力情報表示欄 */}
          <GadgetFlame
            title={
              <Typography
                variant="caption"
                noWrap
                sx={{ color: (t) => t.palette.grey[600] }}
              >
                {t('summary.gadget.customerInput')}
              </Typography>
            }
          >
            {(customerData ?? []).length > 0 ? (
              <Table>
                <TableBody>
                  {customerData?.map((data) => (
                    <TableRow key={data.name}>
                      <TableCell sx={{ tableLayout: 'auto', width: '100px' }}>
                        {data.name}
                      </TableCell>
                      <TableCell>{data.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <>
                <Typography>{t('summary.gadget.noCustomerInput')}</Typography>
              </>
            )}
          </GadgetFlame>
        </Box>
      </Box>
    </>
  );
};

export default Summary;
