import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, IconButton } from '@mui/material';
import { DialogTitleProps } from '@mui/material';

export type DialogTitleWithCloseProps = {
  onClose: () => void;
} & DialogTitleProps;

const DialogTitleWithClose = (props: DialogTitleWithCloseProps) => {
  const { onClose, children, ...dialogTitleProps } = props;
  return (
    <DialogTitle {...dialogTitleProps}>
      {children}
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          position: 'absolute',
          right: 20,
          top: 20,
          color: (t) => t.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
export default DialogTitleWithClose;
