import { Box } from '@mui/material';
import TopLevelHeader from 'Components/Molecules/Global/TopLevelHeader';
import { ReactNode } from 'react';

const TopLevelLayout = (props: { children: ReactNode }) => (
  <Box
    sx={{
      width: 1,
      height: 1,
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      overflow: 'hidden',
    }}
  >
    <Box sx={{ borderBottom: '2px solid #eee' }}>
      <TopLevelHeader />
    </Box>
    <Box gridRow={2}>{props.children}</Box>
  </Box>
);
export default TopLevelLayout;
