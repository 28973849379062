import { useEffect } from 'react';
import { AppError } from 'Utils/error';

import { useNotify } from './Notify';
import useTranslation from './Translate';

export const useErrorLogHandler = () => {
  const notify = useNotify();
  const t = useTranslation('Error');

  useEffect(() => {
    // 同期・非同期のエラー処理共通部分
    const handleError = (e: unknown) => {
      // 予期するエラーでかつ業務エラーであれば通知を表示
      if (e instanceof AppError && e.type === 'Biz') {
        notify(
          t(
            `${e.type}.${e.category}.${e.process}.${e.kinds}${
              e.branch != null ? '.' + e.branch : ''
            }`
          ),
          'error'
        );
      }
    };

    const onErrorEventListener = (e: unknown) => {
      console.error(e);
      handleError(e);
    };
    const unhandledrejectionEventListener = function (e: any) {
      console.error(e);
      handleError(e.reason);
    };

    window.addEventListener('error', onErrorEventListener);
    window.addEventListener(
      'unhandledrejection',
      unhandledrejectionEventListener
    );

    return () => {
      window.removeEventListener('error', onErrorEventListener);
      window.removeEventListener(
        'unhandledrejection',
        unhandledrejectionEventListener
      );
    };
  }, [notify, t]);
};
