import { List, ListSubheader } from '@mui/material';
import FloatScrollBox from 'Components/Atoms/FloatScrollBox';
import Loading from 'Components/Atoms/Loading';
import usePageHistories, {
  PageHistory,
} from 'Components/Hooks/Queries/PageHistories';
import useTranslation from 'Components/Hooks/Translate';
import ActionLogPage from 'Components/Molecules/Sessions/ActionLogPage';
import { ActionLogTrackData } from 'Components/Molecules/Sessions/ActionLogTrack';
import { useEffect, memo } from 'react';
import { groupBy } from 'Utils/array';
import { sessionSideWidth } from 'Utils/constants';

type ActionLogPaneProps = {
  setOpenPlayer: (value: boolean) => void;
  customerId: string;
  onTrackClick?: (value: ActionLogTrackData) => void;
};
const ActionLogPane = (props: ActionLogPaneProps) => {
  const pageHistories = usePageHistories(props.customerId ?? '', true, {
    enabled: props.customerId != null,
    refetchOnMount: false,
    refetchInterval: 10000,
  });

  useEffect(() => {
    const history = document.getElementById('history');
    history?.scrollTo(0, history.scrollHeight);
  }, []);

  // 最適化のため必要な部分だけ渡す
  return (
    <ActionLogPaneMemo
      {...{
        pageHistoriesData: pageHistories.data ?? [],
        pageHistoriesIsLoading: pageHistories.isLoading,
      }}
      {...props}
    />
  );
};

type ActionLogPaneMemoProps = {
  pageHistoriesIsLoading: boolean;
  pageHistoriesData: PageHistory[];
} & ActionLogPaneProps;

// eslint-disable-next-line react/display-name
const ActionLogPaneMemo = memo((props: ActionLogPaneMemoProps) => {
  const t = useTranslation('Session');
  const pages = props.pageHistoriesData;

  // pathでグループ化しつつ再訪問時間を追加
  const pageStayMap = Object.fromEntries(
    groupBy(pages, (x) => x.path).map(([key, items]) => [
      key,
      items.reduce((v, x) => x.endTimestamp - x.originalTimestamp + v, 0),
    ])
  );

  // 合計時間
  const totalTime =
    pages.length === 0
      ? 0
      : pages.slice(-1)[0].endTimestamp - pages[0].originalTimestamp;

  return (
    <FloatScrollBox
      sx={{
        height: 1,
        width: `${sessionSideWidth}px`,
        backgroundColor: (t) => t.palette.secondary.main,
      }}
      id="history"
    >
      {props.pageHistoriesIsLoading ? (
        <Loading />
      ) : (
        <List
          sx={{ py: 0 }}
          subheader={
            // リスト内トップメニュー
            <ListSubheader
              component="div"
              sx={{
                boxShadow: '#cbcbcb 0 1px 3px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {t('controller.log')}
            </ListSubheader>
          }
        >
          {pages.map((page) => {
            // ページ分のtrackを取得
            const pageData = page.tracks.map<ActionLogTrackData>((x) => x);
            // 先頭にページ遷移を追加
            pageData.unshift({
              originalTimestamp: page.originalTimestamp,
              event: 'landing',
            });

            return (
              <ActionLogPage
                key={page.originalTimestamp}
                stayTime={page.endTimestamp - page.originalTimestamp}
                pageLogData={page}
                currentStayRatio={
                  (page.endTimestamp - page.originalTimestamp) / totalTime
                }
                pageStayRatio={pageStayMap[page.path] / totalTime}
                data={pageData}
                onTrackClick={props.onTrackClick}
              />
            );
          })}
        </List>
      )}
    </FloatScrollBox>
  );
});

export default ActionLogPane;
