import DeleteIcon from '@mui/icons-material/Delete';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { IconButton, MenuItem, TextField, Typography } from '@mui/material';
import AutocompleteWithButton from 'Components/Atoms/AutocompleteWithButton';
import useConfirmDelete from 'Components/Hooks/ConfirmDelete';
import { useNotify } from 'Components/Hooks/Notify';
import {
  useDeleteFacetValues,
  useFacetValues,
  useInsertFacetValues,
} from 'Components/Hooks/Queries/FacetValues';
import { useDeleteView, useUpsertView } from 'Components/Hooks/Queries/Views';
import useTranslation from 'Components/Hooks/Translate';
import { useEffect, useState } from 'react';
import { assertNotNull } from 'Utils/guard';
import { Dashboard, View } from 'Utils/supabase';

interface ViewSelectorProps {
  currentDashboard?: Dashboard;
  views?: View[];
  currentView?: View;
  setCurrentView: (view?: View) => void;
  disabled: boolean;
}

const ViewSelector = ({
  currentDashboard,
  views,
  currentView,
  setCurrentView,
  disabled,
}: ViewSelectorProps) => {
  // アクティブビューのファセット設定値を取得（内部でコンテキストを更新している）
  useFacetValues(currentView?.id, {
    enabled: !!currentView,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: upsertView } = useUpsertView();
  const { mutateAsync: deleteView } = useDeleteView();
  const { mutateAsync: insertFacetValues } = useInsertFacetValues();
  const { mutateAsync: deleteFacetValues } = useDeleteFacetValues(
    currentView?.id
  );

  const [inputName, setInputName] = useState('');
  useEffect(() => {
    setInputName(''); // ダッシュボード切替時、いったん入力値を空にする
  }, [currentDashboard]);

  const notify = useNotify();
  const confirmDelete = useConfirmDelete();
  const t = useTranslation('Analytics');

  const handleDeleteView = async (id: string) => {
    assertNotNull(currentView);
    // 削除確認ダイアログを表示します。
    if (await confirmDelete()) {
      await deleteView(id);
      setInputName('');
      notify(t('notify.delete'), 'success');
    }
  };

  const createNewView = async () => {
    if (!inputName || inputName.trim() === '') {
      notify(t('notify.inputName'), 'warning');
      return;
    } else {
      assertNotNull(views);
      // assertNotNull(currentView);
      // ビューの上書き処理
      if (currentView?.name === inputName.trim()) {
        await deleteFacetValues();
        await insertFacetValues(currentView.id);
      }
      // ビューの新規作成処理
      else {
        assertNotNull(currentDashboard);
        const newView = await upsertView({
          name: inputName.trim(),
          dashboardId: currentDashboard.id,
        });
        await insertFacetValues(newView.id);
        setInputName('');
        setCurrentView(newView);
      }
      notify(t('notify.save'), 'success');
    }
  };

  return (
    <AutocompleteWithButton
      size="small"
      multiple={false}
      width={300}
      disabled={disabled || !currentDashboard || !views}
      freeSolo
      inputValue={inputName ?? ''}
      value={currentView ?? null}
      onChange={(event, newView) => {
        if (typeof newView !== 'string') {
          setCurrentView(newView ?? undefined);
        }
      }}
      onInputChange={(event, newInput) => setInputName(newInput)}
      options={views ?? []}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        else return option.name;
      }}
      renderInput={(params) => <TextField {...params} />}
      renderOption={(optionProps, view) => (
        <MenuItem
          {...optionProps}
          key={view.id}
          value={view.name}
          dense={true}
          sx={{ pr: 5 }}
        >
          <Typography sx={{ pr: 5 }} variant="body1">
            {view.name}
          </Typography>
          <IconButton
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              handleDeleteView(view.id);
            }}
            sx={{
              position: 'absolute',
              right: 0,
              color: (t) => t.palette.grey[400],
            }}
          >
            <DeleteIcon sx={{ pr: 0 }} />
          </IconButton>
        </MenuItem>
      )}
      iconButton={<SaveOutlinedIcon />}
      onClickIcon={createNewView}
    />
  );
};
export default ViewSelector;
