import { Backdrop, CircularProgress, Theme } from '@mui/material';
import React, { useCallback } from 'react';

const BackdropLoading = () => {
  const [openCounter, setOpenCounter] = React.useState(0);

  const handleLoadingStart = useCallback(
    (_ev: Event) => {
      setOpenCounter(openCounter + 1);
    },
    [openCounter]
  );
  const handleLoadingEnd = useCallback(
    (_ev: Event) => {
      setOpenCounter(openCounter - 1);
    },
    [openCounter]
  );

  React.useEffect(() => {
    window.addEventListener('loading-start', handleLoadingStart);
    window.addEventListener('loading-end', handleLoadingEnd);
    return () => {
      window.removeEventListener('loading-start', handleLoadingStart);
      window.removeEventListener('loading-end', handleLoadingEnd);
    };
  }, [handleLoadingStart, handleLoadingEnd]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme: Theme) => theme.zIndex.modal + 1 }}
      open={openCounter > 0}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLoading;
