import { IconButton, IconButtonProps } from '@mui/material';
import { ReactElement, SetStateAction } from 'react';

type SidePaneControllButtonProps = {
  open: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
  openedLeftPosition: number;
  closedLeftPosition: number;
  openIcon: ReactElement<any, any>;
  closeIcon?: ReactElement<any, any>;
} & IconButtonProps;

const SidePaneControllButton = (props: SidePaneControllButtonProps) => {
  const {
    open,
    setOpen,
    openedLeftPosition,
    closedLeftPosition,
    openIcon,
    closeIcon,
    sx,
    ...IconButtonProps
  } = props;

  return (
    <>
      <IconButton
        sx={{
          position: 'fixed',
          bottom: 72,
          left: open ? openedLeftPosition : closedLeftPosition,
          backgroundColor: (t) => t.palette.grey[500],
          color: '#fff',
          zIndex: 1200,
          visibility: 'visible',
          boxShadow: (t) => t.shadows[2],
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            backgroundColor: (t) => t.palette.grey[600],
          },
          ...sx,
        }}
        onClick={() => {
          setOpen(!open);
        }}
        {...IconButtonProps}
      >
        {open && closeIcon ? closeIcon : openIcon}
      </IconButton>
    </>
  );
};

export default SidePaneControllButton;
