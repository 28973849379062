import { Box } from '@mui/material';
import AutoScaleContainer from 'Components/Atoms/AutoScaleContainer';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import supabase from 'Utils/supabase';

const useVideoUrl = () => {
  const sessionId = useParams<{ sessionId: string }>()?.sessionId ?? '';
  const keys = ['videoUrl', sessionId];
  return useQuery<string>(
    keys,
    async () => {
      const url = await supabase.storage
        .from('videos')
        .createSignedUrl(`${sessionId}/record.webm`, 60);
      if (url.error) throw url.error;
      if (url.data == null) throw new Error('video url is null');
      return url.data.signedURL;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};

const SessionHistory = () => {
  const videoUrl = useVideoUrl();
  return (
    <>
      {videoUrl.data == null ? (
        <Box m={3}>Recording does not exist.</Box>
      ) : (
        <AutoScaleContainer>
          <video controls src={videoUrl.data} />
        </AutoScaleContainer>
      )}
    </>
  );
};
export default SessionHistory;
