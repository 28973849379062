import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import DialogTitleWithClose from 'Components/Atoms/DialogTitleWithClose';
import { ComponentProps, ReactNode } from 'react';

import useModal, { DialogPropsWithoutOpen } from './Modal';
import useTranslation from './Translate';

export type ConfirmDialogConfig = {
  caption?: string;
  message?: ReactNode;
  content?: ReactNode;
  approveMessage?: string;
  approveColor?: ComponentProps<typeof Button>['color'];
  approveIcon?: ReactNode;
  canCancel?: boolean; // デフォルトはキャンセル無し
  cancelMessage?: string;
  dialogProps?: Omit<DialogPropsWithoutOpen, 'children'>;
};

/** 確認ダイアログ表示を行う */
const useConfirm = () => {
  const t = useTranslation('Global');
  const modal = useModal<boolean>();
  return async (config: ConfirmDialogConfig) =>
    await modal.open({
      ...config.dialogProps,
      children: (
        <>
          <DialogTitleWithClose
            onClose={() => modal.close(config.canCancel ? false : true)}
          >
            {config.caption ?? t('modal.title')}
          </DialogTitleWithClose>
          {config.message == null ? (
            config.content
          ) : (
            <DialogContent>
              <DialogContentText> {config.message} </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            {config.canCancel && (
              <Button
                autoFocus={config.canCancel === true}
                onClick={() => modal.close(false)}
                color="secondary"
              >
                {config.cancelMessage ?? t('modal.cancel')}
              </Button>
            )}
            <Button
              autoFocus={config.canCancel !== true}
              onClick={() => modal.close(true)}
              color={config.approveColor ?? 'primary'}
              startIcon={config.approveIcon}
            >
              {config.approveMessage ?? t('modal.approve')}
            </Button>
          </DialogActions>
        </>
      ),
    });
};
export default useConfirm;
