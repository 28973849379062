import { createClient } from '@supabase/supabase-js';
import { createClient as createClient2 } from '@supabase/supabase-js-new';
import { env } from 'env';
import { definitions } from 'Utils/types';

const supabase = createClient(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  env.REACT_APP_API_SUPABASE_ENDPOINT!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  env.REACT_APP_API_SUPABASE_ANON_KEY!
);
export default supabase;

export const supabaseV2Rc = createClient2(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  env.REACT_APP_API_SUPABASE_ENDPOINT!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  env.REACT_APP_API_SUPABASE_ANON_KEY!
);

export const supabaseEnv = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RESET_TOKEN_KEY_NAME: 'access_token',
};

export type Dashboard = definitions['dashboards'];
export type UserProfile = definitions['user_profiles'];
export type View = definitions['views'];
export type ViewFacetValue = definitions['view_facet_values'];
export type DashboardGadget = definitions['dashboard_gadgets'];
export type Gadget = definitions['dashboard_gadgets']['gadget'];
export type DashboardFacet = definitions['dashboard_facets'];
export type Facet = definitions['dashboard_facets']['facet'];
export type Job = definitions['jobs'];
export type JobsUser = definitions['jobs_users'];
export type ContactReason = definitions['contact_reasons'];
export type ContactReasonCategory = definitions['contact_reason_categories'];
export type JobDashboard = definitions['jobs_dashboards'];
export type SessionHistory = definitions['session_histories'];
export type Tenant = definitions['tenants'];
export type Role = definitions['roles'];
export type RolePermision = definitions['role_permissions'];
export type UsersRole = definitions['users_roles'];
export type PermissionMaster = definitions['permissions'];
