import { Stack } from '@mui/material';
import Accordion from 'Components/Atoms/Accordion';
import LabeledCheckbox from 'Components/Atoms/LabeledCheckbox';
import { definitions } from 'Utils/types';

type ContactReason = definitions['contact_reasons'] & {
  category: definitions['contact_reason_categories'];
};

interface ContactReasonCategoryProps {
  title: string;
  contactReasons: ContactReason[];
  checkedId?: string;
  onCheck: (checkedId?: string) => void;
}
const ContactReasonCategory = (props: ContactReasonCategoryProps) => {
  const { title, contactReasons, checkedId } = props;

  const handleCheck = (reasonId: string, checked: boolean) => {
    props.onCheck(checked ? reasonId : undefined);
  };

  return (
    <Accordion
      aria-controls="panel1a-content"
      id="panel1a-header"
      title={title}
      elevation={3}
    >
      <Stack>
        {contactReasons.map((reason) => {
          return (
            <LabeledCheckbox
              key={reason.id}
              label={reason.name}
              checked={reason.id === checkedId}
              onChange={(e, checked) => handleCheck(reason.id, checked)}
            />
          );
        })}
      </Stack>
    </Accordion>
  );
};
export default ContactReasonCategory;
