import { RealtimeChannel } from '@supabase/supabase-js-new';
import { useCallback, createContext, useContext } from 'react';
import { assertNotNull } from 'Utils/guard';

import useOperator from './Operator';

export type OperatorStatus = 'online' | 'incall' | 'busy' | 'offline';
export type OperatorTrackPayload = {
  operatorId?: string;
  status?: OperatorStatus;
  timestamp: number;
};

export const StatusContext = createContext<OperatorStatus>('offline');
export const useStatusContext = () => useContext(StatusContext);

// ステータス変更
export const useChangeStatus = (channels: RealtimeChannel[] | null) => {
  const operator = useOperator();
  assertNotNull(operator);
  return useCallback(
    (status: OperatorStatus) => {
      // 自分のステータスを全チャネルに通知
      channels?.forEach((channel) => {
        channel.track({
          operatorId: operator.id,
          status,
          timestamp: Date.now(),
        } as OperatorTrackPayload);
      });
    },
    [operator, channels]
  );
};
