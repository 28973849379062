import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import supabase, { ContactReasonCategory } from 'Utils/supabase';

const key = 'Contact Reason Categories';

const useContactReasonCategories = (
  options?: Omit<
    UseQueryOptions<ContactReasonCategory[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const keys = [key];
  return useQuery<ContactReasonCategory[]>(
    keys,
    async () => {
      console.info('Query: ' + keys);
      const query = supabase
        .from<ContactReasonCategory>('contact_reason_categories')
        .select('*, jobs:jobId(name)')
        .order('name');
      const result = await query;
      if (result.error != null) throw result.error;
      return result.data;
    },
    options
  );
};
export default useContactReasonCategories;

export const useInsertContactReasonCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: { name: string; jobId: string }) => {
      const result = await supabase
        .from<ContactReasonCategory>('contact_reason_categories')
        .insert({
          name: item.name,
          jobId: item.jobId,
        });
      if (result.error != null) throw result.error;
      return result.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useUpdateContactReasonCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: { id: string; name: string; jobId: string }) => {
      const result = await supabase
        .from<ContactReasonCategory>('contact_reason_categories')
        .update({
          name: item.name,
          jobId: item.jobId,
        })
        .eq('id', item.id);
      if (result.error != null) throw result.error;
      return result.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};

export const useDeleteContactReasonCategories = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (itemIds: string[]) => {
      const result = await supabase
        .from('contact_reason_categories')
        .delete()
        .in('id', itemIds);
      if (result.error != null) throw result.error;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );
};
