/* eslint-disable @typescript-eslint/naming-convention */

declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_API_GATEWAY: string;
  REACT_APP_API_SUPABASE_ENDPOINT: string;
  REACT_APP_API_SUPABASE_ANON_KEY: string;
  REACT_APP_CUBEJS_API_URL: string;
  REACT_APP_CDP_URL: string;
  REACT_APP_VIDEO_CONTROL: string;
};
export const env: EnvType = { ...process.env, ...window.env };
