import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionProps as MUIAccordionProps,
  AccordionSummary,
  Typography,
} from '@mui/material';
import * as React from 'react';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
  ariaControls?: string;
  id?: string;
  disabled?: boolean;
} & MUIAccordionProps;

const Accordion = (props: AccordionProps) => {
  const { title, children, ariaControls, id, ...muiProps } = props;
  return (
    <MUIAccordion {...muiProps}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={ariaControls}
        id={id}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MUIAccordion>
  );
};

export default Accordion;
